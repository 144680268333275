<template>
  <div>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <GridTable
          :data-rows="showingServerData"
          :headers="headers.massive"
          :headers-for-rows="headersForRows"
          :loading-data="loadingData"
          :num-cols="headers.countCells"
          :max-table-height="maxTableHeight"
          ref="npg-table"
          key="form-2"
      >
        <template #header-npg0="{item}">
          <v-btn  @click="loadFullInfo" class="white__v-btn">{{ !full ? 'Показать все' : "Скрыть пустые" }}  строки </v-btn>
          {{item.text}}
        </template>
        <!--  v-if="!changingHeaders"  :key='' когда меняется хедер проверить    -->
        <template v-for="item in headersForRows" v-slot:[calcHeaderName(item.dataField)]>
          <div :key="item.dataField" :class="item.headerClass" style="max-height: 22px;min-width: 100%">
            <template v-if="item.text === '1'">
              <div style="min-width: 60px">1</div>
              <div class="text-center" style="width: 100%">2</div>
            </template>
            <template v-else>
              <div class="d-flex flex-row align-center" style="max-height: 22px;">
                {{ item.text }}
              </div>
            </template>
          </div>
        </template>
        <template #npg="{row}">
          <template v-if="row.uslName">
            <div class="font-weight-bold font-size-medium ">
              {{ row.uslName[0].toUpperCase() + row.uslName.slice(1) }}
            </div>
          </template>
          <template v-else>
            <div style="">
              {{ row.sprSpec ? row.sprSpec.kodSpec : row.sprKcpGroup.code }}
            </div>
            <div class="ml-3">
              {{ row.sprSpec ? row.sprSpec.name : row.sprKcpGroup.name }}
            </div>
          </template>
        </template>

        <template v-for="item in headersForRowsWithEditableCells" v-slot:[item.dataField]="{row}">
<!--          <threeFourTableCell :key="item.dataField" v-bind="item.props(row,cell, loadingMenu)"-->
<!--                            @open-dialog="openDialog($event, item.dataField, cell, row)"/>-->
          <form2-table-cell :key="item.dataField" :cell="item" :row="row" :value="row[item.dataField]" @change-value-dop="dopChanges($event, row)"></form2-table-cell>
        </template>
        <template #hasAccred="{row}">
          {{ row.hasAccred ? 'да' : 'нет' }}
        </template>
      </GridTable>
      <div class="mt-3 d-flex flex-row justify-center align-center">
        <v-autocomplete
            class="pagination-table"
            v-model="userPages"
            :items="pagesPaginate"
            solo
            dense
            filled
            height="25"
            rounded
            style="max-width: 150px"
            @change="changePage($event)"
        ></v-autocomplete>
        <v-pagination
            v-model="page"
            :length="pagesVuetify"
            :total-visible="7"
            @input="changePage($event)"
        >
        </v-pagination>

      </div>
    </WrapperPreviewCategory>
    <tooltip-forms5-dialog
        ref="tooltip-dialog"
        :data-field="dataFieldTooltip"
    >
    </tooltip-forms5-dialog>
  </div>
</template>

<script>
import GridTable from "@/components/GridTable/GridTable";
import form2 from "@/helpers/forms2-4/form2";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {getFormData} from "@/API/form5";
import {mapGetters} from "vuex";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import {setEditDialog} from "@/mixins/forms2-4/editDialog";
import form2TableCell from "@/components/fields/form2TableCell";
import {tablePagination} from "@/mixins/tablePagination";

export default {
  name: "form2",
  mixins: [headerTooltipFunctions, setEditDialog, tablePagination],
  // eslint-disable-next-line vue/no-unused-components
  components: {
     WrapperPreviewCategory, GridTable, tooltipForms5Dialog, form2TableCell
  },
  data() {
    return {
      serverData: [],
      loadingData: false,
      changingHeaders: false,
      headers: form2,
      editApi: '/api/form2/set',
      full : false
    }
  },
  created() {
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    },
  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_usl_error: 'get_spr_data_usl_error',
    }),
    maxTableHeight() {
      return window.screen.availHeight - 350 + 'px'
    },
    headersForRows() {
      return this.headers.massive.filter(el => el.variable).map(el => {
        return {
          ...el,
          styles: (row) => {
            const color = row.flApproved ? 'background-color: #EBF9EB; color: #29A128;' : row.flApproved === false ? 'color: #ED393E; background-color: #FDEBEB;' : '';

            if (el.dataField !== 'npg')
              return row.uslName ? `${color} display: none!important;` : `${color} justify-content: unset; text-align: center; `
            else
              return `${color}`
          },
          colsDataCalc: (row) => {
            if (el.dataField !== 'npg')
              return 1;
            else
              return row.uslName ? 8 : 1;
          },
          props: (row, cell, loading) => {
            return {
              value: row[cell.dataField],
              title: '',
              cell: cell,
              id: row.id,
              row: row,
              loading: loading,
            }
          }
        }
      })
    },
  },
  methods: {
    loadFullInfo(){
      this.full = !this.full;
      this.loadingDataTable()
    },
    changePage(){
      this.loadingData = true;
      setTimeout(() => {this.loadingData = false;}, 300)
    },
    dopChanges(e){
      const searchIndex = this.serverData.findIndex(el => el.id === e.id);
      if (searchIndex !== -1)
      {
        this.serverData.splice(searchIndex, 1 ,{...this.serverData[searchIndex], ...e,kcpBlock: this.serverData[searchIndex]?.kcpBlock, id: e.id, [e.dataField]: e.value});
      }
      else{
        const newSearchIndex = this.serverData.findIndex(el => el.sprKcpGroup?.id === e.sprKcpGroup?.id);
        this.serverData.splice(newSearchIndex, 1 ,{...this.serverData[newSearchIndex], ...e,kcpBlock: this.serverData[newSearchIndex]?.kcpBlock ,id: e.id, [e.dataField]: e.value});
      }
    },
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getFormData('', true, `/api/form2/get?full=${this.full}`);
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },

  },
}
</script>

<style scoped>

</style>