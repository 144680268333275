<template>
  <div class="appeal">
    <div class="appeal__value appeal__value-header">
      <div class="appeal__header">
        <!--        <div class="appeal__property">Тип: <span class="appeal__span">{{ item.appealType }}</span>-->
        <!--        </div>-->
        <div class="appeal__property">Организация: <span
            class="appeal__span">{{ `(ID: ${item.org.id}) ${item.org.name}` }}</span>
        </div>
        <div class="appeal__property">ID: <span class="appeal__span">{{ item.id }}</span>
        </div>
        <div class="appeal__property">Дата создания апелляции: <span
            class="appeal__span">{{ getCreationDate }}</span>
        </div>
      </div>
    </div>
    <div class="appeal__value">
      <div>{{item.form5KeyId}}</div>
      <div>Количество аппеляций по столбцу: {{item.data.length}}</div>
      <button
          class="white__v-btn v-btn v-btn--contained v-btn--router theme--light v-size--default"
          @click="openAboutAppeal">
        <span class="v-btn__content">Подробная информация</span>
      </button>
    </div>
    <div class="appeal__value appeal__status appeal__value-status">
      <div>Статус: <span class="appeal__span" :class="status.style">{{ status.text }}</span></div>
      <div v-if="getReasonDate"><span class="appeal__dateReason">{{
          getReasonDate
        }}</span></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

moment.locale('ru');
export default {
  props: {
    item: {
      type: Object,
    }
  },
  computed: {
    getCreationDate() {
      if (this.item.date) {
        return moment(this.item.date).format('LLL');
      } else {
        return '-'
      }
    },
    getReasonDate() {
      if (this.item.dateReason) {
        return moment(this.item.dateReason).format('LLL');
      } else {
        return ''
      }
    },
    status() {
      const elementsActive = this.item.data.filter(el => el.accept === null);
      const elementsAgreed = this.item.data.filter(el => el.accept === true);
      const elementsDecline = this.item.data.filter(el => el.accept === false);
      const lengthData = this.item.data.length;
      if (elementsActive.length === lengthData)
        return {
          text: 'На рассмотрении',
          style: 'appeal_not-set'
        }
      else if(elementsAgreed.length === lengthData)
        return {
          text: 'Удовлетворена',
          style: 'appeal_accepted'
        }
      else if (elementsDecline.length === lengthData) {
        return {
          text: 'Отказано',
          style: 'appeal_rejected'
        }
      } else {
        return {
          text: 'Частично принята',
          style: 'appeal_rejected'
        }
      }
    },
  },
  methods: {
    openAboutAppeal() {
      this.$emit('openAboutAppeal', this.item);
    }
  }
}
</script>

<style lang="sass">

</style>