<template>
  <div class="full-indicators-info">
    <alert alert-type="success">
      <div class="d-flex flex-row align-center justify-space-between">
        <v-btn
            class="success__v-btn"
            :loading="downloadNow"
            :disabled="!bloked"
            @click="downloadFile(null,`/api/report/getById/4a6440a0-5739-4602-a5ba-70bd4462d624?orgId=${get_replace_id_admin}`, true, 'Согласование объемов.pdf')"
            >
          Печать
        </v-btn>
        <v-btn
            class="success__v-btn"
            :loading="loadingBlockInfo"
            :disabled="bloked"
            @click="setBlockInfo"
        >
          {{ bloked ? 'Разблокировать' : 'Заблокировать' }}
        </v-btn>
      </div>
    </alert>
   <alert v-if="bloked">
     <scan-card
         class=""
         :item="file"
         :upload-to-serv-now="loadingFileToServer.fileType === file.fileType && loadingFileToServer.value"
         @replace-file="replaceFile($event)"
         @upload-file="uploadFile($event)"
         :token="token"
         identifier="fileType"
     >
     </scan-card>
   </alert>
    <table class="full-indicators-info__table">
      <thead>
      <tr>
        <th>Образовательная организация. УГСН (НПС)</th>
        <th>Очная форма</th>
        <th>Очно-заочная форма</th>
        <th>Заочная форма</th>
      </tr>
      </thead>
      <tbody>
      <template v-if="!loading">
        <tr v-for="(item) in serverData" :key="calcIdRow(item)">
          <td v-if="item.orgFlag" class="full-indicators-info__table-org" colspan="4">{{ item.orgName }}</td>
          <td v-else-if="item.uslName" class="full-indicators-info__table-usl" colspan="4">{{ item.uslName }}</td>
          <template v-else>
            <td>
              <span class="kcp-code">{{ item.kcpGroup.code }}</span>
              <span class="kcp-name">{{ item.kcpGroup.name }}</span>
            </td>
            <td
                @click="openScore(item.id, 1, 1, item.qkcpo23Add, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one  "
                :class="{'kcp-percent-scale__disabled': item.qkcpo23Add == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <div
                    class="d-flex align-center justify-start hh-100 "
                    :class="{'': item.qkcpo23Accept != null && item.qkcpo23Add, 'justify-center': !item.qkcpo23Add}"
                >{{ showingValue(item, 'qkcpo23Add') }}
                </div>
                <div
                    class="tooltip-indicators-decline"
                >
                  <indicators-field-correct
                      v-if="item.qkcpo23Add"
                      :value="item.qkcpo23Accept"
                      data-field="qkcpo23Accept"
                      :row="item"
                      :disabled="bloked"
                      :max-value="item.qkcpo23Add"
                      :loading="editing"
                      @change-value-dop="changeValue($event)"
                  />
                </div>
              </div>
            </td>
            <td
                @click="openScore(item.id, 1, 2, item.qkcpv23Add, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpv23Add == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <div
                    class="d-flex align-center  justify-start hh-100"
                    :class="{'': item.qkcpv23Accept != null && item.qkcpv23Add, 'justify-center': !item.qkcpv23Add}"
                >
                  {{ showingValue(item, 'qkcpv23Add') }}
                </div>
                <div
                    class="tooltip-indicators-decline"
                >
                  <indicators-field-correct
                      v-if="item.qkcpv23Add"
                      :value="item.qkcpv23Accept"
                      data-field="qkcpv23Accept"
                      :row="item"
                      :disabled="bloked"
                      :max-value="item.qkcpv23Add"
                      :loading="editing"
                      @change-value-dop="changeValue($event)"
                  />
                </div>
              </div>

            </td>
            <td
                @click="openScore(item.id, 1, 3, item.qkcpz23Add, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpz23Add == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <div
                    class="d-flex align-center  justify-start hh-100"
                    :class="{'': item.qkcpz23Accept != null && item.qkcpz23Add, 'justify-center': !item.qkcpz23Add}"
                >
                  {{ showingValue(item, 'qkcpz23Add') }}
                </div>
                <div
                    class="tooltip-indicators-decline"
                >
                  <indicators-field-correct
                      v-if="item.qkcpz23Add"
                      :value="item.qkcpz23Accept"
                      data-field="qkcpz23Accept"
                      :max-value="item.qkcpz23Add"
                      :row="item"
                      :disabled="bloked"
                      :loading="editing"
                      @change-value-dop="changeValue($event)"
                  />
                </div>
              </div>
            </td>
          </template>
        </tr>

      </template>
      <template v-else>
        <tr>
          <td class="full-indicators-info__table-org" rowspan="4" colspan="4">
            <v-progress-linear indeterminate></v-progress-linear>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <!--    <confirm-dialog ref="agree-dialog" text="Вы уверены?"/>-->
  </div>
</template>

<script>
import {calcBackground} from "@/utils/indicators";
import {
  getAllGroupedKcpIndicatorsStage1CorrectValues,
  getBlockCorrectIndicatorInfo,
  putCorrectValueAppeal, setBlockCorrectIndicatorInfo
} from "@/API/indicators";
import IndicatorsFieldCorrect from "@/components/fields/indicatorsFieldCorrect";
import Alert from "@/components/UI/Alert";
import {setFileFunctions} from "@/mixins/fileFucntions";
import {mapGetters} from "vuex";
import ScanCard from "@/components/cards/scanCard";
import request from "@/services/request";


export default {
  name: "allListStage1CorrectValues",
  components: {ScanCard, Alert, IndicatorsFieldCorrect},
  mixins: [setFileFunctions],
  data() {
    return {
      serverData: [],
      loading: false,
      loadingBlockInfo: false,
      block: false,
      editing: false,
      downloadNow: false,
    }
  },
  computed: {
    bloked() {
      return !!this.block?.isBlocked
    },
    ...mapGetters('auth', {get_replace_id_admin:'get_replace_id_admin'})
  },
  created() {
    this.getServerData();
    this.getBlockInfo();
    this.getDocuments();
  },
  methods: {
    async getDocuments() {
      this.loadedFiles = false;
      const res = await request({
        endpoint: `/api/file/scan/list`,
        method: 'GET',
      });
      const file = res.data.find(el => el.fileType === 'KCP_ADD_ACCEPT');
      this.file = {...this.file, id: file?.id ?? null, fileType: 'KCP_ADD_ACCEPT' };
      this.loadedFiles = true;
    },
    async getServerData() {
      try {
        this.loading = true;
        this.serverData = await getAllGroupedKcpIndicatorsStage1CorrectValues();
        this.loading = false;
      } catch (e) {
        console.error(e)
        this.loading = false;
      }
    },
    async getBlockInfo() {
      try {
        this.loadingBlockInfo = true;
        this.block = await getBlockCorrectIndicatorInfo();
        this.loadingBlockInfo = false;
      } catch (e) {
        console.error(e)
        this.loadingBlockInfo = false;
      }
    },
    async setBlockInfo() {
      try {
        this.loadingBlockInfo = true;
        const payload = this.block;
        payload.isBlocked = !payload.isBlocked;
        this.block = await setBlockCorrectIndicatorInfo(payload);
        this.loadingBlockInfo = false;
      } catch (e) {
        console.error(e)
        this.loadingBlockInfo = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changeValue(e) {
      try {
        this.editing = true;
        const index = this.serverData.findIndex(el => el.id === e.id);
        const obj = this.serverData[index];
        obj[e.dataField] = e.value;
        const res = await putCorrectValueAppeal(obj);
        this.serverData.splice(index, 1, res);
        this.editing = false;
      } catch (e) {
        this.editing = false;
        console.error(e)
      }
    },
    calcBackground(value, maxValue, minValue = 0) {
      return calcBackground(value, maxValue, minValue)
    },
    // eslint-disable-next-line no-unused-vars
    openScore(rowId, s0b, appForm, score, usl, kcp, org) {
      // const {id, code, name} = kcp;
      // if (score != null)
      //   this.$router.push({path: '/indicators/details', query: {fo: appForm, id: rowId,kcpId : id, code: code, name: name, value: score, usl: usl, org: org}})
    },
    showingValue(item, dataField) {
      return item[dataField] == null ? 'нет заявки' : item[dataField]/*.toFixed(3)*/
    },
    calcIdRow(item) {
      return item.kcpGroup?.id ? Math.random() + '-' + (item.kcpGroup.id + '-' + item.kcpGroup.usl.id + '-' + item.orgId) : (Math.random() + '-' + item.orgId)
    }
  },
}
</script>

<style lang="sass">
.tooltip-indicators-decline
  display: block
  position: absolute
  right: 0
  top: 0

.input-text-center-dop
  //.v-text-field__details
  //  display: none

  .v-input__slot
  //&:before
  //  border: 0!important

  input
    font-size: 14px
    color: #455A64 !important
    text-align: center

    &::placeholder
      font-size: 12px
      color: #455A64
</style>