<template>
<div class="d-flex flex-column align-center justify-center fraction">
  <div class="fraction-top" :style="this.needBottomLine ? ' border-bottom: 1px solid #78909C' : ''">
    <slot>

    </slot>
  </div>
  <div class="fraction-bottom">
    <slot name="bottom">

    </slot>
  </div>
</div>
</template>

<script>
export default {
  name: "fomuls-fraction",
  props: {
    needBottomLine: {
      type: Boolean,
      default: true
    },
  },
}
</script>

<style scoped>

</style>