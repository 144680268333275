export default {
    countCells: 13,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 3,
            text: 'Код и наименование УГСН (НПС)',
            class: 'd-flex flex-row align-center justify-start ',
            headerClass: 'align-end justify-start',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
            // template: (row) => {
            //     return row.sprKcpGroupId + ' - ' + row.sprKcpGroupName
            // }
        },
        {
            dataField: 'npg11',
            cols: 12,
            rows: 1,
            text: 'Контрольные цифры приема, установленные образовательной организации\t\t\t\t\t\t\t\t\t\t\t\n',
            headerClass: 'border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg16',
            cols: 3,
            rows: 1,
            text: "на 2018/2019 учебный год\t\t\n",
            headerClass: 'border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg12',
            cols: 3,
            rows: 1,
            text: 'на 2019/2020 учебный год',
            headerClass: 'border-bottom-zero',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 3,
            rows: 1,
            text: "на 2020/2021 учебный год\t\t\n",
            headerClass: 'border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg14',
            cols: 3,
            rows: 1,
            text: `на 2021/2022 учебный год`,
            headerClass: 'border-bottom-zero',
            order: 4,
            variable: false,
        },
        {
            dataField: 'npg31',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg32',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            headerClass: 'text-center',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg33',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg34',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg35',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg36',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg37',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg38',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg39',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg310',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg311',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg312',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            text: `1`,
            order: 6,
            variable: true,
            class: 'justify-start d-flex flex-row',
        },
        {
            dataField: 'qkcpo18K21',
            cols: 1,
            rows: 1,
            text: `3`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpv18K21',
            cols: 1,
            rows: 1,
            text: `4`,
            order: 8,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpz18K21',
            cols: 1,
            rows: 1,
            text: `5`,
            order: 9,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpo19K21',
            cols: 1,
            rows: 1,
            text: `6`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpv19K21',
            cols: 1,
            rows: 1,
            text: `7`,
            order: 8,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpz19K21',
            cols: 1,
            rows: 1,
            text: `8`,
            order: 9,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpo20K21',
            cols: 1,
            rows: 1,
            text: `9`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpv20K21',
            cols: 1,
            rows: 1,
            text: `10`,
            order: 8,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpz20K21',
            cols: 1,
            rows: 1,
            text: `11`,
            order: 9,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpo21K21',
            cols: 1,
            rows: 1,
            text: `12`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpv21K21',
            cols: 1,
            rows: 1,
            text: `13`,
            order: 8,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
        {
            dataField: 'qkcpz21K21',
            cols: 1,
            rows: 1,
            text: `14`,
            order: 9,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
              styles: 'justify-content: unset; text-align: center;',
            props: (row, cell, loading) => {
                return {
                    value: row[cell.dataField],
                    title: '',
                    cell: cell,
                    id: row.id,
                    row: row,
                    loading: loading,
                }
            },
        },
    ]
}
