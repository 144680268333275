<template>
  <div>
    <v-btn
        icon
        @click="showMenu = !showMenu"
    >
      <v-icon

          :size="15"
          class="grey-light-color__important "
      >
        mdi-information
      </v-icon>
    </v-btn>
    <v-dialog
        v-model="showMenu"
        scrollable
        max-width="950"
    >
      <v-card>
       <span>
        <v-btn
            icon
            @click="showMenu = !showMenu"
            style="position: absolute; right:0;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
<!--        <v-card-title >-->
          <h2 class="font-size-large mb-0 px-5 mt-5" v-text="shortText"></h2>
<!--        </v-card-title>-->
        <v-card-text class="text-justify formatted-text-tooltip" v-html="formattedText">
<!--          <span-->
<!--              class="span-podrobnee"-->
<!--              @click="showFull = !showFull"-->
<!--          >-->
<!--          {{ showFull? 'скрыть': 'подробнее' }}-->
<!--        </span>-->
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: "tooltipDialog",
  props: {
    shortText: {
      type: String,
      default: ''
    },
    fullText: {
      type: String,
      default: ''
    },
  },
  computed: {
    formattedText() {
      return this.fullText/*.replace(/(?:\r\n|\r|\n)/g, '<br />');*/
    },
  },
  data() {
    return {
      showMenu: false,
      showFull : false
    }
  },
}
</script>

<style lang="sass">
.span-podrobnee
  color: #00559b
  text-decoration: underline
.formatted-text-tooltip
  p
    margin-bottom: 12px
  >ul
    li
     list-style-type: disc
     &:last-child
       margin-bottom: 12px
</style>