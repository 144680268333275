<template>
  <div>
    <header-main  :subtitle="subtextBodyHeader">
      <template v-if="thirdText">
        <span style=" font-family: 'Arial'; color: #00599B; font-size: 12px">{{thirdText}}</span>
      </template>
    </header-main>
    <component :is="componentName" :text-body-header="textBodyHeader"/>
  </div>
</template>

<script>
import inProgress from '@/components/InProgress'
import HeaderMain from "@/components/headers/HeaderMain";
import formA from "@/View/dopForms/formA";
import formG from "@/View/dopForms/formG";
import formB from "@/View/dopForms/formB";
import formV from "@/View/dopForms/formV";

export default {
  name: "dopFormsForms",
  components: {
    HeaderMain,
    inProgress,
    'form-a': formA,
    'form-g': formG,
    'form-b': formB,
    'form-v': formV,
  },
  data() {
    return {
      componentName: 'inProgress',
      textBodyHeader: '' ,
      subtextBodyHeader: '',
      thirdText: '',
      defaultComponent: {name: 'inProgress', formId: 'inProgress', text: 'В процессе разработки'},
      componentsData: [
        {name: 'form-a', formId: '/form-a', text: 'Таблица A.', textBodyHeader: 'Сведения о трудоустройстве выпускников (независимо от формы обучения)',
          subtextBodyHeader: 'расчет на основе данных подсистемы «Анализ трудоустройства граждан» информационно-аналитической системы Общероссийская база вакансий «Работа в России» (Минтруд России)'},
        {name: 'form-b', formId: '/form-b', text: 'Таблица Б.', textBodyHeader: 'Численность выпускников, имевших в году, следующем за годом завершения обучения (выпуска), последнее место трудоустройства по субъектам РФ (независимо от формы обучения)',
          subtextBodyHeader: 'расчет на основе данных подсистемы «Анализ трудоустройства граждан» информационно-аналитической системы Общероссийская база вакансий «Работа в России» (Минтруд России)'},
        {name: 'form-v', formId: '/form-v', text: 'Таблица В.', textBodyHeader: 'Среднемесячный размер заработной платы выпускников по УГСН (НПС), имевших в году, следующем за годом завершения обучения (выпуска), последнее место трудоустройства по субъектам РФ (независимо от формы обучения)',
          subtextBodyHeader: 'расчет на основе данных подсистемы «Анализ трудоустройства граждан» информационно-аналитической системы Общероссийская база вакансий «Работа в России» (Минтруд России)'},
        {name: 'form-g', formId: '/form-g', text: 'Таблица Г.', textBodyHeader: 'Перечень научно-исследовательских, опытно-конструкторских и технологических работ (НИОКТР) срок реализации которых на 1 января года проведения конкурса не истек',
          subtextBodyHeader: 'по данным ЕГИСУ НИОКТР'},
      ]
    }
  },
  created() {
    this.initComponent();
  },
  watch: {
    $route() {
      this.initComponent();
    }
  },
  methods: {
    initComponent() {
      const form = this.$route.path;
      const component = this.componentsData.find(el => form === el.formId);
      this.componentName = component?.name ?? this.defaultComponent.name;
      this.textBodyHeader = (component?.text + ' '+  component?.textBodyHeader) ?? this.defaultComponent.text;
      this.subtextBodyHeader = component?.subtextBodyHeader;
      this.thirdText = component?.html;
      this.$route.meta.title = this.textBodyHeader ?? this.defaultComponent.text;
    }
  },
}
</script>

<style scoped>

</style>