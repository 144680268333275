<template>
  <div class="full-indicators-info">
    <div>
      <router-link to="/"><v-btn>Назад</v-btn></router-link>
    </div>
    <table class="full-indicators-info__table full-indicators-info__table-region">
      <thead>
      <tr>
        <th style="border-bottom: 0"></th>
        <th style="grid-column: span 3; border-bottom: 0">Распределение КЦП</th>
        <th style="grid-column: span 3; border-bottom: 0">Оценка заявки</th>
      </tr>
      <tr>
        <th>Образовательная организация. УГСН (НПС)</th>
        <th>Очная форма</th>
        <th>Очно-заочная форма</th>
        <th>Заочная форма</th>
        <th>Очная форма</th>
        <th>Очно-заочная форма</th>
        <th>Заочная форма</th>
      </tr>
      </thead>
      <tbody>
      <template v-if="!loading">
        <tr v-for="(item) in serverData" :key="calcIdRow(item)">
          <td v-if="item.orgFlag" class="full-indicators-info__table-org" colspan="4">{{ item.orgName }}</td>
          <td v-else-if="item.uslName" class="full-indicators-info__table-usl" colspan="4">{{ item.uslName }}</td>
          <template v-else>
            <td>
              <span class="kcp-code">{{ item.kcpGroup.code }}</span>
              <span class="kcp-name">{{ item.kcpGroup.name }}</span>
            </td>
            <td
                @click="openScore(item.id, 1, 1, item.qkcpo23, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpo23 == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <span
                    class="d-flex align-center  justify-center hh-100"
                >{{ showingValue(item, 'qkcpo23') }}
                </span>
                <span
                    class="tooltip-indicators-decline"
                >
          </span>
              </div>
            </td>
            <td
                @click="openScore(item.id, 1, 2, item.qkcpv23, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpv23 == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <span
                    class="d-flex align-center  justify-center hh-100"
                > 
                  {{ showingValue(item, 'qkcpv23') }}
                </span>
                <span
                    class="tooltip-indicators-decline"
                >
          </span>
              </div>
             
            </td>
            <td
                @click="openScore(item.id, 1, 3, item.qkcpz23, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpz23 == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <span
                    class="d-flex align-center  justify-center hh-100"
                > 
                  {{ showingValue(item, 'qkcpz23') }}
                </span>
                <span
                    class="tooltip-indicators-decline"
                >
          </span>
              </div>
            </td>
            <td @click="openScore(item.id, 1, 1, item.iokio22, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)" class="kcp-percent-scale" :class="{'kcp-percent-scale__disabled': item.iokio22 == null}" :style="calcBackground(item.iokio22,item.iowgt22)">
              {{ showingValue(item, 'iokio22')}}
            </td>
            <td @click="openScore(item.id, 1, 2, item.iokiv22, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)" class="kcp-percent-scale" :class="{'kcp-percent-scale__disabled': item.iokiv22 == null}" :style="calcBackground(item.iokiv22,item.iowgt22)">
              {{showingValue(item, 'iokiv22') }}
            </td>
            <td @click="openScore(item.id, 1, 3, item.iokiz22, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)" class="kcp-percent-scale" :class="{'kcp-percent-scale__disabled': item.iokiz22 == null}" :style="calcBackground(item.iokiz22,item.iowgt22)">
              {{showingValue(item, 'iokiz22') }}
            </td>
          </template>
        </tr>

      </template>
      <template v-else>
        <tr>
          <td class="full-indicators-info__table-org" rowspan="4" colspan="4">
            <v-progress-linear indeterminate></v-progress-linear>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <confirm-dialog ref="agree-dialog" text="Вы уверены?"/>
  </div>
</template>

<script>
import {calcBackground} from "@/utils/indicators";
import {getAllGroupedKcpIndicatorsStage1Regions, getAllGroupedKcpIndicatorsByRegion} from "@/API/indicatorsRegion";
import ConfirmDialog from "@/components/Dialogs/confirmDialog";
import {mapActions} from "vuex";


export default {
  name: "allListStage1",
  components: {ConfirmDialog},
  data() {
    return {
      serverData: [],
      loading: false
    }
  },
  created() {
    this.replaceId(this.$route.query.orgId);
    this.getServerData();
  },
  computed: {
    orgId() {
      return this.$route.query?.orgId
    }
  },
  methods: {
    ...mapActions('auth', {getUserInfo: 'getUserInfo', replaceId: 'replaceId'}),
    async getServerData() {
      try {
        this.loading = true;
        const aaa = await getAllGroupedKcpIndicatorsStage1Regions();
        const newData = await getAllGroupedKcpIndicatorsByRegion();
        this.serverData = aaa.map(el => {
          if (el.orgFlag || el.uslName) return el;
          const findEl = newData.find(el2 => el2.kcpGroup.id === el.kcpGroup.id);
          return {...el,...findEl}
        })
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    calcBackground(value, maxValue, minValue = 0) {
      return calcBackground(value, maxValue, minValue)
    },
    // eslint-disable-next-line no-unused-vars
    openScore(rowId, s0b, appForm, score, usl, kcp, org) {
      const {id, code, name} = kcp;
      if (score != null)
        this.$router.push({path: '/indicators/details', query: {fo: appForm, id: rowId,kcpId : id, code: code, name: name, value: score, usl: usl, org: org}})
    },
    showingValue(item, dataField) {
      return item[dataField] == null ? 'нет заявки' : item[dataField]/*.toFixed(3)*/
    },
    calcIdRow(item) {
      return item.kcpGroup?.id ? Math.random() + '-' + (item.kcpGroup.id + '-' + item.kcpGroup.usl.id + '-' + item.orgId) : (Math.random() + '-' + item.orgId)
    }
  },
}
</script>

<style lang="sass" scoped>
.tooltip-indicators-decline
  display: block
  position: absolute
  right: 0
  top: 0

</style>