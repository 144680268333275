import request from "@/services/request";

async function getOrgInfo(commit, state, dispatch, id = state.user.orgID, api = `/api/spr/org/getByID?orgID=${id}`, role = 'org') {
    try {
        const res = await request({
            endpoint: api,
            method: 'get'
        })
        commit('USER_INFO_SUCCESS', res.data);
        if (role === 'org')
            commit('checkFilled/ADD_STATUS_CHECK_FILLED_CONTACTS', res.data.haveContacts, {root: true})
        else
            dispatch('checkFilled/fetchCheckFilled', res.data?.contacts ?? [], {root: true})
        commit('USER_INFO_LOADING', false);
    } catch (e) {
        dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'Ошибка получения данных по пользователю',
            timeout: 5000
        }, {root: true})
        commit('USER_INFO_ERROR', e);
        commit('USER_INFO_LOADING', false);
    }
}

export default {
    namespaced: true,
    state: {
        status: false,
        user: null,
        userInfo: null,
        loading: false,
        accessToken: null,
        refreshToken: null,
        error: null,
        blockedEnterData: false,
        replaceIdAdmin: null,
        replaceId: null,
        replaceNameAdmin: null,
        replaceIdTypeOrg: null,
        replaceRoleIdAdmin: null,
        actualOrgStatus: null,
        loadingUserInfo: false,
        errorUserInfo: null,
        isAdmin: false
    },
    actions: {
        async blockEnterData({commit}) {
            commit('BLOCK_USER_DATA', true)
            // await dispatch('getUserInfo');
        },
        async getUserInfo({commit, dispatch, state}) {
            commit('USER_INFO_LOADING', true);
            if (state.user?.realm_access.roles.includes("ADMIN_ROLE"))
                commit('ADMIN_AUTH', true)
            else if (state.user?.realm_access.roles.includes("FOUNDER_ROLE")) {
                dispatch('replaceIdAdmin', {id: state.user.founderID, role: 'founder'})
                await getOrgInfo(commit, state, dispatch, state.user.orgID, `/api/spr/founder/getById/${state.user.founderID}`, 'founder')
                // commit('USER_INFO_SUCCESS', {founderId: state.user.founderID, founderName: state.user.given_name});
            } else if (state.user?.realm_access.roles.includes("REGION_ROLE")) {
                dispatch('replaceIdAdmin', {id: state.user.regionID, role: 'region'})
                commit('USER_INFO_SUCCESS', {founderId: state.user.regionID, founderName: state.user.given_name});
            } else if (state.user?.realm_access.roles.includes("ORGANIZATION_ROLE")) {
                dispatch('replaceIdAdmin', {id: state.user.orgID, role: 'org'})
                await getOrgInfo(commit, state, dispatch, state.user.orgID, `/api/spr/org/getByID?orgID=${state.user.orgID}`, 'org')
            }
        },
        setUserInfoData({commit}, payload) {
            commit('USER_INFO_SUCCESS', payload);
        },
        async replaceIdAdmin({commit}, payload) {
            commit('REPLACE_ID_ADMIN', payload?.id);
            commit('REPLACE_ID', payload?.id);
            commit('REPLACE_NAME_ADMIN', payload?.name ?? 'Администратор');
            commit('REPLACE_ID_TYPE_ORG_ADMIN', payload?.sprOrgType);
            commit('REPLACE_ROLE_ID_ADMIN', payload?.role)
            // if (payload.id && state.isAdmin) {
            //     if (payload.role === 'org')
            //         await getOrgInfo(commit, state, dispatch, payload.id, `/api/spr/org/getByID?orgID=${payload.id}`)
            //     else if (payload.role === 'founder')
            //         await getOrgInfo(commit, state, dispatch, payload.id, `/api/spr/founder/getById/${payload.id}`)
            //     else if (payload.role === 'region')
            //         commit('USER_INFO_SUCCESS', {regionID: payload.id, regionName: payload.name});
            // }
        },
        async replaceId({commit, }, payload) {
            commit('REPLACE_ID', payload);
        },
        async logout({commit}) {
            commit('LOG_OUT');
            localStorage.removeItem('auth');
            // await router.replace('/login');
            commit('LOADING', false)
        },
    },
    mutations: {
        ['AUTHENTICATION_SUCCESS'](state, payload) {
            state.status = payload?.status ?? null;
            state.accessToken = payload?.token ?? null;
            state.refreshToken = payload?.refreshToken ?? null;
            state.user = payload?.user ?? null;
        },
        ['ADMIN_AUTH'](state, payload) {
            state.isAdmin = payload
        },
        ['USER_INFO_SUCCESS'](state, payload) {
            state.userInfo = payload;
            state.errorUserInfo = null;
        },
        ['LOG_OUT'](state) {
            state.status = false;
            state.user = null;
            state.error = null;
            state.accessToken = null;
            state.userInfo = null;
            state.refreshToken = null;
            state.errorUserInfo = null;
        },
        ['LOADING'](state, payload) {
            state.loading = payload
        },
        ['USER_INFO_ERROR'](state, payload) {
            state.errorUserInfo = payload;
        },
        ['USER_INFO_LOADING'](state, payload) {
            state.loadingUserInfo = payload
        },
        ['BLOCK_USER_DATA'](state, payload) {
            state.blockedEnterData = payload
        },
        ['REPLACE_ID_ADMIN'](state, payload) {
            state.replaceIdAdmin = payload
        },
        ['REPLACE_ID'](state, payload) {
            state.replaceId = payload
        },
        ['REPLACE_NAME_ADMIN'](state, payload) {
            state.replaceNameAdmin = payload
        },
        ['REPLACE_ID_TYPE_ORG_ADMIN'](state, payload) {
            state.replaceIdTypeOrg = payload
        },
        ['REPLACE_ROLE_ID_ADMIN'](state, payload) {
            state.replaceRoleIdAdmin = payload
        },
    },
    getters: {
        isAdmin(state) {
            return state.isAdmin
        },
        replaceNameAdmin(state) {
            return state.replaceNameAdmin
        },
        authData(state) {
            return state.user
        },
        getAuthUserData(state) {
            return state.userInfo
        },
        getAuthUserDataLoading(state) {
            return state.loadingUserInfo
        },
        getAuthUserDataError(state) {
            return state.errorUserInfo
        },
        getAuthLoading(state) {
            return state.loading
        },
        get_blockedAll(state) {
            return state.blockedAll
        },
        get_replace_id_admin(state) {
            return state.replaceIdAdmin
        },
        get_replace_id(state) {
            return state.replaceId
        },
    },
}
