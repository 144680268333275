<template>
  <div>
    <div class='table' v-if="!loadingData">
      <template >
      <div class="table__item table__item-header background bottom-line without-line2 text-center right-line left-line" style="min-width: 400px;">Уровни и формы образования
      </div>
      <div class="table__item table__item-header background right-line bottom-line without-line3 text-center">Всего обучающихся</div>
      <div class="table__item table__item-header background right-line bottom-line without-line4 text-center">Обучающихся</div>
      <div class="table__item table__item-header background right-line bottom-line without-line5 text-center right-line">Обучающихся -
        иностранных граждан
      </div>

      <div class="table__item table__item-header right-line bottom-line top-line without-line3 text-center ">(вкл. квоту иностр.гражд.)</div>
      <div class="table__item table__item-header right-line bottom-line top-line without-line4 text-center ">(искл. квоту иностр.гражд.)
      </div>
      <div class="table__item table__item-header right-line bottom-line text-center top-line">всего</div>
      <div class="table__item table__item-header right-line bottom-line without-line6 text-center top-line">на усл.общего приема</div>
      <div class="table__item table__item-header right-line bottom-line without-line7 text-center top-line right-line">обучающихся по
        квоте
      </div>

      <div class="table__item table__item-header top-line right-line bottom-line text-center">на 1.01.2018 г.</div>
      <div class="table__item table__item-header top-line right-line bottom-line text-center">на 1.01.2019 г.</div>
      <div class="table__item table__item-header top-line right-line bottom-line text-center">на 1.01.2020 г.</div>
      <div class="table__item table__item-header top-line right-line bottom-line text-center">на 1.01.2021 г.</div>
      <div class="table__item table__item-header top-line right-line bottom-line text-center">на 1.01.2019 г.</div>
      <div class="table__item table__item-header top-line right-line bottom-line text-center">на 1.01.2020 г.</div>
      <div class="table__item table__item-header top-line right-line bottom-line text-center">на 1.01.2021 г.</div>
      <div class="table__item table__item-header top-line right-line bottom-line text-center">на 1.01.2020 г.</div>
      <div class="table__item table__item-header top-line right-line bottom-line text-center right-line">на 1.01.2021 г.</div>

      <div class="table__item pa-0 table__item-header bottom-line text-center left-line ">1</div>
      <div class="table__item pa-0 table__item-header left-line right-line bottom-line text-center">2</div>
      <div class="table__item pa-0 table__item-header right-line bottom-line text-center">3</div>
      <div class="table__item pa-0 table__item-header right-line bottom-line text-center">4</div>
      <div class="table__item pa-0 table__item-header right-line bottom-line text-center">5</div>
      <div class="table__item pa-0 table__item-header right-line bottom-line text-center">6</div>
      <div class="table__item pa-0 table__item-header right-line bottom-line text-center">7</div>
      <div class="table__item pa-0 table__item-header right-line bottom-line text-center">8</div>
      <div class="table__item pa-0 table__item-header right-line bottom-line text-center">9</div>
      <div class="table__item pa-0 table__item-header right-line bottom-line text-center">10</div>
      <div class="table__item pa-0 table__item-header right-line right-line bottom-line text-center">11</div>

      <div class="table__item bottom-line left-line">Ординатура</div>
      <div class="table__item left-line right-line bottom-line text-end"> все формы обучения</div>
        <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(0,4)" :key="item.name">
          <fives-table-cell
              v-bind="calcPropsForCell(item.name)"
               style="flex: 1 0"
              @open-dialog="openDialog($event, item.name)"/>
           <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
        </div>
      <div class="table__item right-line bottom-line d-flex justify-center align-center  text-center">
        x
      </div>
      <div class="table__item right-line bottom-line  d-flex justify-center align-center  text-center">
        x
      </div>
        <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(4,7)" :key="item.name">
          <fives-table-cell
              v-bind="calcPropsForCell(item.name)"
               style="flex: 1 0"
              @open-dialog="openDialog($event, item.name)"/>
           <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
        </div>

      <div class="table__item bottom-line left-line">Ассистентура-стажировка</div>
      <div class="table__item left-line right-line bottom-line text-end">все формы обучения</div>
        <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(7,11)" :key="item.name">
          <fives-table-cell
              v-bind="calcPropsForCell(item.name)"
               style="flex: 1 0"
              @open-dialog="openDialog($event, item.name)"/>
           <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
        </div>
      <div class="table__item right-line bottom-line text-center d-flex justify-center align-center ">
       x
      </div>
      <div class="table__item right-line bottom-line text-center d-flex justify-center align-center ">
        x
      </div>
        <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(11,14)" :key="item.name">
          <fives-table-cell
              v-bind="calcPropsForCell(item.name)"
               style="flex: 1 0"
              @open-dialog="openDialog($event, item.name)"/>
           <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
        </div>
      <div class="table__item left-line">Аспирантура</div>
      <div class="table__item left-line right-line text-end">все формы обучения</div>
      <div class="table__item right-line d-flex justify-center align-center text-center">
       x
      </div>
        <div class='table__item  right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(14,22)" :key="item.name">
          <fives-table-cell
              v-bind="calcPropsForCell(item.name)"
               style="flex: 1 0"
              @open-dialog="openDialog($event, item.name)"/>
           <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
        </div>

      <div class="table__item rounded-br-lg rounded-bl-lg left-line without-line bottom-line right-line top-line font-size-small__important">
        Примечание: значения общей численности обучающихся по программам ординатуры, программам ассистентуры-стажировки на 01.01.2018 и на 01.01.2019 годы внесены по данным конкурса прошлого года (эти значения отсутствуют в форме 1-НК на указанные даты)
      </div>

      </template>

    </div>
    <div v-else class="d-flex flex-row align-center justify-center" style="min-width: 150px;">

      <v-progress-linear indeterminate />
    </div>
    <appeal-dialog
        ref="appeal-dialog"
        :appeal-id="appealId"
        :file-id="fileId"
        :appeal="appealCell"
        :value="appealValue"
        :appeal-info="appealInfo"
        @saved-value="saveValue($event)"
        @closing-dialog="closingAppealDialog"
    ></appeal-dialog>
    <tooltip-forms5-dialog
        ref="tooltip-dialog"
        :data-field="dataFieldTooltip"
    >
    </tooltip-forms5-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {getFormData} from "@/API/form5";
import {setAppealFromOneToFourDialog} from "@/mixins/forms5/appealDialogF1-4";
import FivesTableCell from "@/components/fields/fivesTableCell";
import appealDialog from "@/components/Dialogs/appealForms5_1-4Dialog";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";

export default {
  name: "form5_4",
  mixins: [setAppealFromOneToFourDialog, headerTooltipFunctions],
  components: {FivesTableCell, appealDialog, tooltipForms5Dialog},
  data() {
    return {
      serverData: [],
      loadingData: false,
      loadingMenu: {value: false, id: ''},
      dataFields: [
        {name: "noors18K21"},
        {name: "noors19K21"},
        {name: "nsors20Nk1"},
        {name: "nsors21Nk1"},
        {name: "nfors21Nk1"},
        {name: "nqors20Nk1"},
        {name: "nqors21Nk1"},

        {name: "noass18K21"},
        {name: "noass19K21"},
        {name: "nsass20Nk1"},
        {name: "nsass21Nk1"},
        {name: "nfass21Nk1"},
        {name: "nqass20Nk1"},
        {name: "nqass21Nk1"},

        {name: "noaps19Nk1"},
        {name: "nsaps20Nk1"},
        {name: "nsaps21Nk1"},
        {name: "niaps19Nk1"},
        {name: "nfaps20Nk1"},
        {name: "nfaps21Nk1"},
        {name: "nqaps20Nk1"},
        {name: "nqaps21Nk1"},

      ]
    }
  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_usl_error: 'get_spr_data_usl_error',
    }),
  },
  created() {
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    }
  },
  methods: {
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getFormData('getPart4', false);
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },
    calcPropsForCell(dataField) {
      return {
        value: this.serverData[dataField],
        title: '',
        cell: {dataField: dataField},
        id: this.serverData.id,
        row: this.serverData,
        loading: this.loading,
      }
    },
    saveValue(e) {
      this.serverData[e.dataField] = {
        oldValue: e.oldValue,
        newValue: e.newValue,
        id: e.id,
        accept: e.accept
      };
      this.$refs["appeal-dialog"].closeDialog();
      // this.$set(this.serverData, e.dataField, {oldValue: this.serverData[e.dataField], newValue: e.value} )
    }
  },
}
</script>

<style lang="scss" scoped>
.table {
  display: grid;
  grid-template-columns: 1fr 1fr repeat(9, minmax(100px, 150px)) ;
  color: #89A0B0;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  margin-bottom: 20px;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }
}

.table__item-header{
  font-size: 12px;
}

.table__item {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #CFD8DC;
  &:not(.table__item-header){
    color: #455A64
  }
}

.top-line {
  border-top: none;
}

.left-line {
  border-left: none;
}

.bottom-line {
  border-bottom: none;
}

.right-line {
  border-right: none;
}

.without-line {
  grid-column-start: 1;
  grid-column-end: 12;
}

.without-line2 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 4;
}

.without-line3 {
  grid-column-start: 3;
  grid-column-end: 5;
}

.without-line4 {
  grid-column-start: 5;
  grid-column-end: 7;
}

.without-line5 {
  grid-column-start: 7;
  grid-column-end: 12;
}

.without-line6 {
  grid-column-start: 8;
  grid-column-end: 10;
}

.without-line7 {
  grid-column-start: 10;
  grid-column-end: 12;
}

.item-data:hover {
  background: rgba(207, 224, 236, 0.42);
}
</style>