<template>
  <div class="table-grid" :ref="'grid-table' + idTable">
    <div
        class="table-grid__container"
        :style="`grid-template-columns : repeat(${numCols}, 1fr); max-height: ${maxTableHeight}; min-height: ${minTableHeight}; `"
    >
      <!--       header -->
      <div
          class="table-grid-header-row"
          :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr )`"
      >
        <template v-if="!loading || rewriteHeaders || true">
          <slot name="defaultHeader" :headers="localHeaders">

            <div
                v-for="(item, i) in localHeaders" :key="i"
                class="table-grid-header__cell"
                :class="item.headerClass"
                :ref="'table-grid-header__cell-'+item.dataField"
                :style="`grid-column: span ${item.cols }; grid-row: span ${item.rows};
               top: ${topFixedPositionForHeaderCell(item).offsetTop}px; ${item.headerStyles}`"
            >
              <slot :name="'header-'+item.dataField" :item="item">
                <span v-if="!item.html">{{ item.text }}</span><span v-else v-html="item.html"></span>
                <v-tooltip
                    top
                    v-if="item.headerTooltip != null && item.headerTooltip"
                    :color="loaderColor"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        :size="15"
                        class="btn__icon grey-light-color__important"
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <p class="white--text" v-html="item.headerTooltipText()"></p>
                </v-tooltip>
                <span
                    class="d-flex align-center justify-center"
                    v-if="item.sortable"
                >
                <template v-if="sort.header === item.dataField">
                  <v-btn
                      icon
                      @click="clickSort(item, sort.type ==='asc'? 'desc' : null, item.typeCell)"
                  >
                     <v-icon :size="24" :color="loaderColor" class="btn__icon"
                             v-if="sort.type === 'desc'"> mdi-menu-down</v-icon>
                     <v-icon :size="24" :color="loaderColor" class="btn__icon" v-else> mdi-menu-up</v-icon>
                  </v-btn>
                </template>
                <template v-else>
                    <v-btn
                        icon
                        @click="clickSort(item, null, item.typeCell)"
                    >
                       <v-icon :size="24" :color="loaderColor"> mdi-menu-swap</v-icon>
                    </v-btn>
                </template>
          </span>
              </slot>
            </div>
          </slot>
        </template>
        <slot name="customHeader" :headers="headers">

        </slot>
      </div>
      <!--        body-->
      <template v-if="loadingData || loading">
        <div class="d-flex flex-row align-center" :style="`grid-column: span ${numCols}; min-height: 200px;`">
          <v-progress-linear indeterminate :color="loaderColor"></v-progress-linear>
        </div>
      </template>
      <template v-else-if="dataRows.length === 0">
        <div class="table-grid-body-row"
             :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr);
            `"
        >
          <div
              class="table-grid-body-row__cell"
              :style="`grid-column: span ${(numCols )};  min-height: 200px!important;`"
          >
            Нет данных
          </div>
        </div>
      </template>
      <template v-else>
        <div class="table-grid-body-row"
             v-for="(row, j) in dataRows"
             :key="(row.id != null ? row.id : j)"
             :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr)`"
             @click="rowAction($event,row)"
             :ref="'table-grid-body-row__'+(row.id != null ? row.id : j)"
        >
          <div
              class="table-grid-body-row__cell"
              v-for="(cell) in headersForRows"
              :key="cell.dataField"
              :class="[cell.class, painCell(row, cell)]"
              :style="calcStylesForRowCell(row,cell)"
          >
            <!--           grid-template-columns: minmax(200px, ${(numCols )}fr); -->
            <slot :name="cell.dataField" :row="row" :id="row.id != null ? row.id : j" :cell="cell">
              <template>
                <span v-html="cellData(row, cell)"></span>
              </template>
            </slot>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: "GridTable",
  props: {
    headers: {
      type: [Array],
      default: () => []
    },
    idTable: {
      type: String,
      default: ''
    },
    numCols: {
      type: [Number, String],
      default: 0
    },
    headersForRows: {
      type: [Array],
      default: () => []
    },
    maxTableHeight: {
      type: [String, Number],
      default: '550px',
    },
    minTableHeight: {
      type: [String, Number],
      default: '',
    },
    loadingData: {
      type: Boolean,
      default: false
    },
    dataRows: {
      type: Array,
      default: () => []
    },
    paintBackground: {
      type: [Function],
      default: () => {
      }
    },
    importsComponents: {
      type: [Array, Object]
    },
    loaderColor:{
      type: String,
      default: '#00599b'
    },
    rewriteHeaders:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableWidth(){
      return this.$refs['grid-table' + this.idTable]?.clientWidth ?? 0;
    },
    length() {
      return this.dataRows.length
    },
  },
  data() {
    return {
      localHeaders: this.headers,
      loading: false,
      sort: {header: null, type: 'asc'}
    }
  },
  watch: {
    headers(newValue) {
      // console.log(this.$refs['grid-table'])
      // await this.$refs['grid-table'].scrollIntoView({block: "start", inline: "nearest"});
      this.loading = true;
      this.localHeaders = newValue;
      // await this.setTopPositions();
      this.loading = false;
    },
    // async rewriteHeaders(){
    //   this.loading = true;
    //   this.$nextTick(async () =>
    //       await this.setTopPositions()
    //   )
    //   this.loading = false;
    // },
    // tableWidth(){
    //   this.setTopPositions()
    // },
    length(a) {
      if (a !== 0)
        setTimeout(() => {
          this.setTopPositions()
        }, 100)
    }
  },
  mounted() {

    // window.addEventListener('resize', this.setTopPositions)
    // console.log("!!",this.$refs['grid-table' + this.idTable].clientWidth);
    // this.$refs['grid-table' + this.idTable].addEventListener('resize', this.setTopPositions);
    // this.setTopPositions();
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.setTopPositions)
    // this.$refs['grid-table' + this.idTable].removeEventListener('resize', this.setTopPositions)
  },
  methods: {
    rowAction(e, row) {
      this.$emit('row-action', {e: e, row: row})
    },
    cellData(row, cell) {
      return cell.template ? cell.template(row) : row[cell.dataField]
    },
    clickSort(e, type = null, typeCell) {
      console.log(e, ' - ', type);
      this.sort = {header: e.dataField, type: type ?? 'asc'};
      const directions = {
        asc: 1,
        desc: -1
      };
      const direction = directions[type] ?? 1;
      switch (typeCell) {
        case 'number':
          if (e.template)
            this.dataRows.sort((a, b) => direction * (e.template(a) - e.template(b)));
          else
            this.dataRows.sort((a, b) => direction * (a[e.dataField] - b[e.dataField]));
          break;
        case 'string': {
          if (e.template)
            this.dataRows.sort((a, b) => direction * e.template(a).localeCompare(e.template(b), ["ru", 'en'], {caseFirst: 'upper'}));
          else
            this.dataRows.sort((a, b) => direction * a[e.dataField].localeCompare(b[e.dataField], ["ru", 'en'], {caseFirst: 'upper'}));
          break;
        }
        default: {
          if (e.template)
            this.dataRows.sort((a, b) => direction * e.template(a).localeCompare(e.template(b), ["ru", 'en'], {caseFirst: 'upper'}));
          else
            this.dataRows.sort((a, b) => direction * a[e.dataField].localeCompare(b[e.dataField], ["ru", 'en'], {caseFirst: 'upper'}));
          break;
        }
      }
    },
    async setTopPositions() {this.localHeaders = await Promise.all(
          this.localHeaders.map(async el => {
            const offsetHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetHeight : '';
            const offsetTop = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetTop : '';
            const clientHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientHeight : '';
            const clientWidth = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientWidth : '';
            return {
              ...el,
              offsetHeight: offsetHeight,
              offsetTop: offsetTop - 2,
              clientHeight: clientHeight,
              clientWidth: clientWidth
            }
          })

      )
    },
    calcStylesForRowCell(row, cell) {
      return `${typeof cell.styles === 'function' ? cell.styles(row) : cell.styles};` + `grid-column: span ${cell.colsDataCalc ? cell.colsDataCalc(row, cell) : cell.cols}; grid-row: span ${cell.rowsDataCalc ? cell.rowsDataCalc(row) : cell.rowsData};`
    },
    topFixedPositionForHeaderCell(cell) {
      return this.localHeaders.length > 0 ? this.localHeaders.find(el => el.dataField === cell.dataField) : ''
    },
    // eslint-disable-next-line no-unused-vars
    painCell(row, cell) {
      if (this.paintBackground(row))
        return this.paintBackground(row)();
    }
  },
}
</script>

<style scoped>

</style>