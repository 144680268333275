<template>
  <div>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <WrapperCollapse
          title="КОНТАКТНАЯ ИНФОРМАЦИЯ ПРЕДСТАВИТЕЛЯ МИНИСТЕРСТВА, ОТВЕТСТВЕННОГО ЗА УЧАСТИЕ В КОНКУРСЕ"
          :initialOpen="true"
      >
        <ContainerPersonContact
            v-if="!loadingContacts"
            :contact="contact"
            :can-be-deleted="false"
            :saving-data="savingContact"
            :is-single="true"
            :blocked="getBlock"
            :ignore-personal-phone-number="true"
            :loading-contacts="loadingContacts"
            @change-contact="changeContact($event)"

        />
        <div class="d-flex align-center justify-center" style="width: 100%;height: 200px" v-else>
          <v-progress-circular size="30" color="primary"></v-progress-circular>
        </div>
      </WrapperCollapse>
    </WrapperPreviewCategory>


  </div>
</template>

<script>
import request from "@/services/request";
import {mapActions, mapGetters} from 'vuex';
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';
import ContainerPersonContact from "@/components/contacts/ContainerPersonContact";

export default {
  name: "LkOrganization",
  components: {

    WrapperPreviewCategory,
    WrapperCollapse,
    ContainerPersonContact,
  },
  data() {
    return {
      contact:
          {
            contactIndex: 1,
            position: '',
            fio: '',
            workPn: '',
            email: ''
          },

      savingContact: false,
      savingDirectorContact: false,
      loadingContacts: false,
      errorSaving: null,
      loadingBlockEnterData: false,
      loadingBlockEnterWithoutData: false,
      downloadingFileForPrint: false,
    }
  },
  created() {
    // if (this.authData)
      this.getContacts()
  },
  computed: {
    ...mapGetters('checkFilled', {
      get_check_filled_status: 'get_check_filled_status',
      get_contact_data: 'get_contact_data',
      simpleBlock: 'simpleBlock'
    }),
    getBlock() {
      return this.simpleBlock;
    },
    canCloseEnter(){
      return !!this.get_contact_data?.workPhoneNumber
    }
  },
  watch: {},
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    ...mapActions('auth', {blockEnterData: 'blockEnterData'}),
    initContacts(data){
      this.contact = data.find(el => el.contactIndex !== 0) ?? this.contact;
      this.directorContact = data.find(el => el.contactIndex === 0)?? this.directorContact;
    },
    async getContacts() {
      this.loadingContacts = true;
      try {
        const res = await request({
          endpoint: `/api/contact`,
          method: 'get'
        })
        if (res.status === 200) {
          if (res.data) {
            this.initContacts(res.data);
            // await this.fetchCheckFilled({contacts: this.contact})
          }
        }
        this.loadingContacts = false;
      } catch (e) {
        this.loadingContacts = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения данных ответственных исполнителей' + e,
          timeout: 5000
        })
      }
    },
    async changeContact(obj) {
      this['savingContact'] = true;
      try {
        const res = await request({
          endpoint: `/api/contact`,
          method: 'put',
          body: {
            ...obj,
            id: obj?.id,
          },
        })
        if (res.status === 200 || res.status === 201) {
          this['contact'] = res.data;
          // this.initContacts(res.data)
          // await this.fetchCheckFilled({contacts: this.contact});
          // await this.getStatusInfo();
          this['savingContact'] = false;
        } else {
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка сохранения данных контакта',
            timeout: 5000
          });
          this['savingContact'] = false;
        }
      } catch (e) {
        this['savingContact'] = false;
        console.error('e change contact', e);
      }
      this['savingContact'] = false;
    },
  }
}
</script>

<style lang="sass">
.section-preview_lk
  background: #fff
  border-radius: 6px
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06)
  //border: 1px solid #cdd1d4
  margin-bottom: 20px


  .lk-list_flex_start
    justify-content: flex-start
</style>