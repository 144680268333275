<template>
  <div>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <GridTable
          :data-rows="showingServerData"
          :headers="headers.massive"
          :headers-for-rows="headersForRows"
          :loading-data="loadingData"
          :num-cols="headers.countCells"
          :max-table-height="maxTableHeight"
          ref="npg-table"
          :key="'form-5-05'"
      >
        <!--  v-if="!changingHeaders"  :key='' когда меняется хедер проверить    -->
        <template #header-npg0="{item}">
          <v-btn  @click="loadFullInfo" class="white__v-btn">{{ !full ? 'Показать все' : "Скрыть пустые" }}  строки </v-btn>
          {{item.text}}
        </template>
        <template v-for="item in headersForRows" v-slot:[calcHeaderName(item.dataField)]>
          <div :key="item.dataField" :class="item.headerClass" style="max-height: 22px;min-width: 100%">
            <template v-if="item.text === '1'">
              <div style="min-width: 60px">1</div>
              <div class="text-center" style="width: 100%">2</div>
            </template>
            <template v-else>
              <div  class="d-flex flex-row align-center" style="max-height: 22px;">
                {{ item.text }}
                <v-btn v-if="item.text !== '7'" small icon @click="openTooltip(item.dataField)">
                  <v-icon
                      :size="15"
                      class="grey-light-color__important "
                  >
                    mdi-information
                  </v-icon>
                </v-btn></div>
            </template>
          </div>
        </template>
        <template #npg="{row}">
          <template v-if="row.uslName">
            <div class="font-weight-bold font-size-medium ">
              {{ row.uslName[0].toUpperCase() + row.uslName.slice(1) }}
            </div>
          </template>
          <template v-else>
            <div style="">
              {{ row.spec ? row.spec.kodSpec : row.kcpUgs.code }}
            </div>
            <div class="ml-3">
              {{ row.spec ? row.spec.name : row.kcpUgs.name }}
            </div>
          </template>
        </template>
        <template v-for="item in headersForRowsWithEditableCells" v-slot:[item.dataField]="{row,cell}">
          <fives-table-cell :key="item.dataField" v-bind="item.props(row,cell, loadingMenu)"
                            @open-dialog="openDialog($event, item.dataField, cell, row)"/>
        </template>
      </GridTable>
      <div class="mt-2" style="color: #89A0B0; font-size: 12px;">
        Примечание: При наличии профессионально-общественной аккредитации по УГСН в целом информация дублируется в строках по специальностям (направлениям подготовки), входящим в УГСН. При наличии профессионально-общественной аккредитации по образовательным программам, сведения указываются по тем специальностям (направлениям подготовки), к которым относятся данные образовательные программы
      </div>
      <div class="mt-3 d-flex flex-row justify-center align-center">
        <v-autocomplete
            class="pagination-table"
            v-model="userPages"
            :items="pagesPaginate"
            solo
            dense
            filled
            height="25"
            rounded
            style="max-width: 150px"
            @change="changePage($event)"
        ></v-autocomplete>
        <v-pagination
            v-model="page"
            :length="pagesVuetify"
            :total-visible="7"
            @input="changePage($event)"
        >
        </v-pagination>
      </div>

    </WrapperPreviewCategory>
    <tooltip-forms5-dialog
        ref="tooltip-dialog"
        :data-field="dataFieldTooltip"
    >
    </tooltip-forms5-dialog>
    <appeal-dialog
        ref="appeal-dialog"
        :appeal-id="appealId"
        :file-id="fileId"
        :appeal="appealCell"
        :value="appealValue"
        :appeals-arr="appealsArr"
        :appeal-info="appealInfo"
        @saved-value="saveValue($event)"
        @closing-dialog="closingAppealDialog"
    ></appeal-dialog>
    <universal-dialog-v1
        ref="dialog"
        :data-for-dialog="dataForDialog"
        :headers-for-dialog="headersForDialog"
        @fetch-new-data="fetchDialogData($event)"
        :loading="savingData"
        :additional-data="additionalData"
        @closing-dialog="clearDataForDialog()"
    >
      <template #title>
        <h2>{{ mainHeaderTitle ? mainHeaderTitle : 'Добавить' }}</h2>
      </template>
      <template #actions="{close, sendData, loading, validSolo, isOneStage}">
        <div class="d-flex flex-row align-center justify-center" style="width: 100%">
          <v-btn
              class='white__v-btn'
              @click="close()"
              width="120px"
          >
            Отменить
          </v-btn>
          <v-btn
              v-if="isOneStage"
              class='blue__v-btn  ml-2'
              @click="sendData()"
              :loading="loading"
              :disabled="!validSolo"
              width="120px"
          >
            Отправить
          </v-btn>
        </div>
      </template>
    </universal-dialog-v1>
  </div>
</template>

<script>
import GridTable from "@/components/GridTable/GridTable";
import form505 from "@/helpers/forms5/form5-05";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {getFormData} from "@/API/form5";
import {mapGetters} from "vuex";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import FivesTableCell from "@/components/fields/fivesTableCell";
import appealDialog from "@/components/Dialogs/appealDialog";
import {setAppealDialog} from "@/mixins/forms5/appealDialog";
import UniversalDialogV1 from "@/components/Dialogs/universalDialog(v1.1)";
import request from "@/services/request";
import {tablePagination} from "@/mixins/tablePagination";

export default {
  name: "form5",
  mixins: [headerTooltipFunctions, setAppealDialog, tablePagination],
  components: {UniversalDialogV1, FivesTableCell,  WrapperPreviewCategory, GridTable, tooltipForms5Dialog, appealDialog},
  data() {
    return {
      serverData: [],
      loadingData: false,
      changingHeaders: false,
      headers: form505,
      full : false,
      savingData : false,
      dataForDialog: null,
      additionalData: null,
      mainHeaderTitle: '',
      headersForDialog: [
        {
          "text": "серия и номер бланка свидетельства",
          "dataField": "gpsnm22K21",
          "typeVar": "string",
          "rules": 'customRules',
          "customsRulesArray": [
            // v => v != null || 'Значение должно быть выбрано',
            // v => v != null && v.length <=15 || 'Значение должно быть больше 15 символов',
          ],
          "order": 0,
        },
        {
          "text": "серия и номер бланка приложения",
          "dataField": "gpsnp22K21",
          "typeVar": "string",
          "rules": 'customRules',
          "customsRulesArray": [
            // v => v != null || 'Значение должно быть выбрано',
            // v => v != null && v.length <= 15 || 'Значение должно быть больше 15 символов',
          ],
          "order": 1,
        },
        {
          "text": "дата выдачи свидетельства",
          "dataField": "gpdtb22K21",
          "typeVar": "date",
          "rules": 1,
          "order": 2,
        },
        {
          "text": "дата окончания действия",
          "dataField": "gpdte22K21",
          "typeVar": "date",
          "rules": 1,
          "order": 3,
        },
        {
          "text": "примечание",
          "dataField": "gptxt22K21",
          "typeVar": "string",
          "rules": 0,
          "order": 4,
        },
      ]
    }
  },
  created() {
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    },

  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_usl_error: 'get_spr_data_usl_error',
    }),
    maxTableHeight() {
      return window.innerHeight - 225 + 'px'
    },
    headersForRows() {
      return this.headers.massive.filter(el => el.variable).map(el => {
        return {
          ...el,
          styles: (row) => {
            if (el.dataField !== 'npg')
              return row.uslName ? 'display: none;' : 'justify-content: unset; text-align: center; '
            else
              return ''
          },
          colsDataCalc: (row, cell) => {
            if (el.dataField !== 'npg')
              return cell.cols;
            else
              return row.uslName ? 7 : 1;
          },
          props: (row, cell, loading) => {
            return {
              value: row[cell.dataField],
              title: '',
              cell: cell,
              id: row.id,
              row: row,
              loading: loading,
            }
          }
        }
      })
    },
  },
  methods: {
    loadFullInfo(){
      this.full = !this.full;
      this.loadingDataTable()
    },
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getFormData('getPart5', true, '', this.full);
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },
    clearDataForDialog() {
      this.mainHeaderTitle = '';
      this.dataForDialog = null;
      this.additionalData = null;
    },
    // eslint-disable-next-line no-unused-vars
    async openDialog(e, dataField, cell, row){
      //заглушка на редактирование от 08.04.2022
      return;
      // if (row.spec)
      //   this.mainHeaderTitle = row.spec?.kodSpec  + ' ' + row.spec?.name;
      // else
      //   this.mainHeaderTitle = row.kcpUgs?.code  + ' ' + row.kcpUgs?.name;
      // this.additionalData = {
      //   id: row?.id ?? null,
      //   "spec": row?.spec?.id ? {
      //     "id": row?.spec?.id,
      //   } : null,
      //   "kcpUgs": row.kcpUgs?.id ?{
      //     "id": row.kcpUgs?.id,
      //   } : null,
      //   "sprOrgId": row.sprOrgId,
      // };
      // this.dataForDialog = {
      //   gpsnm22K21: row.gpsnm22K21,
      //   gptxt22K21: row.gptxt22K21,
      //   gpdte22K21: row.gpdte22K21,  //date
      //   gpdtb22K21: row.gpdtb22K21, //date
      //   gpsnp22K21: row.gpsnp22K21,
      // };
      // await this.$refs.dialog.openDialog()
    },
    formatDate (date) {
      if (!date) return null

      const  [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async fetchDialogData(obj) {
      this.savingData = true;
      try {

        const res = await request({
          endpoint: `/api/form5/setPart5`,
          method:  'post',
          body: {
            ...obj,
            gptxt22K21: obj.gptxt22K21 ?? '',
            gpdte22K21: obj.gpdte22K21,
            gpdtb22K21: obj.gpdtb22K21,
          }
        })
        if (res.status >= 200 && res.status < 300) {
          this.savingData = false;
          if (obj.id) {
            const index = this.serverData.findIndex(el => el.id === obj.id);
            this.serverData.splice(index, 1,res.data) //res.data
          } else {
            const index = this.serverData.findIndex(el => el.spec?.id === obj.spec?.id);
            this.serverData.splice(index, 1, { ...res.data, spec: this.serverData[index].spec})//res.data
          }
          this.dataForDialog = null;
          await this.$refs.dialog.closeDialog();
        }
        this.savingData = false;
      } catch (e) {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения',
          timeout: 3000
        });
        console.log("e", e)
        this.savingData = false;
      }
    }

  },
}
</script>

<style scoped>

</style>