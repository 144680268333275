<template>
  <div class="info-card">
    <div class="info-card__field" v-for="(value, name, index) in data" :key="index">
      <div class="info-card__name">
        <slot name="name" :row="value"> {{ name }}:</slot>
      </div>
      <div class="info-card__cell">
        <slot name="value" :row="value"> {{ value }}</slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: [Object, Array],
    }
  }
}
</script>
<style lang="sass">
.info-card
  .info-card__name
    color: #455058
    font-size: 16px
    margin-right: 21px
    white-space: nowrap
    line-height: 18.75px
    font-weight: 400

  .info-card__cell
    background: #E3EFFA
    padding: 8px
    border-radius: 7px
    flex-grow: 1
    font-size: 16px
    color: #00599B
    line-height: 18.75px
    min-height: 36px

  .info-card__field
    display: flex
    align-items: center
    margin-bottom: 14px

  .info-card__button
    margin-top: 10px
    margin-left: 10px
</style>
