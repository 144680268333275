<template>
  <section class="letters">
    <!-- <Заголовок> -->
    <HeaderMain title="Прием конвертов" />
    <!-- <Заголовок> -->
    <div class="cms-panel">
    <div class="cms-panel-search">
        <v-icon :size="25" color="#cdd1d4" class="fr-topbar__icon">mdi-magnify</v-icon>
        <input type="search" class="cms-panel-search__input" v-model="searchLetters">
    </div>
    <BSelect :data="searchFields" v-model="selectedSearchField" class="cms-panel-search__sort" />
    <!-- <Выбор конвертов по статусу> -->
    <BSelect :data="selectedOptions" v-model="selectedOption" class="letters__select-type" />
    <!-- </Выбор конвертов по статусу> -->
    </div>

    <!--<Алерт>-->
    <Alert alertType="primary" v-if="selectedTypeLetters && !selectedTypeLetters.length">
      <template v-slot:icon>
        <v-icon :size="30" color="#084298">mdi-information-outline</v-icon>
      </template>
      <template v-slot:default>Активных конвертов нет</template>
    </Alert>
    <!--</Алерт>-->
    <!--<Список конвертов>-->
    <template v-if="loading">
      <div class="d-flex align-center" style="min-height: 250px;">
        <v-progress-linear indeterminate></v-progress-linear>
      </div>

    </template>
    <template v-else>
      <CardLetter v-for="item in resultSearch.data" :key="item.key" :item="item" @acceptConvert="onOpenAcceptConvert($event)" />
    </template>

    <!--<Модальное окно для конвертов>-->
    <v-dialog v-on:input="onCancelAcceptConvert"
              v-model="modalOpen"
              @close="onCancelAcceptConvert"
              @click:outside="onCancelAcceptConvert"
    >
      <v-card>
        <v-card-title>Решение по конверту</v-card-title>
        <v-card-text>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-text-field
                v-model="fio"
                label="ФИО"
                class="mt-2"
                dense
                clearable
                required
            />
            <v-text-field
                v-model="position"
                label="Должность"
                class="mt-2"
                dense
                clearable
                required
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="saving" @click="accept(true)" class="success__v-btn">Принять конверт</v-btn>
<!--          <v-btn :loading="saving" @click="accept(false)" class="error__v-btn">Отменить конверт</v-btn>-->
        </v-card-actions>
      </v-card>
<!--      <AlertError v-if="errorSending">Произошла ошибка, повторите запрос</AlertError>-->
<!--      <FormAcceptConvert @acceptConvertApp="onSendOkAcceptConvert($event)" @cancel="onCancelAcceptConvert" />-->
    </v-dialog>
    <!--</Модальное окно для конвертов>-->

    <p class="paginator-info"> Страница: {{ this.paginatorParams.selectedPage }}, Результатов на странице: {{ this.paginatorParams.itemsPerPage }}, Всего результатов: {{resultSearch.counter}} </p>
    <BPaginator
      v-if="resultSearch"
      :propDataCount="resultSearch.counter"
      v-model="paginatorParams"
    />

    <!--</Список конвертов>-->
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Alert from '@/components/alerts/Alert';
// import AlertError from '@/components/alerts/AlertError';
import request from "@/services/request";
import CardLetter from "@/components/cards/CardLetter";
import BPaginator from "@/components/paginator/BPaginator/BPaginator";
import BSelect from "@/components/controls/BSelect/BSelect";
import HeaderMain from '@/components/headers/HeaderMain';

export default {
  components: {
    CardLetter,
    BSelect,
    Alert,
    HeaderMain,
    BPaginator,
  },
  data() {
    return {
      letters: [],
      searchLetters: '',
      searchFields: ['Наименование', 'ID организации'],
      selectedSearchField: 'Наименование',
      results: 0,
      loading: false,
      saving: false,
      valid: true,
      fio: '',
      position: '',
      currentSort: 'orgId',
      currentSortDir: 'asc', 
      paginatorParams: {'selectedPage' : 1, 'itemsPerPage' : 10},
      errorSending: null,
      modalOpen: false,
      selectedConvertId: null,
      selectedOptions: ['Отправленные конверты', 'Принятые конверты','Отклоненные конверты', 'Все конверты'],
      selectedOption: 'Все конверты',
    }
  },
  mounted() {
    this.getActualLetters();
  },

  computed: {
    ...mapGetters('auth', { authData: 'authData' }),
    selectedField() {
    if (this.selectedSearchField === 'Наименование') {
      return 'orgName'
    } else if (this.selectedSearchField === 'ID организации') {
      return 'orgId'
    } else {
      return 'orgName'
    }
  },
    selectedTypeLetters() {
      if (this.selectedOption === 'Отправленные конверты') {
        return this.letters.filter(el => el.konvertAccept === null)
      } else if (this.selectedOption === 'Принятые конверты') {
        return this.letters.filter(el => el.konvertAccept === true)
      }else if(this.selectedOption === 'Отклоненные конверты'){
        return this.letters.filter(el => el.konvertAccept === false)
      } else {
        return this.letters;
      }
    },

    resultSearch () {
    let results;
    let array = [];
    if (this.selectedTypeLetters) {
      array = this.selectedTypeLetters;
    }
    array = array.sort((a, b) => {
        let mod = 1
        if (this.currentSortDir === 'desc') mod = -1
        if (a[this.currentSort] < b[this.currentSort]) return -1 * mod
        if (a[this.currentSort] > b[this.currentSort]) return 1 * mod
        return 0
      });
    let search = this.searchLetters;
    if (!search) {
      results = array.length;
       array = array.filter((row, index) => {
        let start = (this.paginatorParams.selectedPage-1)*this.paginatorParams.itemsPerPage
        let end = this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage
        if (index >= start && index < end) return true
      })

    return {'data': array, 'counter': results};
    }
    search = search.trim().toLowerCase()
    array = array.filter((item) => {
      if (String(item[this.selectedField]).toLowerCase().indexOf(search) !== -1) {
        return item
      }
    })
    results = array.length;
    array = array.filter((row, index) => {
        let start = (this.paginatorParams.selectedPage-1)*this.paginatorParams.itemsPerPage
        let end = this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage
        if (index >= start && index < end) return true
      })
      if (this.selectedOption==='Принятые конверты'){
        array = array.sort((a, b) => {
          if (a['konvertNumber'] < b['konvertNumber']) return -1
          if (a['konvertNumber'] > b['konvertNumber']) return 1
          return 0
        });
      }
  return {'data': array, 'counter': results};
  },
  },

  methods: {
    async validate() {
      await this.$refs.form.validate()
    },
    async accept(flag){
      await this.validate();
      const res = window.confirm('Вы уверены?')
      if (res){
        if (this.valid){
          try {
            this.saving = true;
            const res = await request({
              endpoint: `/api/admin/acceptKonvert`,
              method: 'post',
              body: {
                fio: this.fio,
                position: this.position,
                konvertAccept: flag,
                orgId: this.selectedConvertId
              }
            });
            const findOrg = this.letters.findIndex(el => el.orgId === res.data.orgId);
            this.letters.splice(findOrg, 1, res.data)
            this.saving = false;
            this.modalOpen = false;
          }catch (e) {
            console.error('/api/admin/getAllOrgsKonvert', e)
            this.saving = false;
            this.modalOpen = false;
          }
        }
      }

    },
    async getActualLetters() {
      try {
        this.loading = true;
        const letters = await request({
          endpoint: `/api/admin/getAllOrgsKonvert`,
          method: 'GET',
        });
        this.letters = letters.data;
        this.loading = false;
      }catch (e) {
        console.error('/api/admin/getAllOrgsKonvert', e)
        this.loading = false;
      }
    },
    onOpenAcceptConvert(event) {
      this.modalOpen = true;
      this.selectedConvertId = event;
    },

    onCancelAcceptConvert() {
      this.modalOpen = false;
      this.valid = true;
      this.selectedConvertId = null;
    },

    // async onSendOkAcceptConvert(event) {
    //   let convert = await request({
    //     endpoint: `/api/admin/receiveConvert`,
    //     method: 'POST',
    //     body: {
    //       fioRasp: event.fioRasp,
    //       position: event.position,
    //       convertID: this.selectedConvertId,
    //     }
    //   });
    //  if (convert.status === 200) {
    //    this.onCancelAcceptConvert();
    //    this.errorSending = false;
    //    this.getActualLetters();
    //  } else {
    //    this.errorSending = true;
    //  }
    // }
  }
}
</script>

<style lang="sass">
.letters
        width: 100%
.info-card__button
        margin-top: 10px
        margin-left: 10px   
.info-card__button
        margin-top: 10px
        margin-left: 10px
.info-card__button:hover
        background: #0292e1
.info-card__cell_changed
        background: #fff3cd
        border-radius: 3px
        color: #856404
        padding: 0px 3px
.modal-content
        max-width: 900px
.info-cards
        display: flex
.letter__select-type
        margin-bottom: 20px
        margin-top: 10px
        background-color: #fff
        padding: 5px 10px
        border: 1px solid #00599b
        cursor: pointer
        min-width: 200px
.cms-panel-search
    display: flex
    align-items: center
    background: #fff
    border-radius: 5px
    padding: 0px 10px
    border: 1px solid #cdd1d4
.cms-panel
    margin-bottom: 20px
    display: flex
.cms-panel-search__sort, .letters__select-type
    background-color: #fff
    display: flex
    align-items: center
    border-radius: 5px
    padding: 0px 10px
    border: 1px solid #cdd1d4
    margin-left: 10px
.letters__select-type.select
    margin-left: auto    
.sort-panel
    display: flex
    width: 100%
    justify-content: space-between
.reference-main
  width: 100%
.paginator-info
    text-align: center
    color: #00599b
.sort-panel
    font-weight: 600
    color: #00599b
    margin-bottom: 10px
.card__info_sort
    cursor: pointer
.card__info.card__info__action .button_border
    text-align: center
    max-height: 35px
.cms-panel-search__sort.select
    min-width: 170px
.cms-panel-search__input
    min-width: 500px                
</style>