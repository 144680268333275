export default {
    namespaced: true,
    state: {
        keycloak: null,
    },
    mutations: {
        ['SET_AUTH_DATA'](state, data) {
            state.keycloak = data;
        },
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        async setAuthData({commit, dispatch}, payload) {
            commit('SET_AUTH_DATA', payload);
            commit('auth/AUTHENTICATION_SUCCESS', {
                'user': payload.tokenParsed,
                'token': payload.token,
                'refreshToken': payload.refreshToken,
                'status': true
            }, { root: true });
            const id = payload.subject === '538b8ab0-c337-4d0b-9e60-8bb9462fb93d' ? 4 : payload.tokenParsed?.orgID;
            await dispatch('auth/replaceIdAdmin', {id}, {root: true})
            dispatch('spr/dataForSpr', {}, {root: true})
            dispatch('tables/getAllIndicators', {}, {root: true})
            dispatch('checkFilled/getOrgInfo', {}, {root: true})
            dispatch('checkFilled/getFilialsOrgInfo', {}, {root: true})
        },
        async keycloakLogout({commit, state}) {
            if (state.keycloak) {
                await localStorage.removeItem('auth');
                commit('auth/AUTHENTICATION_SUCCESS', null, {root: true});
                // commit('auth/ADMIN_AUTH', false, {root: true});
                // commit('auth/REPLACE_NAME_ADMIN', null, {root: true});
                state.keycloak.logout();
                commit('SET_AUTH_DATA', null)
            }
        }
    },
    getters: {
        getAuthData(state) {
            return state.keycloak
        },
        /*
        * realmAccess:Object
roles:Array[8]
0:"SPR_QUERIES_ROLE"
1:"ORGANIZATION_ROLE"
2:"REGION_ROLE"
3:"offline_access"
4:"FOUNDER_ROLE"
5:"ADMIN_ROLE"
6:"uma_authorization"
7:"default-roles-ined_users"
*/
        isAdmin(state){
            return !!state.keycloak?.realmAccess.roles.find(el => el === 'ADMIN_ROLE')
        },
        isFounder(state){
            return !!state.keycloak?.realmAccess.roles.find(el => el === 'FOUNDER_ROLE')
        },
        isOrganization(state){
            return !!state.keycloak?.realmAccess.roles.find(el => el === 'ORGANIZATION_ROLE')
        },
        isRegion(state){
            return !!state.keycloak?.realmAccess.roles.find(el => el === 'REGION_ROLE')
        },
    }
}