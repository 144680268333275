<template>
  <div>
    <template v-if="rowOfValues.isbsm22 === '1'">
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Корректирующий коэффициент</h2>
      </v-col>
    </v-row>
    <block-pn :item="item" :row-of-values="{data: rowOfValues}"/>
    <v-row dense>
      <v-col cols="10">
        <h3 class="b-indicator__collapse-subtitle">Расчет приведенного контингента лиц с ОВЗ, инвалидов,
          детей-инвалидов:</h3>
        <p>
          <span class="font-weight-bold mb-1">Форма 5.09 (фрагмент)</span><br>
          Численность лиц с ограниченными возможностями здоровья, инвалидов, детей-инвалидов, обучающихся по программам
          бакалавриата, программам специалитета, программам магистратуры
        </p>
      </v-col>
    </v-row>
    <p>По данным формы ВПО-1, человек</p>
    <table>
      <thead>
      <tr>
        <th rowspan="4" colspan="3" class="kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="6">Лиц с ОВЗ, инвалидов, детей-инвалидов</th>
        <th rowspan="5" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="2"  class="border-right-zero" colspan="3">Всего лиц с ОВЗ,<br> инвалидов, детей-инвалидов</th>
      </tr>
      <tr>
        <th colspan="3">
          из обучающихся<br> (искл. квоту иностр. граждан)
        </th>
        <th colspan="3">
          из иностранных граждан,<br> обучающихся по квоте
        </th>

      </tr>
      <tr>
        <th colspan="3">
          на 1.10.2021 г.
        </th>
        <th colspan="3">
          на 1.10.2021 г.
        </th>
        <th colspan="3" class="border-right-zero">
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>

        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          <!--          <div class="d-flex align-center justify-center">-->
          заочно
          <!--          </div>-->

        </th>

        <th rowspan="2" style="border-bottom: 0">
          очно<br/>
          <span class="font-weight-bold"> гр.3 + гр.6</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">

          очно-заочно<br/>
          <span class="font-weight-bold">гр.4 + гр.7</span>
        </th>
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
          заочно<br/>
          <span class="font-weight-bold">гр.5 + гр.8</span>
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th v-for="(item) in 6" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.kcpGroup.name }}</span>
        </td>
        <td>{{ rowOfValues.ndsgo21 }}</td>
        <td>{{ rowOfValues.ndsgv21 }}</td>
        <td>{{ rowOfValues.ndsgz21 }}</td>
        <td>{{ rowOfValues.ndqgo21 }}</td>
        <td>{{ rowOfValues.ndqgv21 }}</td>
        <td>{{ rowOfValues.ndqgz21 }}</td>
        <td class="border-bottom-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
        <td class="border-bottom-zero">{{ rowOfValues.ndugo21 }}</td>
        <td class="border-bottom-zero">{{ rowOfValues.ndugv21 }}</td>
        <td class="border-bottom-zero">{{ rowOfValues.ndugz21 }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 10" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 10" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          DIo
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          DIv
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          DIz
        </td>
      </tr>
      </tbody>
    </table>
    <p>Приведенный контингент лиц с ОВЗ, инвалидов, детей-инвалидов:</p>
    <div class="d-flex flex-row">
      <formuls-fraction :need-bottom-line="false">
        <template>
          DI
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">=</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">1,0 × </span> DIo
        </template>
        <template #bottom>
          очная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,25 ×</span> DIv
        </template>
        <template #bottom>
          очно-заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,1 ×</span> DIz
        </template>
        <template #bottom>
          заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">=</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">1,0 ×</span> {{ rowOfValues.ndugo21 }}
        </template>
        <template #bottom>
          очная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,25 ×</span> {{ rowOfValues.ndugv21 }}
        </template>
        <template #bottom>
          очно-заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,1 ×</span> {{ rowOfValues.ndugz21 }}
        </template>
        <template #bottom>
          заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template >
          <span class="alternate-text">=</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.ndugp21, 1, 'float') }}
        </template>
        <template #bottom>
          приведенный<br>контингент
        </template>
      </formuls-fraction>
    </div>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения корректирующего коэффициента:</h3>
    <div class="d-flex flex-row align-center justify-start pt-4">
      <div class="mr-2 formul-text">
        KI
      </div>
      <div class="mr-2 alternate-text">
        =
      </div>
      <div>
        <v-img alt="" class="hh-100" :src="require('@/assets/images/brackets/Скобка_two_lines.svg')"/>
      </div>
      <div class="d-flex flex-column justify-center ml-2">
        <div class="d-flex flex-row align-center">
          <div class="alternate-text mr-1">1 + 0,5 × </div>
          <fomuls-fraction-simple >
            <template>DI <span class="alternate-text">/</span> PN <span class="alternate-text">× 0,2 </span></template>
            <template #bottom><span class="alternate-text">0,8</span></template>
          </fomuls-fraction-simple>
        </div>
        <div class="d-flex flex-row align-center alternate-text mt-2">
          но не менее 1
        </div>
      </div>
      <div class="mr-2 alternate-text">
        =
      </div>
      <div>
        <v-img alt="" class="hh-100" :src="require('@/assets/images/brackets/Скобка_two_lines.svg')"/>
      </div>
      <div class="d-flex flex-column justify-center ml-2">
        <div class="d-flex flex-row align-center">
          <div class="alternate-text mr-1">1 + 0,5 × </div>
          <fomuls-fraction-simple >
            <template>  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.ndugp21, 1, 'float') }} <span class="alternate-text mx-1">/</span>  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.nougp21, 1, 'float') }} <span class="alternate-text ml-1">× 0,2 </span></template>
            <template #bottom><span class="alternate-text">0,8</span></template>
          </fomuls-fraction-simple>
        </div>
        <div class="d-flex flex-row align-center alternate-text mt-2">
          но не менее 1
        </div>
      </div>
      <div class="mx-3 alternate-text">
        =
      </div>
      <div class="mr-2 formul-text">
        {{ rowOfValues.kiugs21 }}
      </div>
    </div>
    </template>
    <div class="mt-10">
      <h2 v-if="rowOfValues.isbsm22 === '1'" class="b-indicator__collapse-title">2. Расчет сводной оценки заявки</h2>
      <div class="d-flex align-center">
        <span class="font-size-medium main-dark-color font-weight-bold mr-2">Сводная оценка заявки </span>  <span class="mx-2 alternate-text"> =</span>   <span class="mx-2 text-center" style="font-weight: 600"> сумма баллов<br> по всем показателям </span> <span class="mx-1 alternate-text">×</span>  <span class="formul-text">KI</span>
        <span class="mx-2 alternate-text"> =</span>
        <span class="formul-text">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues['iosb'+rowOfValuesOs+'22'], 3, 'float') }}  <span class="mx-1 alternate-text">×</span>  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.kiugs21, 3, 'float') }}</span>   <span class="mx-2 alternate-text"> =</span>  <span class="font-size-extra-large main-dark-color font-weight-bold">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues['ioki'+rowOfValuesOs+'22'], 3, 'float') }}</span>

      </div>
    </div>
  </div>
</template>

<script>
import FormulsFraction from "@/components/cards/indicatiors/formuls-fraction";
import FomulsFractionSimple from "@/components/cards/indicatiors/formuls-fraction-simple";
import BlockPn from "@/components/cards/indicatiors/details/blockPn";

export default {
  name: "generalValue",
  components: {BlockPn, FomulsFractionSimple, FormulsFraction},
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  computed: {
    rowOfValuesOs() {
      let valueOs='o';
      switch (this.$route.query.fo) {
        case '1':
          valueOs = 'o';
          break;
        case '2':
          valueOs = 'v';
          break;
        case '3':
          valueOs = 'z';
          break;
      }
      return valueOs;
    }
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',null,false, 'color: #C6C8CB;')
    }
  },
}
</script>

<style lang="sass" scoped>

</style>