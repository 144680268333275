import {mapActions, mapGetters} from "vuex";

export const setAppealFromOneToFourDialog = {
    data() {
        return {
            loadingMenu: {value: false, id: ''},
            appealId: null,
            fileId: null,
            appealCell: null,
            appealValue: null,
            appealAccept: null,
            appealInfo: {
                ugs: null,
                cell: null,
                nameForm: null
            }
        }
    },
    created() {
        if (this.appeal_loading === false)
            this.initCellsFilesForAppeals();
    },
    watch: {
        appealsLength() {
            this.initCellsFilesForAppeals();
        },
        get_replace_id(){
            this.loadingDataTable();
        }
    },
    computed: {
        ...mapGetters('tables', {
            appeal_data: 'get_appeals_data',
            appeal_error: 'get_appeals_error',
            appeal_loading: 'get_appeals_loading',
        }),
        ...mapGetters('auth', {
            get_replace_id: 'get_replace_id'
        }),
        appealsLength() {
            return this.appeal_data.counter
        },
    },
    methods: {
        ...mapActions('tables', {pushNewElement: 'pushNewElement'}),
        initCellsFilesForAppeals() {
            // if (!this.appeal_loading && this.appeal_data.counter > 0) {
            //     const fields = this.dataFields.map(el => el.name)
            //     const item = this.appeal_data.data.find(appealElement => fields.includes(appealElement.jsonId));
            //     this.fileId = item ? item.fileId : null;
            //     this.appealCell = item ? item : null
            // }
        },
        closingAppealDialog() {
            this.appealId = null;
            this.appealAccept = null;
            this.appealCell = null;
            this.fileId = null;
        },
        async openDialog(value, dataField = '', cell, row = this.serverData) {
            this.appealValue = value;
            this.appealInfo = {
                ugs: '',
                cellInfo: {...cell,...this.findInfoForCell(dataField), dataField: dataField},
                rowId: row.id,
                nameForm: this.appealInfo.nameForm,
                orgId: row.sprOrgId
            }
            if (value?.id) {
                this.appealId = value.id;
                this.appealAccept = value.accept;
            }
            await this.$refs["appeal-dialog"].openDialog();
        },
        saveValue(e) {
            const el = {
                dataField: e.dataField,
                fileId: e.fileId,
                decision: e.decision,
                accept: e.accept,
                appeal: e.appeal
            };
            // this.files.push(el);
            this.pushNewElement({...e, ...el});
            this.fileId = e.fileId;
            this.appealCell = el;
           this.serverData[e.dataField] = {
                oldValue: e.oldValue,
                newValue: e.newValue,
                id: e.id,
                accept: e.accept
            };
            this.$refs["appeal-dialog"].closeDialog();
        }
    }
}