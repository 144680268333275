import request from "@/services/request";

export default {
    namespaced: true,
    state: {
        checkFilledContacts: false,
        contact: null,
        pages: [],
        mainOrg: null,
        mainOrgLoading: false,
        mainOrgError: null,
        statusOrg: null,
        statusOrgLoading: false,
        statusOrgError: null,
        filials: [],
        filialsLoading: false,
        filialsError: null,
    },
    mutations: {
        ['ADD_STATUS_CHECK_FILLED_CONTACTS'](state, payload) {
            state.checkFilledContacts = payload;
        },
        ['ADD_CONTACTS'](state, payload) {
            state.contact = payload;
        },
        ['ADD_PAGES'](state, payload) {
            state.pages.push(payload);
        },
        ['MAIN_ORG_INFO'](state, payload) {
            state.mainOrg = payload;
        },
        ['MAIN_ORG_INFO_LOADING'](state, payload) {
            state.mainOrgLoading = payload;
        },
        ['MAIN_ORG_INFO_ERROR'](state, payload) {
            state.mainOrgError = payload;
        },
        ['STATUS_INFO'](state, payload) {
            state.statusOrg = payload;
        },
        ['STATUS_INFO_LOADING'](state, payload) {
            state.statusOrgLoading = payload;
        },
        ['STATUS_INFO_ERROR'](state, payload) {
            state.statusOrgError = payload;
        },
        ['FILIALS_ORG_INFO'](state, payload) {
            state.filials = payload;
        },
        ['FILIALS_INFO_LOADING'](state, payload) {
            state.filialsLoading = payload;
        },
        ['FILIALS__INFO_ERROR'](state, payload) {
            state.filialsError = payload;
        },
    },
    actions: {
        async fetchCheckFilled({commit}, payload) {
            const {contacts} = payload
            if (Array.isArray(contacts) && contacts && contacts.length > 0 && contacts[0].fio.length > 0) {
                commit('ADD_STATUS_CHECK_FILLED_CONTACTS', true)
            } else if (contacts && contacts.position && contacts.firstName.length > 0 && contacts.email.length > 0 && contacts.position.length > 0) {
                commit('ADD_STATUS_CHECK_FILLED_CONTACTS', true)
                commit('ADD_CONTACTS', contacts)
            }
        },
        setPage({commit, state}, payload) {
            if (state.pages.findIndex(el => el.path === payload.path) === -1)
                commit('ADD_PAGES', payload)
        },
        async getOrgInfo({commit, rootGetters}) {
            if (rootGetters['keycloak/isFounder'] && !rootGetters['keycloak/isAdmin'] || rootGetters['keycloak/isRegion'] && !rootGetters['keycloak/isAdmin']) return;
            try {
                commit('MAIN_ORG_INFO_LOADING', true);
                let organization;
                if (rootGetters['keycloak/isAdmin'] && (rootGetters['auth/get_replace_id'] || rootGetters['auth/get_replace_id']))
                organization = await request({
                    endpoint: `/api/spr/getOrgById?sprOrgId=${
                        rootGetters['auth/get_replace_id'] ? rootGetters['auth/get_replace_id'] : rootGetters['auth/get_replace_id_admin']
                    }`,
                    method: 'GET',
                });
                else if (!rootGetters['keycloak/isAdmin'] && !rootGetters['keycloak/isFounder'])
                    organization = await request({
                        endpoint: `/api/spr/getOrgById?sprOrgId=${
                            rootGetters['auth/get_replace_id'] ? rootGetters['auth/get_replace_id'] : rootGetters['auth/get_replace_id_admin']
                        }`,
                        method: 'GET',
                    });
                if (organization.status === 200) {
                    commit('MAIN_ORG_INFO', organization.data)
                    commit('MAIN_ORG_INFO_LOADING', false)
                    commit('MAIN_ORG_INFO_ERROR', null)
                } else {
                    commit('MAIN_ORG_INFO_LOADING', false)
                    commit('MAIN_ORG_INFO_ERROR', organization.error)
                }
            } catch (e) {
                commit('MAIN_ORG_INFO_LOADING', false)
                commit('MAIN_ORG_INFO_ERROR', e)
            }
        },
        async getStatusInfo({commit, rootGetters}) {
            if (!rootGetters['keycloak/isAdmin'] && rootGetters['keycloak/isRegion']) return;
            try {
                commit('STATUS_INFO_LOADING', true);
                let organization;
                if (rootGetters['keycloak/isAdmin'] && rootGetters['auth/get_replace_id_admin'])
                organization = await request({
                    endpoint: `/api/block/getBlock?orgId=${rootGetters['auth/get_replace_id_admin']}`,
                    method: 'GET',
                });
                else if(!rootGetters['keycloak/isAdmin'])
                    organization = await request({
                        endpoint: `/api/block/getBlock?orgId=${rootGetters['auth/get_replace_id_admin']}`,
                        method: 'GET',
                    });
                if (organization.status === 200) {
                    commit('STATUS_INFO', organization.data)
                    commit('STATUS_INFO_LOADING', false)
                    commit('STATUS_INFO_ERROR', null)
                } else {
                    commit('STATUS_INFO_LOADING', false)
                    commit('STATUS_INFO_ERROR', organization.error)
                }
            } catch (e) {
                commit('STATUS_INFO_LOADING', false)
                commit('STATUS_INFO_ERROR', e)
            }
        },
        async changeStatusInfo({commit, rootGetters}, obj) {
            const {type, block} = obj;
            try {
                commit('STATUS_INFO_LOADING', true);
                const organization = await request({
                    endpoint: `/api/block/setBlock?orgId=${rootGetters['auth/get_replace_id_admin']}&type=${type}&flag=${block}`,
                    method: 'post',
                });
                if (organization.status === 200) {
                    commit('STATUS_INFO', organization.data)
                    commit('STATUS_INFO_LOADING', false)
                    commit('STATUS_INFO_ERROR', null)
                } else {
                    commit('STATUS_INFO_LOADING', false)
                    commit('STATUS_INFO_ERROR', organization.error)
                }
            } catch (e) {
                commit('STATUS_INFO_LOADING', false)
                commit('STATUS_INFO_ERROR', e)
            }
        },
        initInfoForStatus({state, dispatch}) {
            if (!state.statusOrg?.hasContacts && state.statusOrgLoading === false)
                dispatch('notifications/addNotification', {
                    notificationStatus: 'error',
                    notificationMessage: 'Не заполненые котактные данные организации',
                    timeout: 3000
                }, {root: true})
            if (!state.statusOrg?.hasScans && state.statusOrgLoading === false)
                dispatch('notifications/addNotification', {
                    notificationStatus: 'error',
                    notificationMessage: 'Загружены не все файлы в личном кабинете',
                    timeout: 3000
                }, {root: true})
        },
        async getFilialsOrgInfo({commit, rootGetters}) {
            if (rootGetters['keycloak/isFounder'] && !rootGetters['keycloak/isAdmin'] || rootGetters['keycloak/isRegion'] && !rootGetters['keycloak/isAdmin']) return;
            try {
                commit('FILIALS_INFO_LOADING', true);
                let organization;
                if (rootGetters['keycloak/isAdmin'] && (rootGetters['auth/get_replace_id'] || rootGetters['auth/get_replace_id_admin']))
                organization = await request({
                    endpoint: `/api/spr/getChildByOwnerId?sprOrgId=${rootGetters['auth/get_replace_id'] ? rootGetters['auth/get_replace_id'] : rootGetters['auth/get_replace_id_admin']}`,
                    method: 'GET',
                });
                else if (!rootGetters['keycloak/isAdmin'] && !rootGetters['keycloak/isFounder'])
                    organization = await request({
                        endpoint: `/api/spr/getChildByOwnerId?sprOrgId=${rootGetters['auth/get_replace_id'] ? rootGetters['auth/get_replace_id'] : rootGetters['auth/get_replace_id_admin']}`,
                        method: 'GET',
                    });
                if (organization.status === 200) {
                    commit('FILIALS_ORG_INFO', organization.data)
                    commit('FILIALS_INFO_LOADING', false)
                    commit('FILIALS__INFO_ERROR', null)
                } else {
                    commit('FILIALS_INFO_LOADING', false)
                    commit('FILIALS__INFO_ERROR', organization.error)
                }
            } catch (e) {
                commit('FILIALS_INFO_LOADING', false)
                commit('FILIALS__INFO_ERROR', e)
            }
        }

    },
    getters: {
        get_check_filled_status(state) {
            return state.checkFilledContacts;
        },
        get_contact_data(state) {
            return state.contact;
        },
        getFilials(state) {
            return {data: state.filials, error: state.filialsError, loading: state.filialsLoading}
        },
        getMainOrg(state) {
            return {data: state.mainOrg, error: state.mainOrgError, loading: state.mainOrgLoading}
        },
        canOrgEdit(state, rootGetters) {
            return state.statusOrg?.hasContacts && state.statusOrg?.hasScans || rootGetters['keycloak/isAdmin'];
        },
        simpleBlock(state) {
            return state.statusOrg.block;
        },
        statusOrg(state) {
            return state.statusOrg;
        },
        konvertBlock(state) {
            return state.statusOrg.konvert;
        },
    }
}