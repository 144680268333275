<template>
  <v-form
      class="contact-card__input"
      ref="form_contact"
      v-model="valid"
      lazy-validation
  >
    <div class="info-card__field">
      <div class="info-card__name">
        Фамилия Имя Отчество:
      </div>
      <v-text-field
          v-model="form.fio"
          :rules="[rules.required]"
          class="info-card__cell"
          color="#00599B"
          outlined
          dense
      />
    </div>
    <div v-if="role ==='director'" class="info-card__field">
      <div class="info-card__name">
        ФИО (в родительном падеже):
      </div>
      <v-text-field
          v-model="form.fioRp"
          :rules="[rules.required]"
          class="info-card__cell"
          color="#00599B"
          outlined
          dense
      />
    </div>
    <div class="info-card__field">
      <div class="info-card__name">
        Должность:
      </div>
      <v-text-field
          v-model="form.position"
          :rules="[rules.required, rules.dolg]"
          class="info-card__cell"
          color="#00599B"
          outlined
          dense
      />
    </div>
    <div v-if="role ==='director'" class="info-card__field">
      <div class="info-card__name">
        Должность (в родительном падеже):
      </div>
      <v-text-field
          v-model="form.positionRp"
          :rules="[rules.required, rules.dolg]"
          class="info-card__cell"
          color="#00599B"
          outlined
          dense
      />
    </div>

    <div class="info-card__field">
      <div class="info-card__name">
        Контактный e-mail:
      </div>
      <v-text-field
          v-model="form.email"
          :rules="[rules.required, rules.email]"
          class="info-card__cell"
          color="#00599B"
          outlined
          dense
      />
    </div>
    <div class="info-card__field">
      <div class="info-card__name pb-0">
        Номер телефона:
      </div>
      <VuePhoneNumberInput
          class=""
          v-model="form.workPn"
          :only-countries="['RU', 'BY', 'AM', 'KG', 'TJ']"
          default-country-code="RU"
          valid-color="#00599B"
          error-color="#ED393E"
          :error="!validator.workPn"
          color="#546e7a"
          @update="updatePhoneValue($event, 'workPn','workPn', 'workPhoneFormattedNumber')"
          :translations="translations1"
      />
      <v-text-field
          v-model="form.workPnAdd"
          :rules="[]"
          class="info-card__cell dop-number"
          color="#00599B"
          label="Добавочный номер"
          outlined
          dense
      />
    </div>
    <div class="info-card__field mt-4" >
      <div class="info-card__name pb-0">
        Мобильный телефон:
      </div>
      <VuePhoneNumberInput
          class=""
          v-model="form.personalPn"
          :only-countries="['RU', 'BY', 'AM', 'KG', 'TJ']"
          default-country-code="RU"
          valid-color="#00599B"
          error-color="#ED393E"
          :error="!validator.personalPn"
          color="#546e7a"
          @update="updatePhoneValue($event, 'personalPn','personalPn', 'personalPhoneFormattedNumber')"
          :translations="translations1"
      />
    </div>
    <div class="d-flex justify-end mt-4">
      <v-btn
          :disabled="!isOnButtonSave || savingData"
          @click="sendData"
          @keyup.enter="sendData"
          :loading="savingData"
          :class="['blue__v-btn mr-2', {'button_disabled': !isOnButtonSave}]">
        Сохранить
      </v-btn>
      <v-btn v-if="canCloseEnter" class="white__v-btn" @click.prevent="cancel">Отмена</v-btn>
    </div>
  </v-form>

</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input/dist/vue-phone-number-input.common';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  name: "CardInput",
  components: {
    VuePhoneNumberInput,
  },
  props: {
    value: {
      type: Object,
    },
    sprData:{
      type: Object,
      default: () => {return {data: [], loading: false}}
    },
    savingData:{
      type: Boolean,
      default: false
    },
    role: {
      type: String,
      default: 'FILLER'
    },
    ignoreMobilePhoneNumber: {
      type: Boolean,
      default: false
    },
    canCloseEnter:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      valid: false,
      isOnButtonSave: false,
      translations1:{
        countrySelectorLabel: 'Код страны',
        countrySelectorError: 'Неверный код страны',
        phoneNumberLabel: 'Номер телефона',
        example: 'Пример :'
      },
      validator: {
        region: true,
        category: true,
        email: true,
        workPnAdd: true,
        workPn: false,
        personalPn: false,
        organization: true,
        position: true,
        fio: true,
        positionRp: true,
        fioRp: true,
      },
      form: {
        fio: this.value.fio,
        fioRp: this.value.fioRp,
        organization: this.value.organization,
        position: this.value.position,
        positionRp: this.value.positionRp,
        email: this.value.email,
        workPn: this.value.workPn,
        workPnAdd: this.value.workPnAdd,
        personalPn: this.value.personalPn,
        workPhoneFormattedNumber: this.value.workPhoneFormattedNumber,
        personalPhoneFormattedNumber: this.value.personalPhoneFormattedNumber,
        personID: this.value.personID
      },
      rules: {
        required: value => !!value || 'Обязательное поле!',
        counter: value => value.length > 10 || 'Поле не может содержать меньше 10 символов',
        counter3: value => value.length >= 3 || 'Поле не может содержать меньше 3 символов',
        dolg: value => value.length >= 5 || 'Поле не может содержать меньше 5 символов',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неверно указан адрес почты'
        },
      },
    }
  },
  methods: {
    inputPhone(str,  variable){
      if (!str)
        this.form[variable] = ''
    },
    updatePhoneValue(obj, validateVariable, variable, formattedVariable){
      this.form[variable] = obj.e164;
      this.form[formattedVariable] = obj.e164;
        this.validator[validateVariable] = obj.isValid
        this.onChange()
    },
    async validate() {
      this.$refs.form_contact.validate()
    },
    async sendData(){
      await this.validate();
      this.validator.personalPn = this.validator.personalPn || this.ignoreMobilePhoneNumber;
      const check = Object.values(this.validator);
      if (this.valid && check.every(el => el === true)) {
        this.$emit('input', {
          ...this.value,
          ...this.form,
          personalPn: this.form.personalPhoneFormattedNumber,
          workPn: this.form.workPhoneFormattedNumber
        })
      }
      else
        console.log("не все данные заполнены")
    },
    onChange() {
      this.isOnButtonSave = true;
    },
    cancel() {
      this.$emit('cancel');
    }
  }

}
</script>

<style lang="sass" >
.dop-number
  padding-top: 4px!important
  margin-left: 8px!important
  max-width: 200px!important
  .v-text-field__details
    display: none
.contacts-autocomplete
  &.theme--light.v-text-field--filled
    >.v-input__control
     > .v-input__slot
        background-color: #E3EFFA
.contact-card__input
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus
    -webkit-text-fill-color: #00599B
    -webkit-box-shadow: 0 0 0px 1000px #E3EFFA inset
  .input-country-selector
    input
      background-color: #E3EFFA!important
      color: #00599B
  .input-phone-number
    //.input-tel__label
    input
      background-color: #E3EFFA!important
      color: #00599B
  .info-card__name
    color: #455058
    font-size: 16px
    margin-right: 21px
    padding-bottom: 20px
    white-space: nowrap
    line-height: 18.75px
    font-weight: 400
  .info-card__cell
    &.v-text-field--outlined fieldset :not(.error--text)
      //&:not(.error--text)
      border-color: #0D72BD
      border-radius: 7px
      max-height: 36px
    //&.error--text
    //  .v-text-field--outlined //fieldset
    //    border-color: #ED393E

    .v-input__slot
      min-height: 20px!important
      max-height: 36px!important
      background: #E3EFFA!important
    .v-text-field__slot
      height: 36px
      input
        color: #00599B!important

  .info-card__field
    display: flex
    align-items: center
  .info-card__button
    margin-top: 10px
    margin-left: 10px
</style>