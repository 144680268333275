import request from "@/services/request";
import store from "@/store";

async function sendFormData(api = '', payload, notificationErrorText = 'ошибка отправки аппеляции '){
    try{
        const res = await request({
            endpoint: api,
            method: 'post',
            body: payload
        });
        if (res.status === 200 || res.status === 201){
            return res.data;
        }else if(res.error && res.error.response.status === 409){
            throw new Error(res.error.response.data.detail);
        }else{
            throw new Error('error forms-2-4 post');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: notificationErrorText+ ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error forms-2-4 post' + e )
    }
}
export {sendFormData}