/**
 * @param str string | number | null | undefined строка
 * @param unit string размерность default px
 * @return string | undefined
 * */
export function convertToUnit(str, unit = 'px') {
    if (str == null || str === '') {
        return undefined
    } else if (Number.isNaN(str)) {
        return String(str)
    } else {
        return `${Number(str)}${unit}`
    }
}

export function mapSpr(spr = [], idName = '', tempFunc = () => {
    return ''
}) {
    const buf = [...spr];
    if (buf.length === 0) return buf;
    return buf.map(el => {
        return {value: el[idName], text: tempFunc(el)}
    })
}

export async function mapHeadersForDialog(spr = [], func = () => {
}) {
    const data = [...spr];
    return data
        .filter(el => func(el))
        .map(el => {
            return {
                "text": el.jobName,
                "shortTooltip": /*el.sprJobTip?.shortText ??*/ null,
                "tooltip": el.jobTip,
                "dataField": el.id,
                "typeVar": "integer",
                "dependence": true,
                "rules": 1,
                "order": 6,
                "emits": true,

            }
        });
}

export function sortArr(data, fieldValue, orderValue, typeCell) {
    const newArr = [...data];
    const directions = {
        asc: 1,
        desc: -1
    };
    const direction = directions[orderValue];
    switch (typeCell) {
        case 'number':
            newArr.sort((a, b) => direction * (a[fieldValue] - b[fieldValue]));
            break;
        case 'string':
            newArr.sort((a, b) => direction * a[fieldValue].localeCompare(b[fieldValue], ["ru", 'en'], {caseFirst: 'upper'}));
            break;
        case 'date':
            newArr.sort((a, b) => direction * (new Date(b[fieldValue]) - new Date(a[fieldValue])));
            break;
        case 'boolean':
            //eslint-disable-next-line
            newArr.sort(a => {if(a[fieldValue] === null) return -1;}).sort((a) => direction * ((a[fieldValue]) ? 1 : -1));
            break;
        default:
            newArr.sort((a, b) => direction * a[fieldValue].localeCompare(b[fieldValue], ["ru", 'en'], {caseFirst: 'upper'}));
            break;
    }
    return newArr;

}