export default {
    countCells: 7,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 3,
            text: 'Код и наименование УГСН (НПС)',
            class: 'd-flex flex-row align-center justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
            // template: (row) => {
            //     return row.sprKcpGroupId + ' - ' + row.sprKcpGroupName
            // }
        },
        {
            dataField: 'npg11',
            cols: 6,
            rows: 1,
            text: 'Лиц с ОВЗ, инвалидов, детей-инвалидов',
            headerClass: 'border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg12',
            cols: 3,
            rows: 1,
            text: 'из обучающихся (искл. квоту иностр.граждан) на 1.10.2021 г.',
            headerClass: 'text-center border-bottom-zero',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 3,
            rows: 1,
            text: "из иностр.граждан, обучающихся по квоте на 1.10.2021 г.",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },

        {
            dataField: 'npg31',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg32',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg33',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg34',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg35',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg36',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row text-left',
            text: `1`,
            order: 6,
            variable: true,
        },
        {
            dataField: 'ndsgo21Vp1',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `3`,
            order: 7,
            variable: true,
        },
        {
            dataField: 'ndsgv21Vp1',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `4`,
            order: 8,
            variable: true,

        },
        {
            dataField: 'ndsgz21Vp1',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `5`,
            order: 9,
            variable: true,
        },
        {
            dataField: 'ndqgo21Vp1',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `6`,
            order: 7,
            variable: true,
        },
        {
            dataField: 'ndqgv21Vp1',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `7`,
            order: 8,
            variable: true,
        },
        {
            dataField: 'ndqgz21Vp1',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `8`,
            order: 9,
            variable: true,
        },
    ]
}
