<template>
<div>
  <v-row dense>
    <v-col cols="10">
      <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
      <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      <p>
        <span class="font-weight-bold mb-1">Форма 5.04. (фрагмент)</span><br>
        Общая численность обучающихся по программам ординатуры, программам ассистентуры-стажировки, программам подготовки научных и научно-педагогических кадров в аспирантуре
        <br>
        по данным формы 1-НК, человек
      </p>
    </v-col>
  </v-row>
  <table>
    <thead>
    <tr>
      <th rowspan="3" colspan="3" class="text-left kcp-td">Уровни и формы образования</th>
      <th rowspan="3" style="vertical-align: bottom">...</th>
      <th colspan="1">Обучающихся <br>(искл. квоту иностр.граждан)</th>
      <th></th>
      <th colspan="3">Обучающихся - иностранных граждан</th>
      <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
      <th rowspan="1" class="border-right-zero" colspan="2"></th>
    </tr>
    <tr>
      <th colspan="1">
      </th>
      <th rowspan="1">

      </th>
      <th colspan="1">
        на усл.общего приема
      </th>
      <th></th>
      <th>обучающихся по квоте
      </th>
      <th colspan="1">
        Всего<br> обучающихся
      </th>
      <th colspan="1" class="border-right-zero">
        Всего иностр.<br>граждан
      </th>
    </tr>
    <tr>
      <th>
        на 1.01.2021 г.
      </th>
      <th>
        …
      </th>
      <th>
        на 1.01.2021 г.
      </th>
      <th>
        …
      </th>
      <th>
        на 1.01.2021 г.
      </th>
      <th rowspan="2" style="border-bottom: 0">
        <br/>
        <span class="font-weight-bold">гр.6 + гр.11</span>
      </th>
      <th rowspan="2" class="border-right-zero" style="border-bottom: 0">

        <br/>
        <span class="font-weight-bold">гр.9 + гр.11</span>
      </th>
    </tr>
    <tr style="border-top: 1px solid #B7CED9">
      <th colspan="1" class="border-right-zero" style="text-align: left"><span>1</span></th>
      <th colspan="2"  style="text-align: left"><span>2</span></th>
      <th>...</th>
      <th >6</th>
      <th>...</th>
      <th>9</th>
      <th>...</th>
      <th>11</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td rowspan="1" class="border-right-zero  text-left" >
        <span class="usl-name ">Ординатура</span>
      </td>
      <td colspan="2" class="text-left kcp-name">все формы обучения</td>
      <td>...</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsors21, 0, 'integer') }}</td>
      <td>...</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nfors21, 0, 'integer') }}</td>
      <td>...</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqors21, 0, 'integer') }}</td>
      <td rowspan="1" class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
      <td class="border-right-zero border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noors21, 0, 'integer') }}</td>
      <td class="border-right-zero border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.niors21, 0, 'integer') }}</td>
    </tr>

    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 10" :key="item"></td>
      <td class="border-bottom-zero border-right-zero border-left-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>

    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
      <td colspan="3" class="border-bottom-zero border-right-zero text-right">
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        Ns
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        ИК
      </td>
    </tr>
    </tbody>
  </table>
  <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
  <div class="d-flex flex-row align-center">
    <formuls-fraction :need-bottom-line="true">
      <template>
        <div class="d-flex flex-row align-end">
          {{ item.title }}
          <div class="little-text ">0</div>
        </div>
      </template>
      <template #bottom>значение<br> показателя</template>
    </formuls-fraction>
    <formuls-fraction :need-bottom-line="false">
      <template>
        <span class="mt-8"> =</span>
      </template>
    </formuls-fraction>
    <formuls-fraction-simple>
      <template>
        ИК
      </template>
      <template #bottom>
        <div class="formul-text" style="  min-height: 49px">
          Ns
        </div>
      </template>
    </formuls-fraction-simple>
    <formuls-fraction :need-bottom-line="false" class="mx-1">
      <template>
        <span class="mt-8 alternate-text"> × 100</span>
      </template>
    </formuls-fraction>
    <formuls-fraction :need-bottom-line="false">
      <template>
        <span class="mt-8"> =</span>
      </template>
    </formuls-fraction>
    <formuls-fraction-simple>
      <template>

        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.niors21, 0, 'integer') }}
      </template>
      <template #bottom>
        <div class="formul-text" style="  min-height: 49px">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noors21, 0, 'integer') }}
        </div>
      </template>
    </formuls-fraction-simple>
    <formuls-fraction :need-bottom-line="false" class="mx-1">
      <template>
        <span class="mt-8 alternate-text"> × 100</span>
      </template>
    </formuls-fraction>
    <formuls-fraction :need-bottom-line="false">
      <template>
        <span class="mt-8"> =</span>
      </template>
    </formuls-fraction>
    <formuls-fraction :need-bottom-line="false">
      <template>
        <span class="mt-8"> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
      </template>
    </formuls-fraction>
  </div>
  <p>ИК	-	иностранных граждан, обучающихся по программам ассистентуры-стажировки на 01.01.2021 года</p>
  <p>Ns	-	всего обучающихся по программам ординатуры на 01.01.2021 года</p>
  <p v-if="rowOfValues.data[item.dataFieldName+'nf22'] !== '0'" class="mb-4">Если значение показателя Ns менее 10, фактическое значение показателя не рассчитывается, а его нормированная оценка определяется в соответствии с пунктом 7 Методики (по средним)</p>

</div>
</template>

<script>
export default {
name: "o43",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false)
    }
  },
}
</script>

<style lang="sass" scoped>

</style>