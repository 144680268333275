<template>
<div>
  <div class="d-flex flex-row">
    <div class="pr-2" style="min-width: 850px; max-width: 850px">
      <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
      <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      <p>
        <span class="font-weight-bold mb-1">Форма 5.07. (фрагмент)</span><br>
        Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета
        <br>
        без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации
        <br>
        по данным Мониторинга приемной кампании, человек
      </p>
    </div>
    <div>
      <h2 class="b-indicator__collapse-title">&nbsp;</h2>
      <h3 class="b-indicator__collapse-subtitle"> &nbsp;</h3>
      <p>
        <span class="font-weight-bold mb-1">Форма 5.17. (фрагмент)</span><br>
        Дополнительные сведения о целевом обучении и о приеме на очную форму обучения по программам ординатуры, программам ассистентуры-стажировки
        <br>
        без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации
        <br>
        по данным Мониторинга целевого обучения (МЦО) и Мониторинга приемной кампании (МПК), человек
      </p>
    </div>
  </div>
  <table>
    <thead>
    <tr>
      <th rowspan="4" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
      <th rowspan="3" colspan="1"></th>
      <th colspan="1" style="min-width: 130px;">Зачислено на обучение<br> за счет средств:</th>
      <th></th>
      <th rowspan="5" class="border-right-zero" style="min-width: 50px;"></th>
      <th rowspan="4" colspan="3" class="text-left kcp-td" style="vertical-align: bottom">Код и наменование УГСН (НПС)</th>
      <th rowspan="3" style="vertical-align: bottom">...</th>
      <th colspan="3">Зачислено на обучение<br> за счет средств:</th>
      <th colspan="1">Подано заявлений<br> о приеме</th>
    </tr>
    <tr>
      <th colspan="1">
        в 2021/2022 учебном<br> году за счет:
      </th>
      <th></th>
      <th colspan="3">
        в 2021 г.
      </th>
      <th rowspan="1">
        в 2021 г.
      </th>
    </tr>
    <tr>
      <th>
        федерального <br>бюджета
      </th>
      <th rowspan="1" colspan="1"></th>
      <th>бюджета<br> субъекта РФ</th>
      <th>местного бюджета</th>
      <th>на платное обучение</th>
      <th>всего</th>
    </tr>
    <tr>
      <th>
        ...
      </th>
      <th>
        очно
      </th>
      <th>
        ...
      </th>
      <th>
        ...
      </th>
      <th>
        очно
      </th>
      <th>
        очно
      </th>
      <th>
        очно
      </th>
      <th>
        очно
      </th>
    </tr>
    <tr style="border-top: 1px solid #B7CED9">
      <th colspan="3" style="text-align: left">
        <span>1</span><span style="margin-left: 66px">2</span>
      </th>
      <th>...</th>
      <th>9</th>
      <th>...</th>
      <th colspan="3" style="text-align: left">
        <span>1</span><span style="margin-left: 66px">2</span>
      </th>
      <th>...</th>
      <th>6</th>
      <th>7</th>
      <th>8</th>
      <th>9</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td colspan="3" style="text-align: left">
        <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
        <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
      </td>
      <td>...</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfo21, 0, 'integer') }}</td>
      <td>...</td>
      <td class="border-bottom-zero border-right-zero"></td>
      <td colspan="3" style="text-align: left">
        <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
        <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
      </td>
      <td>...</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsro21, 0, 'integer') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsmo21, 0, 'integer') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npspo21, 0, 'integer') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qasgo21, 0, 'integer') }}</td>
    </tr>

    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
      <td colspan="10" class="border-bottom-zero border-right-zero ">
        <img class="ww-100" :src="require('@/assets/images/brackets/bracket-10.svg')" alt=""/>
      </td>
      <td class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
      <td colspan="10" class="border-bottom-zero border-right-zero formul-text-table">
        ЗПК =  {{ a() }}
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        ЗМ
      </td>
    </tr>

    </tbody>
  </table>
  <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
  <div class="d-flex flex-row align-center">
    <formuls-fraction :need-bottom-line="true">
      <template>
        <div class="d-flex flex-row align-end">
          {{ item.title }}
          <div class="little-text ">0</div>
        </div>
      </template>
      <template #bottom>значение</template>
    </formuls-fraction>
    <formuls-fraction :need-bottom-line="false">
      <template>
        <span class="mt-7 alternate-text"> = </span>
      </template>
    </formuls-fraction>
    <formuls-fraction-simple>
      <template>
        ЗМ
      </template>
      <template #bottom>
        <span class="formul-text" style="  min-height: 49px">ЗПК</span>
      </template>
    </formuls-fraction-simple>
    <formuls-fraction :need-bottom-line="false">
      <template>
        <span class="mt-7 alternate-text"> = </span>
      </template>
    </formuls-fraction>
    <formuls-fraction-simple>
      <template>
        {{  $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qasgo21, 0, 'integer')  }}

      </template>
      <template #bottom>
        <span class="formul-text" style="  min-height: 49px">{{  a()  }}</span>
      </template>
    </formuls-fraction-simple>
    <formuls-fraction :need-bottom-line="false">
      <template>
        <span class="mt-8"> = </span>
      </template>
    </formuls-fraction>
    <formuls-fraction :need-bottom-line="false">
      <template>
        <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
      </template>
    </formuls-fraction>
  </div>
  <p>ЗМ	-	всего заявлений на поступление на I курс, поданных в 2021 году (за счет всех источников финансирования)</p>
  <p class="mb-4">ЗПК	-	всего абитуриентов, принятых в 2021 году на обучение на I курс (за счет всех источников финансирования)</p>
</div>
</template>

<script>
import specSum from "@/helpers/indicators/sumM12M13O12";

export default {
name: "o12",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false)
    },
    a(){
      return specSum(this.rowOfValues)
    }
  },
}
</script>

<style lang="sass" scoped></style>