<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
      </v-col>
    </v-row>
    <block-od v-bind="$props"/>
    <block-npr v-bind="$props"/>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        (
      </div>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ОД
            <div class="little-text ">2018</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              НПР
              <div class="little-text ">2018</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ОД
            <div class="little-text ">2019</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              НПР
              <div class="little-text ">2019</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ОД
            <div class="little-text ">2020</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              НПР
              <div class="little-text ">2020</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        )
      </div>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 ml-1 alternate-text"> ÷</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" class="mr-1">
        <template>
          <span class="mt-8"> n </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        (
      </div>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.doorg19, 1, 'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr19,1) }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.doorg20,1, 'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr20,1) }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.doorg21, 1, 'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr21, 1) }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        )
      </div>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 ml-1 alternate-text">  ÷</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" class="mr-1">
        <template>
          <span class="mt-8">   {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnprQ3, 0, 'integer') }}</span>
        </template>
      </formuls-fraction>

      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>OДk	-	объем всех поступлений образовательной организации из всех источников в k-ом году</p>
    <p>НПРk	-	численность научно-педагогических (научных) работников в k-ом году</p>
    <p class="mb-4">n	-	число лет, за которые имеются отчетные данные НПРk</p>
    <block-g v-bind="$props"/>
  </div>
</template>

<script>
import BlockOd from "@/components/cards/indicatiors/details/blockOd";
import BlockNpr from "@/components/cards/indicatiors/details/blockNpr";
import BlockG from "@/components/cards/indicatiors/details/blockG";
export default {
  name: "a35",
  components: {BlockG, BlockNpr, BlockOd},
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {

  },
}
</script>

<style lang="sass" scoped>

</style>