<template>
<ContainerReferenceMain />
</template>


<script>
import ContainerReferenceMain from '@/containers/reference/ContainerReferenceMain.vue';

export default {

	components: {
		ContainerReferenceMain,
	},
	data() {
			return {

		}
	},
}

</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"

</style>