<template>
  <div>
<!--    <v-btn v-if="path !== '/forms'" class="blue__v-btn mb-3" @click="$router.push('/forms')">Назад</v-btn>-->
    <router-view/>
  </div>

</template>

<script>
export default {
  name: "lkLayoutForm",
  data() {
    return {
    }
  },
  computed: {
    path() {
      return this.$route.fullPath
    }
  },

}
</script>

<style scoped>

</style>