<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      </v-col>
    </v-row>
    <block-od :item="item" :row-of-values="rowOfValues"/>
    <v-row dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.03. (фрагмент)</span><br>
          Общая численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры
          <br>
          по данным формы ВПО-1, человек
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Уровни и формы образования</th>
        <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
        <th rowspan="3" style="vertical-align: bottom">...</th>
        <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
        <th rowspan="3" style="vertical-align: bottom">...</th>
        <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
      </tr>
      <tr>
        <th colspan="3">
          всего
        </th>
        <th colspan="3"></th>
<!--        <th colspan="3">-->
<!--          Всего обучающихся-->
<!--        </th>-->
      </tr>
      <tr>
        <th>
          на 1.10.2018 г.
        </th>
        <th>
          на 1.10.2019 г.
        </th>
        <th>
          на 1.10.2020 г.
        </th>

        <th>
          на 1.10.2018 г.
        </th>
        <th>
          на 1.10.2019 г.
        </th>
        <th>
          на 1.10.2020 г.
        </th>
        <th rowspan="2" style="border-bottom: 0">
          на 1.10.2018 г.<br/>
          <span class="font-weight-bold">гр.3 + гр.8</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">
          на 1.10.2019 г.<br/>
          <span class="font-weight-bold">гр.4 + гр.9</span>
        </th >
        <th rowspan="2" class="border-right-zero"  style="border-bottom: 0">
          на 1.10.2020 г.<br/>
          <span class="font-weight-bold">гр.5 + гр.10</span>
        </th>

      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="1" class="border-right-zero" style="text-align: left"><span>1</span></th>
        <th colspan="2" style="text-align: left"><span >2</span></th>
        <th v-for="(item) in 3" :key="item">{{ item + 2 }}</th>
        <th>...</th>
        <th v-for="(item) in 3" :key="item">{{ item + 7 }}</th>
        <th>...</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td rowspan="3" style="text-align: left; vertical-align: top" class="border-right-zero pt-1" >
          <span class="usl-name">Бакалавриат</span>
        </td>
        <td colspan="2" class="text-left kcp-name">очная форма</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbco18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbco19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbco20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbco18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbco19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbco20, 0, 'integer') }}</td>
        <td>...</td>
        <td rowspan="9" class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobco18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobco19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobco20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">очно-заочная форма</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbcv18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbcv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbcv20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbcv18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbcv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbcv20, 0, 'integer') }}</td>
        <td>...</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobcv18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobcv19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobcv20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">заочная форма</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbcz18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbcz19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbcz20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbcz18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbcz19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbcz20, 0, 'integer') }}</td>
        <td>...</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobcz18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobcz19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobcz20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td rowspan="3" style="text-align: left; vertical-align: top" class="border-right-zero pt-1" >
          <span class="usl-name">Специалитет</span>
        </td>
        <td colspan="2" class="kcp-name text-left">очная форма</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspo18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspo19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspo20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspo18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspo19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspo20, 0, 'integer') }}</td>
        <td>...</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospo18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospo19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospo20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">очно-заочная форма</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspv18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspv20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspv18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspv20, 0, 'integer') }}</td>
        <td>...</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospv18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospv19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospv20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">заочная форма</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspz18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspz19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspz20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspz18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspz19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspz20, 0, 'integer') }}</td>
        <td>...</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospz18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospz19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospz20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td rowspan="3" style="text-align: left; vertical-align: top" class="border-right-zero pt-1" >
          <span class="usl-name">Магистратура</span>
        </td>
        <td colspan="2" class="text-left kcp-name">очная форма</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgo18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgo19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgo20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgo18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgo19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgo20, 0, 'integer') }}</td>
        <td>...</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgo18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgo19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgo20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">очно-заочная форма</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgv18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgv20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgv18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgv20, 0, 'integer') }}</td>
        <td>...</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgv18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgv19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgv20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">заочная форма</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgz18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgz19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgz20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgz18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgz19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgz20, 0, 'integer') }}</td>
        <td>...</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgz18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgz19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgz20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 12" :key="item"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero text-right">сумма по очной форме:</td>
        <td colspan="2" class="border-bottom-zero border-right-zero text-right">
          <div class="d-flex justify-end flex-row align-end formul-text">
              No
            <div class="little-text font-weight-medium mr-1">2018, 2019, 2020</div>
            =
          </div>

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmo18, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmo19, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmo20, 0, 'integer') }}
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero text-right">сумма по очно-заочной форме:</td>
        <td colspan="2" class="border-bottom-zero border-right-zero text-right">
          <div class="d-flex justify-end flex-row align-end formul-text">
              Nv
            <div class="little-text font-weight-medium mr-1">2018, 2019, 2020</div>
            =
          </div>

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmv18, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmv19, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmv20, 0, 'integer') }}
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero text-right">сумма по заочной форме:</td>
        <td colspan="2" class="border-bottom-zero border-right-zero text-right">
          <div class="d-flex justify-end flex-row align-end formul-text">
              Nz
            <div class="little-text font-weight-medium mr-1">2018, 2019, 2020</div>
            =
          </div>

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmz18, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmz19, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmz20, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.04. (фрагмент)</span><br>
          Общая численность обучающихся по программам ординатуры, программам ассистентуры-стажировки, программам подготовки научных и научно-педагогических кадров в аспирантуре
          <br>
          по данным формы 1-НК, человек
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Уровни и формы образования</th>
        <th colspan="2">Всего обучающихся<br>
          (вкл. квоту иностр.гражд.)
        </th>
        <th colspan="1">Обучающихся<br>
          (искл. квоту иностр.гражд.)
        </th>
        <th colspan="1">
        </th>
        <th colspan="1">Иностранных граждан,<br>
          обучающихся по квоте
        </th><th colspan="1">
      </th>
         <th rowspan="4" class="border-right-zero" style="min-width: 160px;border-bottom: 0!important;"></th>
        <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
      </tr>
      <tr>
        <th colspan="3">

        </th>
        <th colspan="3"></th>
        <!--        <th colspan="3">-->
        <!--          Всего обучающихся-->
        <!--        </th>-->
      </tr>
      <tr>
        <th>
          на 1.01.2018 г.
        </th>
        <th>
          на 1.01.2019 г.
        </th>
        <th>
          на 1.01.2020 г.
        </th>

        <th>
         ...
        </th>
        <th>
          на 1.01.2020 г.
        </th>
        <th>
          ...
        </th>
        <th rowspan="2" style="border-bottom: 0">
          на 1.10.2018 г.<br/>
          <span class="font-weight-bold">гр.3 </span>
        </th>
        <th rowspan="2" style="border-bottom: 0">
          на 1.10.2019 г.<br/>
          <span class="font-weight-bold">гр.4 </span>
        </th >
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
          на 1.10.2020 г.<br/>
          <span class="font-weight-bold">гр.5 + гр.10</span>
        </th>

      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="1" class="border-right-zero" style="text-align: left"><span>1</span></th>
        <th colspan="2" style="text-align: left"><span >2</span></th>
        <th v-for="(item) in 3" :key="item">{{ item + 2 }}</th>
        <th>...</th>
        <th >10</th>
        <th>...</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td  style="text-align: left; " class="border-right-zero " >
          <span class="usl-name">Ординатура</span>
        </td>
        <td colspan="2" class="text-left kcp-name">все формы обучения</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noors18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noors19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsors20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqors20, 0, 'integer') }}</td>
        <td>...</td>
        <td rowspan="2" class="border-bottom-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noors18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noors19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noors20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td  style="text-align: left; " class="border-right-zero " >
          <span class="usl-name">Ассистентура</span>
        </td>
        <td colspan="2" class="text-left kcp-name">все формы обучения</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noass18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noass19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsass20, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqass20, 0, 'integer') }}</td>
        <td>...</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noass18, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noass19, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noass20, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 10" :key="item"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 5" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero text-right">сумма по всем формам обучения:</td>
        <td colspan="2" class="border-bottom-zero border-right-zero text-right">
          <div class="d-flex justify-end flex-row align-end formul-text">
            NOA
            <div class="little-text font-weight-medium mr-1">2018, 2019, 2020</div>
            =
          </div>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nooas18, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nooas19, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nooas20, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <h3  class="b-indicator__collapse-subtitle my-3">Расчет приведенных контингентов:</h3>
    <div class="d-flex flex-row ">
      <div class="d-flex flex-column">
        <div class="formul-text"><span class="d-flex flex-row align-end">PN<span class="little-text " style="line-height:12px;">2018</span></span></div>
        <div class="formul-text"><span class="d-flex flex-row align-end">PN<span class="little-text " style="line-height:12px;">2019</span></span></div>
        <formuls-fraction :need-bottom-line="false">
          <span class="d-flex flex-row align-end">PN<span class="little-text " style="line-height:12px;">2020</span></span>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">=</div>
        <div class="alternate-text">=</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">=</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex"><span class="alternate-text ">1,0 ×</span> <span class="d-flex flex-row align-end">No<span class="little-text " style="line-height:12px;">2018</span></span> </div>
        <div class="formul-text d-flex"><span class="alternate-text ">1,0 ×</span> <span class="d-flex flex-row align-end">No<span class="little-text " style="line-height:12px;">2019</span></span></div>
        <formuls-fraction :need-bottom-line="true">
          <template>
            <span class="alternate-text mr-1">1,0 ×</span>
            <span class="d-flex flex-row align-end">No<span class="little-text " style="line-height:12px;">2020</span></span>
          </template>
          <template #bottom>очная <br>
            форма</template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">+</div>
        <div class="alternate-text">+</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">+</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex"><span class="alternate-text ">   0,25 ×</span> <span class="d-flex flex-row align-end">Nv<span class="little-text " style="line-height:12px;">2018</span></span> </div>
        <div class="formul-text d-flex"><span class="alternate-text ">   0,25 ×</span> <span class="d-flex flex-row align-end">Nv<span class="little-text " style="line-height:12px;">2019</span></span></div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="alternate-text mr-1">   0,25 ×</span><span class="d-flex flex-row align-end">Nv<span class="little-text " style="line-height:12px;">2020</span></span></template>
          <template #bottom>очно-заочная <br>
            форма</template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">+</div>
        <div class="alternate-text">+</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">+</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex"><span class="alternate-text ">   0,1 ×</span> <span class="d-flex flex-row align-end">Nz<span class="little-text " style="line-height:12px;">2018</span></span></div>
        <div class="formul-text d-flex"><span class="alternate-text ">   0,1 ×</span> <span class="d-flex flex-row align-end">Nz<span class="little-text " style="line-height:12px;">2019 </span></span></div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="alternate-text mr-1">   0,1 ×</span><span class="d-flex flex-row align-end">Nz<span class="little-text " style="line-height:12px;">2020</span></span></template>
          <template #bottom>заочная <br>
            форма</template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">+</div>
        <div class="alternate-text">+</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">+</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex"><span class="d-flex flex-row align-end">NOA<span class="little-text " style="line-height:12px;">2018</span></span></div>
        <div class="formul-text d-flex"><span class="d-flex flex-row align-end">NOA<span class="little-text " style="line-height:12px;">2019 </span></span></div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="d-flex flex-row align-end">NOA<span class="little-text " style="line-height:12px;">2020</span></span></template>
          <template #bottom>ординатура +
            <br>
            ассистентура</template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">=</div>
        <div class="alternate-text">=</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">=</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text"><span class="alternate-text ">1,0 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmo18, 0, 'integer') }}</div>
        <div class="formul-text"><span class="alternate-text ">1,0 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmo19, 0, 'integer') }}</div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="alternate-text mr-1">1,0 ×</span>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmo20, 0, 'integer') }}</template>
          <template #bottom>очная <br>
            форма</template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">+</div>
        <div class="alternate-text">+</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">+</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text"><span class="alternate-text ">   0,25 ×</span>    {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmv18, 0, 'integer') }}</div>
        <div class="formul-text"><span class="alternate-text ">   0,25 ×</span>    {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmv19, 0, 'integer') }}</div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="alternate-text mr-1">   0,25 ×</span>   {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmv20, 0, 'integer') }}</template>
          <template #bottom>очно-заочная <br>
            форма</template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">+</div>
        <div class="alternate-text">+</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">+</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text"><span class="alternate-text ">   0,1 ×</span>    {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmz18, 0, 'integer') }}</div>
        <div class="formul-text"><span class="alternate-text ">   0,1 ×</span>    {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmz19, 0, 'integer') }}</div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="alternate-text mr-1">   0,1 ×</span>    {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmz20, 0, 'integer') }}</template>
          <template #bottom>заочная <br>
            форма</template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">+</div>
        <div class="alternate-text">+</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">+</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex justify-center ">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nooas18, 0, 'integer') }}</div>
        <div class="formul-text d-flex justify-center ">    {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nooas19, 0, 'integer') }}</div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="d-flex flex-row align-center justify-center">  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nooas20, 0, 'integer') }}</span></template>
          <template #bottom>ординатура +
            <br>
            ассистентура</template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">=</div>
        <div class="alternate-text">=</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">=</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text text-center">   {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.norgp18, 1, 'float') }}</div>
        <div class="formul-text text-center">   {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.norgp19, 1, 'float') }}</div>
        <formuls-fraction :need-bottom-line="true">
          <template>    {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.norgp20, 1, 'float') }}</template>
          <template #bottom>приведенный
            <br>
            контингент
          </template>
        </formuls-fraction>
      </div>
    </div>
    <h3  class="b-indicator__collapse-subtitle my-3">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        (
      </div>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ОД
            <div class="little-text ">2018</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              PN
              <div class="little-text ">2018</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ОД
            <div class="little-text ">2019</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              PN
              <div class="little-text ">2019</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ОД
            <div class="little-text ">2020</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              PN
              <div class="little-text ">2020</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        )
      </div>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 ml-1 alternate-text"> ÷</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" class="mr-1">
        <template>
          <span class="mt-8">n</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        (
      </div>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.doorg19, 1, 'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')})   }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.norgp18, 1, 'float') }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{  $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.doorg20, 1, 'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')})     }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.norgp19, 1, 'float') }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{   $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.doorg21, 1, 'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')})      }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.norgp20, 1, 'float') }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        )
      </div>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 ml-1 alternate-text"> ÷</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" class="mr-1">
        <template>
          <span class="mt-8">   {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.norgpQ3, 0, 'integer') }}</span>
        </template>
      </formuls-fraction>

      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>OДk	-	объем всех поступлений образовательной организации из всех источников в k-ом году;</p>
    <p>PNk	-	приведенный контингент студентов образовательной организации, по данным k-го года;</p>
    <p>n	-	число лет, за которые имеются отчетные данные PNk</p>
    <block-g v-bind="$props"/>
  </div>
</template>

<script>
import BlockOd from "@/components/cards/indicatiors/details/blockOd";
import BlockG from "@/components/cards/indicatiors/details/blockG";

export default {
  name: "b44",
  components: {BlockG, BlockOd},
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>

</style>