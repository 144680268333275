<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      </v-col>
    </v-row>
    <template v-if="rowOfValues.data.orggl22 === '0'">
    <v-row dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.06. (фрагмент)</span><br>
          Контрольные цифры приема, установленные образовательной организации
          <br>
          по данным Министерства науки и высшего образования РФ, мест
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">УГСН, организации</th>
        <th colspan="9">Контрольные цифры приема, установленные образовательной организации</th>
        <th rowspan="4" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
        <th rowspan="1" colspan="2" class=" border-right-zero"><span class="formul-text-table">dgs</span></th>
      </tr>
      <tr>
        <th colspan="3">
          на 2019/2020 учебный год
        </th>
        <th colspan="3">
          на 2020/2021 учебный год
        </th>
        <th colspan="3">
          на 2021/2022 учебный год
        </th>
        <th colspan="2" class="border-right-zero" rowspan="3">
          вес группы<br> научных<br> специальностей<br> в УГСН
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th v-for="(item) in 9" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in rowOfValues.data.tabA11AspQkc[0]" :key="row.id">
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ row.kcpGroup ? row.kcpGroup.code : row.kcpGroupOld.code}}</span>
          <span class="kcp-name">{{ row.kcpGroup ? row.kcpGroup.name : row.kcpGroupOld.name}}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpo19, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpv19, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpz19, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpo20, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpv20, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpz20, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpo21, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpv21, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpz21, 0) }}</td>
        <td v-if="index === 0" :rowspan="rowOfValues.data.tabA11AspQkc[0].length"
            class="border-bottom-zero border-right-zero">
          <v-icon size="30">mdi-close</v-icon>
        </td>
        <td class="border-right-zero">{{
            $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt, 2)
          }}
        </td>
      </tr>
      <tr>
        <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
        <td colspan="11"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
          перевод значений в группу научных специальностей
        </td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей, организации</td>
        <td colspan="11" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-11.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpo19, 2, 'float') }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpv19, 2, 'float') }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpz19, 2, 'float') }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpo20, 2, 'float') }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpv20, 2, 'float') }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpz20, 2, 'float') }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpo21, 2, 'float') }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpv21, 2, 'float') }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpz21, 2, 'float') }}</td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero border-bottom-zero text-left"></td>
        <td colspan="9" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-9.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table" colspan="9">
          КЦО = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpsS3,2) }}
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.07. (фрагмент)</span><br>
          Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета
          <br>
          без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом,
          обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование
          иностранных граждан и лиц без гражданства в Российской Федерации
          <br>
          по данным Мониторинга приемной кампании, человек
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">УГСН</th>
        <th colspan="9">Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета</th>
        <th rowspan="4" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
        <th rowspan="1" class="border-right-zero" colspan="2"><span class="formul-text-table">dgs</span></th>
      </tr>
      <tr>
        <th colspan="3">
          на 2019/2020 учебный год
        </th>
        <th colspan="3">
          на 2020/2021 учебный год
        </th>
        <th colspan="3">
          на 2021/2022 учебный год
        </th>
        <th colspan="2" class="border-right-zero" rowspan="3">
          вес группы<br> научных<br> специальностей<br> в УГСН
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th v-for="(item) in 9" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in rowOfValues.data.tabA11AspNps[0]" :key="row.id">
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ row.kcpGroup ? row.kcpGroup.code : row.kcpGroupOld.code}}</span>
          <span class="kcp-name">{{ row.kcpGroup ? row.kcpGroup.name : row.kcpGroupOld.name}}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfo19, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfv19, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfz19, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfo20, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfv20, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfz20, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfo21, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfv21, 0) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfz21, 0) }}</td>
        <td v-if="index === 0" :rowspan="rowOfValues.data.tabA11AspNps[0].length"
            class="border-bottom-zero border-right-zero">
          <v-icon size="30">mdi-close</v-icon>
        </td>
        <td class="border-right-zero">{{
            $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt, 2)
          }}
        </td>
      </tr>
      <tr>
        <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
        <td colspan="11"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
          перевод значений в группу научных специальностей
        </td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей, организации</td>
        <td colspan="11" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-11.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfo19, 2) }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfv19, 2) }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfz19, 2) }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfo20, 2) }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfv20, 2) }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfz20, 2) }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfo21, 2) }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfv21, 2) }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfz21, 2) }}</td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero border-bottom-zero text-left"></td>
        <td colspan="9" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-9.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table" colspan="9">
          ПРФ = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfsS3, 2) }}
        </td>
      </tr>
      </tbody>
    </table>
    </template>
    <template v-else>
    <v-row dense>
      <v-col cols="10">
        <p>
          Для головных организаций показатели ПРФ и КЦО определяются с учетом их филиалов.
        </p>
        <p>
          <span class="font-weight-bold mb-1">Форма 5.06. (фрагмент)</span><br>
          Контрольные цифры приема, установленные образовательной организации
          <br>
          по данным Министерства науки и высшего образования РФ, мест
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">УГСН, организации</th>
        <th colspan="9">Контрольные цифры приема, установленные образовательной организации</th>
        <th rowspan="4" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
        <th rowspan="1" class=" border-right-zero" colspan="2"><span class="formul-text-table">dgs</span></th>
      </tr>
      <tr>
        <th colspan="3">
          на 2019/2020 учебный год
        </th>
        <th colspan="3">
          на 2020/2021 учебный год
        </th>
        <th colspan="3">
          на 2021/2022 учебный год
        </th>
        <th colspan="2" rowspan="3" class="border-right-zero">
          вес группы<br> научных<br> специальностей<br> в УГСН
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th v-for="(item) in 9" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(row, index) in qkcMappedData.data">
        <tr :key="row.id">
          <td colspan="3" style="text-align: left" class="border-bottom-zero">
            <span class="kcp-code">{{ row.kcpGroup.code }}</span>
            <span class="kcp-name">{{ row.kcpGroup.name }}</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td v-if="index=== 0" :rowspan="qkcMappedData.length" class="border-bottom-zero border-right-zero pt-7">
            <v-icon size="30">mdi-close</v-icon>
          </td>
          <td class="border-bottom-zero border-right-zero"></td>
        </tr>
        <tr v-if="row.mainOrg" :key="row.id + row.orgId.id">
          <td colspan="3" style="text-align: left" class="kcp-name">
            {{ row.orgId.name }}
          </td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpo19,0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpv19,0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpz19,0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpo20,0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpv20,0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpz20,0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpo21,0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpv21,0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qkcpz21,0) }}</td>
          <td class="border-right-zero">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt, 2)
            }}
          </td>
        </tr>
        <tr v-for="filial in row.filials" :key="filial.id + filial.orgId.id">
          <td colspan="3" style="text-align: left" class="kcp-name">
            {{ filial.orgId.name }}
          </td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.qkcpo19, 2) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.qkcpv19, 2) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.qkcpz19, 2) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.qkcpo20, 2) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.qkcpv20, 2) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.qkcpz20, 2) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.qkcpo21, 2) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.qkcpv21, 2) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.qkcpz21, 2) }}</td>
          <td class="border-right-zero ">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(filial.stdgsSt, 2)
            }}
          </td>
        </tr>
      </template>

      <tr>
        <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
        <td colspan="11"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
          перевод значений в группу научных специальностей
        </td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей, организации</td>
        <td colspan="11" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-11.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: left" class="border-bottom-zero">
          <!--          <span class="kcp-code">01.00.00</span>-->
          <span class="usl-name">{{ rowOfValues.data.ugtxt }}</span>
        </td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
      </tr>
      <tr v-for="(org,index) in qkcMappedData.sum" :key="index + org.orgId.id">
        <td colspan="3" style="text-align: left" class="kcp-name">
          {{ org.orgId.name }}
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.qkcpo19, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.qkcpv19, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.qkcpz19, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.qkcpo20, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.qkcpv20, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.qkcpz20, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.qkcpo21, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.qkcpv21, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.qkcpz21, 2) }}</td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero border-bottom-zero text-left"></td>
        <td colspan="9" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-9.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table" colspan="9">
          КЦО = {{
            $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpsS3,2)
          }}
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.07. (фрагмент)</span><br>
          Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета
          <br>
          без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом,
          обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование
          иностранных граждан и лиц без гражданства в Российской Федерации
          <br>
          по данным Мониторинга приемной кампании, человек
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left">УГСН, Наименование организаций</th>
        <th colspan="9">Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета</th>
        <th rowspan="4" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
        <th rowspan="1" class=" border-right-zero" colspan="2"><span class="formul-text-table">dgs</span></th>
      </tr>
      <tr>
        <th colspan="3">
          на 2019/2020 учебный год
        </th>
        <th colspan="3">
          на 2020/2021 учебный год
        </th>
        <th colspan="3">
          на 2021/2022 учебный год
        </th>
        <th colspan="2" rowspan="3" class="border-right-zero">
          вес группы<br> научных<br> специальностей<br> в УГСН
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th v-for="(item) in 9" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(row, index) in npsMappedData.data">
        <tr :key="row.id">
          <td colspan="3" style="text-align: left" class="border-bottom-zero">
            <span class="kcp-code">{{ row.kcpGroup.code }}</span>
            <span class="kcp-name">{{ row.kcpGroup.name }}</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td v-if="index=== 0" :rowspan="npsMappedData.length" class="border-bottom-zero border-right-zero pt-7">
            <v-icon size="30">mdi-close</v-icon>
          </td>
          <td class="border-bottom-zero border-right-zero"></td>
        </tr>
        <tr v-if="row.mainOrg" :key="row.id + row.orgId.id">
          <td colspan="3" style="text-align: left" class="kcp-name">
            {{ row.orgId.name }}
          </td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfo19, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfv19, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfz19, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfo20, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfv20, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfz20, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfo21, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfv21, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npsfz21, 0) }}</td>
          <td class="border-right-zero">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt,2)
            }}
          </td>
        </tr>
        <tr v-for="filial in row.filials" :key="filial.id + filial.orgId.id + Math.random()">
          <td colspan="3" style="text-align: left" class="kcp-name">
            {{ filial.orgId.name }}
          </td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.npsfo19, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.npsfv19, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.npsfz19, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.npsfo20, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.npsfv20, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.npsfz20, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.npsfo21, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.npsfv21, 0) }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(filial.npsfz21, 0) }}</td>
          <td class="border-right-zero ">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(filial.stdgsSt, 2)
            }}
          </td>
        </tr>
      </template>

      <tr>
        <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
        <td colspan="11"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
          перевод значений в группу научных специальностей
        </td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей, организации</td>
        <td colspan="11" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-11.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: left" class="border-bottom-zero kcp-td">
          <!--          <span class="kcp-code">01.00.00</span>-->
          <span class="usl-name">{{ rowOfValues.data.ugtxt }}</span>
        </td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
      </tr>
      <tr v-for="(org,index) in npsMappedData.sum" :key="index + org.orgId.id">
        <td colspan="3" style="text-align: left" class="kcp-name">
          {{ org.orgId.name }}
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.npsfo19, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.npsfv19, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.npsfz19, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.npsfo20, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.npsfv20, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.npsfz20, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.npsfo21, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.npsfv21, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(org.npsfz21, 2) }}</td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero border-bottom-zero text-left"></td>
        <td colspan="9" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-9.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table" colspan="9">
          ПРФ = {{
            $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfsS3, 2)
          }}
        </td>
      </tr>
      </tbody>
    </table>
    </template>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          ПРФ
        </template>
        <template #bottom>
          <div class="formul-text" style="min-height: 49px">
            КЦО
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> × 100 </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['npsfsS3'],2) }}
        </template>
        <template #bottom>
          <div class="formul-text" style="min-height: 49px">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['qkcpsS3'],2) }}
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> × 100 </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName + 'b022'], item.prd+1, 'float')
            }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>ПРФ - число обучающихся, зачисленных на обучение за счет средств федерального бюджета на I курс обучения по всем
      формам обучения за три последних отчетных года, предшествовавших текущему учебному году</p>
    <p class="mb-4">КЦО - сумма контрольных цифр приема по всем формам обучения (без учета отказа образовательной
      организации от установленных контрольных цифр приема) на учебные годы, за которые рассчитывается показатель
      ПРФ</p>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
// eslint-disable-next-line no-unused-vars
const arr1 = [
    'qkcpo19',
    'qkcpv19',
    'qkcpz19',
    'qkcpo20',
    'qkcpv20',
    'qkcpz20',
    'qkcpo21',
    'qkcpv21',
    'qkcpz21',
];
// eslint-disable-next-line no-unused-vars
const arr2 = [
    'npsfo19',
    'npsfv19',
    'npsfz19',
    'npsfo20',
    'npsfv20',
    'npsfz20',
    'npsfo21',
    'npsfv21',
    'npsfz21',
];

export default {
  name: "a11",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  computed: {
    ...mapGetters('checkFilled', {getMainOrg: 'getMainOrg', getFilials: 'getFilials'}),
    filialsIds() {
      return this.getFilials.data.map(el => el.id)
    },
    qkcMappedData() {
      const res = [];
      // const sum = [
      //   {
      //     orgId: {
      //       ...this.getMainOrg.data,
      //       qkcpo19: 0,
      //       qkcpv19: 0,
      //       qkcpz19: 0,
      //       qkcpo20: 0,
      //       qkcpv20: 0,
      //       qkcpz20: 0,
      //       qkcpo21: 0,
      //       qkcpv21: 0,
      //       qkcpz21: 0,
      //       stdgsSt: 0
      //     }
      //   },
      // ];
      // this.getFilials.data.forEach(el => sum.push({
      //   orgId: {...el},
      //   qkcpo19: 0,
      //   qkcpv19: 0,
      //   qkcpz19: 0,
      //   qkcpo20: 0,
      //   qkcpv20: 0,
      //   qkcpz20: 0,
      //   qkcpo21: 0,
      //   qkcpv21: 0,
      //   qkcpz21: 0,
      //   stdgsSt: 0
      // }));
      const sum = this.rowOfValues.data.tabA11AspQkc[this.rowOfValues.data.tabA11AspQkc.length -1];
      let count = 0;
      const allData = [].concat(...this.rowOfValues.data.tabA11AspQkc.filter((el, ind) => ind !== (this.rowOfValues.data.tabA11AspQkc.length- 1)));
      // console.log('tabA11AspQkc', allData)
      const main = this.rowOfValues.data.tabA11AspQkc.find( el =>  el.find(el2 => el2.orgId.id === this.getMainOrg.data.id));
      // console.log('main1', main)
      main.forEach(el => {
        const search = allData.filter(d => /*d.kcpGroup?.id === el.kcpGroup?.id ||*/ d.kcpGroupOld?.id === el.kcpGroupOld?.id);
        const m = search.find(elF => elF.orgId.id === this.getMainOrg.data.id);
        const ff = search.filter(elF => this.filialsIds.includes(elF.orgId.id)).map(elF => {return {...elF, kcpGroup: elF.kcpGroup ?? elF.kcpGroupOld}})
        res.push({...el,kcpGroup: m.kcpGroup ?? m.kcpGroupOld, grouped: true, mainOrg: {...m, kcpGroup: m.kcpGroup ?? m.kcpGroupOld}, filials: ff});
        count += (m ? 1 : 0) + ff.length + 1;
      //   const aaa = sum.find(b => b.orgId.id === el.orgId.id);
      //   const aaaIndex = sum.findIndex(b => b.orgId.id === el.orgId.id);
      //   arr1.forEach(a => {
      //     if (el[a] != null && el[a] !== 'NA'){
      //       if(aaa[a] == null) aaa[a] = 0;
      //       let count2 = el[a].includes(',') ? el[a].replace(',', '.')  : el[a]
      //       aaa[a] += Number.parseFloat(count2);
      //     }
      //   });
      //   sum[aaaIndex] = aaa;
      });

      return {data: res, sum: sum, length: count};
    },
    npsMappedData() {
      const res = [];
      // const sum = [
      //   {
      //     orgId: {
      //       ...this.getMainOrg.data,
      //       npsfo19: 0,
      //       npsfv19: 0,
      //       npsfz19: 0,
      //       npsfo20: 0,
      //       npsfv20: 0,
      //       npsfz20: 0,
      //       npsfo21: 0,
      //       npsfv21: 0,
      //       npsfz21: 0,
      //       stdgsSt: 0
      //     }
      //   },
      // ];
      // this.getFilials.data.forEach(el => sum.push({
      //   orgId: {...el},
      //   npsfo19: 0,
      //   npsfv19: 0,
      //   npsfz19: 0,
      //   npsfo20: 0,
      //   npsfv20: 0,
      //   npsfz20: 0,
      //   npsfo21: 0,
      //   npsfv21: 0,
      //   npsfz21: 0,
      //   stdgsSt: 0
      // }));
      const sum = this.rowOfValues.data.tabA11AspNps[this.rowOfValues.data.tabA11AspNps.length -1];
      let count = 0;
      const allData = [].concat(...this.rowOfValues.data.tabA11AspNps.filter((el, ind) => ind !== (this.rowOfValues.data.tabA11AspNps.length- 1)));
      // console.log('tabA11AspNps', allData)
      const main = this.rowOfValues.data.tabA11AspNps.find( el =>
        el.find(el2 => el2.orgId.id  === this.getMainOrg.data.id)
      )
      // console.log('main2', main)
      main.forEach(el => {
        const search = allData.filter(d => /*d.kcpGroup?.id === el.kcpGroup?.id ||*/ d.kcpGroupOld?.id === el.kcpGroupOld?.id);
        const m = search.find(elF => elF.orgId.id === this.getMainOrg.data.id);
        // console.log('search', search)
        const ff = search.filter(elF => this.filialsIds.includes(elF.orgId.id)).map(elF => {return {...elF, kcpGroup: elF.kcpGroup ?? elF.kcpGroupOld}})
        res.push({...el,kcpGroup: m.kcpGroup ?? m.kcpGroupOld, grouped: true, mainOrg: {...m, kcpGroup: m.kcpGroup ?? m.kcpGroupOld}, filials: ff});
        count += (m ? 1 : 0) + ff.length + 1;
        // const aaa = sum.find(b => b.orgId.id === el.orgId.id);
        // const aaaIndex = sum.findIndex(b => b.orgId.id === el.orgId.id);
        // arr2.forEach(a => {
        //   if (el[a] != null && el[a] !== 'NA'){
        //     if(aaa[a] == null)
        //       aaa[a] = 0;
        //     let count2 = typeof el[a] === 'string' && el[a].includes(',') ? el[a].replace(',', '.')  : el[a]
        //     aaa[a] += Number.parseFloat(count2);
        //   }
        // });
        // sum[aaaIndex] = aaa;
      });
      return {data: res, sum: sum, length: count};
    },
  },
  methods: {
    checkData(value) {
      return value//this.$scripts.TableJs.checkValueWithNullAndReturnND(value.value,'float',value.rnd,false, 'color: #C6C8CB;')
    }
  },
}
</script>

<style lang="sass" scoped>

</style>