<template>
<div>
  <v-row >
    <v-col>
      <p class="mb-0">Блокировка:</p>
      <p>Печать появится после блокировки</p>
    </v-col>
    <v-col class=" d-flex justify-end">
      <v-btn
        class="blue__v-btn"
        @click="blockOrg()"
        :loading="loading"
        :disabled="simpleBlock"
      >
        {{simpleBlock ? 'Разблокировать' : 'Заблокировать'}}
      </v-btn>
    </v-col>
  </v-row>
  <v-divider/>
  <template v-if="simpleBlock">
    <v-row >
      <v-col>
        Печать:
      </v-col>
      <v-col class=" d-flex justify-end">
        <v-btn
            class="success__v-btn"
            :loading="loadingFile"
            :disabled="konvertBlock && !isAdmin"
            @click="loadFile"
        >
          Печать
        </v-btn>
        <!--      <a     :href="`/api/report/getOrgReport/${get_replace_id_admin}`"-->
        <!--             download="renamed.pdf">cccc</a>-->
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <scan-card
        class="mt-0"
        :item="file"
        :disabled="konvertBlock && !isAdmin"
        :upload-to-serv-now="loadingFileToServer.fileType === file.fileType && loadingFileToServer.value"
        @replace-file="replaceFile($event)"
        @upload-file="uploadFile($event)"
        :token="token"
        identifier="fileType"
        >
        </scan-card>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row >
      <v-col>
        <p class="mb-0">Отправить конверт:</p>
      </v-col>
      <v-col class=" d-flex justify-end">
        <v-btn
            class="blue__v-btn"
            @click="blockKonvertOrg"
            :loading="loadingKonvert"
            :disabled="konvertBlock && !isAdmin"
        >
          {{konvertBlock ? 'Конверт отправлен' : 'Отправить конверт'}}
        </v-btn>
      </v-col>
    </v-row>

  </template>
  <kovert-status class="mb-3"/>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import request from "@/services/request";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import {saveFile} from "@/utils/saveFile";
// eslint-disable-next-line no-unused-vars
import {saveBlobFile} from "@/utils/saveFile";
import ScanCard from "@/components/cards/scanCard";
import {setFileFunctions} from "@/mixins/fileFucntions";
import KovertStatus from "@/components/cards/kovertStatus";
import request from "@/services/request";
const type = [
    'ORG_BLOCK',
  'KONVERT_BLOCK'
];
export default {
  name: "PrintBlockOrg",
  components: {KovertStatus, ScanCard},
  mixins: [setFileFunctions],
  data() {
    return {
      loading: false,
      loadingKonvert: false,
      loadingFile: false
    }
  },
  computed: {
    ...mapGetters('checkFilled' , { simpleBlock: 'simpleBlock', konvertBlock: 'konvertBlock', statusOrg: 'statusOrg'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('keycloak', {getAuthData: 'getAuthData', isAdmin: 'isAdmin'}),
    token(){
      return this.getAuthData?.token;
    }
  },
  watch: {
    statusOrg:{
      immediate: true,
      handler(){
        this.getFile();
      }
    }
  },
  created() {
  },
  methods: {
    ...mapActions('checkFilled', {changeStatusInfo:'changeStatusInfo'}),
    getFile() {
      this.file = {...this.file, name: 'Конверт', id: this.statusOrg?.konvertFile?.id, fileType: 'KONVERT_TYPE'};
    },
    async blockOrg() {
      try{
        this.loading = true;
        await this.changeStatusInfo({type: type[0], block: !this.simpleBlock});
        this.loading = false;
      }catch (e) {
        this.loading = false;
      }
    },
    async blockKonvertOrg() {
      try{
        this.loadingKonvert = true;
        if (this.file?.id)
          await this.changeStatusInfo({type: type[1], block: !this.konvertBlock});
        this.loadingKonvert = false;

      }catch (e) {
        this.loadingKonvert = false;
      }
    },
    async uploadFile(event) {
      this.loadingFileToServer.value = true;
      this.loadingFileToServer.fileType = event.fileType;
      let fd = new FormData();
      fd.append('file', event.file);
      try {
        const res = await request({
          endpoint: `/api/file/scan/${this.isAdmin ? 'upload_admin': 'upload'}?scanType=${event.fileType}${this.isAdmin ? `&orgId=${this.get_replace_id_admin}`: ''}`,
          method: this.isAdmin ? 'put': 'post',
          body: fd,
        });
        this.file = {...this.file, ...res.data};
        this.loadingFileToServer.value = false;
      } catch (e) {
        this.loadingFileToServer.value = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка загрузки',
          timeout: 3000
        })
      }
    },
    async loadFile() {
      try{
        this.loadingFile = true;
        const response = await axios.get(`/api/report/getOrgReport/${this.get_replace_id_admin}`, {
          responseType: 'blob',
          headers: {Authorization: this.token ? `Bearer ${this.token}` : ''},
        })
        const blob = new Blob([response.data], {type: "application/pdf"});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'report.pdf';
        link.click();
        link.remove();
        this.loadingFile = false;
      }catch (e) {
        this.loadingFile = false;
      }
    }
  },
}
</script>

<style scoped>

</style>