<template>
  <div class="full-indicators-info">
    <table class="full-indicators-info__table">
      <thead>
      <tr>
        <th>Образовательная организация. УГСН (НПС)</th>
        <th>Очная форма</th>
        <th>Очно-заочная форма</th>
        <th>Заочная форма</th>
      </tr>
      </thead>
      <tbody>
      <template v-if="!loading">
        <tr v-for="(item) in serverData" :key="calcIdRow(item)">
          <td v-if="item.orgFlag" class="full-indicators-info__table-org" colspan="4">{{ item.orgName }}</td>
          <td v-else-if="item.uslName" class="full-indicators-info__table-usl" colspan="4">{{ item.uslName }}</td>
          <template v-else>
            <td>
              <span class="kcp-code">{{ item.kcpGroup.code }}</span>
              <span class="kcp-name">{{ item.kcpGroup.name }}</span>
            </td>
            <td
                @click="openScore(item.id, 1, 1, item.qkcpo23, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpo23 == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <span
                    class="d-flex align-center  justify-center hh-100"
                    :class="{'text-decoration-line-through': item.qkcpo23reject && item.qkcpo23}"
                >{{ showingValue(item, 'qkcpo23') }}
                </span>
                <span
                    class="tooltip-indicators-decline"
                >
<!--            <v-tooltip-->
<!--                v-if="item.qkcpo23"-->
<!--                top-->
<!--                :color="!item.qkcpo23reject ? 'error' : 'success'"-->
<!--            >-->
<!--          <template :item="item" v-slot:activator="{ on, attrs }">-->
<!--            <v-btn-->
<!--                @click="rejectKcp(item.id, 1, item.qkcpo23reject, index)"-->
<!--                v-on="on"-->
<!--                v-bind="attrs"-->
<!--                icon>-->
<!--              <v-icon size="20" :color="!item.qkcpo23reject ? 'error' : 'success'"> {{!item.qkcpo23reject ? 'mdi-close' : 'mdi-check'}}</v-icon>-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          <p class="mb-0" style="color: white">-->
<!--            {{!item.qkcpo23reject ? 'отказаться от КЦП' : 'вернуть КЦП'}}-->
<!--          </p>-->
<!--        </v-tooltip>-->
          </span>
              </div>
            </td>
            <td
                @click="openScore(item.id, 1, 2, item.qkcpv23, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpv23 == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <span
                    class="d-flex align-center  justify-center hh-100"
                    :class="{'text-decoration-line-through': item.qkcpv23reject && item.qkcpv23}"
                > 
                  {{ showingValue(item, 'qkcpv23') }}
                </span>
                <span
                    class="tooltip-indicators-decline"
                >
<!--            <v-tooltip-->
<!--                v-if="item.qkcpv23"-->
<!--                top-->
<!--                :color="!item.qkcpv23reject ? 'error' : 'success'"-->
<!--            >-->
<!--          <template :item="item" v-slot:activator="{ on, attrs }">-->
<!--            <v-btn-->
<!--                @click="rejectKcp(item.id, 2, item.qkcpv23reject, index)"-->
<!--                v-on="on"-->
<!--                v-bind="attrs"-->
<!--                icon>-->
<!--              <v-icon size="20" :color="!item.qkcpv23reject ? 'error' : 'success'"> {{!item.qkcpv23reject ? 'mdi-close' : 'mdi-check'}}</v-icon>-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          <p class="mb-0" style="color: white">-->
<!--            {{!item.qkcpv23reject ? 'отказаться от КЦП' : 'вернуть КЦП'}}-->
<!--          </p>-->
<!--        </v-tooltip>-->
          </span>
              </div>
             
            </td>
            <td
                @click="openScore(item.id, 1, 3, item.qkcpz23, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpz23 == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <span
                    class="d-flex align-center  justify-center hh-100"
                    :class="{'text-decoration-line-through': item.qkcpz23reject && item.qkcpz23}"
                > 
                  {{ showingValue(item, 'qkcpz23') }}
                </span>
                <span
                    class="tooltip-indicators-decline"
                >
<!--            <v-tooltip-->
<!--                v-if="item.qkcpz23"-->
<!--                top-->
<!--                :color="!item.qkcpz23reject ? 'error' : 'success'"-->
<!--            >-->
<!--          <template :item="item" v-slot:activator="{ on, attrs }">-->
<!--            <v-btn-->
<!--                @click="rejectKcp(item.id, 3, item.qkcpz23reject, index)"-->
<!--                v-on="on"-->
<!--                v-bind="attrs"-->
<!--                icon>-->
<!--              <v-icon size="20" :color="!item.qkcpz23reject ? 'error' : 'success'"> {{!item.qkcpz23reject ? 'mdi-close' : 'mdi-check'}}</v-icon>-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          <p class="mb-0" style="color: white">-->
<!--            {{!item.qkcpz23reject ? 'отказаться от КЦП' : 'вернуть КЦП'}}-->
<!--          </p>-->
<!--        </v-tooltip>-->
          </span>
              </div>
            </td>
          </template>
        </tr>

      </template>
      <template v-else>
        <tr>
          <td class="full-indicators-info__table-org" rowspan="4" colspan="4">
            <v-progress-linear indeterminate></v-progress-linear>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <confirm-dialog ref="agree-dialog" text="Вы уверены?"/>
  </div>
</template>

<script>
import {calcBackground} from "@/utils/indicators";
import {declineIndicatorAppeal, getAllGroupedKcpIndicatorsStage1} from "@/API/indicators";
import ConfirmDialog from "@/components/Dialogs/confirmDialog";

export default {
  name: "allListStage1",
  components: {ConfirmDialog},
  data() {
    return {
      serverData: [],
      loading: false
    }
  },
  created() {
    this.getServerData();
  },
  methods: {
    async getServerData() {
      try {
        this.loading = true;
        this.serverData = await getAllGroupedKcpIndicatorsStage1();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async rejectKcp(id, foId, reject, ind) {
      let agree = false;
      await this.$refs["agree-dialog"].openDialog().then(data => agree = data);
      if (agree) {
        try{
          const payload = {id: id,foId: foId, flag: !reject}
          const res = await declineIndicatorAppeal(payload);
          this.serverData.splice(ind , 1, res)
        }catch (e) {
          console.error(e)
        }
      }
    },
    calcBackground(value, maxValue, minValue = 0) {
      return calcBackground(value, maxValue, minValue)
    },
    // eslint-disable-next-line no-unused-vars
    openScore(rowId, s0b, appForm, score, usl, kcp, org) {
      // const {id, code, name} = kcp;
      // if (score != null)
      //   this.$router.push({path: '/indicators/details', query: {fo: appForm, id: rowId,kcpId : id, code: code, name: name, value: score, usl: usl, org: org}})
    },
    showingValue(item, dataField) {
      return item[dataField] == null ? 'нет заявки' : item[dataField]/*.toFixed(3)*/
    },
    calcIdRow(item) {
      return item.kcpGroup?.id ? Math.random() + '-' + (item.kcpGroup.id + '-' + item.kcpGroup.usl.id + '-' + item.orgId) : (Math.random() + '-' + item.orgId)
    }
  },
}
</script>

<style lang="sass" scoped>
.tooltip-indicators-decline
  display: block
  position: absolute
  right: 0
  top: 0

</style>