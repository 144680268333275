import request from "@/services/request";

export default {
    namespaced: true,
    state: {
        appealsData: {data: [], counter: 0},
       appealsLoading: false,
       appealsError: null,
    },
    actions: {
        async getAllIndicators({commit}){
                try {
                    const res = await request({
                        endpoint: `/api/appeal/getAll`,
                        method: 'get',
                    });
                    if (res.status === 200) {
                        commit('SET_DATA_SPR',  res.data)
                        commit('SET_DATA_SPR_LOADING',false)
                    } else {
                        commit('SET_DATA_SPR_LOADING',  false)
                        commit('SET_DATA_SPR_ERROR', res.error)
                    }
                } catch (e) {
                    console.error('appeals' , e)
                }
        },
        pushNewElement({commit}, payload){
            commit('PUSH_NEW_DATA', payload);
        }
    },
    mutations: {
        ['SET_DATA_SPR'](state, payload) {
            state.appealsData = {data: payload, counter: payload.length};
        },
        ['PUSH_NEW_DATA'](state, payload) {
            const checkDouble = state.appealsData.data.findIndex(el => el.id === payload.id);
            if (checkDouble === -1)
                state.appealsData.data.push(payload);
            else
                state.appealsData.data.splice(checkDouble,1,payload);
        },
        ['SET_DATA_SPR_LOADING'](state, payload) {
            state.appealsLoading = payload;
        },
        ['SET_DATA_SPR_ERROR'](state, payload) {
            state.appealsError = payload;
        }
    },
    getters: {
        get_appeals_data(state){
            return state.appealsData;
        },
        get_appeals_loading(state){
            return state.appealsLoading;
        },
        get_appeals_error(state){
            return state.appealsError;
        },
    }
}