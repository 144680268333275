import request from "@/services/request";
import store from "@/store";
import Table from "@/utils/Table";

async function getAllGroupedKcpIndicatorsRegion() {
    try {
        const res = await request({
            endpoint: `/api/tab/kcp23sumReg?regionId=${store.getters['auth/get_replace_id_admin']}`,
            method: 'get',
        });
        let sumRow = {id:null,orgId:null,orgName:'Всего',ownerId:null,qkcpo23:0,qkcpv23:0,qkcpz23:0}
        /*
          {
            "regionId": 54,
            "regionName": "Пензенская Область",
            "orgId": 61,
            "orgName": "Пензенский казачий институт технологий (филиал) Московского государственного университета технологий и управления имени К.Г. Разумовского (Первый казачий университет)",
            "ownerId": 639,
            "qkcpo23": 76,
            "qkcpv23": 66,
            "qkcpz23": 56
          },
         */
        if (res.status === 200) {
            let returnData = [];
            returnData.push(
                {
                    regionName: res.data[0].regionName,
                    regionId: res.data[0].regionId,
                    orgId: res.data[0].orgId,
                    id: Math.random(),
                    orgFlag: true
                }
            )
            res.data.forEach(el => {
                sumRow.qkcpo23 += el.qkcpo23;
                sumRow.qkcpv23 += el.qkcpv23;
                sumRow.qkcpz23 += el.qkcpz23;
                returnData.push(
                    {
                        orgName: el.orgName,
                        orgId: el.orgId,
                        ownerId: el.ownerId,
                        id: el.orgId,
                        qkcpo23: el.qkcpo23,
                        qkcpv23: el.qkcpv23,
                        qkcpz23: el.qkcpz23
                    }
                );
            });
            returnData.push(sumRow);
            return returnData;
        } else {
            throw new Error('error getAllGroupedKcpIndicatorsRegion');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения общих показателей ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error getAllGroupedKcpIndicatorsRegion ' + e)
    }
}

async function getAllGroupedKcpIndicatorsStage1Regions() {
    try {
        const res = await request({
            endpoint: `/api/tab/kcp23r?orgId=${store.getters['auth/get_replace_id']}&regionId=${store.getters['auth/get_replace_id_admin']}`,
            method: 'get',
        });
        if (res.status === 200) {
            let returnData = []; let orgOwner; let orgFilials;
            const getOwner = await request({endpoint: `/api/spr/getOrgById?sprOrgId=${store.getters['auth/get_replace_id']}`, method: 'GET'});
            if (getOwner.status === 200) {
                orgOwner = getOwner.data;
            }
            const getFilials = await request({endpoint: `/api/spr/getChildByOwnerId?sprOrgId=${store.getters['auth/get_replace_id']}`,method: 'GET'});
            if (getFilials.status === 200) {
                orgFilials = getFilials.data;
            }
            const usls = store.getters['spr/get_spr_data_usl'].data;
            const mainOrgData = res.data.filter(el => (el.orgId === orgOwner.id && (
                    el.qkcpo23 !== 'NA' || el.qkcpv23 !== 'NA' || el.qkcpz23 !== 'NA'
                )
            ));
            const filialsData = orgFilials.map(el => {
                return res.data.filter(el2 => (el2.orgId === el.id  && (
                    el2.qkcpo23 !== 'NA' || el2.qkcpv23 !== 'NA' || el2.qkcpz23 !== 'NA'
                )))
            });
            returnData.push(
                {
                    orgName: orgOwner.name,
                    orgId: orgOwner.id,
                    ownerId: orgOwner.id,
                    id: Math.random(),
                    orgFlag: true
                }
            )
            usls.forEach(el => {
                if (res.data.find(elData => elData.kcpGroup.usl.id === el.id))
                    returnData.push({
                            id: Math.random(),
                            uslName: el.name
                        }, ...mainOrgData.filter(elData => elData.kcpGroup.usl.id === el.id ).map(elData => {
                            return {
                                ...elData,
                                qkcpo23: elData.qkcpo23 === 'NA' ? null : elData.qkcpo23,
                                qkcpv23: elData.qkcpv23 === 'NA' ? null : elData.qkcpv23,
                                qkcpz23: elData.qkcpz23 === 'NA' ? null : elData.qkcpz23,
                            }})
                    );
            });
            if (filialsData.length > 0)
                for (const elFilialData of filialsData) {
                    if (elFilialData.length === 0)
                        continue;

                    returnData.push(
                        {
                            orgName: elFilialData[0].orgName,
                            orgId: elFilialData[0].orgId,
                            ownerId: elFilialData[0].ownerId,
                            id: Math.random(),
                            orgFlag: true,
                        }
                    );
                    usls.forEach(el => {
                        if (elFilialData.find(elData => elData.kcpGroup.usl.id === el.id))
                            returnData.push({
                                id: Math.random(),
                                uslName: el.name
                            }, ...elFilialData.filter(elData => elData.kcpGroup.usl.id === el.id  ).map(elData => {
                                return {
                                    ...elData,
                                    qkcpo23: elData.qkcpo23 === 'NA' ? null : elData.qkcpo23,
                                    qkcpv23: elData.qkcpv23 === 'NA' ? null : elData.qkcpv23,
                                    qkcpz23: elData.qkcpz23 === 'NA' ? null : elData.qkcpz23,
                                }}));
                    });

                }
            return returnData;
        } else {
            throw new Error('error getAllGroupedKcpIndicatorsStage1Regions');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения общих показателей ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error getAllGroupedKcpIndicatorsStage1Regions ' + e)
    }
}
async function getAllGroupedKcpIndicatorsByRegion(api = '') {
    try {
        let params = '';
        if (store.getters['auth/get_replace_id'])
            params = `?orgId=${store.getters['auth/get_replace_id']}`;

        const res = await request({
            endpoint: api ? api + params : `/api/tab/ind${params}`,
            method: 'get',
        });
        if (res.status === 200) {

            return res.data.map(elData => {
                return {
                    ...elData,
                    iokio22: elData.iokio22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iokio22, 3, 'float'),
                    iokiv22: elData.iokiv22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iokiv22, 3, 'float'),
                    iokiz22: elData.iokiz22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iokiz22, 3, 'float'),
                    iowgt22: elData.iowgt22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iowgt22, 3, 'float')
                }});
        } else {
            throw new Error('error getAllGroupedKcpIndicators');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения общих показателей ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error getAllGroupedKcpIndicators ' + e)
    }
}


export {getAllGroupedKcpIndicatorsRegion,getAllGroupedKcpIndicatorsStage1Regions, getAllGroupedKcpIndicatorsByRegion};