<template>
<tr>
  <td :colspan="colspan" class="border-right-zero border-top-1 kcp-name"><slot>нет сведений</slot></td>
</tr>
</template>

<script>
export default {
  name: "zeroRows",
  props: {
    colspan: {
      type: Number,
      default: 0
    },
  },
}
</script>

<style scoped>

</style>