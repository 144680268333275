import Table from "@/utils/Table";
export default function specSum( rowOfValues) {
    return [
        Table.checkValueWithNullAndReturnND(rowOfValues.data.npsfo21, 0, 'integer'),
        Table.checkValueWithNullAndReturnND(rowOfValues.data.npsro21, 0, 'integer'),
        Table.checkValueWithNullAndReturnND(rowOfValues.data.npsmo21, 0, 'integer'),
        Table.checkValueWithNullAndReturnND(rowOfValues.data.npspo21, 0, 'integer'),
    ].reduce((prev, cur) => {
        if (cur !== 'NA')
            prev +=cur;
        return prev;
    })
}