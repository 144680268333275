export default {
    countCells: 6,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 4,
            text: 'Код и наименование УГСН (НПС)',
            class: 'd-flex flex-row align-center justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
            // template: (row) => {
            //     return row.sprKcpGroupId + ' - ' + row.sprKcpGroupName
            // }
        },
        {
            dataField: 'npg11',
            cols: 3,
            rows: 1,
            text: 'Зачислено на обучение',
            headerClass: 'text-center border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg12',
            cols: 2,
            rows: 1,
            text: 'Средний балл ЕГЭ',
            headerClass: 'text-center border-bottom-zero',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 3,
            rows: 1,
            text: "в 2021/2022 учебном году",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg14',
            cols: 2,
            rows: 1,
            text: `в 2021/2022 учебном году`,
            headerClass: 'text-center border-bottom-zero',
            order: 4,
            variable: false,
        },
        {
            dataField: 'npg31',
            cols: 1,
            rows: 1,
            text: `без вступит. испытаний`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg32',
            cols: 1,
            rows: 1,
            text: `с учетом ЕГЭ, общ.конкур.`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg33',
            cols: 1,
            rows: 1,
            text: `с учетом ЕГЭ по цел.квоте`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg34',
            cols: 1,
            rows: 1,
            text: `по общ. конкурсу`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg35',
            cols: 1,
            rows: 1,
            text: `по цел .квоте`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg36',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg37',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg38',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg39',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg310',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start',
            text: `1`,
            order: 6,
            variable: false,
        },
        {
            dataField: 'npeao21Mpk',
            cols: 1,
            rows: 1,
            text: `3`,
            order: 7,
            variable: false,
        },
        {
            dataField: 'npebo21Mpk',
            cols: 1,
            rows: 1,
            text: `4`,
            order: 8,
            variable: false,
        },
        {
            dataField: 'npeco21Mpk',
            cols: 1,
            rows: 1,
            text: `5`,
            order: 9,
            variable: false,
        },
        {
            dataField: 'beebo21Mpk',
            cols: 1,
            rows: 1,
            text: `6`,
            order: 11,
            variable: false,
        },
        {
            dataField: 'beeco21Mpk',
            cols: 1,
            rows: 1,
            text: `7`,
            order: 12,
            variable: false,
        },
    ],
    massiveForRows: [
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            order: 0,
            text: '1',
            variable: true,
            headerClass: 'justify-start d-flex flex-row pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row text-left',
            colsDataCalc: (row) => {
                return row.uslName ? 6 : 1;
            },
        },
        {
            dataField: 'npeao21Mpk',
            cols: 1,
            rows: 1,
            order: 2,
            text: '3',
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
        }, {
            dataField: 'npebo21Mpk',
            cols: 1,
            rows: 1,
            order: 3,
            text: '4',
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
        }, {
            dataField: 'npeco21Mpk',
            cols: 1,
            rows: 1,
            order: 4,
            text: '5',
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
        },{
            dataField: 'beebo21Mpk',
            cols: 1,
            rows: 1,
            order: 5,
            text: '6',
            variable: true,
            template: (v) => {
                if (v && v !== 'н.д.'){
                    const dot = v.indexOf(',');
                    return v.slice(0, dot +1) + v.slice(dot +1 , dot+3);
                }else{
                    return v;
                }},
            headerClass: 'pa-0 font-size-small__important',
        },{
            dataField: 'beeco21Mpk',
            cols: 1,
            rows: 1,
            order: 6,
            text: '7',
            variable: true,
            template: (v) => {
                if (v && v !== 'н.д.'){
                    const dot = v.indexOf(',');
                    return v.slice(0, dot +1) + v.slice(dot +1 , dot+3);
                }else{
                    return v;
                }},
            headerClass: 'pa-0 font-size-small__important',
        },
    ]
}
