<template>
  <div class="appeals-list">
    <p class="mb-1">Фильтры: </p>
    <v-row dense>
      <v-col class="d-flex flex-row align-center " >
      <div class="cms-panel-search">
        <v-icon :size="25" color="#cdd1d4" class="fr-topbar__icon"
        >mdi-magnify
        </v-icon
        >
        <input
            type="search"
            class="cms-panel-search__input"
            v-model="searchOrg"
        />
      </div>
      <BSelect
          :data="searchFields"
          v-model="selectedSearchField"
          class="cms-panel-search__sort"
      />
      </v-col>
      <v-col cols="3" class="mx-2">
        <v-autocomplete multiple label="Статусы заявок" v-model="currentStatus" :items="statusArr"></v-autocomplete>
      </v-col>
      <v-col cols="3" class="d-flex align-center">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
                v-model="dates"
                multiple
                chips
                dense
                label="Выбрать даты по которым смотреть"
                small-chips
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-combobox>
          </template>
          <v-date-picker
              v-model="dates"
              multiple
              locale="ru"
              no-title
              scrollable
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <p class="mb-1">Сортировка: </p>
    <v-row>
      <v-col class="d-flex flex-row align-center justify-space-between">
        <v-autocomplete v-model="currentSort" :items="sort"></v-autocomplete>
        <v-autocomplete v-model="currentSortDir" :items="sortDir" class="mx-3"></v-autocomplete>
        <v-btn class="blue__v-btn" @click="sortData" >Сортировать</v-btn>
      </v-col>
    </v-row>


    <v-progress-linear indeterminate
                       color="#00599b"
                       v-if="!appeals.length && !resultSearch.data.length || loading">
    </v-progress-linear>

    <Alert alertType="primary" v-else-if="appeals.length && !resultSearch.data.length ">
      <template v-slot:icon>
        <v-icon :size="30" color="#084298">mdi-information-outline</v-icon>
      </template>
      <template v-slot:default>Поиск не дал результатов</template>
    </Alert>

    <div v-else>

      <CardAppeal
          v-for="(appeal, index) of resultSearch.data"
          :key="index+appeal.id"
          :item="appeal"
          @openAboutAppeal="onOpenAboutAppeal($event)"
      />

      <v-dialog
         v-model="modalOpen"
      >
        <v-card>
          <v-card-text class="pt-3">
            <template v-if="activeAppeal">
            <div class="appeal__header">
              <div class="appeal__container-flex">
                <div class="appeal__property">
                  Форма | поле | id Вуза:
                  <span class="appeal__span">{{ dialogCellInfo.idForm }} | {{ dialogCellInfo.idOriginal }} | {{activeAppeal.org.id}}</span>
                </div>
                <div class="appeal__property">
                  Наименование организации:
                  <span class="appeal__span">{{ activeAppeal.org.name }}</span>
                </div>
                <div class="appeal__property">
                  Форма обучения | адрес:
                  <span class="appeal__span">{{ dialogCellInfo.form + ' ' + dialogCellInfo.address }}</span>
                </div>
                <div class="appeal__property">
                  Наименование поля:
                  <span class="appeal__span">{{ dialogCellInfo.name + ' ' + dialogCellInfo.ist }}</span>
                </div>
                <div class="appeal__property">
                  Дата создания:
                  <span class="appeal__span">{{ activeAppeal.date }}</span>
                </div>
              </div>

              <div class="appeal__container-mainData">
                <div class="appeal__mainData">
                  <div class="appeal__mainData-item" style="min-width: 50%">УГСН</div>
                  <div class="appeal__mainData-item" style="max-width: 150px">Старое значение</div>
                  <div class="appeal__mainData-item" style="max-width: 150px">Новое значение</div>
                  <div class="appeal__mainData-item" style="max-width: 50px"></div>
                  <div class="appeal__mainData-item" style="max-width: 50px"></div>
                </div>

                  <div class="appeal__mainData" v-for="(item, index) of activeAppeal.data" :key="index+item.id">
                    <div class="appeal__mainData-item text-left" style="min-width: 50%">
                      {{ calcUgsnInfo(item.kcpGroup)  }}
                    </div>
                    <div class="appeal__mainData-item" style="max-width: 150px">
                      {{ item.oldValue }}
                    </div>
                    <div class="appeal__mainData-item appeal__mainData-item-editable" style="max-width: 150px">
                      <input class="appeal__mainData-input" type="text" v-model="item.newValue">
                    </div>
                    <div
                        class="appeal__mainData-item appeal__mainData-action "
                        :class="item.accept === true ? 'appeal__mainData-action-selected text-decoration-underline' : ''"
                        @click="changeAppealStatus(item, true)"
                        style="max-width: 50px"
                    >
                      <v-icon :size="30" color="#00599B">
                        mdi-check
                      </v-icon>
                    </div>
                    <div
                        class="appeal__mainData-item appeal__mainData-action"
                        :class="item.accept === false ? 'appeal__mainData-action-selected text-decoration-underline' : ''"
                        @click="changeAppealStatus(item, false)"
                        style="max-width: 50px"
                    >
                      <v-icon :size="30" color="#00599B">
                        mdi-close
                      </v-icon>
                    </div>
                  </div>

              </div>

              <div>
                <div class="appeal__property">
                  Описание:
                  <p v-for="(item, j) in descriptionActiveAppeal" :key="j" class="d-flex align-center mb-1  appeal__span">{{ item.kcp + ': ' + item.text }}</p>
                </div>
                <v-btn
                    v-for="file in files"
                    :key="file.fileId"
                    class=" blue__v-btn ml-2"
                    :loading="loadingFile"
                    @click="getFile(file.fileId)"
                >{{ file.fileId ? file.fileId : 'файл не приложен' }}
                </v-btn>
              </div>
              <div class="my-4"/>
              <div class="appeal__property">
                Основание отказа:
                <div>
                  <textarea v-model="decision" class="appeal__input" rows="4"></textarea>
                </div>
                Описание отказов:
                <p v-for="(item, j) in decisionActiveAppeal" :key="j" class="d-flex align-center mb-1  appeal__span">{{ item.kcp + ': ' + item.text }}</p>
              </div>
              <div class="appeal__container-flex">
                <div class="appeal__property">
                  Статус:
                  <span class="appeal__span">{{ status.text }}</span>
                </div>
                <div class="appeal__property" v-if="this.activeAppeal.accept !== null">
                  Дата принятия решения:
                  <span class="appeal__span">{{ activeAppeal.dateReason }}</span>
                </div>
              </div>

              <div class="appeal__property appeal__actions">
              </div>
            </div>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
      <p class="paginator-info">
        Страница: {{ this.paginatorParams.selectedPage }},
        Результатов на странице: {{ this.paginatorParams.itemsPerPage }},
        Всего результатов: {{ resultSearch.counter }}
      </p>
      <BPaginator
          v-if="resultSearch"
          :propDataCount="resultSearch.counter"
          v-model="paginatorParams"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import request from "@/services/request";
import CardAppeal from "@/components/cards/custom/CardAppeal";
import {mapActions, mapGetters} from 'vuex';
import {saveFile} from '@/utils/saveFile';

import BSelect from "@/components/controls/BSelect/BSelect";
import BPaginator from "@/components/paginator/BPaginator/BPaginator";
import utils from "@/utils/utils";
import Alert from "../../../components/UI/Alert";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import {sendAppealAdminData} from "@/API/form5";
import {sortArr} from "@/utils/helpers";

moment.locale('ru');

export default {
  components: {
    Alert,
    CardAppeal,
    BSelect,
    BPaginator
  },
  mixins: [headerTooltipFunctions],
  data() {
    return {
      appeals: [],
      decision: '',
      activeAppeal: null,
      activeAppealFile: '',
      searchOrg: '',
      searchFields: ['ID', 'Организация', 'UUID'],
      selectedSearchField: 'ID',
      menu: false,
      dates: [],
      sort: [
        {text: 'id организации', value: 'orgId', type: 'number'},
        {text: 'наименование организации', value: 'id', type: 'string'},
        {text: 'date create', value: 'date', type: 'date'},
        {text: 'status', value: 'accept', type: 'boolean'},
        // {text: 'id', value: 'id'},
      ],
      sortDir: [
        {text: 'По убыванию', value: 'asc'},
        {text: 'По возрастанию', value: 'desc'},
      ],
     statusArr: [
        // {text: 'Все заявки', value: 1},
        {text: 'На рассмотрении', value: null},
        {text: 'Удовлетворена', value: true},
        {text: 'Отказано', value: false},
      ],
      results: 0,
      loading: false,
      loadingFile: false,
      currentStatus: [true, false, null],
      currentSort: 'id',
      currentSortDir: 'desc',
      paginatorParams: {'selectedPage': 1, 'itemsPerPage': 10},
      modalOpen: false,
    }
  },
  computed: {
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    dateCreate() {
      return moment(this.activeAppeal.createDate).format('LLL');
    },
    dateEdit() {
      return moment(this.activeAppeal.changeDate).format('LLL');
    },
    files(){
      return this.activeAppeal.data.filter(el => el.fileId)
    },
    selectedField() {
      switch (this.selectedSearchField) {
        case "ID":
          return 'id';
        case 'Организация':
            return 'name';
        case 'UUID':
            return 'uuid';
        default:
          return 'name';
      }
    },
    descriptionActiveAppeal(){
      if (!this.activeAppeal?.data)
        return null;
      return this.activeAppeal.data.map(el => { return {text: el.appeal,kcp: this.calcUgsnInfoCode(el.kcpGroup) }});
    },
    decisionActiveAppeal(){
      if (!this.activeAppeal?.data)
        return null;
      return this.activeAppeal.data.map(el => { return {text: el.decision ?? '',kcp: this.calcUgsnInfoCode(el.kcpGroup) }});
    },
    dialogCellInfo(){
      const findItem = this.findInfoForCell(this.activeAppeal.jsonId);
      return {idForm: findItem?.idForm, idOriginal: findItem.idOriginal, name: findItem?.name, ist: findItem?.ist, form: findItem?.form, address: findItem?.address}
    },
    resultSearch() {
      let results;
      let array = this.appeals.filter((item) => {
        // if (this.currentStatus === 1) return item;
        if (this.currentStatus.includes(item.accept))
          return item;
      });
      let search = this.searchOrg;
      if (!search && this.dates.length === 0) {
        results = array.length;
        array = utils.divideArrayInPages(array, this.paginatorParams.selectedPage, this.paginatorParams.itemsPerPage)
        return {'data': array, 'counter': results};
      }
      let filterFn = null;
      search = search.trim().toLowerCase();
      switch (this.selectedField) {
        case "id":
          filterFn = (item) => {
            if (String(item.org.id).toLowerCase().indexOf(search) !== -1) {
              return item;
            }
          };
          break;
        case 'name':
          filterFn = (item) => {
            if (String(item.org[this.selectedField]).toLowerCase().indexOf(search) !== -1) {
              return item;
            }
          };
          break;
        case 'uuid':
          filterFn = (item) => {
            if (item.data.find(el => el.id === search)) {
              return item;
            }
          };
          break;
        default:
          filterFn = (item) => {
            if (String(item.org[this.selectedField]).toLowerCase().indexOf(search) !== -1) {
              return item;
            }
          };
          break;
      }
      if (this.dates.length > 0){
        filterFn = (item) => {
          const mappedDateArr = item.data.map(el => {return el.date?.slice(0,10)});
          if (item?.date && (this.dates.includes(item.date.slice(0,10)) || this.dates.some(el => mappedDateArr.includes(el)))) {
            return item;
          }
        };
      }
      array = array.filter((item) => {
        const res = filterFn(item);
        if (res) return res
      })

      results = array.length;
      array = utils.divideArrayInPages(array, this.paginatorParams.selectedPage, this.paginatorParams.itemsPerPage)
      return {'data': array, 'counter': results};
    },
    appealType() {
      let appealType;
      switch (this.activeAppeal.appealType) {
        case 'SOURCE_DATA_ERROR':
          appealType = 'Апелляция на ошибку в исходных данных';
          break;
        case 'INDICATORS_VALUE_ERROR':
          appealType = 'Апелляция на ошибку в расчете значения показателя';
          break;
        case 'SUMMARY_RATING_ERROR':
          appealType = 'Апелляция на ошибку в расчете сводной оценки';
          break;
        case 'NORMALIZED_RATING_ERROR':
          appealType = 'Апелляция на ошибку в расчете нормированной оценки';
          break;
      }
      return appealType;
    },
    status() {
      const elementsActive = this.activeAppeal.data.filter(el => el.accept === null);
      const elementsAgreed = this.activeAppeal.data.filter(el => el.accept === true);
      const elementsDecline = this.activeAppeal.data.filter(el => el.accept === false);
      const lengthData = this.activeAppeal.data.length;
      if (elementsActive.length === lengthData)
        return {
          text: 'На рассмотрении',
          style: 'appeal_not-set'
        }
      else if(elementsAgreed.length === lengthData)
        return {
          text: 'Удовлетворена',
          style: 'appeal_accepted'
        }
       else if (elementsDecline.length === lengthData) {
        return {
          text: 'Отказано',
          style: 'appeal_rejected'
        }
      } else {
        return {
          text: 'Частично принята',
          style: 'appeal_rejected'
        }
      }
    },

  },
  mounted() {
    this.getAllAppeals();
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    prevPage() {
      if (this.paginatorParams.selectedPage > 1) this.paginatorParams.selectedPage -= 1
    },
    calcUgsnInfoCode(kcp){
      if (!kcp)
        return;
      return kcp.code ;
    },
    calcUgsnInfo(kcp){
      if (!kcp)
        return;
      return kcp.code + ' ' +  kcp.name;
    },
    nextPage() {
      if ((this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage) < this.resultSearch.length) this.paginatorParams.selectedPage += 1
    },
    async changeAppealStatus(item, flag){
      if (flag === false && this.decision){
        console.log('changeAppealStatus false', item);
        const res = await sendAppealAdminData({id: item.id, newValue: item.newValue, reason: flag, decision: this.decision});
        const ind  = this.activeAppeal.data.findIndex(el => item.id === el.id);
        this.activeAppeal.data.splice(ind,1,res);
      }
      else if(flag === true){
        console.log('changeAppealStatus true', item);
        const res = await sendAppealAdminData({id: item.id, newValue: item.newValue, reason: flag, decision: this.decision});
        const ind  = this.activeAppeal.data.findIndex(el => item.id === el.id);
        this.activeAppeal.data.splice(ind,1,res);
      }else
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Заполните поле причины отказа' ,
          timeout: 5000
        })
    },
    async getAllAppeals() {
      try {
        this.loading = true;
        const res = await request({
          endpoint: `/api/admin/getAllAppeal`,
          method: 'GET',
        });
        if (res.status === 200) {
          const apps = [];
          if (res.data.length > 0)
          {
            let filterData = res.data;
            res.data.forEach(el => {
              if (apps.length === 0)
              {
                const searchResult = filterData.filter(a => a.jsonId === el.jsonId && a.org.id === el.org.id);
                filterData = filterData.filter(a => !(el.jsonId === a.jsonId && a.org.id === el.org.id));
                apps.push({...el,orgId:el.org.id, data: searchResult});
              }
              else{
                const searchDouble = apps.find(a => a.jsonId === el.jsonId && a.org.id === el.org.id );
                if (!searchDouble){
                  const searchResult = filterData.filter(a => a.jsonId === el.jsonId && a.org.id === el.org.id);
                  filterData = filterData.filter(a => !(el.jsonId === a.jsonId && a.org.id === el.org.id));
                  // console.log(el.jsonId,  el.org.id, searchResult, filterData)
                  apps.push({...el,orgId:el.org.id, data: searchResult});
                }
              }
            })
          }
          // console.log('apps',apps);
          this.appeals = apps;
        }
        this.loading = false;
      }
      catch (e) {
        this.loading = false;
        console.error('getAllAppeals', e)
      }
    },

    onOpenAboutAppeal(event) {
      this.modalOpen = true;
      this.activeAppeal = event;
    },
    sortData(){
      const item = this.sort.find(el => el.value === this.currentSort)
      // console.log( this.currentSort, this.currentSortDir, item.type)
      this.appeals = sortArr(this.appeals, this.currentSort, this.currentSortDir, item.type);
      // console.log(this.appeals[0].accept)
    },
    async getFile(id =this.activeAppeal.fileId ) {
      try {
        this.loadingFile = true;
        const response = await request({
          endpoint: `/api/file/scan/download?file=${id}`,
          method: 'GET',
        });
        saveFile(response.data, false);
        this.loadingFile = false;
      }catch (e) {
        this.loadingFile = false;
        console.error(e)
      }
    },
    getStatus(item){
      const elementsActive =  item.data.filter(el => el.accept === null);
      const elementsAgreed =  item.data.filter(el => el.accept === true);
      const elementsDecline = item.data.filter(el => el.accept === false);
      const lengthData = item.data.length;
      if (elementsActive.length === lengthData)
        return {
          text: 'На рассмотрении',
          style: 'appeal_not-set'
        }
      else if(elementsAgreed.length === lengthData)
        return {
          text: 'Удовлетворена',
          style: 'appeal_accepted'
        }
      else if (elementsDecline.length === lengthData) {
        return {
          text: 'Отказано',
          style: 'appeal_rejected'
        }
      } else {
        return {
          text: 'Частично принята',
          style: 'appeal_rejected'
        }
      }
    },
    async accept(id) {
      let response = await request({
        endpoint: `/api/admin/acceptAppeal?appealID=${id}`,
        method: 'GET',
      });

      if (response.status === 200) {
        this.getAllAppeals();
        this.modalOpen = false;
      }
    },
    async reject(id) {
      let response = await request({
        endpoint: `/api/admin/rejectAppeal?appealID=${id}`,
        method: 'GET',
      });
      if (response.status === 200) {
        this.getAllAppeals();
        this.modalOpen = false;
      }
    }
  }
}
</script>


<style>
.appeal {
  background: #fff;
  margin-bottom: 15px;
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 3px solid #00599b;
  transition: 0.3s;
}

.appeal__container-mainData {
  width: 100%;
  margin: 10px 0;
  border-bottom: 1px solid #00599B;
  border-radius: 3px;
}

.appeal__mainData {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #00599B;
}

.appeal__mainData:last-child {
  border: none;
}

.appeal__mainData-item {
  flex-basis: 0;
  flex-grow: 1;
  padding: 10px;
  text-align: center;
}

.appeal__mainData-item:nth-child(1), .appeal__mainData-item:nth-child(2) {
  min-width: 220px;
}

.appeal__mainData-item-editable {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00599B;
  cursor: pointer;
}

.appeal__mainData-item-editable > div {
  margin-left: 5px;
}

.appeal__mainData-item-editable > div:hover {
  text-decoration: underline;
}

.appeal__mainData-input {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #00599b;
  border-radius: 7px;
}

.appeal__mainData-action {
  opacity: 0.5;
  cursor: pointer;
}

.appeal__mainData-action:hover {
  opacity: 1;
}

.appeal__mainData-action-selected {
  opacity: 1;
}

.appeal__container-flex > div {
  margin-right: 60px;
}

.appeal__container-flex > div:last-child {
  margin-right: 0;
}

.appeal__value {
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 10px;
}

.appeal__value-header {
  flex-basis: 100px;
}

.appeal__value-status {
  flex-basis: 50px;
}

.appeal__span {
  background: #E3EFFA;
  padding: 2px 5px;
  border-radius: 7px;
  flex-grow: 1;
  color: #00599B;
  line-height: 18.75px;
  height: 36px;
  width: 100%;
  /*display: flex;*/
  /*align-items: center;*/
}

.appeal__dateReason {
  font-size: 12px;
  text-align: center;
}

.appeal:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02);
  transition: 0.3s;
}

.appeal__header {
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
}

.appeal__property {
  margin-bottom: 5px;
}

.appeal__input {
  width: 100%;
  padding: 10px;
  border: 1px solid #00599b;
  border-radius: 7px;
}

.modal_left .modal-content .modal-body {
  overflow: hidden;
  text-align: left;
}

.modal-content {
  min-width: 700px;
}

.appeal_accepted {
  background: #EBF9EB;
  border: 1px solid #8FE28E;
  color: #29A128;
}

.appeal_rejected {
  background: #FDEBEB;
  border: 1px solid #F89B9B;
  color: #ED393E;
}

.appeal-files__item {
  color: #00599b;
  text-decoration: underline;
  cursor: pointer;
}

.appeal-files {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.appeal__text {
  background: #E3EFFA;
  padding: 2px 5px;
  border-radius: 7px;
  flex-grow: 1;
  color: #00599B;
  line-height: 18.75px;
}

.appeal__status {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.appeals-list {
  width: 100%;
}

.appeal__actions {
  justify-content: space-around;
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
}

.cms-panel-search {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 0px 10px;
  border: 1px solid #cdd1d4;
}

.cms-panel {
  margin-bottom: 20px;
  display: flex;
}

.cms-panel-search__sort, .letters__select-type {
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0px 10px;
  border: 1px solid #cdd1d4;
  margin-left: 10px;
}

.sort-panel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.sort-panel {
  font-weight: 600;
  color: #00599b;
  margin-bottom: 10px;
}

.cms-panel-search__sort.select {
  min-width: 100px;
}

.cms-panel-search__input {
  min-width: 300px;
}

</style>