<template>
<div v-if="rowOfValues.data.isfil22 === '1'">
  <h3 class="b-indicator__collapse-subtitle">Учет влияния головной организации в значении показателя:</h3>
  <div class="d-flex flex-row mt-3">
    <div class="d-flex flex-row align-end formul-text">
      {{ item.title }}
      <div class="little-text ">0</div>
    </div>
    <div class="d-flex flex-row align-end alternate-text ml-1" style="line-height: 26px"> = (1 − </div>
    <div class="alternate-text d-flex flex-row align-end ml-1"><span class="formul-text">G</span></div>
    <div class="d-flex flex-row align-end alternate-text"> ) × </div>
    <div class="d-flex flex-row align-end formul-text ml-1">
      {{ item.title }}
      <div class="little-text ">Ф</div>
    </div>
    <div class="d-flex flex-row align-end alternate-text"> + </div>
    <div class="formul-text d-flex flex-row align-end mr-1"> G <span class="alternate-text">×</span> </div>
    <div class="d-flex flex-row align-end formul-text">
      {{ item.title }}
      <div class="little-text">Г</div>
    </div>
    <div class="d-flex flex-row align-end alternate-text ml-1" style="line-height: 26px"> = (1 − </div>
    <div class="d-flex flex-row align-end ml-1 formul-text">{{$scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+ 'gv22'], 1, 'float')}}</div>
    <div class="d-flex flex-row align-end alternate-text"> ) × </div>
    <div class="d-flex flex-row align-end formul-text ml-1">
      {{$scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+ 'b022'], item.prd+1, 'float') }}
    </div>
    <div class="d-flex flex-row align-end alternate-text mx-1"> + </div>
    <div class="formul-text d-flex flex-row align-end mr-1"> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+ 'gv22'], 1, 'float') }} <span class="alternate-text">×</span> </div>
    <div class="d-flex flex-row align-end formul-text">
      {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+ 'h022'], item.prd+1, 'float') }}
    </div>
    <div class="d-flex flex-row align-end alternate-text ml-1" style="line-height: 26px"> = </div>
    <div class="formul-text ml-1"> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+ 'b122'], item.prd+1, 'float') }}</div>
  </div>
  <p class="mt-2 mb-5">
    G	-	доля оценки головной организации в значении по филиалу: 0,2 или 0 (при отсутствии значения у головной организации)<br>
    {{item.title}} -	с индексами "ф" и "г" − базовые значения показателя соответственно для филиала и его головной образовательной организации

  </p>
</div>
</template>

<script>
export default {
  name: "blockG",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
}
</script>

<style scoped>

</style>