<template>
  <div>
    <h2 class="b-indicator__collapse-title">2. Нормированная оценка показателя</h2>
    <template v-if="rowOfValues.data[item.dataFieldName+'nf22'] ==='0'">
    <div class="d-flex flex-row align-center pt-4" >
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">N</div>
          </div>
        </template>
        <template #bottom>
          нормированная <br>оценка
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <div>
        <v-img alt="" class="hh-100" :src="require('@/assets/images/brackets/Скобка_three_lines.svg')"/>
      </div>
      <div class="d-flex flex-column justify-center ml-2">
        <div class="d-flex flex-row align-center alternate-text mb-2">
          <span class="alternate-text">0,  если </span> <span
            class="d-flex flex-row align-end formul-text ml-1">      {{ item.title }}<span class="little-text mr-1">0</span> ≤ V<span
            class="little-text">min</span></span>
        </div>
        <div class="d-flex flex-row align-center alternate-text mb-2">
          <span class="alternate-text">1,  если </span> <span
            class="d-flex flex-row align-end formul-text ml-1">      {{ item.title }}<span class="little-text mr-1">0</span> ≥ V<span
            class="little-text">max</span></span>
        </div>
        <div class="d-flex flex-row align-center">
          <fomuls-fraction-simple>
            <template>
              <div class="">{{ item.title }}<span class="little-text">0</span> <span class="alternate-text mx-1">-</span> V<span class="little-text">min</span></div>
            </template>
            <template #bottom>
              <div class="formul-text">V<span class="little-text">max</span> <span class="alternate-text mx-1">-</span> V<span class="little-text">min</span>
              </div>
            </template>
          </fomuls-fraction-simple>
          <div class="alternate-text ml-1">в иных случаях</div>
        </div>
      </div>
      <div class="mx-2 alternate-text">
        =
      </div>
      <div>
        <v-img alt="" class="hh-100" :src="require('@/assets/images/brackets/Скобка_three_lines.svg')"/>
      </div>
      <div class="d-flex flex-column justify-center ml-2">
        <div class="d-flex flex-row align-center alternate-text mb-2">
          <span class="alternate-text">0,  если </span> <span class="d-flex flex-row align-center formul-text ml-1"> {{ $scripts.TableJs.checkValueWithNullAndReturnND(
        rowOfValues.data[item.dataFieldName+'b122'], item.prd+1, 'float') }} ≤ {{  $scripts.TableJs.checkValueWithNullAndReturnND(
            item.min, item.prd , 'float')  }}</span>
        </div>
        <div class="d-flex flex-row align-center alternate-text mb-2">
          <span class="alternate-text">1,  если </span> <span class="d-flex flex-row align-center formul-text ml-1"> {{  $scripts.TableJs.checkValueWithNullAndReturnND(
            rowOfValues.data[item.dataFieldName+'b122'], item.prd+1, 'float')  }}  ≥
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(
              item.max, item.prd , 'float') }}</span>
        </div>
        <div class="d-flex flex-row align-center">
          <fomuls-fraction-simple>
            <template>{{   $scripts.TableJs.checkValueWithNullAndReturnND(
                rowOfValues.data[item.dataFieldName+'b122'], item.prd+1, 'float')   }}   <span class="alternate-text mx-1">-</span> {{   $scripts.TableJs.checkValueWithNullAndReturnND(
                item.min, item.prd, 'float')   }}</template>
            <template #bottom>
              <div class="formul-text">{{   $scripts.TableJs.checkValueWithNullAndReturnND(
                  item.max, item.prd, 'float')   }} <span class="alternate-text mx-1">-</span> {{   $scripts.TableJs.checkValueWithNullAndReturnND(
                  item.min, item.prd, 'float')   }}</div>
            </template>
          </fomuls-fraction-simple>
          <div class="alternate-text ml-1">в иных случаях</div>
        </div>
      </div>
      <div class="mx-3 alternate-text">
        =
      </div>
      <div class="mr-2 formul-text">
        {{   $scripts.TableJs.checkValueWithNullAndReturnND(
          rowOfValues.data[item.dataFieldName+'ns22'], 3, 'float')   }}
      </div>
    </div>
    <p class="mt-7 mb-6">Vmin, Vmax — установленное минимальное и максимальное значение показателя (см. {{ item.ppr }}
      Приложения 2 к Порядку)</p>
    </template>
    <p v-if="rowOfValues.data[item.dataFieldName+'nf22'] === '1'" class="mb-6">При невозможности расчета значения показателя в качестве его нормированной оценки
      принимается минимальное из средних нормированных значений по субъекту РФ, федеральному округу или Российской
      Федерации в целом.</p>
    <div v-if="rowOfValues.data[item.dataFieldName+'nf22'] === '1'" class="d-flex flex-row">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">N</div>
          </div>
        </template>
        <template #bottom>нормированная<br/> оценка</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> Min</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <img style="max-height: 35px;" class=""
               :src="require('@/assets/images/brackets/open-bracket-removebg-preview.png')" alt=""/>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">РФ</div>

          </div>
        </template>
        <template #bottom>средняя по РФ<br/>в целом</template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">ФО</div>

          </div>
        </template>
        <template #bottom>средняя <br/>по Фед. округ</template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">Рег</div>

          </div>
        </template>
        <template #bottom>средняя<br/>по субъекту РФ</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <img style="max-height: 35px;" class=""
               :src="require('@/assets/images/brackets/close-bracket-removebg-preview.png')" alt=""/>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> Min</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <img style="max-height: 35px;" class=""
               :src="require('@/assets/images/brackets/open-bracket-removebg-preview.png')" alt=""/>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <div class="d-flex flex-row align-end">
            {{   $scripts.TableJs.checkValueWithNullAndReturnND(
              rowOfValues.data[item.dataFieldName+'f'+ foName.value+ '22'], 3, 'float')   }}
          </div>
        </template>
        <template #bottom>средняя по РФ<br/>в целом</template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <div class="d-flex flex-row align-end">
            {{   $scripts.TableJs.checkValueWithNullAndReturnND(
              rowOfValues.data[item.dataFieldName+'k'+ foName.value+ '22'], 3, 'float')   }}
          </div>
        </template>
        <template #bottom>средняя <br/>по Фед. округ</template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <div class="d-flex flex-row align-end">
            {{   $scripts.TableJs.checkValueWithNullAndReturnND(
              rowOfValues.data[item.dataFieldName+'r'+ foName.value+ '22'], 3, 'float')   }}
          </div>
        </template>
        <template #bottom>средняя<br/>по субъекту РФ</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <img style="max-height: 35px;" class=""
               :src="require('@/assets/images/brackets/close-bracket-removebg-preview.png')" alt=""/>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          {{   $scripts.TableJs.checkValueWithNullAndReturnND(
            rowOfValues.data[item.dataFieldName+'n'+ foName.value+ '22'], 3, 'float')   }}
        </template>
      </formuls-fraction>
    </div>

    <p v-if="rowOfValues.data[item.dataFieldName+'nf22'] === '2'" class=" mt-2">При невозможности расчета значения показателя для УГСН (НПС), контрольные цифры приема по
      которым распределяются без учета места нахождения образовательных организаций, нормированная оценка принимается
      равной среднему значению по Российской Федерации в целом.</p>

    <div v-if="rowOfValues.data[item.dataFieldName+'nf22'] === '2'" class="d-flex flex-row my-5">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">N</div>
          </div>
        </template>
        <template #bottom>нормированная<br/> оценка</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">РФ</div>
          </div>
        </template>
        <template #bottom>средняя<br/>по РФ в целом</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          {{    $scripts.TableJs.checkValueWithNullAndReturnND(
            rowOfValues.data[item.dataFieldName+'n'+ foName.value+ '22'], 3, 'float')    }}
        </template>
      </formuls-fraction>
    </div>
    <h2 class="b-indicator__collapse-title">3. Сумма баллов по показателю</h2>
    <div class="d-flex flex-row mt-5">
      <formuls-fraction :need-bottom-line="false">
        <template>
          <div class="d-flex flex-row align-end main-color">
            {{ item.title }}
          </div>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">N</div>
          </div>
        </template>
        <template #bottom>нормированная<br/> оценка</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">×</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="true">
        <template>
          W
        </template>
        <template #bottom>вес показателя <br/>в сводн. оценке</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">=</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="true">
        <template>
          {{    $scripts.TableJs.checkValueWithNullAndReturnND(
            rowOfValues.data[item.dataFieldName+'n'+ foName.value+ '22'], 3, 'float')    }}
        </template>
        <template #bottom>нормированная<br/> оценка</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">×</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="true">
        <template>
          {{$scripts.TableJs.checkValueWithNullAndReturnND(
          rowOfValues.data[item.dataFieldName+'wt22'], 0, 'float')
          }}
        </template>
        <template #bottom>вес показателя <br/>в сводн. оценке</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">=</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="true">
        <template>
          <span class="main-color">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(
                  rowOfValues.data[item.dataFieldName+'o'+ foName.value+ '22'], 3, 'float')
            }}</span>
        </template>
        <template #bottom>итоговый <br>балл</template>
      </formuls-fraction>
<!--      <div class="d-flex flex-row align-start" style="position: relative">-->
<!--        <v-tooltip-->
<!--            right-->
<!--            color="#FFF8E4"-->
<!--        >-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <img-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--                alt=""-->
<!--                @click="$emit('appeal-indicator', item)"-->
<!--                style="position: absolute; top: -20px;min-width: 26px; height: 25px;"-->
<!--                :src="require('@/assets/images/appeal_indicator.svg')"/>-->
<!--          </template>-->
<!--          <p class="mb-0" style="color: #ECAE00">-->
<!--            Подать апелляцию на расчет показателя-->
<!--          </p>-->
<!--        </v-tooltip>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

import FomulsFractionSimple from "@/components/cards/indicatiors/formuls-fraction-simple";
import FormulsFraction from "@/components/cards/indicatiors/formuls-fraction";

export default {
  name: "cardBottom",
  components: {FomulsFractionSimple, FormulsFraction},
  props: {
    available: {
      type: Array,
      default: () => []
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
    item: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      foData: [
        {id: 1, name: 'оч', value: 'o'},
        {id: 2, name: 'вч', value: 'v'},
        {id: 3, name: 'зч', value: 'z'},
      ]
    }
  },
  computed: {
    foName() {
      return this.foData.find(el => el.id === this.rowOfValues.fo);
    }
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false, 'color: #C6C8CB;')
    }
  },
}
</script>

<style lang="sass" scoped>

</style>