export default {
    countCells: 4,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 1,
            text: 'НПС (код и наименование)',
            class: 'd-flex flex-row align-start justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
        },

        {
            dataField: 'npg12',
            cols: 1,
            rows: 1,
            text: 'Регистрационный номер',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg14',
            cols: 1,
            rows: 1,
            text: `Дата выдачи лицензии`,
            order: 4,
            variable: false,
        },
        {
            dataField: 'npg16',
            cols: 1,
            rows: 1,
            html: "Примечания  <br/>" +
                "<span class='font-size-small'>(заполняется при необходимости)</span>",
            text: "Примечания" +
                "(заполняется при необходимости)",
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row',
            text: `1`,
            order: 6,
            variable: true,
            template: (row) => {
                return row.sprKcpGroupId + ' - ' + row.sprKcpGroupName
            }
        },
        {
            dataField: 'glsnm22K21',
            cols: 1,
            rows: 1,
            text: `3`,
            order: 7,
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
            rules: [
                v => !!v || 'введите значение',
            ],
            styles: 'justify-content: unset; text-align: center; text-align: center;',
        },
        // {
        //     dataField: 'glsnp22K21',
        //     cols: 1,
        //     rows: 1,
        //     text: `4`,
        //     order: 8,
        //     variable: true,
        //     rules: [
        //         v => !!v || 'введите значение',
        //         v => !!v && v.length === 9 || 'Количество символов номера и серии должна быть 9'
        //     ],
        //     styles: 'justify-content: unset; text-align: center;',
        // },
        {
            dataField: 'gldtb22K21',
            cols: 1,
            rows: 1,
            text: `4`,
            order: 9,
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
            typeValue: 'date',
            styles: 'justify-content: unset; text-align: center;',
        },
        {
            dataField: 'gltxt22K21',
            cols: 1,
            rows: 1,
            text: `5`,
            order: 10,
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
            styles: 'justify-content: unset; text-align: center;',
        },
    ]
}
