<template>
    <v-dialog
        v-model="show"
        scrollable
        @click:outside="closeDialog()"
        @keydown.esc="closeDialog()"
        max-width="550"
    >
      <v-card>
       <span>
        <v-btn
            icon
            @click="closeDialog()"
            style="position: absolute; right:0;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
        <v-card-text class="text-left formatted-text-tooltip mt-5"  v-if="!get_spr_data_indicators_loading">
          <template  v-if="tooltip">
            <div>
              <div>Полное наименование показателя:</div>
              <p class="mb-0">{{ tooltip.name }}</p>
              <p class="font-weight-bold">{{ tooltip.form }}</p>
            </div>
            <div>
              <div>Источник данных</div>
              <p class="mb-1">{{ tooltip.ist }}</p>
              <p v-html="address"></p>
            </div>
          </template>
          <div v-else>
            Нет данных
          </div>
        </v-card-text>
        <div v-else class="d-flex flex-row align-center justify-center" style="min-height: 200px;">
          <v-progress-circular indeterminate color="primary" size="30"></v-progress-circular>
        </div>

      </v-card>
    </v-dialog>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "tooltipDialog",
  props: {
    dataField: {
      type: String,
      default: ''
    },

  },
  data() {
    return {
      show: false,
      tooltip : null,
      tooltipDefault: {
        address: "",
        id: "",
        idForm: "",
        idOriginal: "",
        ist: "",
        jsonId: "",
        name: "",
        section: ""
      }
    }
  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_indicators_loading: 'get_spr_data_indicators_loading',
      get_spr_data_indicators_error: 'get_spr_data_indicators_error',
      get_spr_data_indicators: 'get_spr_data_indicators',
    }),
    address(){
      return this.tooltip?.address?.replaceAll('|', '<br />') ?? '';
    }
  },
  watch: {
    dataField(newValue) {
      if (newValue)
        this.findInfo()
    },
    show(newValue) {
      if (newValue)
        this.findInfo()
    }
  },
  created() {
    this.tooltip = this.tooltipDefault;
  },
  dialogController: null,
  methods: {
    findInfo(){
      if (this.get_spr_data_indicators_error == null && this.get_spr_data_indicators_loading === false){
        this.tooltip = this.get_spr_data_indicators.data.find(el => el.jsonId === this.dataField) ?? this.tooltipDefault;
      }
    },
    closeDialog() {
      this.$emit('closing-dialog');
      this.$options.dialogController.resolve(false);
      this.tooltip = this.tooltipDefault;
      this.show = false;
    },
    agree(){
      this.$emit('agree');
      this.$options.dialogController.resolve(true);
      this.show = false;
    },
    openDialog() {
      let resolve;
      let reject;
      const dialogPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      })
      this.$options.dialogController = {resolve, reject};
      this.show = true;
      return dialogPromise;
    },
  },
}
</script>

<style lang="sass">
.formatted-text-tooltip
  div
    color: #78909C
    font-size: 12px
  p
    color: black
    font-size: 16px
    margin-bottom: 12px
  >ul
    li
     list-style-type: disc
     &:last-child
       margin-bottom: 12px
</style>