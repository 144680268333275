import {mapGetters} from "vuex";

export const setEditDialog = {
    data() {
        return {
            loadingMenu: {value: false, id: ''},
            row: null,
            value: null,
            editInfo: {
                ugs: null,
                cell: null
            },
            dataField: null,
            typeValue: null,
            rules: null
        }
    },
    computed: {
        ...mapGetters('auth', {
            authData: 'authData',
            get_replace_id: 'get_replace_id'
        }),
        appealsLength() {
            return this.appeal_data.counter
        },
        headersForRowsWithEditableCells() {
            return this.headersForRows.filter(el => el.text !== '1')
        },
    },
    watch: {
        get_replace_id(){
            this.loadingDataTable();
        }
    },
    methods: {
        async closingEditDialog() {
            this.row = null;
            this.value = null;
            this.dataField = null;
            this.rules = null;
            this.typeValue = null;
            this.editInfo =  {
                ugs: null,
                cell: null
            };
        },
        templateUgsInfoForDialog(row){
            if (row.sprKcpUgs)
                return row.sprKcpUgs.code + ' ' + row.sprKcpUgs.name
            return ((row.sprSpec ? row.sprSpec?.kodSpec : row.sprKcpGroup?.code) + ' ' + (row.sprSpec ? row.sprSpec?.name : row.sprKcpGroup?.name))
        },
        async openDialog(value, dataField = '', cell, row) {
            this.value = value;
            this.editInfo = {
                ugs: this.templateUgsInfoForDialog(row),
                cellInfo: this.findInfoForCell(dataField),
                rowId: row.id
            }
            this.typeValue = cell.typeValue;
            this.rules = cell.rules;
            this.row = row;
            this.dataField = dataField;
            await this.$refs["edit-dialog"].openDialog();
        },
        async saveValue(e) {
            let item = this.serverData.find(el => el.id === e.id);
            if (!item){
                if (e.sprKcpUgs)
                    item = this.serverData.find(el => el.sprKcpUgs?.id === e.sprKcpUgs.id);
                else
                    item = this.serverData.find(el => el.sprSpec?.id === e.sprSpec.id);
                item.id = e.id;
            }
            item[e.dataField] =  e[e.dataField];
            await this.closingEditDialog();
            await this.$refs['edit-dialog'].closeDialog();
        }
    }
}