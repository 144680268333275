import request from "@/services/request";
import store from "@/store";


async function getFormData(nameForm = '', mapUslRows= false, api = '', full = false){
    try{
        let params = '';
        if (store.getters['auth/get_replace_id_admin'])
            params = `&orgId=${store.getters['auth/get_replace_id'] ? store.getters['auth/get_replace_id'] : store.getters['auth/get_replace_id_admin']}`;

        const res = await request({
            endpoint: api ? api+params : `/api/form5/${nameForm}?full=${full+params}`,
            method: 'get',
        });
        if (res.status === 200){
            let returnData= res.data;
            if (mapUslRows)
            {
                returnData = [];
                const usls = store.getters['spr/get_spr_data_usl'].data;
                usls.forEach(el => {
                    if (Object.hasOwnProperty.call(res.data, el.requestName) && res.data[el.requestName])
                        returnData.push( {id: Math.random(), uslName: el.name}, ...res.data[el.requestName]);
                });
                return returnData;
            }
          else
              return returnData;
        }else{
            throw new Error('error form five');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных формы '+nameForm + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error form five catch ' + e )
    }
}
async function getAppealData(id = ''){
    try{
        let params = '';
        if (store.getters['auth/get_replace_id_admin'])
            params = `&orgId=${store.getters['auth/get_replace_id'] ? store.getters['auth/get_replace_id'] : store.getters['auth/get_replace_id_admin']}`;
        const res = await request({
            endpoint: `/api/appeal/get?id=${id+params}`,
            method: 'get',
        });
        if (res.status === 200){
            return res.data;
        }else{
            throw new Error('error appeal Data');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных по аппеляции '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error appeal Data' + e )
    }
}
async function sendAppealData(payload){
    try{
        const res = await request({
            endpoint: `/api/appeal`,
            method: 'put',
            body: payload
        });
        if (res.status === 200 || res.status === 201){
            return res.data;
        }else{
            throw new Error('error appeal post');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка отправки аппеляции '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error appeal post' + e )
    }
}
async function sendAppealAdminData(payload){
    try{
        const res = await request({
            endpoint: `/api/appeal/admin`,
            method: 'put',
            body: payload
        });
        if (res.status === 200 || res.status === 201){
            return res.data;
        }else{
            throw new Error('error appeal post');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка отправки аппеляции '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error appeal post' + e )
    }
}

async function sendDataWithDopCell(payload){
    try{
        const res = await request({
            endpoint: `/api/appeal`,
            method: 'post',
            body: payload
        });
        if (res.status === 200 || res.status === 201){
            return res.data;
        }else{
            throw  new Error('error appeal post');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка отправки значения '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error appeal post' + e )
    }
}
export {getFormData, getAppealData, sendAppealData,sendAppealAdminData, sendDataWithDopCell}