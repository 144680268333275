<template>
  <div>


    <Alert alertType="warning" v-if="applicationActive">
      <template v-slot:icon>
        <v-icon :size="30" color="#856404">mdi-alert</v-icon>
      </template>
      <template v-slot:default>
        Есть активная заявка на изменение данных
        <button class="ml-2 main-color text-decoration-underline" @click="modalOpen = !modalOpen">подробно о заявке</button>
        <button class="ml-2 main-color text-decoration-underline" @click="deleteApplication">отменить или создать новую</button>
      </template>
    </Alert>


    <v-dialog v-model="modalOpen" title="Подробно о заявке">
      <v-card class="pt-5">
        <v-card-text class="">
          <div class="info-card " v-if="organization && !editMode">
            <div class="info-card__field" v-for="(value, name, index) in applicationToShow" :key="index">
              <div class="info-card__name">
                {{ name }}:
              </div>
              <div class="info-card__cell" :class="{'info-card__cell_changed': value.changed}">
                {{ value.value }}
              </div>
            </div>
            <div class="info-card__file" @click="getFile">Основание для изменения</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <Перенести в компонент> -->
    <div class="info-card" v-if="organization && !editMode">
      <div class="info-card__field" v-for="(value, name, index) in infoOrganization" :key="index">
        <div class="info-card__name">
          {{ name }}:
        </div>
        <div class="info-card__cell">
          {{ value }}
        </div>
      </div>
    </div>
    <!-- </Перенести в компонент> -->
    <!--редактирование пока выключил нет бэка для него-->
    <v-btn v-if="/*authData.role.id === 1 && */!applicationActive && !simpleBlock && organization && !editMode && !get_blockedAll"
           class="blue__v-btn" @click="editMode = !editMode">Редактировать
    </v-btn>
    <!--    <button v-if="authData.role.id !== 1 && !applicationActive && organization && !editMode && !get_blockedAll"-->
    <!--            class="button info-card__button">Редактировать-->
    <!--    </button>-->

    <FormKcpContestInfoOrganization v-model="organization" v-if="editMode" v-on:input="requestUpdateOrg($event)"
                                    @cancel="onCancel"/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import FormKcpContestInfoOrganization
  from '@/components/forms/custom/formsKcpContestInfo/FormKcpContestInfoOrganization';
import Alert from '@/components/alerts/Alert';

import request from "@/services/request";
import {saveFile} from '@/utils/saveFile';
import {getAppealOrgInfo, sendAppealORgInfo, deleteAppealOrgInfo} from "@/API/lkOrganization";
import axios from "axios";

export default {
  components: {
    FormKcpContestInfoOrganization,
    Alert,
  },
  data() {
    return {
      organization: {
        inn: null,
        kpp: null,
        orgAddress: null,
        orgFullName: null,
        orgName: null,
        orgShortName: null,
        orgFounderName: null,
        sprOrgType: null
      },
      organizationInitial: {
        inn: null,
        kpp: null,
        orgAddress: null,
        orgFullName: null,
        orgName: null,
        orgShortName: null,
        orgFounderName: null,
        sprOrgType: null
      },
      editMode: false,
      application: {
        inn: null,
        kpp: null,
        orgAddress: null,
        orgFullName: null,
        orgName: null,
        orgShortName: null,
        orgFounderName: null,
        sprOrgType: null
      },
      applicationActive: false,
      modalOpen: false,
    }
  },
  computed: {
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
    ...mapGetters('checkFilled' , { simpleBlock: 'simpleBlock'}),
    token() {
      return this.getAuthData.token
    },
    get_blockedAll() {
      return false
    },
    infoOrganization() {
      const {
        inn,
        kpp,
        address,
        nameFull,
        name,
        nameShort,
        orgFounder,
        orgType
      } = this.organizationInitial;
      return {
        'Полное название': nameFull,
        'Название': name,
        'Сокращенное название': nameShort,
        'Тип организации': orgType?.name,
        'Наименование учредителя': orgFounder?.name,
        'Адрес': address,
        'ИНН': inn,
        'КПП': kpp,
      }
    },
    applicationToShow() {
      return {
        'ID заявки': {value: "cf1360c7-4339-4965-821e-0643edc2af84", changed: false},
        'Полное название': {
          value: this.application.nameFull,
          changed: this.application.nameFull !== this.organization.nameFull
        },
        'Название': {value: this.application.name, changed: this.application.name !== this.organization.name},
        'Сокращенное название': {
          value: this.application.nameShort,
          changed: this.application.nameShort !== this.organization.nameShort
        },
        'Учредитель': {
          value: this.application.founderName,
          changed: this.application.founderName !== this.organization.founderName
        },
        'Адрес': {
          value: this.application.address,
          changed: this.application.address !== this.organization.address
        },
        'ИНН': {value: this.application.inn, changed: this.application.inn !== this.organization.inn},
        'КПП': {value: this.application.kpp, changed: this.application.kpp !== this.organization.kpp},
      }
    },
  },
  mounted() {
    this.getInfoOrganization();
    this.getAllApplicationsByUser();
  },
  methods: {
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    // Получаем данные с сервера
    async getInfoOrganization() {
      let organization = await request({
        endpoint: `/api/spr/getOrgById`,
        method: 'GET',
      });
      if (organization.data) {
        organization = organization.data;
        for (let key in organization) {
          if (organization[key] === null) {
            organization[key] = '';
          }
        }
        this.organization = {
          inn: organization.inn,
          kpp: organization.kpp,
          orgAddress: organization.address,
          orgFullName: organization.nameFull,
          orgName: organization.name,
          orgShortName: organization.nameShort,
          orgFounderName: organization.orgFounder.name,
          sprOrgType: organization?.name,
          ...organization
        };
        this.organizationInitial = {
          inn: organization.inn,
          kpp: organization.kpp,
          orgAddress: organization.address,
          orgFullName: organization.nameFull,
          orgName: organization.name,
          orgShortName: organization.nameShort,
          orgFounderName: organization.orgFounder.name,
          sprOrgType: organization?.name,
          ...organization
        };
      }

    },
    // Сработает при отмене редактирования
    onCancel() {
      this.organization = {...this.organizationInitial};
      this.editMode = false;

    },

    async requestUpdateOrg(e) {
      /*
      file: null
 inn: "4123414323"
 kpp: "321432143"
 orgAddress: "тест43213443"
 orgFounderName: "Тестовый учредитель"
 orgFullName: "Тестовый вуз"
 orgName: "Тестовый name4 «ЫЫЫййй » "ЫЫЫ" «3557» - n4 Н4 н44"
 orgOpCode: undefined
 orgShortName: "тест"
 sprOrgType: "Тестовый name4 «ЫЫЫййй » "ЫЫЫ" «3557» - n4 Н4 н44"
      * */
      const objToSend = {
        "org": {
          "id": this.organizationInitial.id,
        },
        "owner": {
          "id": this.organizationInitial.owner.id,
        },
        "orgFounder": {
          "id": this.organizationInitial.orgFounder.id,
          "name": "string"
        },
        "founderName": e.orgFounderName,
        // "founderNameRp": null,
        // "orgTypeId":  this.organizationInitial.orgType.id,
        // "orgTypeName": this.organizationInitial.orgType.name,
        "nameShort": e.orgShortName,
        "name": e.orgName,
        "nameFull": e.orgFullName,
        "address": e.orgAddress,
        "inn": e.inn,
        "kpp": e.kpp,
        "appealType": "MAIN_ORG",
        "fileId": e.file.id
      }
      try {
        const res = await sendAppealORgInfo(objToSend);
        // console.log(res)/
        this.applicationActive = true;
        this.application = res;
        this.editMode = false;
      } catch (e) {
        this.editMode = false;
      }
    },

    async getAllApplicationsByUser() {
      try {
        const res = await getAppealOrgInfo();
        const applicationActive = res.find(el => [null].includes(el.accept) && el.appealType === 'MAIN_ORG');
        if (applicationActive) {
          this.applicationActive = true;
          this.application = applicationActive;
        }
      }catch (e) {
        console.error('getAllApplicationsByUser')
      }
    },

    async deleteApplication() {
      try {
        const res  = await deleteAppealOrgInfo(this.application.id);
        if (res){
          this.getAllApplicationsByUser();
          this.applicationActive = false;
        }
      }catch (e) {
        // this.applicationActive = false;
        console.error('deleteApplication')
      }
    },

    async getFile() {
      this.uploadNow = true;
      try {
        const response = await axios.get(`/api/file/scan/download?file=${this.application.fileId}`, {
          headers: {Authorization: this.token ? `Bearer ${this.token}` : ''},
        })
        this.uploadNow = false;
        saveFile({...response.data, fileBytes: response.data.file}, false);
      } catch (e) {
        this.uploadNow = false;
        console.log("e", e)
      }
    },


  },
}
</script>

<style lang="sass">
.info-card__button
  margin-top: 10px
  margin-left: 10px

.info-card__button:hover
  background: #0292e1

.info-card__cell_changed
  background: #fff3cd
  border-radius: 3px
  color: #856404
  padding: 0px 3px

.info-card__file
  color: #EB722E
  text-decoration: underline
  cursor: pointer
</style>