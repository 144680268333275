<template>
  <v-dialog
      v-model="show"
      scrollable
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
      max-width="800"
  >
    <v-card>
       <span>
        <v-btn
            icon
            @click="closeDialog()"
            style="position: absolute; right:0;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <v-card-title>Предложения по изменению значений показателя</v-card-title>
      <v-card-text class="text-justify formatted-text-tooltip-appeal-1 " v-if="!loading && !loadingAppeal">
        <v-row no-gutters>
          <v-col class="">
            <div>
              <p class="mb-0 " style="font-size: 14px;">{{ infoCell.name }}</p>
              <p class="font-weight-bold font-size-small__important">{{ infoCell.form }}</p>
            </div>
            <div>
              <p class="mb-1 font-weight-bold" style="font-size: 14px;">{{ infoCell.ist }}</p>
              <p class="font-size-small__important" v-html="address"></p>
            </div>
          </v-col>
        </v-row>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-row>
            <v-col class="d-flex flex-row align-center text-left" >
            </v-col>
            <v-col class="d-flex flex-column align-center justify-center pl-0 pr-0 text-no-wrap" cols="2">
              Текущее значение
            </v-col>
            <v-col cols="2" class="text-center pl-0 pr-0">
             Новое значение
            </v-col>
          </v-row>
          <v-row v-for="(item, i ) in appealsArrFiltered" :key="item.id + i" dense style=" max-height: 55px;">
            <v-col class="d-flex flex-row align-center text-left " ><span style="width: 100%; border-bottom: 1px solid #78909C">{{ calcRowInfo(item) }}</span>
            </v-col>
            <v-col class="d-flex flex-column align-end justify-end font-size-medium black-color__important" cols="2" >
              <span class="text-center" style="width: 100%; border-bottom: 1px solid #78909C">{{item.oldValue}}</span>
            </v-col>
            <v-col class="d-flex flex-column align-center justify-end  font-size-medium black-color black-color__important " cols="2">
              <span class="text-center" style="width: 100%; border-bottom: 1px solid #78909C">
                {{item.newValue}}
                  <v-icon v-if="item.accept == null"   color="" size="15" >mdi-timer-sand</v-icon>
                  <v-icon v-if="item.accept === false"  color="error" size="15" >mdi-close</v-icon>
                  <v-icon v-if="item.accept === true" color="success" size="15" >mdi-check</v-icon>
              </span>

              <!--    :rules="valMenuRules(maxValue,minValue)"               -->
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex flex-row align-center text-left " ><span style="width: 100%; border-bottom: 1px solid #78909C">{{ appealInfo.ugs }}</span>
            </v-col>
            <v-col class="d-flex flex-column align-center justify-center" cols="2">
<!--              <v-text-field-->
<!--                  v-model="oldValue"-->
<!--                  readonly-->
<!--                  class="mt-2"-->
<!--                  dense-->
<!--                  required-->
<!--              />-->
              <span class="text-center black-color__important" style="width: 100%; border-bottom: 1px solid #78909C">{{oldValue}}</span>
            </v-col>
            <v-col cols="2">
              <v-text-field
                  v-model="newValue"
                  :type="typeValue"
                  placeholder="Введите значение"
                  class="mt-3 input-text-center"
                  dense
                  color="primary"
                  :rules="valMenuRules()"
                  required
              >
                <template #default>
                  <span class="text-center">{{newValue}}</span>
                </template>
              </v-text-field>
              <!--    :rules="valMenuRules(maxValue,minValue)"               -->
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                  rows="1"
                  auto-grow
                  dense
                  color="primary"
                  :rules="valMenuRules()"
                  v-model="textAppeal"
                  label="Основания изменения: "
              ></v-textarea>
            </v-col>
          </v-row>
          <scan-card
              class="mt-0"
              :item="file"
              :upload-to-serv-now="loadingFileToServer.fileType === file.fileType && loadingFileToServer.value"
              @replace-file="replaceFile($event)"
              @upload-file="uploadFile($event)"
              :token="token"
              identifier="fileType"
          >
            <template #emptyFile>
              Загрузить скан формы. <br/>Без подтверждающего документа, изменение не будет учтено.
            </template>
            <template #deleteBtn><span></span></template>
          </scan-card>
        </v-form>
        <v-row class="mt-7" dense v-if="mainStatus">
          <v-col class="font-weight-bold font-size-large__important" :class="{'error--text' : mainStatus === 'Изменения отклонены', 'text-success' : mainStatus === 'Изменения приняты' || mainStatus === 'Изменения частично приняты' }">
            {{mainStatus}}
          </v-col>
        </v-row>
        <v-row dense v-if="decision">
          <v-col >
      {{decision}}
          </v-col>
        </v-row>
      </v-card-text>
      <div v-else class="d-flex flex-row align-center justify-center" style="min-height: 200px;">
        <v-progress-circular indeterminate color="primary" size="30"></v-progress-circular>
      </div>
      <v-card-actions class="justify-end">
<!--        <v-btn class="error__v-btn" @click="closeDialog">Отменить</v-btn>-->
        <v-btn class="blue__v-btn" :loading="loadingNewAppeal" :disabled="loading || loadingAppeal || !valid || disabled" @click="saveAppeal">Внести изменения</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {getAppealData, sendAppealData} from "@/API/form5";
import ScanCard from "@/components/cards/scanCard";
import request from "@/services/request";
// import request from "@/services/request";

export default {
  name: "appealDialog",
  components: { ScanCard},
  props: {
    appealId: {
      type: String,
      default: ''
    },
    fileId: {
      type: String,
      default: ''
    },
    appeal: {
      type: Object
    },
    appealsArr: {
      type: Array,
      default: () => {return []}
    },
    appealInfo: {
      type: [Object]
    },
    value: {
      type: [Object, Number, String]
    },
    typeValue: {
      type: String,
      default: 'Number'
    },
    specialObj:{
      type: Object
    }
  },
  data() {
    return {
      show: false,
      loadingAppeal: false,
      serverAppeal: null,
      oldValue: null,
      newValue: null,
      valid: true,
      loadingNewAppeal: false,
      textAppeal: '',
      loadingFileToServer: {
        value: false,
        fileType: ''
      },
      file: {
        name: 'Подтверждающие документы',
        types: ['application/pdf'],

      },
    }
  },
  computed: {
    ...mapGetters('tables', {
      error: 'get_appeals_error',
      loading: 'get_appeals_loading',
    }),
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
    token() {
      return this.getAuthData.token
    },
    disabled(){
      return true
    },
    appealsArrFiltered(){
      return this.appealsArr.filter(el => el.id !== this.appealId);
    },
    infoCell() {
      return this.appealInfo.cellInfo ?? {name : '', ist: '', form : ''}
    },
    address(){
      return this.infoCell?.address?.replaceAll('|', '<br/>') ?? '';
    },
    decision(){
      return this.serverAppeal?.decision
    },
    mainStatus(){
       if (this.serverAppeal?.decision != null && this.serverAppeal?.decision !== '' && this.serverAppeal?.accept === true)
         return 'Изменения частично приняты';
       else if (this.serverAppeal?.decision && this.serverAppeal?.accept === false)
         return 'Изменения отклонены';
       else if ((this.serverAppeal?.decision == null || this.serverAppeal?.decision === '') && this.serverAppeal?.accept === true)
         return 'Изменения приняты';
       else if (this.serverAppeal?.id){
         return 'На рассмотрении'
       }
       else
       {return ''}
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.getFile();
        this.getAppeal();
      }
    }
  },
  created() {

  },
  dialogController: null,
  methods: {
    calcRowInfo(item){
      return ((item.spec ? item.spec?.kodSpec : item.kcpGroup?.code ) + ' ' + (item.spec ?  item.spec?.name : item.kcpGroup?.name));
    },
    getFile() {
      this.file = {...this.file, id: this.fileId, fileType: 'FORMS_FIVE_APPEALS_' + this.appealInfo.cellInfo.jsonId};
    },
    async uploadFile(event) {
      this.loadingFileToServer.value = true;
      this.loadingFileToServer.fileType = event.fileType;
      let fd = new FormData();
      fd.append('file', event.file);
      try {
        const res = await request({
          endpoint: `/api/file/scan/upload?scanType=${event.fileType}`,
          method: 'put',
          body: fd,
        });
        this.file = {...this.file, ...res.data};
        this.loadingFileToServer.value = false;
      } catch (e) {
        this.loadingFileToServer.value = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка загрузки',
          timeout: 3000
        })
      }
      // this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});
    },
    async deleteFile(event) {
      try {
        const response = await request({
          endpoint: `/api/file/scan/delete?fileId=${event.id}`,
          method: 'DELETE',
          token: this.token,
        });
        if (response.status === 200) {
          this.file = {
            name: this.file.name,
            fileType: event.fileType,
            types: this.file.types
          }
        }
      } catch (e) {
        // this.uploadNow = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка удаления',
          timeout: 3000
        });
        console.log("e delete", e)
      }
    },
    async replaceFile(event) {
      await this.deleteFile(event)
      await this.uploadFile(event)
    },
    async getAppeal() {
      this.oldValue = this.value?.oldValue ?? this.value;
      this.textAppeal = this.appeal?.appeal ?? ''
      if (this.error == null && this.loading === false) {
        if (this.appealId) {
          try {
            this.loadingAppeal = true;
            this.serverAppeal = await getAppealData(this.appealId);
            this.textAppeal = this.serverAppeal.appeal;
            if (this.serverAppeal.accept === true)
            {
              this.oldValue = this.serverAppeal.newValue;
              this.newValue = 0;
            }
            else{
              this.newValue = this.serverAppeal.newValue;
              this.oldValue = this.serverAppeal.oldValue;
            }
            this.file.id = this.serverAppeal?.fileId ?? this.fileId;
            this.loadingAppeal = false;
          } catch (e) {
            this.loadingAppeal = false;
            console.error('getAppeal error ' + e);
          }
        } else {
          this.serverAppeal = {
            ...this.appeal,
            id: null
          }
        }
      }
    },
    closeDialog() {
      this.$emit('closing-dialog');
      this.$options.dialogController.resolve(false);
      this.serverAppeal = null;
      this.loadingAppeal = false;
      this.newValue = null;
      this.show = false;
    },
    async validate() {
      this.$refs.form.validate()
    },
    valMenuRules() {
      return [
        v => !!v || 'введите значение',
      ]
    },
    async saveAppeal() {
      await this.validate();
      if (this.valid) {
        const obj = {
          "id": this.serverAppeal?.id ?? null,
          "form5Id": this.appealInfo.rowId,
          "form5KeyId": this.appealInfo.cellInfo.jsonId,
          "oldValue": this.oldValue,
          "kcpGroup": {id: this.appealInfo?.kcpGroup?.id},
          "newValue": this.newValue,
          "appeal": this.textAppeal,
          "fileId": this.file.id,
          "org": {id: this.appealInfo?.orgId},
          ...this.specialObj
        };
        try{
          this.loadingNewAppeal = true;
          const res = await sendAppealData(obj);
          if (res)
            this.$emit('saved-value', {
              ...res,
              dataField: this.appealInfo.cellInfo.jsonId,
              jsonId: this.appealInfo.cellInfo.jsonId,
            });
          this.loadingNewAppeal = false;
        }catch {
          this.loadingNewAppeal = false;
        }
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Заполнены не все данные',
          timeout: 3000
        })
      }
    },
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    openDialog() {
      let resolve;
      let reject;
      const dialogPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      })
      this.$options.dialogController = {resolve, reject};
      this.show = true;
      return dialogPromise;
    },
  },
}
</script>

<style lang="sass">
.text-success
  font-size: 20px!important
  color: #29A128!important
.formatted-text-tooltip-appeal-1
  div
    font-size: 14px
  //  color: #78909C
  .input-text-center
    input
      text-align: center
  p
    color: black
    font-size: 16px
    margin-bottom: 12px

  > ul
    li
      list-style-type: disc

      &:last-child
        margin-bottom: 14px
</style>