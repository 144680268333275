<template>
<div class="d-flex align-self-stretch justify-center" >
  <v-progress-circular v-if="loading" color="primary" indeterminate size="20"></v-progress-circular>
  <div
      v-else
      class="five-cell-dop d-flex flex-row align-center justify-center "
      style="flex: 0 0 100%;"
  >
    <v-text-field
        v-model.lazy="vv"
        :type="typeValue"
        class="input-text-center-dop"
        dense
        :placeholder="disabled ? 'х' :'Введите значение'"
        :disabled="disabled"
        color="primary"
        @change="emitData($event)"
    >
      <template #default>
        <span class="text-center">{{vv}}</span>
      </template>
    </v-text-field>
<!--    <span style=" flex: 0 0 100%;" class="text-center">{{value === null ? 'null' : formattedValue()}}</span>-->
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import {sendFormData} from "@/API/forms2-4";

export default {
  name: "form2TableCell",
  props: {
    value: {
      type: [Number, String, Object],
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    maxValue: {
      type: [String, Number]
    },
    minValue: {
      type: [String, Number]
    },
    id: {
      type: [String, Number]
    },
    cell: {
      type: [Object, String]
    },
    row: {
      type: [Object, String],
      validator: (value) =>{
        return ['string', 'object'].includes(typeof value)
      }
    },

  },
  data() {
    return {
      loading: false,
      vv: this.value
    }
  },
  watch: {
    value(){
      this.vv= this.value;
    }
    },
  computed: {
    typeValue(){
      return this.cell?.typeValue ? this.cell.typeValue : 'Number'
    },
    disabled(){
      return true //this.row?.kcpBlock ?  this.row?.kcpBlock.includes(this.cell.dataField) || this.simpleBlock || !this.row?.hasLicense : this.simpleBlock || !this.row?.hasLicense;
    },
    ...mapGetters('checkFilled' , { simpleBlock: 'simpleBlock'}),
  },
  methods: {
    formattedValue() {
      return this.cell.template ? this.cell.template(this.value) : this.value;
    },
    async sendData(value){
      try{
        this.loading = true;
        const obj = {
          ...this.row,
          fieldName: this.cell.dataField,
          [this.cell.dataField]: value
        };
        const res = await sendFormData('/api/form2/set', obj, 'Ошибка');
        this.loading = false;
        return res;
      }catch (e) {
        this.loading = false;
        this.vv = this.value;
        return false
      }
    },
    async emitData(e){
      if (!this.simpleBlock){
        try{
          const res = await this.sendData(e);
          if (res)
          {
            this.$emit('change-value-dop', {value: e,...res, id: res.id, cell: this.cell, dataField: this.cell.dataField});
          }
          else
          {
            this.vv = this.value;
          }
        }catch (e) {
          this.vv = this.value;
          console.error('not emit ', e)
        }
      }
    }
  },
}
</script>

<style lang="sass">
.five-cell-dop
  .input-text-center-dop
    .v-text-field__details
      display: none
    .v-input__slot
      &:before
        border: 0!important
    input
      font-size: 14px
      color: #455A64!important
      text-align: center
      &::placeholder
        font-size: 12px
        color: #455A64


.five-cell

  &:hover
    color: #00599B
    font-weight: 500
</style>