<template>
  <wrapper-preview-category >
    <div class="requests-buttons">
      <button class="white__v-btn mr-3 v-btn v-btn--contained theme--light v-size--default"
              :class="{'blue__v-btn': 'Org' === orgType}"
              @click="changeOrgType('Org')">
        <span class="v-btn__content">Данные организации</span>
      </button>
      <button class="white__v-btn mr-3 v-btn v-btn--contained theme--light v-size--default"
              :class="{'blue__v-btn': 'SubOrg' === orgType}"
              @click="changeOrgType('SubOrg')">
        <span class="v-btn__content">Данные филиалов</span>
      </button>
    </div>

    <v-autocomplete
        :items="selectedOptions"
        v-model="selectedOption"
        class="mb-2"
    />

    <v-progress-linear
        indeterminate
        color="#00599b"
        v-if="!applications.length"
    ></v-progress-linear>
    <CardApplication
        v-for="item in selectedTypeApplication"
        :key="item.key"
        :item="item"
        :orgType="orgType"
        @reject="onReject($event)"
        @resolve="onResolve($event)"
        @aboutApplication="onClickAboutApplication($event)"
    />
    <div v-if="getActualApplicationsByOrgType.length === 0" class="text-center main-color font-size-large">
      Нет заявок
    </div>

    <Modal
        v-on:input="modalOpen = !modalOpen"
        v-if="modalOpen"
        title="Подробно о заявке"
    >
      <div class="info-cards">
        <div class="compare-application">
          <h4
              class="compare-application__title compare-application__title_application"
          >
            Заявка на изменение
          </h4>
          <div
              class="compare-application__field"
              v-for="(value, name, index) in selectedApplication.actualData"
              :key="index"
          >
            <div
                class="compare-application__name"
            >
              {{ name }}:
            </div>
            <div
                class="compare-application__cell"
            >
              {{ value }}
            </div>
          </div>
        </div>

        <div class="compare-application">
          <h4
              class="compare-application__title compare-application__title_actual"
          >
            Данные на сервере
          </h4>
          <div
              class="compare-application__field"
              v-for="(value, name, index) in selectedApplication.applicationData"
              :key="index"
          >
            <div
                class="compare-application__name"
                :class="{ 'compare-application__name_changed': value.changed }">
              {{ name }}:
            </div>
            <div
                class="compare-application__cell"
                :class="{ 'info-card__cell_changed': value.changed }"
            >
              {{ value.value }}
            </div>
          </div>
        </div>

      </div>
      <div>
        <v-btn class="blue__v-btn mt-2" @click="getFile(selectedApplication.fileId)">Скачать приложенный файл</v-btn>
      </div>
    </Modal>
  </wrapper-preview-category>
</template>

<script>
// import Alert from "@/components/alerts/Alert";

import Modal from "@/components/modals/Modal";
import {mapGetters} from "vuex";
import request from "@/services/request";
import CardApplication from "@/components/cards/custom/CardApplication/CardApplication";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {getFileFromServer} from "@/mixins/getFile";
import {acceptAppealORgInfo} from "@/API/lkOrganization";

export default {
  components: {
    WrapperPreviewCategory,
    CardApplication,
    Modal,
    // Alert,
  },
  mixins: [getFileFromServer],
  data() {
    return {
      applications: [],
      applicationsNew: [],
      orgType: 'Org',
      modalOpen: false,
      selectedApplicationId: "",
      selectedOptions: [
        {value : 1, text: "Активные заявки"},
        {value : 2, text: "Неактивные заявки"} ,
        {value : 3, text: "Все заявки"} //[true,false, null]
      ],
      selectedOption:  1,
    };
  },
  mounted() {
    this.getActualApplications();
  },

  computed: {
    ...mapGetters("auth", {authData: "authData"}),

    selectedApplication() {
      return this.applicationsNew.find(
          (el) => el.id === this.selectedApplicationId
      );
    },
    selectedTypeApplication() {
      if (this.selectedOption === 1) {
        return this.getActualApplicationsByOrgType.filter((el) => el.accept === null);
      } else if (this.selectedOption === 2) {
        return this.getActualApplicationsByOrgType.filter((el) => el.accept === true || el.accept === false);
      } else {
        return this.getActualApplicationsByOrgType;
      }
    },
    getActualApplicationsByOrgType() {
      if (this.orgType === "Org") {
        return this.applicationsNew.filter(item => item.appealType === "MAIN_ORG")
      } else if (this.orgType === "SubOrg") {
        return this.applicationsNew.filter(item =>  ["FILIAL_ORG_UPDATE", 'FILIAL_ORG_CREATE', 'FILIAL_ORG_DELETE'].includes(item.appealType))
      }
      else return this.applicationsNew
    },

  },

  methods: {
    onClickAboutApplication(e) {
      this.selectedApplicationId = e.id;
      this.modalOpen = !this.modalOpen;
    },

    changeOrgType(type) {
      this.orgType = type
    },
    applicationTypeName(el){
      switch (el) {
        case 'MAIN_ORG':
          return 'Редактирование головной организации';
        case 'FILIAL_ORG_UPDATE':
          return 'Редактирование филиала организации';
        case 'FILIAL_ORG_CREATE':
          return 'Создание филиала';
        case 'FILIAL_ORG_DELETE':
          return 'Удаление филиала';
        default:
          return ''
      }
    },
    applicationStatusName(el){
      switch (el) {
        case null:
          return 'на рассмотрении';
        case true:
          return 'Одобрена';
        case false:
          return 'Отклонена';
        default:
          return 'на рассмотрении'
      }
    },
    async getActualApplications() {
      let applications = await request({
        endpoint: `/api/admin/getAllAppealOrg`,
        method: 'GET',
      });
      applications = applications.data;
      this.applications = applications;
      let applicationsNew = [];
      for (let application of applications) {
        await (async () => {
          let app = {...application};

          app.applicationID = application.id;
          app.orgName = application.org.name;
          app.orgID = application.org.id;
          app.applicationStatus = this.applicationStatusName(application.accept);
          app.applicationTypeName = this.applicationTypeName(application.appealType);
          app.createDate = new Date(application.createDate);

          if (application.org.id === application.owner.id) {
            app.orgType = 'Org'
          } else {
            app.orgType = 'SubOrg'
          }

          app.actualData = {
            "Полное название": application.nameFull,
            'Название': application.name,
            "Сокращенное название": application.nameShort,
            'Учредитель': application.founderName,
            'Адрес': application.address,
            'ИНН': application.inn,
            'КПП': application.kpp,
          };

          app.applicationData = {
            "Полное название": {
              value: application.org.nameFull,
              changed: application.org.nameFull !== application.nameFull,
            },
            'Название': {
              value: application.org.name,
              changed: application.org.name !== application.name,
            },
            "Сокращенное название": {
              value: application.org.nameShort,
              changed: application.org.nameShort !== application.nameShort,
            },
            'Учредитель': {
              value: application.orgFounder.name,
              changed: application.orgFounder.name !== app.actualData['Учредитель'],
            },
            'Адрес': {
              value: application.org.address,
              changed: application.org.address !== application.address,
            },
            'ИНН': {
              value: application.org.inn,
              changed: application.org.inn !== application.inn,
            },
            'КПП': {
              value: application.org.kpp,
              changed: application.org.kpp !== application.kpp,
            },
          };
          applicationsNew.push(app);
        })();
      }

      this.applicationsNew = applicationsNew.sort(
          (a, b) => b.createDate - a.createDate
      );
    },
    async onReject(e) {
      const item = this.applicationsNew.find(el => el.id === e.id);
      try {
        const res = await acceptAppealORgInfo({id: item.id, accept: false});
        item.accept = res.accept;
        item.applicationStatus = this.applicationStatusName(res.accept);
      } catch (e) {
        console.error(e);
      }
    },
    async onResolve(e) {
      const item = this.applicationsNew.find(el => el.id === e.id);
      try {
        const res = await acceptAppealORgInfo({id: item.id, accept: true});
        item.accept = res.accept;
        item.applicationStatus = this.applicationStatusName(res.accept);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="sass">
.applications
  width: 100%
  margin-top: 15px

.requests-buttons
  margin-bottom: 15px

.requests-buttons__button
  margin-right: 10px

.info-card__button
  margin-top: 10px
  margin-left: 10px

.info-card__button:hover
  background: #0292e1

.info-card__cell_changed
  background: #fff3cd
  border-radius: 3px
  color: #856404
  padding: 0px 3px

.modal-content
  max-width: 900px

.info-cards
  display: flex

.compare-application
  text-align: left
  flex: 1

.compare-application:first-of-type
  border-right: 1px solid #6e8da2

.compare-application__name
  background: #e3effb
  color: #00599b
  padding: 1px 7px
  border-radius: 3px
  margin-right: 10px
  display: inline-block
  white-space: nowrap

.compare-application__field
  padding: 5px 10px
  display: flex
  flex-direction: column
  align-items: flex-start

.compare-application__cell
  margin-left: 10px

.compare-application__cell_changed
  background: #fff3cd
  color: #856404
  border-radius: 3px

.compare-application__name_changed
  background: #fff3cd
  color: #856404

.compare-application__title
  font-size: 18px
  text-align: center
  padding: 5px

.compare-application__title_actual
  background: #d4edda
  color: #155724

.compare-application__title_application
  background: #fff3cd
  color: #856404

.applications__select-type
  margin-bottom: 20px
  margin-top: 10px
  background-color: #fff
  padding: 5px 10px
  border: 1px solid #00599b
  cursor: pointer
  min-width: 200px

.cms-card-news__actions
  display: flex

.cms-card-news
  background: #fff
  margin-bottom: 15px
  padding: 5px 10px
  display: flex
  justify-content: space-between
  align-items: center
  border-left: 3px solid #00599b
  transition: 0.3s

.cms-card-news__header
  flex-grow: 0
  flex-basis: 700px
  overflow: hidden

.application-change__title
  font-weight: 500
  color: #eb8e1c

.cms-card-news:hover
  transform: translateY(-5px)
  box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
  transition: 0.3s

.cms-card-news__ok
  background: #41cf8f
  padding: 3px
  border-radius: 3px
  border: 1px solid #129a5d
  cursor: pointer

.cms-card-news__ok:hover
  background: #108751

.cms-card-news__edit:hover
  background: #ffeeba

.cms-card-news__delete
  background: #ce303f
  padding: 3px
  border-radius: 3px
  border: 1px solid #8c0c1a
  cursor: pointer

.cms-card-news__delete:hover
  background: #4c040b

.application-status_ACTIVE
  background: #fff

.application-change-content__field
  display: flex

.application-change-content__label
  white-space: nowrap
  color: #00599b
  font-weight: 500
  margin-right: 15px

.application-status_DONE .application__status
  color: #155724
  background: #d4edda
  border: 1px solid #c3e6cb

.application-status_REJECT .application__status
  color: #721c24
  background: #f8d7da
  border: 1px solid #f5c6cb

.applications__card
  background: #fff
  margin-bottom: 15px
  padding: 5px 10px
  display: flex
  justify-content: space-between
  align-items: center
  border-left: 3px solid #00599b
  transition: 0.3s

.applications__item
  flex-basis: 0
  flex-grow: 1
  padding: 0 10px

.applications__item > div
  margin-bottom: 5px

.applications__header
  flex-basis: 100px

.application__status
  text-align: center
  font-weight: 500
  padding: 5px
  border-radius: 5px
  min-width: 190px

.applications__actions
  min-width: 100px
  display: flex
  justify-content: flex-end
  align-items: center

.applications__actions > div
  margin-bottom: 0

.cms-card-news__ok
  margin-right: 5px

.application__moderate-time
  font-size: 12px

.application-moderate
  text-align: center

.application-status_DONE, .application-status_REJECT
  opacity: 0.7

.application-status_DONE:hover, .application-status_REJECT:hover
  opacity: 1

.applications__header span
  background: #e3effb
  color: #00599b
  padding: 2px 5px
  border-radius: 3px
  margin-left: 10px

.application-type
  padding: 2px 5px
  display: inline-flex
  border-radius: 5px
  margin-bottom: 10px

.application-type_DELETE
  background: #f8d7da
  border-color: #f5c2c7
  color: #842029

.application-type_CREATE
  background: #d1e7dd
  border-color: #badbcc
  color: #0f5132

.application-type_UPDATE
  background: #fff3cd
  border-color: #ffeeba
  color: #856404
</style>