<template>
  <div class="reference-main">

    <div class="cms-panel">
      <div class="cms-panel-search">
        <v-icon :size="25" color="#cdd1d4" class="fr-topbar__icon">mdi-magnify</v-icon>
        <input type="search" class="cms-panel-search__input" v-model="searchOrg">
      </div>
      <BSelect :data="searchFields" v-model="selectedSearchField" class="cms-panel-search__sort"/>
    </div>

    <v-progress-linear
        indeterminate
        color="#00599b"
        v-if="!sprOrgList.length && !resultSearch.data.length || loading"
    ></v-progress-linear>

    <Alert alertType="primary" v-else-if="sprOrgList.length && !resultSearch.data.length">
      <template v-slot:icon>
        <v-icon :size="30" color="#084298">mdi-information-outline</v-icon>
      </template>
      <template v-slot:default>Поиск не дал результатов</template>
    </Alert>

    <div v-else>
      <div class="sort-panel">
        <div class="card__info card__info_sort card__info__id" @click="sort('id')">ID &#8595;</div>
        <div class="card__info card__info_sort card__info__name" @click="sort('name')">Наименование
          &#8595;
        </div>
        <div class="card__info card__info_sort card__info__inn" @click="sort('inn')">ИНН &#8595;
        </div>
        <div class="card__info card__info_sort card__info__address" @click="sort('address')">Адрес
          &#8595;
        </div>
        <!--<div class="card__info card__info__action"></div>-->
        <div class="card__info card__info__action"></div>
      </div>

      <div>
        <CardAdminOrg v-for="item in resultSearch.data" :key="item.key" :item="item"
                      @auth-in-org="onAuthInOrg($event)"/>
      </div>
      <p class="paginator-info">
        Страница: {{ this.paginatorParams.selectedPage }},
        Результатов на странице: {{ this.paginatorParams.itemsPerPage }},
        Всего результатов: {{ resultSearch.counter }}
      </p>
      <BPaginator
          :propDataCount="resultSearch.counter"
          v-model="paginatorParams"
      />
    </div>


  </div>
</template>


<script>
import {mapActions, mapGetters} from 'vuex';
import CardAdminOrg from "@/components/cards/custom/CardAdminOrg/CardAdminOrg";
import BSelect from "@/components/controls/BSelect/BSelect";
import BPaginator from "@/components/paginator/BPaginator/BPaginator";
import request from "@/services/request";
import utils from "@/utils/utils";
import Alert from "../../components/UI/Alert";

export default {
  components: {
    CardAdminOrg,
    BSelect,
    BPaginator,
    Alert
  },
  data() {
    return {
      sprOrgList: [],
      searchOrg: '',
      searchFields: ['ID', 'Наименование', 'ИНН', 'Адрес'],
      selectedSearchField: 'ID',
      results: 0,
      currentSort: 'id',
      currentSortDir: 'asc',
      loading: false,
      paginatorParams: {'selectedPage': 1, 'itemsPerPage': 10},
    }
  },
  mounted() {
    if (!this.sprOrgList.length) {
      this.getSprOrgList();
    }
  },
  computed: {
    // ...mapGetters('spr', {get_spr_data_org_main: 'get_spr_data_org_main'}),
    ...mapGetters('spr', {get_spr_data_org_loading: 'get_spr_data_org_loading'}),

    selectedField() {
      if (this.selectedSearchField === 'ID') {
        return 'id'
      } else if (this.selectedSearchField === 'Наименование') {
        return 'name'
      } else if (this.selectedSearchField === 'ИНН') {
        return 'inn'
      } else if (this.selectedSearchField === 'Адрес') {
        return 'address'
      } else {
        return 'name'
      }
    },

    resultSearch() {
      let results;
      let array = [];
      if (!this.sprOrgList) {
        return;
      }

      array = this.sprOrgList.filter(el => el.id === el.owner.id);
      array = array.sort((a, b) => {

        let mod = 1
        let tempA;
        let tempB;

        if (typeof a[this.currentSort] === 'string' && typeof b[this.currentSort] === 'string') {
          tempA = a[this.currentSort].toLowerCase()
          tempB = b[this.currentSort].toLowerCase()
        } else {
          tempA = a[this.currentSort]
          tempB = b[this.currentSort]
        }

        if (this.currentSortDir === 'desc') mod = -1
        if (tempA < tempB) return -1 * mod
        if (tempA > tempB) return 1 * mod

        return 0
      });

      let search = this.searchOrg;

      if (!search) {
        results = array.length;
        array = utils.divideArrayInPages(array, this.paginatorParams.selectedPage, this.paginatorParams.itemsPerPage)
        return {'data': array, 'counter': results};
      }

      search = search.trim().toLowerCase()
      array = array.filter((item) => {
        if (String(item[this.selectedField]).toLowerCase().indexOf(search) !== -1) {
          return item
        }
      })

      results = array.length;
      array = utils.divideArrayInPages(array, this.paginatorParams.selectedPage, this.paginatorParams.itemsPerPage)
      return {'data': array, 'counter': results};
    }
  },
  methods: {
    // ...mapActions('spr', {dataForSpr: 'dataForSpr'}),
    ...mapActions('auth', {replaceIdAdmin: 'replaceIdAdmin'}),
    ...mapActions('checkFilled', {getOrgInfo: 'getOrgInfo', getFilialsOrgInfo: 'getFilialsOrgInfo'}),
    async getSprOrgList() {
      try {
        this.loading= true;
        let orgs = await request({
          endpoint: `/api/admin/getAllOrgs`,
          method: 'GET',
        });
        this.sprOrgList = orgs.data;
        this.loading= false;
      }catch (e) {
        this.loading = false;
        console.error(e)
      }

    },
    // Pagination
    prevPage() {
      if (this.paginatorParams.selectedPage > 1) this.paginatorParams.selectedPage -= 1
    },
    nextPage() {
      if ((this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage) < this.resultSearch.counter) this.paginatorParams.selectedPage += 1
    },
    sort(e) {
      if (e === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }
      this.currentSort = e
    },
    async onAuthInOrg(event) {
      // console.log(event)
      await this.replaceIdAdmin(event);
      this.getOrgInfo();
      this.getFilialsOrgInfo();
      await this.$router.push('/forms');
    }
  }
}
</script>


<style lang="sass" scoped>
@import "@/assets/styles/utils/variables"

.cms-panel-search
  display: flex
  align-items: center
  background: #fff
  border-radius: 5px
  padding: 0px 10px
  border: 1px solid #cdd1d4

.cms-panel
  margin-bottom: 20px
  display: flex

.cms-panel-search__sort
  background-color: #fff
  display: flex
  align-items: center
  border-radius: 5px
  padding: 0px 10px
  border: 1px solid #cdd1d4
  margin-left: 10px

.sort-panel
  display: flex
  //width: 100%
  justify-content: space-between
  font-weight: 600
  color: #00599b
  margin-bottom: 10px
  padding: 0 13px

.reference-main
  width: 100%

.paginator-info
  text-align: center
  color: #00599b

.card__info_sort
  cursor: pointer

.card__info.card__info__action .button_border
  text-align: center
  max-height: 35px

.cms-panel-search__sort.select
  min-width: 170px

.cms-panel-search__input
  min-width: 500px
</style>