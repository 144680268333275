import request from "@/services/request";
import store from "@/store";

async function sendAppealORgInfo( payload){
    try{
        const res = await request({
            endpoint: '/api/orgAppeal',
            method: 'put',
            body: payload
        });
        if (res.status === 200 || res.status === 201){
            return res.data;
        }else{
            throw new Error('error org appeal  put');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка отправки '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        return new Error('error org appeal  put' + e )
    }
}
async function acceptAppealORgInfo( payload){
    const {id, accept} = payload;
    try{
        const res = await request({
            endpoint: '/api/orgAppeal',
            method: 'post',
            body:
                {
                    'id': id,
                    'accept': accept
                }
        });
        if (res.status === 200 || res.status === 201){
            return res.data;
        }else{
            throw new Error('error org appeal  post');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка отправки '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        return new Error('error org appeal  post' + e )
    }
}
async function deleteAppealOrgInfo( id){
    try{
        const res = await request({
            endpoint: `/api/orgAppeal?id=${id}`,
            method: 'delete',
        });
        if (res.status === 200){
            return res;
        }else{
            throw new Error('error org appeal  delete');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка удаления '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        return new Error('error org appeal  delete' + e )
    }
}
async function getAppealOrgInfo(){
    try{
        const res = await request({
            endpoint: '/api/orgAppeal',
            method: 'get',
        });
        if (res.status === 200){
            return res.data.length >0  ? res.data : [];
        }else{
            throw new Error('error org appeal  get');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        return new Error('error org appeal  get' + e )
    }
}
export {sendAppealORgInfo, getAppealOrgInfo, deleteAppealOrgInfo, acceptAppealORgInfo}