<template>
  <div>
    <div class='table' v-if="!loadingData && serverData">
      <div class='table__item table__item-header left-line right-line bottom-line without-line3'>Уровни и формы образования</div>
      <div class='table__item table__item-header right-line bottom-line text-center without-line'>Обучающихся (искл. квоту иностр.граждан)
        всего
      </div>
      <div class='table__item table__item-header right-line bottom-line text-center'>граждан РФ</div>
      <div class='table__item table__item-header right-line bottom-line without-line2 text-center'>Иностранных граждан,обучающихся по
        квоте
      </div>

      <div class='table__item table__item-header right-line bottom-line top-line text-center'>на 1.10.2018 г.</div>
      <div class='table__item table__item-header right-line bottom-line top-line text-center'>на 1.10.2019 г.</div>
      <div class='table__item table__item-header right-line bottom-line top-line text-center'>на 1.10.2020 г.</div>
      <div class='table__item table__item-header right-line bottom-line top-line text-center'>на 1.10.2021 г.</div>
      <div class='table__item table__item-header right-line bottom-line top-line text-center'>на 1.10.2021 г.</div>
      <div class='table__item table__item-header right-line bottom-line top-line text-center'>на 1.10.2018 г.</div>
      <div class='table__item table__item-header right-line bottom-line top-line text-center'>на 1.10.2019 г.</div>
      <div class='table__item table__item-header right-line bottom-line top-line text-center'>на 1.10.2020 г.</div>
      <div class='table__item table__item-header right-line bottom-line top-line'>на 1.10.2021 г.</div>

      <div class='table__item pa-0 table__item-header right-line bottom-line text-center left-line'>1</div>
      <div class='table__item pa-0 table__item-header right-line bottom-line text-center' style="min-width: 150px">2</div>
      <div class='table__item pa-0 table__item-header right-line bottom-line text-center'>3</div>
      <div class='table__item pa-0 table__item-header right-line bottom-line text-center'>4</div>
      <div class='table__item pa-0 table__item-header right-line bottom-line text-center'>5</div>
      <div class='table__item pa-0 table__item-header right-line bottom-line text-center'>6</div>
      <div class='table__item pa-0 table__item-header right-line bottom-line text-center'>7</div>
      <div class='table__item pa-0 table__item-header right-line bottom-line text-center'>8</div>
      <div class='table__item pa-0 table__item-header right-line bottom-line text-center'>9</div>
      <div class='table__item pa-0 table__item-header right-line bottom-line text-center'>10</div>
      <div class='table__item pa-0 table__item-header right-line bottom-line text-center'>11</div>

      <div class='table__item right-line bottom-line left-line' style="grid-row: span 3">Бакалавриат</div>
      <div class='table__item right-line bottom-line left-line text-end'>очная форма</div>
      <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(0,9)" :key="item.name">
        <fives-table-cell
           style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>

<!--      <div class='table__item right-line bottom-line left-line'></div>-->
      <div class='table__item right-line bottom-line left-line text-end'>очно-заочная форма</div>
      <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(9,18)" :key="item.name">
        <fives-table-cell
           style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>

<!--      <div class='table__item right-line bottom-line left-line'></div>-->
      <div class='table__item right-line bottom-line left-line text-end left-line'>заочная форма</div>
      <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(18,27)" :key="item.name">
        <fives-table-cell
           style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>


      <div class='table__item right-line bottom-line left-line' style="grid-row: span 3">Специалитет</div>
      <div class='table__item right-line bottom-line left-line text-end'>очная форма</div>
      <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(27,36)" :key="item.name">
        <fives-table-cell
           style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>


<!--      <div class='table__item right-line bottom-line left-line'></div>-->
      <div class='table__item right-line bottom-line left-line text-end'>очно-заочная форма</div>
      <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(36,45)" :key="item.name">
        <fives-table-cell
           style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>

<!--      <div class='table__item right-line bottom-line left-line'></div>-->
      <div class='table__item right-line bottom-line left-line text-end'>заочная форма</div>
      <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(45,54)" :key="item.name">
        <fives-table-cell
           style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>

      <div class='table__item right-line  left-line' style="grid-row: span 3">Магистратура</div>
      <div class='table__item right-line bottom-line left-line text-end'>очная форма</div>
      <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(54,63)" :key="item.name">
        <fives-table-cell
           style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>

<!--      <div class='table__item right-line bottom-line left-line'></div>-->
      <div class='table__item right-line bottom-line left-line text-end'>очно-заочная форма</div>
      <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(63,72)" :key="item.name">
        <fives-table-cell
           style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>

<!--      <div class='table__item right-line left-line'></div>-->
      <div class='table__item right-line left-line text-end '>заочная форма</div>
      <div class='table__item  right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(72,81)" :key="item.name">
        <fives-table-cell
           style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else class="d-flex flex-row align-center justify-center" style="min-width: 150px;">
      <v-progress-linear indeterminate />
    </div>
    <appeal-dialog
        ref="appeal-dialog"
        :appeal-id="appealId"
        :file-id="fileId"
        :appeal="appealCell"
        :value="appealValue"
        :appeal-info="appealInfo"
        @saved-value="saveValue($event)"
        @closing-dialog="closingAppealDialog"
    ></appeal-dialog>
    <tooltip-forms5-dialog
        ref="tooltip-dialog"
        :data-field="dataFieldTooltip"
    >
    </tooltip-forms5-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getFormData} from "@/API/form5";
import {setAppealFromOneToFourDialog} from "@/mixins/forms5/appealDialogF1-4";
import FivesTableCell from "@/components/fields/fivesTableCell";
import appealDialog from "@/components/Dialogs/appealForms5_1-4Dialog";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";

export default {
  name: "form5b",
  mixins: [setAppealFromOneToFourDialog, headerTooltipFunctions],
  components: {FivesTableCell, appealDialog, tooltipForms5Dialog},
  data() {
    return {
      serverData: null,
      loadingData: false,
      loadingMenu: {value: false, id: ''},
      dataFields: [
        {name: "nsbco18Vp1"},
        {name: "nsbco19Vp1"},
        {name: "nsbco20Vp1"},
        {name: "nsbco21Vp1"},
        {name: "nrbco21Vp1"},
        {name: "nqbco18Vp1"},
        {name: "nqbco19Vp1"},
        {name: "nqbco20Vp1"},
        {name: "nqbco21Vp1"},

        {name: "nsbcv18Vp1"},
        {name: "nsbcv19Vp1"},
        {name: "nsbcv20Vp1"},
        {name: "nsbcv21Vp1"},
        {name: "nrbcv21Vp1"},
        {name: "nqbcv18Vp1"},
        {name: "nqbcv19Vp1"},
        {name: "nqbcv20Vp1"},
        {name: "nqbcv21Vp1"},

        {name: "nsbcz18Vp1"},
        {name: "nsbcz19Vp1"},
        {name: "nsbcz20Vp1"},
        {name: "nsbcz21Vp1"},
        {name: "nrbcz21Vp1"},
        {name: "nqbcz18Vp1"},
        {name: "nqbcz19Vp1"},
        {name: "nqbcz20Vp1"},
        {name: "nqbcz21Vp1"},

        {name: "nsspo18Vp1"},
        {name: "nsspo19Vp1"},
        {name: "nsspo20Vp1"},
        {name: "nsspo21Vp1"},
        {name: "nrspo21Vp1"},
        {name: "nqspo18Vp1"},
        {name: "nqspo19Vp1"},
        {name: "nqspo20Vp1"},
        {name: "nqspo21Vp1"},

        {name: "nsspv18Vp1"},
        {name: "nsspv19Vp1"},
        {name: "nsspv20Vp1"},
        {name: "nsspv21Vp1"},
        {name: "nrspv21Vp1"},
        {name: "nqspv18Vp1"},
        {name: "nqspv19Vp1"},
        {name: "nqspv20Vp1"},
        {name: "nqspv21Vp1"},

        {name: "nsspz18Vp1"},
        {name: "nsspz19Vp1"},
        {name: "nsspz20Vp1"},
        {name: "nsspz21Vp1"},
        {name: "nrspz21Vp1"},
        {name: "nqspz18Vp1"},
        {name: "nqspz19Vp1"},
        {name: "nqspz20Vp1"},
        {name: "nqspz21Vp1"},

        {name: "nsmgo18Vp1"},
        {name: "nsmgo19Vp1"},
        {name: "nsmgo20Vp1"},
        {name: "nsmgo21Vp1"},
        {name: "nrmgo21Vp1"},
        {name: "nqmgo18Vp1"},
        {name: "nqmgo19Vp1"},
        {name: "nqmgo20Vp1"},
        {name: "nqmgo21Vp1"},

        {name: "nsmgv18Vp1"},
        {name: "nsmgv19Vp1"},
        {name: "nsmgv20Vp1"},
        {name: "nsmgv21Vp1"},
        {name: "nrmgv21Vp1"},
        {name: "nqmgv18Vp1"},
        {name: "nqmgv19Vp1"},
        {name: "nqmgv20Vp1"},
        {name: "nqmgv21Vp1"},

        {name: "nsmgz18Vp1"},
        {name: "nsmgz19Vp1"},
        {name: "nsmgz20Vp1"},
        {name: "nsmgz21Vp1"},
        {name: "nrmgz21Vp1"},
        {name: "nqmgz18Vp1"},
        {name: "nqmgz19Vp1"},
        {name: "nqmgz20Vp1"},
        {name: "nqmgz21Vp1"},
      ]

    }
  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_usl_error: 'get_spr_data_usl_error',
    }),
  },
  created() {
    this.appealInfo.nameForm = 'FORM5_3'
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    }
  },
  methods: {
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getFormData('getPart3', false);
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },
    calcPropsForCell(dataField) {
      return {
        value: this.serverData[dataField],
        title: '',
        cell: {dataField: dataField},
        id: this.serverData.id,
        row: this.serverData,
        loading: this.loading,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  display: grid;
  grid-template-columns: 2fr 3fr repeat(9, minmax(100px, 150px));
  color: #89A0B0;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  margin-bottom: 20px;
  overflow: auto;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }
}

.table__item-header{
  font-size: 12px;
}

.table__item {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #CFD8DC;

  &:not(.table__item-header) {
    color: #455A64
  }
}

.table__header {
  border-top: 1px solid #CFD8DC;
}

.top-line {
  border-top: none;
}

.left-line {
  border-left: none;
}

.bottom-line {
  border-bottom: none;
}

.right-line {
  border-right: none;
}

.without-line {
  grid-column-start: 3;
  grid-column-end: 7;
}

.without-line2 {
  grid-column-start: 8;
  grid-column-end: 12;
}

.without-line3 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.item-data:hover {
  background: rgba(207, 224, 236, 0.42);
}
</style>