<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
        <p>
          <span class="font-weight-bold mb-1">Таблица A. (фрагмент)</span><br>
          Сведения о трудоустройстве выпускников (независимо от формы обучения)<br>
          расчет на основе данных подсистемы «Анализ трудоустройства граждан» информационно-аналитической системы
          Общероссийская база вакансий «Работа в России» (Минтруд России)
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="4" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="6">Численность выпускников (искл. продолживших обучение)</th>
        <!--      <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr>
        <th colspan="3">всего</th>
        <th colspan="3">
          из них - трудоустроенных
        </th>
      </tr>
      <tr>
        <th>
          в 2017 г.
        </th>
        <th>
          в 2018 г.
        </th>
        <th>
          в 2019 г.
        </th>
        <th>
          в 2018 г.
        </th>
        <th>
          в 2019 г.
        </th>
        <th>
          в 2020 г.
        </th>
      </tr>
      <tr>
        <th>
          все формы
        </th>
        <th>
          все формы
        </th>
        <th>
          все формы
        </th>
        <th>
          все формы
        </th>
        <th>
          все формы
        </th>
        <th>
          все формы
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th v-for="(item) in 6" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvtrs17, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvtrs18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvtrs19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nttrs17, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nttrs18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nttrs19, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
      </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ДОВ = {{  $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvtrsS3, 0, 'integer')  }}
        </td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          УТВ = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nttrsS3, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense>
      <v-col cols="10"><p>
        <span class="font-weight-bold mb-1">Формы 5.12а,б. (фрагмент)</span><br>
        Численность иностранных граждан и лиц без гражданства, завершивших обучение в 2017-2019 годах по программам
        бакалавриата, программам специалитета<br>
        по данным 1-Мониторинг, человек
      </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="9">Численность выпускников - иностранных граждан, обучавшихся на условиях общего приема</th>
        <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr>
        <th colspan="3">
          с 01.10.2016 по 30.09.2017
        </th>
        <th colspan="3">
          с 01.10.2017 по 30.09.2018
        </th>
        <th colspan="3">
          с 01.10.2018 по 30.09.2019
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th v-for="(item) in 9" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviso17, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvisv17, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvisz17, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviso18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvisv18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvisz18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviso19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvisv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvisz19, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class=" border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="9" class="font-size-small border-right-zero" style="line-height: 15px;">
          Численность выпускников - иностранных граждан, обучавшихся по квоте приема иностранных граждан и лиц без гражданства
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviqo17, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviqv17, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviqz17, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviqo18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviqv18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviqz18, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviqo19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviqv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nviqz19, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="3" class="border-bottom-zero border-right-zero">
        </td>
        <td colspan="9" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-9.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="9" class="border-bottom-zero border-right-zero formul-text-table">
          ЧВИ = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvigsS3, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          УТВ
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">ДОВ <span class="alternate-text mx-1">−</span>	ЧВИ</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">× 100</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nttrsS3, 0, 'integer') }}
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvtrsS3, 0, 'integer') }} <span class="alternate-text mx-1">−</span>	{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvigsS3, 0, 'integer') }}</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">× 100</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{  $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float')  }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>УТВ - численность выпускников (за исключением продолживших обучение по образовательным программам высшего
      образования), завершивших обучение в 2017-2019 годах, имевших в году, следующем за годом завершения обучения
      (выпуска), заработную плату в среднем размере не менее одного прожиточного минимума трудоспособного населения,
      установленного для региона их трудоустройства, либо зарегистрированных в указанном году в качестве индивидуальных
      предпринимателей или самозанятых;</p>
    <p>ДОВ - численность выпускников (за исключением продолживших обучение по образовательным программам высшего
      образования), завершивших обучение в 2017-2019 годах;</p>
    <p>ЧВИ - численность иностранных граждан и лиц без гражданства, завершивших обучение в 2017-2019 годах по программам
      бакалавриата, программам специалитета</p>
    <p v-if="rowOfValues.data[item.dataFieldName+'nf22'] !== '0'">Если разница значений ДОВ и ЧВИ менее 5, фактическое значение показателя не рассчитывается, а его нормированная
      оценка определяется в соответствии с пунктом 7 Методики (по средним).</p>

  </div>

</template>

<script>
export default {
  name: "b31",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>
</style>