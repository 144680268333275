export default {
    countCells: 6,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 2,
            text: 'Регистрационный номер НИОКТР',
            class: 'd-flex flex-row align-center justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
            // template: (row) => {
            //     return row.sprKcpGroupId + ' - ' + row.sprKcpGroupName
            // }
        },
        {
            dataField: 'npg11',
            cols: 3,
            rows: 1,
            text: 'Параметры НИОКТР',
            headerClass: 'text-center border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg111',
            cols: 2,
            rows: 2,
            text: 'Рубрики ГРНТИ',
            headerClass: 'text-center ',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg31',
            cols: 1,
            rows: 1,
            text: `начало`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg32',
            cols: 1,
            rows: 1,
            text: `конец`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg33',
            cols: 1,
            rows: 1,
            text: `тыс.рублей`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'rdreg22Egs',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row text-left',
            text: `1`,
            order: 6,
            variable: true,
        },
        {
            dataField: 'rddtb22Egs',
            cols: 1,
            rows: 1,
            text: `3`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'rddte22Egs',
            cols: 1,
            rows: 1,
            text: `4`,
            order: 8,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'rdfin22Egs',
            cols: 1,
            rows: 1,
            text: `5`,
            order: 9,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'rdrub22Egs',
            cols: 2,
            rows: 1,
            text: `6`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
    ]
}
