export default {
    countCells: 7,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 4,
            text: 'Код и наименование УГСН (НПС)',
            class: 'd-flex flex-row align-center justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
        },
        {
            dataField: 'npg11',
            cols: 2,
            rows: 2,
            text: 'Всего принятых на обучение (вкл. квоту иностр.граждан)',
            headerClass: 'text-center border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg111',
            cols: 4,
            rows: 1,
            text: 'Выпускников',
            headerClass: 'text-center border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg1111',
            cols: 2,
            rows: 1,
            text: 'искл. квоту иностр.граждан',
            headerClass: 'text-center border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg12',
            cols: 2,
            rows: 1,
            text: 'иностр.граждан, обучавшихся по квоте',
            headerClass: 'text-center border-bottom-zero',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 1,
            rows: 1,
            text: "в 2016 г.",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 1,
            rows: 1,
            text: "в 2017 г.",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg14',
            cols: 1,
            rows: 1,
            text: "в 2019 г.",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg15',
            cols: 1,
            rows: 1,
            text: "в 2020 г.",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg21',
            cols: 1,
            rows: 1,
            text: `в 2019 г.`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg22',
            cols: 1,
            rows: 1,
            text: `в 2020 г.`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg23',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg24',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg25',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg26',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg31',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg32',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row text-left',
            text: `1`,
            order: 6,
            variable: true,
        },
        {
            dataField: 'npugs17K21', //npugs17K21
            cols: 1,
            rows: 1,
            text: `3`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'npugs18Dop',
            cols: 1,
            rows: 1,
            text: `4`,
            order: 8,
            alternateEdit: true,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvsgs20Nk1',
            cols: 1,
            rows: 1,
            text: `5`,
            order: 9,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvsgs21Nk1',
            cols: 1,
            rows: 1,
            text: `6`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvqgs20Dop',
            cols: 1,
            rows: 1,
            text: `7`,
            order: 8,
            alternateEdit: true,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvqgs21Dop',
            cols: 1,
            rows: 1,
            text: `8`,
            order: 9,
            alternateEdit: true,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
    ]
}
