<template>
  <v-dialog
      v-model="show"
      scrollable
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
      max-width="1500"
  >
    <v-card>
       <span>
        <v-btn
            icon
            @click="closeDialog()"
            style="position: absolute; right:0;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <v-card-title>
        <div v-if="!loading && !loadingAppeal">
          <div class="d-flex flex-row align-center font-size-medium__important text-left black-color__important"><span class="b-indicator__title mr-1">{{ infoAppeal.title }}</span> {{ infoAppeal.about }}</div>
          <div class="d-flex flex-row align-center font-size-medium__important mt-2 text-left black-color__important"><span class="b-indicator__title font-weight-bold mr-1">{{ infoAppeal.count }}</span> баллов</div>
        </div>
      </v-card-title>
      <v-card-text class="text-justify formatted-text-tooltip-appeal mt-5" v-if="!loading && !loadingAppeal">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-row>
            <v-col>
              <v-textarea
                  disabled
                  auto-grow
                  :rules="valMenuRules()"
                  v-model="decision"
                  label="Текст аппеляции: "
              ></v-textarea>
            </v-col>
          </v-row>
          <scan-card
              :disabled="disabled"
              :canDelete="!disabled"
              :item="file"
              :upload-to-serv-now="loadingFileToServer.fileType === file.fileType && loadingFileToServer.value"
              @replace-file="replaceFile($event)"
              @upload-file="uploadFile($event)"
              :token="token"
              identifier="fileType"
          />
        </v-form>
        <v-row class="mt-7" dense v-if="mainStatus">
          <v-col class="font-weight-bold font-size-large__important error--text">
<!--              Апелляция на рассмотрении-->
            {{mainStatus}}
          </v-col>
        </v-row>
        <v-row dense v-if="appeal && appeal.appeal">
          <v-col >

            {{appeal.appeal}}
          </v-col>
        </v-row>
      </v-card-text>
      <div v-else class="d-flex flex-row align-center justify-center" style="min-height: 200px;">
        <v-progress-circular indeterminate color="primary" size="30"></v-progress-circular>
      </div>
      <v-card-actions class="d-flex justify-center">
<!--        <v-btn class="error__v-btn" @click="closeDialog">Отменить</v-btn>-->
        <div class="d-flex flex-row">
          <v-btn class="blue__v-btn mr-3" :loading="loadingNewAppeal" :disabled="loading || loadingAppeal || !valid || disabled" @click="saveAppeal">Подать апелляцию</v-btn>
          <div class="d-flex flex-row align-center font-size-medium__important text-left black-color__important">Дата подачи: <span class="grey-color ml-1">{{ date }}</span></div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import {getAppealData, sendAppealData} from "@/API/form5";
import ScanCard from "@/components/cards/scanCard";
import request from "@/services/request";
import {putAppeal} from "@/API/indicators";
import moment from 'moment'

moment.locale('ru');
export default {
  name: "appealIndicators",
  components: {ScanCard},
  props: {
    appeal: {
      type: Object,
      default: () =>{
        return {
          decision: '',
          fileId: null,
          id: null,
          date: null
        }
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    appealInfo: {
      type: [Object],
      default: () =>{
        return {
          title: '',
          about: '',
          count: null,
          dataFieldName: '',
        }
      }
    },
  },
  data() {
    return {
      show: false,
      loadingAppeal: false,
      serverAppeal: null,
      valid: true,
      loadingNewAppeal: false,
      decision: '',
      loadingFileToServer: {
        value: false,
        fileType: ''
      },
      file: {
        name: 'Подтверждающие документы (при необходимости)',
        types: 'all'/*['application/pdf',]*/,
      },
    }
  },
  computed: {
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
    token() {
      return this.getAuthData.token
    },
    disabled(){
      return true
    },
    mainStatus(){
      if (this.appeal?.appeal != null && this.appeal?.appeal !== '' && this.appeal?.accept === true)
        return 'Изменения приняты';
      else if (this.appeal?.appeal && this.appeal?.accept === false)
        return 'Изменения отклонены';
      else if ((this.appeal?.appeal == null || this.appeal?.appeal === '') && this.appeal?.accept === true)
        return 'Изменения приняты';
      else if (this.appeal?.id){
        return 'На рассмотрении'
      }
      else
      {return ''}
    },

    date(){
       return this.appeal?.date ? moment(this.appeal.date).format('LLL') : null
    },
    infoAppeal(){
      return this.appealInfo ?? {
        title: '',
        about: '',
        count: null,
      }
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.getFile();
        this.decision = this.appeal?.decision ?? ''
        // this.getAppeal();
      }
    }
  },
  dialogController: null,
  methods: {
    getFile() {
      this.file = {...this.file, id: this.appeal?.fileId, fileType: 'INDICATORS' +  this.appealInfo.dataFieldName};
    },
    async uploadFile(event) {
      this.loadingFileToServer.value = true;
      this.loadingFileToServer.fileType = event.fileType;
      let fd = new FormData();
      fd.append('file', event.file);
      try {
        const res = await request({
          endpoint: `/api/file/scan/upload?scanType=${event.fileType}`,
          method: 'put',
          body: fd,
        });
        this.file = {...this.file, ...res.data};
        this.loadingFileToServer.value = false;
      } catch (e) {
        this.loadingFileToServer.value = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка загрузки',
          timeout: 3000
        })
      }
      // this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});
    },
    async deleteFile(event) {
      try {
        const response = await request({
          endpoint: `/api/file/scan/delete?fileId=${event.id}`,
          method: 'DELETE',
          token: this.token,
        });
        if (response.status === 200) {
          this.file = {
            name: this.file.name,
            fileType: event.fileType,
            types: this.file.types
          }
        }
      } catch (e) {
        // this.uploadNow = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка удаления',
          timeout: 3000
        });
        console.log("e delete", e)
      }
    },
    async replaceFile(event) {
      await this.deleteFile(event)
      await this.uploadFile(event)
    },
    closeDialog() {
      this.$emit('closing-dialog');
      this.$options.dialogController.resolve(false);
      this.serverAppeal = null;
      this.loadingAppeal = false;
      this.newValue = null;
      this.show = false;
    },
    async validate() {
      this.$refs.form.validate()
    },
    valMenuRules() {
      return [
        v => !!v || 'введите значение',
      ]
    },
    async saveAppeal() {
      await this.validate();
      if (this.valid ) {
        const obj = {
          "id": this.appeal?.id ?? null,
          "indicator": this.appealInfo.dataFieldName,
          "decision": this.decision,
          "fileId": this.file.id,
          "accept": null,
          usl: {id: this.appealInfo.usl.id},
          fo: {id: this.appealInfo.fo.id},
          kcp: {id: this.appealInfo.kcp.id},
          org: {id: this.appealInfo.org.id},
        };
        // if (!this.file.id)
        //   await this.addNotification({
        //     notificationStatus: 'warning',
        //     notificationMessage: 'Заявка не будет рассматриваться ебз потверждающего документа',
        //     timeout: 3000
        //   })
        try {
          this.loadingNewAppeal = true;
          const res = await putAppeal(obj);
          if (res)
            this.$emit('saved-value', {
              ...res,
              appealInfo: this.appealInfo,
            });
          this.loadingNewAppeal = false;
        }catch (e) {
          this.loadingNewAppeal = false;
        }
      }
    },
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    openDialog() {
      let resolve;
      let reject;
      const dialogPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      })
      this.$options.dialogController = {resolve, reject};
      this.show = true;
      return dialogPromise;
    },
  },
}
</script>

<style lang="sass">
.text-success
  font-size: 20px!important
  color: #29A128!important
.formatted-text-tooltip-appeal
  div
    color: #78909C
    font-size: 14px

  p
    color: black
    font-size: 16px
    margin-bottom: 12px

  > ul
    li
      list-style-type: disc

      &:last-child
        margin-bottom: 14px
</style>