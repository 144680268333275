<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.19. (фрагмент)</span><br>
          Численность принятых на обучение и завершивших обучение по программам подготовки научных и научно-педагогических кадров в аспирантуре
          <br>
          по данным формы 1-НК, человек

        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="4" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="2">Всего принятых на обучение<br>
          (вкл. квоту иностр.граждан)
        </th>
        <th></th>
        <th rowspan="5" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
        <th rowspan="2" class=" border-right-zero" colspan="2"><span class="formul-text-table">dgs</span></th>
      </tr>
      <tr>
        <th colspan="1">
        </th>
        <th colspan="1">
        </th>
        <th></th>
      </tr>
      <tr>

        <th >
          в 2016 г.
        </th>
        <th >
          в 2017 г.
        </th>
        <th >

        </th>
        <th rowspan="3" colspan="2" class="border-right-zero">
          вес группы<br> научных<br> специальностей<br> в УГСН
        </th>
      </tr>
      <tr>
        <th>
          все формы
        </th>
        <th>
          все формы
        </th>
        <th>
          ...
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>

        <th v-for="(item) in 2" :key="item">{{ item + 2}}</th>
        <th>...</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in rowOfValues.data.tabA21ugorg" :key="row.id">
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ row.idugn }}</span>
          <span class="kcp-name">{{ row.ugtxt }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npugs17, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npugs18, 0, 'integer') }}</td>
        <td>...</td>
        <td v-if="index === 0" :rowspan="rowOfValues.data.tabA21ugorg.length" class="border-bottom-zero border-right-zero"><v-icon size="30">mdi-close</v-icon></td>
        <td class="border-right-zero" colspan="2">{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt,2) }}</td>
      </tr>
      <tr>
        <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
        <td colspan="5"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
          перевод значений в группу научных специальностей
        </td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей</td>
        <td colspan="5" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-5.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npugs17, 2) }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npugs18, 2) }}</td>
        <td class="border-top-1">...</td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero border-bottom-zero text-left"></td>
        <td colspan="2" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-2.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="2" class="border-bottom-zero border-right-zero formul-text-table">
          ЧПА = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npugsS2, 2) }}
        </td>
      </tr>
      </tbody>
    </table>
    <p v-if="rowOfValues.data.ison222 === '1'">
      Если значение ЧПА по группе научных специальностей менее 5, значения ЗКН и ЧПА рассчитываются по области наук
    </p>
    <v-row dense v-if="rowOfValues.data.ison222 === '1'">
      <v-col cols="10">
        <h3 class="b-indicator__collapse-subtitle">Исходные данные по области наук:</h3>
        <p>
          <span class="font-weight-bold mb-1">Форма 5.19. (фрагмент)</span><br>
          Численность принятых на обучение и завершивших обучение по программам подготовки научных и научно-педагогических кадров в аспирантуре
          <br>
          по данным формы 1-НК, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.ison222 === '1'">
      <thead>
      <tr>
        <th rowspan="4" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="2">Всего принятых на обучение<br>
          (вкл. квоту иностр.граждан)
        </th>
        <th></th>
        <th rowspan="5" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
        <th rowspan="2" class=" border-right-zero" colspan="2"><span class="formul-text-table">dgs</span></th>
      </tr>
      <tr>
        <th>
        </th>
        <th>
        </th>
        <th></th>
      </tr>
      <tr>

        <th >
          в 2016 г.
        </th>
        <th >
          в 2017 г.
        </th>
        <th >
        </th>

        <th rowspan="3" colspan="2" class="border-right-zero">
          вес группы<br> научных<br> специальностей<br> в УГСН
        </th>
      </tr>
      <tr>


        <th>
          все формы
        </th>
        <th>
          все формы
        </th>
        <th>
          ...
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>

        <th v-for="(item) in 2" :key="item">{{ item + 2 }}</th>
        <th>...</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in rowOfValues.data.tabA21noorg" :key="row.id">
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ row.idugn }}</span>
          <span class="kcp-name">{{ row.ugtxt }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npugs17, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npugs18, 0, 'integer') }}</td>
        <td>...</td>
        <td v-if="index === 0" :rowspan="rowOfValues.data.tabA21noorg.length" class="border-bottom-zero border-right-zero"><v-icon size="30">mdi-close</v-icon></td>
        <td class="border-right-zero" colspan="2">{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt,2) }}</td>
      </tr>
      <tr>
        <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
        <td colspan="5"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
          перевод значений в группу научных специальностей
        </td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей</td>
        <td colspan="5" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-5.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: left" class="border-bottom-zero font-weight-bold">
          <span class="kcp-name">{{ rowOfValues.data.uotxt }}</span>
        </td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
      </tr>
      <tr v-for="(row) in rowOfValues.data.tabA21biggroup" :key="row.id">
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ row.kcpGroup.code }}</span>
          <span class="kcp-name">{{ row.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npugs17,2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.npugs18,2) }}</td>
        <td>...</td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero border-bottom-zero text-left"></td>
        <td colspan="2" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-2.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="2" class="border-bottom-zero border-right-zero formul-text-table">
          ЧПА =           {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npuosS2,2) }}
        </td>
      </tr>
      </tbody>
    </table>
    <block-zkn :item="item" :row-of-values="rowOfValues"/>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" >
        <template>
          <span class="mt-7"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          ЗКН
        </template>
        <template #bottom>
          <div class="formul-text" style="min-height: 49px">
            ЧПА
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> × 100 </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[ rowOfValues.data.ison222 === '0' ? 'qdugsS2' : 'qduosS2'], 2) }}
        </template>
        <template #bottom>
          <div class="formul-text" style="min-height: 49px">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[ rowOfValues.data.ison222 === '0' ? 'npugsS2' : 'npuosS2'], 2) }}
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> × 100 </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>ЗКН	-	защищенных диссертаций лицами, успешно освоившими программы аспирантуры (независимо от образовательной организации, в которой была защищена диссертация) в 2019-2020 годах</p>
    <p class="mb-4">ЧПА	-	число лиц, принятых в аспирантуру в 2016-2017 годах</p>
    <ul class="pl-0" v-if="rowOfValues.data[item.dataFieldName+'nf22'] !== '0'">Если значение ЧПА, рассчитанное по области наук, менее 5:
      <li>- если частное ЗКН и ЧПА, рассчитанных по области наук, равно или более 0,5, то значение показателя А22 рассчитывается по указанной выше формуле;</li>
      <li>- в иных случаях значение показателя А22 не рассчитывается, а его нормированная оценка определяется в соответствии с пунктом 7 Методики (по средним).</li>
    </ul>


  </div>
</template>

<script>
import BlockZkn from "@/components/cards/indicatiors/details/blockZkn";
export default {
  name: "a22",
  components: {BlockZkn},
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false)
    }
  },
}
</script>

<style lang="sass" scoped>

</style>