export default {
    countCells: 10,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 3,
            text: 'Код и наименование УГСН (НПС)',
            class: 'd-flex flex-row align-center justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
            // template: (row) => {
            //     return row.sprKcpGroupId + ' - ' + row.sprKcpGroupName
            // }
        },
        {
            dataField: 'npg11',
            cols: 9,
            rows: 1,
            text: 'Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета\t\t\t\t\t\t\t\t\n',
            headerClass: 'border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg12',
            cols: 3,
            rows: 1,
            text: 'в 2019/2020 учебном году',
            headerClass: 'border-bottom-zero',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 3,
            rows: 1,
            text: "в 2020/2021 учебном году",
            headerClass: 'border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg14',
            cols: 3,
            rows: 1,
            text: `в 2021/2022 учебном году`,
            headerClass: 'border-bottom-zero',
            order: 4,
            variable: false,
        },
        {
            dataField: 'npg31',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg32',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg33',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg34',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg35',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg36',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg37',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg38',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg39',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row text-left',
            text: `1`,
            order: 6,
            variable: true,
        },
        {
            dataField: 'npsfo19K21',
            cols: 1,
            rows: 1,
            text: `3`,
            headerClass: 'pa-0 font-size-small__important',
            order: 7,
            variable: true,
        },
        {
            dataField: 'npsfv19K21',
            cols: 1,
            rows: 1,
            text: `4`,
            headerClass: 'pa-0 font-size-small__important',
            order: 8,
            variable: true,
        },
        {
            dataField: 'npsfz19K21',
            cols: 1,
            rows: 1,
            text: `5`,
            headerClass: 'pa-0 font-size-small__important',
            order: 9,
            variable: true,
        },
        {
            dataField: 'npsfo20K21',
            cols: 1,
            rows: 1,
            text: `6`,
            headerClass: 'pa-0 font-size-small__important',
            order: 7,
            variable: true,
        },
        {
            dataField: 'npsfv20K21',
            cols: 1,
            rows: 1,
            text: `7`,
            headerClass: 'pa-0 font-size-small__important',
            order: 8,
            variable: true,
        },
        {
            dataField: 'npsfz20K21',
            cols: 1,
            rows: 1,
            text: `8`,
            headerClass: 'pa-0 font-size-small__important',
            order: 9,
            variable: true,
        },
        {
            dataField: 'npsfo21Mpk',
            cols: 1,
            rows: 1,
            text: `9`,
            headerClass: 'pa-0 font-size-small__important',
            order: 7,
            variable: true,
        },
        {
            dataField: 'npsfv21Mpk',
            cols: 1,
            rows: 1,
            text: `10`,
            headerClass: 'pa-0 font-size-small__important',
            order: 8,
            variable: true,
        },
        {
            dataField: 'npsfz21Mpk',
            cols: 1,
            rows: 1,
            text: `11`,
            headerClass: 'pa-0 font-size-small__important',
            order: 9,
            variable: true,
        },
    ]
}
