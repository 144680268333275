<template>
  <div>
    <kovert-status class="mb-3"/>
    <WrapperPreviewCategory customClass="section-preview_lk" v-if="statusOrg.numBsmAdd !== null">
      <WrapperCollapse
          title="Итоги перераспределения контрольных цифр приема по специальностям и направлениям подготовки и (или) укрупненным группам специальностей
и направлений подготовки для обучения по имеющим государственную аккредитацию образовательным программам высшего образования (программам
бакалавриата, программам специалитета, программам магистратуры) за счет бюджетных ассигнований федерального бюджета
на 2023/24 учебный год"
          :initialOpen="true"
      >
        <ContainerKCPInformationDocumentsAddKcp :status-org="statusOrg"/>
      </WrapperCollapse>
    </WrapperPreviewCategory>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <WrapperCollapse
          title="Результаты открытого публичного конкурса по распределению контрольных цифр приема за счет бюджетных ассигнований федерального бюджета на 2023/24 учебный год"
          :initialOpen="true"
      >
        <ContainerKCPInformationDocuments :status-org="statusOrg"/>
      </WrapperCollapse>
    </WrapperPreviewCategory>
    <template v-if="!isAdmin">
      <WrapperPreviewCategory customClass="section-preview_lk">
        <WrapperCollapse
            title="КОНТАКТНАЯ ИНФОРМАЦИЯ ПРЕДСТАВИТЕЛЯ ОБРАЗОВАТЕЛЬНОЙ ОРГАНИЗАЦИИ, ОТВЕТСТВЕННОГО ЗА УЧАСТИЕ В КОНКУРСЕ"
            :initialOpen="true"
        >
          <ContainerPersonContact
              v-if="!loadingContacts"
              :contact="contact"
              :can-be-deleted="false"
              :saving-data="savingContact"
              :is-single="true"
              :blocked="getBlock"
              :ignore-personal-phone-number="true"
              :loading-contacts="loadingContacts"
              @change-contact="changeContact($event, false)"

          />
          <div class="d-flex align-center justify-center" style="width: 100%;height: 200px" v-else>
            <v-progress-circular size="30" color="primary"></v-progress-circular>
          </div>
        </WrapperCollapse>
      </WrapperPreviewCategory>
      <WrapperPreviewCategory customClass="section-preview_lk">
        <WrapperCollapse
            title="СВЕДЕНИЯ О РУКОВОДИТЕЛЕ ОБРАЗОВАТЕЛЬНОЙ ОРГАНИЗАЦИИ"
            :initialOpen="true"
        >
          <ContainerPersonContact
              v-if="!loadingContacts"
              :contact="directorContact"
              :can-be-deleted="false"
              :saving-data="savingDirectorContact"
              :is-single="true"
              :blocked="getBlock"
              role="director"
              :ignore-personal-phone-number="true"
              :loading-contacts="loadingContacts"
              @change-contact="changeContact($event, true)"

          />
          <div class="d-flex align-center justify-center" style="width: 100%;height: 200px" v-else>
            <v-progress-circular size="30" color="primary"></v-progress-circular>
          </div>
        </WrapperCollapse>
      </WrapperPreviewCategory>
      <WrapperPreviewCategory customClass="section-preview_lk">
        <WrapperCollapse
            title="Информация об организации"
            :initialOpen="true"
        >
          <ContainerOrganization/>
        </WrapperCollapse>
      </WrapperPreviewCategory>
      <!-- </Организация> -->


      <!--     <Филиалы> -->
      <WrapperPreviewCategory customClass="section-preview_lk">
        <WrapperCollapse
            title="Информация о структурных подразделениях/филиалах"
            :initialOpen="true"
        >
          <ContainerBranchOffice/>
        </WrapperCollapse>
      </WrapperPreviewCategory>
      <!-- </Филиалы> -->

      <!--    &lt;!&ndash; <Уставные документы> &ndash;&gt;-->
      <WrapperPreviewCategory customClass="section-preview_lk">
        <WrapperCollapse
            title="Уставные документы"
            :initialOpen="true"
        >
          <ContainerDocuments/>
        </WrapperCollapse>
      </WrapperPreviewCategory>
    </template>
    <!-- </Уставные документы> -->
  </div>
</template>

<script>
import request from "@/services/request";
import {mapActions, mapGetters} from 'vuex';
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';
import ContainerPersonContact from "@/components/contacts/ContainerPersonContact";
import ContainerOrganization from "@/containers/ContainerOrganization";
import ContainerBranchOffice from "@/containers/ContainerBranchOffice";
import ContainerDocuments from "@/containers/ContainerDocuments";
import KovertStatus from "@/components/cards/kovertStatus";
import ContainerKCPInformationDocuments from "@/containers/ContainerKCPInformationDocuments"
import ContainerKCPInformationDocumentsAddKcp from "@/containers/ContainerKCPInformationDocumentsAddKcp"

export default {
  name: "LkOrganization",
  components: {
    KovertStatus,
    ContainerDocuments,
    ContainerBranchOffice,
    ContainerOrganization,
    WrapperPreviewCategory,
    WrapperCollapse,
    ContainerPersonContact,
    ContainerKCPInformationDocuments,
    ContainerKCPInformationDocumentsAddKcp
  },
  data() {
    return {
      contact:
          {
            contactIndex: 1,
            position: '',
            fio: '',
            workPn: '',
            workPnAdd: '',
            email: ''
          },
      directorContact:
          {
            contactIndex: 0,
            position: '',
            positionRp: '',
            fio: '',
            fioRp: '',
            workPn: '',
            workPnAdd: '',
            email: ''
          },
      savingContact: false,
      savingDirectorContact: false,
      loadingContacts: false,
      errorSaving: null,
      loadingBlockEnterData: false,
      loadingBlockEnterWithoutData: false,
      downloadingFileForPrint: false,
    }
  },
  created() {
    // if (this.authData)
    this.getContacts()
  },
  computed: {
    ...mapGetters('checkFilled', {
      get_check_filled_status: 'get_check_filled_status',
      get_contact_data: 'get_contact_data',
      simpleBlock: 'simpleBlock',
      statusOrg: 'statusOrg'
    }),
    ...mapGetters('keycloak', {isAdmin: 'isAdmin'}),
    getBlock() {
      return this.simpleBlock;
    },
    canCloseEnter() {
      return !!this.get_contact_data?.workPhoneNumber
    }
  },
  watch: {},
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled', getStatusInfo: 'getStatusInfo'}),
    ...mapActions('auth', {blockEnterData: 'blockEnterData'}),
    initContacts(data) {
      this.contact = data.find(el => el.contactIndex !== 0) ?? this.contact;
      this.directorContact = data.find(el => el.contactIndex === 0) ?? this.directorContact;
    },
    async getContacts() {
      this.loadingContacts = true;
      try {
        const res = await request({
          endpoint: `/api/contact`,
          method: 'get'
        })
        if (res.status === 200) {
          if (res.data) {
            this.initContacts(res.data);
            // await this.fetchCheckFilled({contacts: this.contact})
          }
        }
        this.loadingContacts = false;
      } catch (e) {
        this.loadingContacts = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения данных ответственных исполнителей' + e,
          timeout: 5000
        })
      }


    },
    async changeContact(obj, isDirector) {
      this[isDirector ? 'savingDirectorContact' : 'savingContact'] = true;
      try {
        const res = await request({
          endpoint: `/api/contact`,
          method: 'put',
          body: {
            ...obj,
            id: obj?.id,
          },
        })
        if (res.status === 200 || res.status === 201) {
          this[isDirector ? 'directorContact' : 'contact'] = res.data;
          // this.initContacts(res.data)
          // await this.fetchCheckFilled({contacts: this.contact});
          await this.getStatusInfo();
          this[isDirector ? 'savingDirectorContact' : 'savingContact'] = false;
        } else {
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка сохранения данных контакта',
            timeout: 5000
          });
          this[isDirector ? 'savingDirectorContact' : 'savingContact'] = false;
        }

      } catch (e) {
        this[isDirector ? 'savingDirectorContact' : 'savingContact'] = false;
        console.error('e change contact', e);
        // await this.addNotification({
        //   notificationStatus: 'error',
        //   notificationMessage: 'Ошибка сохранения данных ответственного исполнителя',
        //   timeout: 5000
        // })
      }
      this[isDirector ? 'savingDirectorContact' : 'savingContact'] = false;
    },
  }
}
</script>

<style lang="sass">
.section-preview_lk
  background: #fff
  border-radius: 6px
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06)
  //border: 1px solid #cdd1d4
  margin-bottom: 20px


  .lk-list_flex_start
    justify-content: flex-start
</style>
