export function calcBackground(value, maxValue, minValue = 0, mainColor = '#75A5C9', secondColor= '#C6DAE9'){
    if (value ==null)
        return '';
    let a=  Number.parseFloat(value)
    let b=  Number.parseFloat(maxValue) - Number.parseFloat(minValue)
    let per = a * 100 / b
    return  isNaN(per) ?``:
        `background: -webkit-linear-gradient(left, ${mainColor} ${per}%,${secondColor} ${per}%);
      background:    -moz-linear-gradient(left, ${mainColor} ${per}%,${secondColor} ${per}%);
      background:     -ms-linear-gradient(left, ${mainColor} ${per}%,${secondColor} ${per}%);
      background:      -o-linear-gradient(left, ${mainColor} ${per}%,${secondColor} ${per}%);
      background:         linear-gradient(to right, ${mainColor} ${per}%,${secondColor} ${per}%);`
}