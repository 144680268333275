<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.20. (фрагмент)</span><br>
          Дополнительные сведения о количестве защищенных диссертаций на соискание ученой степени кандидата наук и выпуске закончивших обучение по программам подготовки научных и научно-педагогических кадров в аспирантуре
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="4" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th></th>
        <th colspan="2">Выпуск целевого обучения
        </th>
        <th rowspan="5" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
        <th rowspan="2" class=" border-right-zero" colspan="2"><span class="formul-text-table">dgs</span></th>

      </tr>
      <tr>
        <th ></th>
        <th colspan="1">
          всего
        </th>
        <th>с защитой<br> диссер.</th>
      </tr>
      <tr>
        <th ></th>
        <th >
          в 2020 г.
        </th>
        <th >
          в 2020 г.
        </th>

        <th rowspan="3" colspan="2" class="border-right-zero">
          вес группы<br> научных<br> специальностей<br> в УГСН
        </th>
      </tr>
      <tr>
        <th>
          ...
        </th>
        <th>
          все формы
        </th>
        <th>
          все формы
        </th>

      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th>...</th>
        <th v-for="(item) in 2" :key="item">{{ item + 4}}</th>

      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in rowOfValues.data.tabA21ugorg" :key="row.id">
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ row.idugn }}</span>
          <span class="kcp-name">{{ row.ugtxt }}</span>
        </td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nvcls21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qdcls21, 0, 'integer') }}</td>

        <td v-if="index === 0" :rowspan="rowOfValues.data.tabA21ugorg.length" class="border-bottom-zero border-right-zero"><v-icon size="30">mdi-close</v-icon></td>
        <td class="border-right-zero" colspan="2">{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt, 2) }}</td>
      </tr>
      <tr>
        <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
        <td colspan="5"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
          перевод значений в группу научных специальностей
        </td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей</td>
        <td colspan="5" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-5.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td class="border-top-1">...</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvcls21, 2) }}</td>
        <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qdcls21, 2) }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ЦВ
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
        ЦЗ
      </td>
      </tr>
      </tbody>
    </table>
    <p v-if="rowOfValues.data.ison322 === '1'">Если значение ЦВ по группе научных специальностей менее 5, значения ЦЗ и ЦВ рассчитываются по области наук</p>
    <v-row dense v-if="rowOfValues.data.ison322 === '1'">
      <v-col cols="10">
        <h3 class="b-indicator__collapse-subtitle">Исходные данные по области наук:</h3>
        <p>
          <span class="font-weight-bold mb-1">Форма 5.20. (фрагмент)</span><br>
          Дополнительные сведения о количестве защищенных диссертаций на соискание ученой степени кандидата наук и выпуске закончивших обучение по программам подготовки научных и научно-педагогических кадров в аспирантуре
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.ison322 === '1'">
      <thead>
      <tr>
        <th rowspan="4" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th></th>
        <th colspan="2">Выпуск целевого обучения</th>

        <th rowspan="5" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
        <th rowspan="2" class=" border-right-zero" colspan="2"><span class="formul-text-table">dgs</span></th>
      </tr>
      <tr>
        <th>
        </th>
        <th>всего
        </th>
        <th>с защитой<br> диссер.
        </th>
      </tr>
      <tr>
        <th >
        </th>
        <th >
          в 2020 г.
        </th>
        <th >
          в 2020 г.
        </th>
        <th rowspan="3" colspan="2" class="border-right-zero">
          вес группы<br> научных <br>специальностей<br> в УГСН
        </th>
      </tr>
      <tr>
        <th>
          ...
        </th>

        <th>
          все формы
        </th>
        <th>
          все формы
        </th>

      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th>...</th>
        <th v-for="(item) in 2" :key="item">{{ item + 4 }}</th>

      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in rowOfValues.data.tabA21noorg" :key="row.id">
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ row.idugn }}</span>
          <span class="kcp-name">{{ row.ugtxt }}</span>
        </td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nvcls21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qdcls21, 0, 'integer') }}</td>

        <td v-if="index === 0" :rowspan="rowOfValues.data.tabA21noorg.length" class="border-bottom-zero border-right-zero"><v-icon size="30">mdi-close</v-icon></td>
        <td class="border-right-zero" colspan="2">{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt, 2) }}</td>
      </tr>
      <tr>
        <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
        <td colspan="5"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
          перевод значений в группу научных специальностей
        </td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей</td>
        <td colspan="5" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-5.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: left" class="border-bottom-zero font-weight-bold">
          <span class="kcp-name">{{ rowOfValues.data.uotxt }}</span>
        </td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
        <td class="border-bottom-zero border-top-1"></td>
      </tr>
      <tr v-for="(row) in rowOfValues.data.tabA21biggroup" :key="row.id">
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ row.kcpGroup.code }}</span>
          <span class="kcp-name">{{ row.kcpGroup.name }}</span>
        </td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nvcls21, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qdcls21, 2) }}</td>

      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ЦВ =          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvcos21,2) }}
        </td><td class="border-bottom-zero border-right-zero formul-text-table">
         ЦЗ =           {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qdcos21, 2) }}
      </td>
      </tr>
      </tbody>
    </table>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" >
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          ЦЗ
        </template>
        <template #bottom>
          <div class="formul-text" style="min-height: 49px">
            ЦВ
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> × 100 </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[ rowOfValues.data.ison322 === '0' ? 'qdqls21' : 'qdcos21'], 2) }}
        </template>
        <template #bottom>
          <div class="formul-text" style="min-height: 49px">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[ rowOfValues.data.ison322 === '0' ? 'nvcls21' : 'nvcos21'], 2) }}
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> × 100 </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>

    <p>ЦЗ	-	численность обучавшихся по договорам о целевом обучении, закончивших обучение в 2020 году с защитой диссертации</p>
    <p class="mb-4">ЦВ	-	численность обучавшихся по договорам о целевом обучении, закончивших обучение в 2020 году</p>
    <ul class="pl-0" v-if="rowOfValues.data[item.dataFieldName+'nf22'] !== '0'">Если значение ЦВ, рассчитанное по области наук, менее 5:
      <li>- если частное ЦЗ и ЦВ, рассчитанных по области наук, равно или более 0,6, то показатель А23 рассчитывается по указанной выше формуле;</li>
      <li>- в иных случаях значение показателя А23 не рассчитывается, а его нормированная оценка определяется в соответствии с пунктом 7 Методики (по средним).</li>
    </ul>


  </div>
</template>

<script>
export default {
  name: "a23",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false)
    }
  },
}
</script>

<style lang="sass" scoped>

</style>