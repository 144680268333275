<template>
  <WrapperForm >
    <wrapper-collapse title="Добавить" >
      <v-form
          class="contact-card__input pt-2"
          ref="org-input"
          v-model="valid"
          lazy-validation
      >
        <div class="info-card__field">
          <div class="info-card__name">
            Полное наименование организации
          </div>
          <v-text-field
              v-model="form.orgFullName"
              :rules="[rules.required, rules.counter]"
              class="info-card__cell"
              color="#00599B"
              outlined
              dense
          />
        </div>
        <div class="info-card__field">
          <div class="info-card__name">
            Наименование организации без организационно-правовой формы
          </div>
          <v-text-field
              v-model="form.orgName"
              :rules="[rules.required, rules.counter]"
              class="info-card__cell"
              color="#00599B"
              outlined
              dense
          />
        </div>
        <div class="info-card__field">
          <div class="info-card__name">
            Сокращенное наименование организации
          </div>
          <v-text-field
              v-model="form.orgShortName"
              :rules="[rules.required, rules.counter3]"
              class="info-card__cell"
              color="#00599B"
              outlined
              dense
          />
        </div>
<!--        <div class="info-card__field">-->
<!--          <div class="info-card__name">-->
<!--            Учредитель-->
<!--          </div>-->
<!--          <v-text-field-->
<!--              v-model="form.founderName"-->
<!--              :rules="[rules.required, rules.counter3]"-->
<!--              class="info-card__cell"-->
<!--              color="#00599B"-->
<!--              outlined-->
<!--              dense-->
<!--          />-->
<!--        </div>-->
        <div class="info-card__field">
          <div class="info-card__name">
            Адрес
          </div>
          <v-text-field
              v-model="form.orgAddress"
              :rules="[rules.required, rules.counter]"
              class="info-card__cell"
              color="#00599B"
              outlined
              dense
          />
        </div>
        <div class="info-card__field">
          <div class="info-card__name">
            КПП
          </div>
          <v-text-field
              v-model="form.kpp"
              :rules="[rules.required, rules.kpp]"
              class="info-card__cell"
              color="#00599B"
              outlined
              dense
          />
        </div>
        <scan-card
            :item="file"
            class="mb-2"
            :upload-to-serv-now="loadingFileToServer.fileType === file.fileType && loadingFileToServer.value"
            @replace-file="replaceFile($event)"
            @upload-file="uploadFile($event)"
            :token="token"
            identifier="fileType"
        />
        <v-btn @click.prevent="onSubmit" :disabled="!valid"
               :loading="loading"
               :class="['blue__v-btn', {'button_disabled': !valid}]">Отправить заявку на добавление
        </v-btn>
      </v-form>
    </wrapper-collapse>
  </WrapperForm>
</template>


<script>
import {required, numeric, minLength, maxLength} from 'vuelidate/lib/validators'
import WrapperForm from '@/components/wrappers/WrapperForm';
import ScanCard from "@/components/cards/scanCard";
import {mapActions, mapGetters} from "vuex";
import request from "@/services/request";
import WrapperCollapse from "@/components/wrappers/WrapperCollapse";

export default {
  components: {
    WrapperCollapse,
    ScanCard,
    WrapperForm
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form: {
        orgFullName: this.$attrs.value.orgFullName,
        orgName: this.$attrs.value.orgName,
        orgShortName: this.$attrs.value.orgShortName,
        orgAddress: this.$attrs.value.address,
        kpp: this.$attrs.value.kpp,
        fileId: this.$attrs.value?.fileId ?? null,
      },
      valid: true,
      rules: {
        required: value => !!value || 'Обязательное поле!',
        counter: value =>  value &&  value.length > 10 || 'Поле не может содержать меньше 10 символов',
        counter3: value => value &&  value.length >= 3 || 'Поле не может содержать меньше 3 символов',
        inn: value => value &&  value.length === 10 || 'Поле должно содержать 10 символов',
        kpp: value => value &&  value.length === 9 || 'Поле должно содержать 9 символов',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неверно указан адрес почты'
        },
      },
      loadingFileToServer: {
        value: false,
        fileType: ''
      },
      file: {
        name: 'Подтверждающие документы',
        types: ['application/pdf'],
      },
      validator: {
        orgName: true,
        address: true,
        kpp: true,
        file: true,
      },
    }
  },
  computed: {
    orgType() {
      return this.$attrs.value.sprOrgType;
    },
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
    token() {
      return this.getAuthData.token
    },
  },
  validations: {
    form: {
      orgName: {
        required,
      },
      address: {
        required,

      },
      kpp: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
    },
  },
  methods: {
    getFile() {
      this.file = {...this.file, id: this.$attrs.value.fileId, fileType: 'ORG_APPEAL_CREATE' };
    },
    ...mapActions('notifications', {addNotification: "addNotification"}),
    async uploadFile(event) {
      this.loadingFileToServer.value = true;
      this.loadingFileToServer.fileType = event.fileType;
      let fd = new FormData();
      fd.append('file', event.file);
      try {
        const res = await request({
          endpoint: `/api/file/scan/upload?scanType=${event.fileType}`,
          method: 'put',
          body: fd,
        });
        this.file = {...this.file, ...res.data};
        this.loadingFileToServer.value = false;
      } catch (e) {
        this.loadingFileToServer.value = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка загрузки',
          timeout: 3000
        })
      }
      // this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});
    },
    async deleteFile(event) {
      try {
        const response = await request({
          endpoint: `/api/file/scan/delete?fileId=${event.id}`,
          method: 'DELETE',
          token: this.token,
        });
        if (response.status === 200) {
          this.file = {
            name: this.file.name,
            fileType: event.fileType,
            types: this.file.types
          }
        }
      } catch (e) {
        // this.uploadNow = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка удаления',
          timeout: 3000
        });
        console.log("e delete", e)
      }
    },
    async replaceFile(event) {
      await this.deleteFile(event)
      await this.uploadFile(event)
    },
    async validate() {
      this.$refs['org-input'].validate()
    },
    async onSubmit() {
      await this.validate();
      if (this.valid && this.file.id){
        this.$emit('input', {...this.form, file: this.file});
      }else if (!this.file.id){
        await this.addNotification( {
          notificationStatus: 'error',
          notificationMessage: 'Приложите файл ',
          timeout: 3000
        });
      }

    },
  }

}
</script>
<style lang="sass">
.form_inline
  flex-wrap: nowrap
  align-items: center
  margin-top: 20px

.form_inline .field, .form_inline .button
  flex-basis: 0
  flex-grow: 1
</style>