<template>
  <div>
    <div class="d-flex justify-end mb-2">
      <v-btn class="success__v-btn" @click="loadFile" :loading="loadingFile">Выгрузить в эксель</v-btn>
    </div>
    <v-row align="center">
      <v-col class="d-flex align-center">
        <v-text-field
            v-model.lazy="searchOrg"
            dense
            clearable
            required
        />
      </v-col>
      <v-col cols="3" class="d-flex align-center">
        <v-autocomplete label="Тип фильтра" v-model="selectedSearchField" :items="searchFields"></v-autocomplete>
      </v-col>

    </v-row>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <!--    <v-btn @click="rwrw = !rwrw">rwrw</v-btn>-->
      <GridTable
          :data-rows="showingServerData"
          :rewrite-headers="rwrw"
          :headers="headers.massive"
          :headers-for-rows="headersForRows"
          :loading-data="loadingData"
          :num-cols="headers.countCells"
          :max-table-height="maxTableHeight"
          ref="npg-table"
          key="form-5-06"
      >
        <!--  v-if="!changingHeaders"  :key='' когда меняется хедер проверить    -->
        <!--        <template #header-npg0="{item}">-->
        <!--          {{item.text}}-->
        <!--        </template>-->
        <template v-for="item in headersForRows" v-slot:[calcHeaderName(item.dataField)]>
          <div :key="item.dataField" :class="item.headerClass" style="max-height: 22px;min-width: 100%">
            <template v-if="item.text === '1'">
              <div style="min-width: 40px">1</div>
              <div class="text-center" style="width: 100%">2</div>
            </template>
            <template v-else-if="item.text === '2'">
              <div style="min-width: 60px">3</div>
              <div class="text-center" style="width: 100%">4</div>
            </template>
            <template v-else>
              <div class="d-flex flex-row align-center" style="max-height: 22px;">
                {{ item.text }}
              </div>
            </template>
          </div>
        </template>
        <template #org="{row}">
          <template v-if="row.uslName">
            <div class="font-weight-bold font-size-medium ">
              {{ row.uslName[0].toUpperCase() + row.uslName.slice(1) }}
            </div>
          </template>
          <template v-else>
            <div style="min-width: 40px">
              {{ row.sprOrgId }}
            </div>
            <div class="ml-3">
              {{ row.orgName }}
            </div>
          </template>
        </template>
        <template #npg="{row}">
          <template v-if="!row.uslName">
            <div style="">
              {{ row.sprSpec ? row.sprSpec.kodSpec : row.sprKcpGroup.code }}
            </div>
            <div class="ml-3">
              {{ row.sprSpec ? row.sprSpec.name : row.sprKcpGroup.name }}
            </div>
          </template>
        </template>
        <template #hasAccred="{row}">
          {{ row.hasAccred ? 'да' : 'нет' }}
        </template>
        <template #hasLicense="{row}">
          {{ row.hasLicense ? 'да' : 'нет' }}
        </template>
        <template #kcpList="{row}">
          <template v-if="parseKcpList(row.kcpList, 1)">
            <div v-for="item in parseKcpList(row.kcpList, 1)" :key="item.year" class="d-flex flex-nowrap text-no-wrap">
              {{ item.year }} год - {{ item.value }}
            </div>
          </template>
          <div v-else></div>
        </template>
        <template #kcpList1="{row}">
          <template v-if="parseKcpList(row.kcpList, 2)">
            <div v-for="item in parseKcpList(row.kcpList, 2)" :key="item.year" class="d-flex flex-nowrap text-no-wrap">
              {{ item.year }} год - {{ item.value }}
            </div>
          </template>
          <div v-else></div>
        </template>
        <template #kcpList2="{row}">
          <template v-if="parseKcpList(row.kcpList, 3)">
            <div v-for="item in parseKcpList(row.kcpList, 3)" :key="item.year" class="d-flex flex-nowrap text-no-wrap">
              {{ item.year }} год - {{ item.value }}
            </div>
          </template>
          <div v-else></div>
        </template>

        <template #hasGa2018="{row}">
          <div>
            2018 год - {{ parseGaList(row.hasGa2018) }}
          </div>
          <div>
            2019 год -{{ parseGaList(row.hasGa2019) }}
          </div>
          <div>
            2020 год -{{ parseGaList(row.hasGa2020) }}
          </div>
          <div>
            2021 год - {{ parseGaList(row.hasGa2021) }}
          </div>
          <!--          <div>-->
          <!--            год: 2018; значение: {{row.hasGa2018}}-->
          <!--          </div>-->
        </template>
        <template #flApproved="{row}">
          <div class="d-flex flex-column">
            <v-btn :disabled="isBlocked" :loading="loadingMenu.id ===row.id && loadingMenu.value === true" class="white__v-btn"
                   :class="{'blue__v-btn': row.flApproved === true}" @click="changeStatus(row, true)">Одобрить
            </v-btn>
            <v-btn :disabled="isBlocked" :loading="loadingMenu.id ===row.id && loadingMenu.value === true" class="white__v-btn mt-2"
                   :class="{'blue__v-btn': row.flApproved === false}" @click="changeStatus(row, false)">Отклонить
            </v-btn>
          </div>
        </template>
      </GridTable>
      <div class="mt-3 d-flex flex-row justify-center align-center">
        <v-autocomplete
            class="pagination-table"
            v-model="userPages"
            :items="pagesPaginate"
            solo
            dense
            filled
            height="25"
            rounded
            style="max-width: 150px"
            @change="changePage($event)"
        ></v-autocomplete>
        <v-pagination
            v-model="page"
            :length="pagesVuetify"
            :total-visible="7"
            @input="changePage($event)"
        >
        </v-pagination>

      </div>
    </WrapperPreviewCategory>
    <tooltip-forms5-dialog
        ref="tooltip-dialog"
        :data-field="dataFieldTooltip"
    >
    </tooltip-forms5-dialog>
  </div>
</template>

<script>
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
// import {setAppealDialog} from "@/mixins/forms5/appealDialog";
import {tablePagination} from "@/mixins/tablePagination";
// import FivesTableCell from "@/components/fields/fivesTableCell";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import GridTable from "@/components/GridTable/GridTable";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";
// import appealDialog from "@/components/Dialogs/appealDialog";
import form2 from "@/helpers/founder/form2";
import {mapGetters} from "vuex";
import {getFormData} from "@/API/form5";
import {changeStatusLicense} from "@/API/founder";
import axios from "axios";

export default {
  name: "accredAgree",
  mixins: [headerTooltipFunctions, tablePagination],
  components: { WrapperPreviewCategory, GridTable, tooltipForms5Dialog},
  data() {
    return {
      serverData: [],
      loadingData: false,
      changingHeaders: false,
      loadingFile: false,
      searchOrg: '',
      searchFields: [
        {text: 'ID',value: 'sprOrgId'},
        {text: 'Наименование',value: 'orgName'},
      ],
      selectedSearchField: 'orgName',
      headers: form2,
      loadingMenu: {value: false, id: ''},
      full: false,
      rwrw: false
    }
  },
  created() {
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    }
  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_usl_error: 'get_spr_data_usl_error',
    }),
    isBlocked() {
      return true;
    },
    // selectedField() {
    //   if (this.selectedSearchField === 'ID') {
    //     return 'id'
    //   } else if (this.selectedSearchField === 'Наименование') {
    //     return 'orgName'
    //   } else {
    //     return 'orgName'
    //   }
    // },
    filteredData(){
      if (!this.searchOrg) return this.serverData;
      return this.serverData.filter((item) => {
        if (String(item[this.selectedSearchField]).toLowerCase().indexOf(String(this.searchOrg).toLowerCase()) !== -1) {
          return item
        }
      })
    },
    showingServerData(){
      return this.filteredData.slice((this.page-1) * this.userPages, (this.page) * this.userPages)
    },
    pagesVuetify(){
      return Math.ceil(this.filteredData.length/this.userPages);
    },
    lengthData(){
      return this.filteredData.length
    },
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
    token() {
      return this.getAuthData.token
    },
    maxTableHeight() {
      return window.innerHeight - 225 + 'px'
    },
    headersForRows() {
      return this.headers.massive.filter(el => el.variable).map(el => {
        return {
          ...el,
          styles: (row) => {
            const color = row.flApproved ? 'background-color: #EBF9EB; color: #29A128;' : row.flApproved === false ? 'color: #ED393E; background-color: #FDEBEB;' : '';
            if (el.dataField !== 'org')
              return row.uslName ? `${color} display: none!important;` : `${color} justify-content: unset; text-align: center; `
            else
              return `${color}`
          },
          colsDataCalc: (row) => {
            if (el.dataField !== 'org')
              return 1;
            else
              return row.uslName ? 12 : 1;
          },
          props: (row, cell, loading) => {
            return {
              value: row[cell.dataField],
              title: '',
              cell: cell,
              id: row.id,
              row: row,
              loading: loading,
            }
          }
        }
      })
    },
  },
  methods: {
    loadFullInfo() {
      this.full = !this.full;
      this.loadingDataTable()
    },
    parseKcpList(arr, foId) {
      if (!arr || arr.length === 0)
        return null;
      const res = arr.filter(el => el.foId === foId);
      if (!res || res.length === 0)
        return null;
      return res
    },
    parseGaList(value) {
      return value ? 'есть' : value === null ? 'н.д.' : 'нет';
    },
    async changeStatus(row, flag) {
      this.loadingMenu = {value: true, id: row.id}
      try {
        const res = await changeStatusLicense({id: row.id, flag: flag});
        if (res) {
          const changedObj = this.serverData.findIndex(el => el.id === row.id);
          this.serverData.splice(changedObj, 1, res);
        }
        this.loadingMenu = {value: false, id: row.id}
      } catch (e) {
        this.loadingMenu = {value: false, id: row.id}
      }
    },
    async loadFile() {
      try{
        this.loadingFile = true;
        const response = await axios.get(`/api/foiv/form2/excel`, {
          responseType: 'blob',
          headers: {Authorization: this.token ? `Bearer ${this.token}` : ''},
        })
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'report.xlsx';
        link.click();
        link.remove();
        this.loadingFile = false;
      }catch (e) {
        this.loadingFile = false;
      }
    },

    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getFormData('', true, '/api/foiv/form2');
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },
  }

}
</script>

<style scoped>

</style>