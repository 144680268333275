<template>
  <v-row
      dense
      :class="cardClass"
      class="b-indicator"
      @click="btnClick(show)"
  >
    <template v-if="!loading">
      <v-col cols="5" class="b-indicator__header mr-11 py-3" >
        <div class="b-indicator__title">{{ item.title }}</div>
        <div class="b-indicator__about">{{ item.about }}</div>
      </v-col>
      <!--  :style="show ? 'border-bottom: 1px solid #78909C' : ''"  -->
      <v-col cols="5" class="d-flex justify-space-between b-indicator__header align-center py-3" >
        <div
            class="d-flex align-center kcp-percent-scale mr-3"
            :class="{'kcp-percent-scale__disabled': item.count == null || isNaN(+item.count)}"
            :style="calcBackground(item.count, item.maxGradient, item.minGradient)"
            style="width: 100%;"
        >
        <span>
          {{ value }}
        </span>
        </div>
        <div
            class="d-flex flex-column b-indicator__max align-center justify-center"
            :class="isNaN(item.count) ? 'b-indicator__max_disable' : ''">
          <span>{{ item.maxGradient }}</span>
          <span>max</span>
        </div>
      </v-col>
    </template>
    <div v-else class="d-flex flex-row justify-center" style="min-height: 45px;width: 100%">
      <v-progress-circular  indeterminate size="30" color="primary"></v-progress-circular>
    </div>
  </v-row>
</template>

<script>
import {calcBackground} from "@/utils/indicators";

export default {
  name: "cardIndicatorHead",
  props: {
    showProp: {
      type: Boolean
    },
    item: {
      type: Object,
      default: () => {
        return {title: '', about: '', count: '', max: '', min: ''}
      }
    },
    available: {
      type: Boolean,
      default: false
    },
    loading:{
      type: Boolean,
      default: false
    },
    typeStyle: {
      type: String,
      default: ''
    }
  },
  computed: {
    show: {
      get() {
        return this.$attrs.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    value(){
      return !isNaN(this.item.count) ? this.item.count : 'Показатель не рассчитывается для этой УГСН(НПС)'
    },
    cardClass() {
      let cssClass = 'b-indicator'
      if (this.typeStyle !== 'general' && !this.available)
        return "b-indicator b-indicator-unavailable"
      else {
        switch (this.typeStyle) {
          case 'general':
            cssClass = "b-indicator b-indicator-general"
            break;
          case 'unavailable':
            cssClass = "b-indicator b-indicator-unavailable"
            break;
          default:
            cssClass = "b-indicator"
            break;
        }
        return cssClass
      }
    }
  },
  methods: {
    btnClick(value) {
      if (this.available)
        this.show = !value
    },
    calcBackground(value, maxValue, minValue) {
      return calcBackground(value, maxValue, minValue, this.typeStyle === 'general' ? '#00599B' : '#95BFDE')
    }
  },
}
</script>

<style>

</style>