<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
        <p>
          <span class="font-weight-bold mb-1">Форма 5.03. (фрагмент)</span><br>
          Общая численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры
          <br>
          по данным формы ВПО-1, человек
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Уровни и формы образования</th>
        <th rowspan="3" style="vertical-align: bottom">...</th>
        <th colspan="2">Обучающихся <br>(искл. квоту иностр.граждан)</th>
        <th  ></th>
        <th colspan="1">Иностр.граждан,<br> обучающихся по квоте</th>
        <th  ></th>
        <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="1" class="border-right-zero" colspan="2"></th>
      </tr>
      <tr>
        <th colspan="1">
          всего
        </th>
        <th colspan="1">
          граждан РФ
        </th>
        <th></th>
        <th></th>
        <th rowspan="2" style="vertical-align: bottom">...</th>
        <th colspan="1">
          Всего<br> обучающихся
        </th>
        <th class="border-right-zero" colspan="1">
          Всего иностр.<br>граждан
        </th>
      </tr>
      <tr>
        <th>
          на 1.10.2021 г.
        </th>
        <th>
          на 1.10.2021 г.
        </th>
        <th>
          …
        </th>
        <th>
          на 1.10.2021 г.
        </th>
        <th rowspan="2" style="border-bottom: 0">
          <br/>
          <span class="font-weight-bold">гр.6 + гр.11</span>
        </th>
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">

          <br/>
          <span class="font-weight-bold">гр.6 - гр.7 + гр.11</span>
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="1" style="text-align: left"><span>1</span></th>
        <th colspan="2" style="text-align: left"><span>2</span></th>
        <th>...</th>
        <th v-for="(item) in 2" :key="item">{{ item + 5 }}</th>
        <th>...</th>
        <th>11</th>
        <th>...</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td rowspan="3" style="text-align: left; vertical-align: top" class="border-right-zero pt-1" >
          <span class="usl-name">Бакалавриат</span>
        </td>
        <td colspan="2" class="text-left kcp-name">очная форма</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbco21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nrbco21, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbco21, 0, 'integer') }}</td>
        <td>...</td>
        <td rowspan="9" class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobco21, 0, 'integer') }}</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nibco21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">очно-заочная форма</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbcv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nrbcv21, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbcv21, 0, 'integer') }}</td>
        <td>...</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobcv21, 0, 'integer') }}</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nibcv21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">заочная форма</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsbcz21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nrbcz21, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqbcz21, 0, 'integer') }}</td>
        <td>...</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobcz21, 0, 'integer') }}</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nibcz21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td rowspan="3" style="text-align: left; vertical-align: top" class="border-right-zero pt-1" >
          <span class="usl-name">Специалитет</span>
        </td>
        <td colspan="2" class="text-left kcp-name">очная форма</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nrspo21, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspo21, 0, 'integer') }}</td>
        <td>...</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospo21, 0, 'integer') }}</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nispo21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">очно-заочная форма</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nrspv21, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspv21, 0, 'integer') }}</td>
        <td>...</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospv21, 0, 'integer') }}</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nispv21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">заочная форма</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsspz21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nrspz21, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqspz21, 0, 'integer') }}</td>
        <td>...</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nospz21, 0, 'integer') }}</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nispz21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td rowspan="3" style="text-align: left; vertical-align: top" class="border-right-zero pt-1" >
          <span class="usl-name">Магистратура</span>
        </td>
        <td colspan="2" class="text-left kcp-name">очная форма</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nrmgo21, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgo21, 0, 'integer') }}</td>
        <td>...</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgo21, 0, 'integer') }}</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nimgo21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">очно-заочная форма</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nrmgv21, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgv21, 0, 'integer') }}</td>
        <td>...</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgv21, 0, 'integer') }}</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nimgv21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-left kcp-name">заочная форма</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsmgz21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nrmgz21, 0, 'integer') }}</td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqmgz21, 0, 'integer') }}</td>
        <td>...</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nomgz21, 0, 'integer') }}</td>
        <td class=" border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nimgz21, 0, 'integer') }}</td>
      </tr>

      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 10" :key="item"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>

      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero text-right">
          сумма по очной форме:
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          No =       {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmo21, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          Io =  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nibmo21, 0, 'integer') }}
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero text-right">
          сумма по очно-заочной форме:
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          Nv =       {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmv21, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          Iv =  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nibmv21, 0, 'integer') }}
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero text-right">
          сумма по заочной форме:
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          Nz =       {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmz21, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          Iz =  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nibmz21, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" >
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <span class="alternate-text mx-1"> 1 ×</span> Io <span class="ml-1 alternate-text">+</span>
          <span class="alternate-text mx-1"> 0,25 ×</span> Iv <span class="ml-1 alternate-text">+</span>
          <span class="alternate-text mx-1"> 0,1 ×</span> Iz
        </template>
        <template #bottom>
          <div class="formul-text" style="min-height: 49px">
            <span class="alternate-text mx-1"> 1 ×</span> No <span class="ml-1 alternate-text">+</span>
            <span class="alternate-text mx-1"> 0,25 ×</span> Nv <span class="ml-1 alternate-text">+</span>
            <span class="alternate-text mx-1"> 0,1 ×</span> Nz
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> × 100 </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <span class="alternate-text mx-1"> 1 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nibmo21, 0, 'integer') }} <span class="ml-1 alternate-text">+</span>
          <span class="alternate-text mx-1"> 0,25 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nibmv21, 0, 'integer') }} <span class="ml-1 alternate-text">+</span>
          <span class="alternate-text mx-1"> 0,1 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nibmz21, 0, 'integer') }}
        </template>
        <template #bottom>
          <div class="formul-text" style="min-height: 49px">
            <span class="alternate-text mx-1"> 1 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmo21, 0, 'integer') }} <span class="ml-1 alternate-text">+</span>
            <span class="alternate-text mx-1"> 0,25 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmv21, 0, 'integer') }} <span class="ml-1 alternate-text">+</span>
            <span class="alternate-text mx-1"> 0,1 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nobmz21, 0, 'integer') }}
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> × 100 </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>Io, Iv, Iz	-	студентов-иностранных граждан по данным отчетного года (очной, очно-заочной и заочной формы обучения соответственно)</p>
    <p class="mb-4">No, Nv, Nz	-	всего студентов по данным отчетного года (очной, очно-заочной и заочной формы обучения соответственно)</p>

  </div>
</template>

<script>
export default {
  name: "b43",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {

  },
}
</script>

<style lang="sass" scoped>

</style>