<template>
<div>
  <v-row dense class="" v-if="rowOfValues.data.orgtp22 === '1'">
    <v-col cols="10">
       <p class="">
        <span class="font-weight-bold mb-1 ">Форма 5.01а. (фрагмент)</span><br>
         Численность научно-педагогических работников и доходы образовательной организации
         <br>
         по данным формы ВПО-2
       </p>
    </v-col>
  </v-row>
  <table v-if="rowOfValues.data.orgtp22 === '1'">
    <thead>
    <tr>
      <th rowspan="1" colspan="3" class="text-left kcp-td">Показатели</th>
      <th colspan="1">за 2018 г.</th>
      <th colspan="1">за 2019 г.</th>
      <th colspan="1">за 2020 г.</th>
    </tr>
    <tr style="border-top: 1px solid #B7CED9">
      <th class="border-right-zero" colspan="2" style="text-align: left">
        <span>1</span>
      </th>
      <th >2</th>
      <th >3</th>
      <th >4</th>
      <th >5</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td rowspan="2" class="border-right-zero kcp-name pr-4" style="text-align: left">Профессорско-<br>преподавательский состав</td>
      <td style="text-align: left" class="border-right-zero kcp-name font-size-normal__important">
        среднесписочная численность (без внешних совместителей)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpppo19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpppo20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpppo21,1, 'float') }}</td>
    </tr>
    <tr>
      <td style="text-align: left" class="border-right-zero kcp-name font-size-normal__important">
       внешние совместители (пропорционально отработанному времени)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wppps19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wppps20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wppps21,1, 'float') }}</td>
    </tr>
    <tr>
      <td rowspan="2" class="border-right-zero kcp-name" style="text-align: left">Научые работники</td>
      <td style="text-align: left" class="border-right-zero font-size-normal__important kcp-name">
        среднесписочная численность (без внешних совместителей)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnro19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnro20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnro21,1, 'float') }}</td>
    </tr>
    <tr>
      <td style="text-align: left" class="border-right-zero kcp-name font-size-normal__important">
       внешние совместители (пропорционально отработанному времени)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnrs19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnrs20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnrs21,1, 'float') }}</td>
    </tr>

    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr19,1, 'float') }}
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr20,1, 'float') }}
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr21,1, 'float') }}
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">НПР<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2018</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">НПР<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">НПР<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
      </td>

    </tr>
    </tbody>
  </table>
  <v-row dense class="" v-if="rowOfValues.data.orgtp22 === '3'">
    <v-col cols="10">
      <p class="">
        <span class="font-weight-bold mb-1 ">Форма 5.01б. (фрагмент)</span><br>
        Численность научно-педагогических работников и доходы организации дополнительного профессионального образованния
        <br>
        по данным формы 1-ПК
      </p>
    </v-col>
  </v-row>
  <table v-if="rowOfValues.data.orgtp22 === '3'">
    <thead>
    <tr>
      <th rowspan="1" colspan="3" class="text-left">Показатели</th>
      <th colspan="1">за 2018 г.</th>
      <th colspan="1">за 2019 г.</th>
      <th colspan="1">за 2020 г.</th>
    </tr>
    <tr style="border-top: 1px solid #B7CED9">
      <th class="border-right-zero" colspan="2" style="text-align: left">
        <span>1</span>
      </th>
      <th >2</th>
      <th >3</th>
      <th >4</th>
      <th >5</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td rowspan="2" class="border-right-zero kcp-name pr-4" style="text-align: left">Профессорско-<br>преподавательский состав</td>
      <td style="text-align: left" class="border-right-zero kcp-name font-size-normal__important">
        среднесписочная численность (без внешних совместителей)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpppo19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpppo20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpppo21,1, 'float') }}</td>
    </tr>
    <tr>
      <td style="text-align: left" class="border-right-zero kcp-name font-size-normal__important">
        внешние совместители (пропорционально отработанному времени)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wppps19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wppps20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wppps21,1, 'float') }}</td>
    </tr>
    <tr>
      <td rowspan="2" class="border-right-zero kcp-name" style="text-align: left">Научные работники</td>
      <td style="text-align: left" class="border-right-zero kcp-name font-size-normal__important">
        среднесписочная численность (без внешних совместителей)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnro19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnro20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnro21,1, 'float') }}</td>
    </tr>
    <tr>
      <td style="text-align: left" class="border-right-zero kcp-name font-size-normal__important">
        внешние совместители (пропорционально отработанному времени)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnrs19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnrs20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnrs21,1, 'float') }}</td>
    </tr>

    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr19,1, 'float') }}
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr20,1, 'float') }}
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr21,1, 'float') }}
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">НПР<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2018</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">НПР<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">НПР<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
      </td>

    </tr>
    </tbody>
  </table>
  <v-row dense class="" v-if="rowOfValues.data.orgtp22 === '2'">
    <v-col cols="10">
      <p class="">
        <span class="font-weight-bold mb-1 ">Форма 5.01в. (фрагмент)</span><br>
        Численность научно-педагогических работников и доходы научной организации
        <br>
        по данным формы 2-наука
      </p>
    </v-col>
  </v-row>
  <table v-if="rowOfValues.data.orgtp22 === '2'">
    <thead>
    <tr>
      <th rowspan="1" colspan="3" class="text-left">Показатели</th>
      <th colspan="1">за 2018 г.</th>
      <th colspan="1">за 2019 г.</th>
      <th colspan="1">за 2020 г.</th>
    </tr>
    <tr style="border-top: 1px solid #B7CED9">
      <th class="border-right-zero" colspan="2" style="text-align: left">
        <span>1</span>
      </th>
      <th >2</th>
      <th >3</th>
      <th >4</th>
      <th >5</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td rowspan="2" class="border-right-zero kcp-name pr-4" style="text-align: left">Профессорско-<br>преподавательский состав</td>
      <td style="text-align: left" class="border-right-zero kcp-name font-size-normal__important">
        среднесписочная численность (без внешних совместителей)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpppo19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpppo20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpppo21,1, 'float') }}</td>
    </tr>
    <tr>
      <td style="text-align: left" class="border-right-zero kcp-name font-size-normal__important">
        внешние совместители (пропорционально отработанному времени)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wppps19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wppps20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wppps21,1, 'float') }}</td>
    </tr>
    <tr>
      <td colspan="2" style="text-align: left" class="border-right-zero kcp-name font-size-normal__important">
        Работники, выполнявшие НИР - исследователи (на конец года без внешних совместителей)
      </td>
      <td class="kcp-name font-size-normal__important">человек</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wprdo19,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wprdo20,1, 'float') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wprdo21,1, 'float') }}</td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr19,1, 'float') }}
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr20,1, 'float') }}
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr21,1, 'float') }}
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">НПР<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2018</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">НПР<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">НПР<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
      </td>

    </tr>
    </tbody>
  </table>
</div>
</template>

<script>
export default {
  name: "blockNpr",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
}
</script>

<style scoped>

</style>