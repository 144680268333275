<template>
<div>
  <v-row dense >
    <v-col cols="10">
      <p>
        <span class="font-weight-bold mb-1">Форма 5.20. (фрагмент)</span><br>
        Дополнительные сведения о количестве защищенных диссертаций на соискание ученой степени кандидата наук и выпуске закончивших обучение по программам подготовки научных и научно-педагогических кадров в аспирантуре
      </p>
    </v-col>
  </v-row>
  <table v-if="rowOfValues.data[item.dataFieldName === 'a21'? 'ison122': 'ison222'] === '0'">
    <thead>
    <tr>
      <th rowspan="4" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
      <th colspan="2">Защиты диссертаций<br>
        незав. от места защиты
      </th>
      <th></th>
      <th rowspan="5" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
      <th rowspan="2" class=" border-right-zero" colspan="2"><span class="formul-text-table">dgs</span></th>
    </tr>
    <tr>
      <th colspan="1">
      </th>
      <th colspan="1">
      </th>
      <th></th>
    </tr>
    <tr>

      <th >
        в 2019 г.
      </th>
      <th >
        в 2020 г.
      </th>
      <th >

      </th>
      <th rowspan="3" colspan="2" class="border-right-zero">
        вес группы<br> научных<br> специальностей<br> в УГСН
      </th>
    </tr>
    <tr>
      <th>
        все формы
      </th>
      <th>
        все формы
      </th>
      <th>
        ...
      </th>
    </tr>
    <tr style="border-top: 1px solid #B7CED9">
      <th colspan="3" style="text-align: left">
        <span>1</span><span style="margin-left: 66px">2</span>
      </th>

      <th v-for="(item) in 2" :key="item">{{ item + 2}}</th>
      <th>...</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(row, index) in rowOfValues.data.tabA21ugorg" :key="row.id">
      <td colspan="3" style="text-align: left">
        <span class="kcp-code">{{ row.idugn }}</span>
        <span class="kcp-name">{{ row.ugtxt }}</span>
      </td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qdugs20, 0, 'integer') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qdugs21, 0, 'integer') }}</td>
      <td>...</td>
      <td v-if="index === 0" :rowspan="rowOfValues.data.tabA21ugorg.length" class="border-bottom-zero border-right-zero"><v-icon size="30">mdi-close</v-icon></td>
      <td class="border-right-zero" colspan="2">{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt,2) }}</td>
    </tr>
    <tr>
      <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
      <td colspan="5"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
        перевод значений в группу научных специальностей
      </td>
    </tr>
    <tr>
      <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей</td>
      <td colspan="5" class="border-bottom-zero border-right-zero ">
        <img class="ww-100" :src="require('@/assets/images/brackets/bracket-5.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td colspan="3" style="text-align: left">
        <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
        <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
      </td>
      <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qdugs20, 2) }}</td>
      <td class="border-top-1">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qdugs21, 2) }}</td>
      <td class="border-top-1">...</td>
    </tr>
    <tr>
      <td colspan="3" class=" border-right-zero border-bottom-zero text-left"></td>
      <td colspan="2" class="border-bottom-zero border-right-zero ">
        <img class="ww-100" :src="require('@/assets/images/brackets/bracket-2.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td colspan="2" class="border-bottom-zero border-right-zero formul-text-table">
        ЗКН = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qdugsS2,2) }}
      </td>
    </tr>
    </tbody>
  </table>
  <table v-if="rowOfValues.data[item.dataFieldName === 'a21'? 'ison122': 'ison222'] === '1'">
    <thead>
    <tr>
      <th rowspan="4" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
      <th colspan="2">Защиты диссертаций<br>
        незав. от места защиты
      </th>
      <th></th>
      <th rowspan="5" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
      <th rowspan="2" class=" border-right-zero" colspan="2"><span class="formul-text-table">dgs</span></th>
    </tr>
    <tr>
      <th colspan="1">
      </th>
      <th colspan="1">
      </th>
      <th></th>
    </tr>
    <tr>

      <th >
        в 2019 г.
      </th>
      <th >
        в 2020 г.
      </th>
      <th >

      </th>
      <th rowspan="3" colspan="2" class="border-right-zero">
        вес группы<br> научных<br> специальностей<br> в УГСН
      </th>
    </tr>
    <tr>
      <th>
        все формы
      </th>
      <th>
        все формы
      </th>
      <th>
        ...
      </th>
    </tr>
    <tr style="border-top: 1px solid #B7CED9">
      <th colspan="3" style="text-align: left">
        <span>1</span><span style="margin-left: 66px">2</span>
      </th>

      <th v-for="(item) in 2" :key="item">{{ item + 2}}</th>
      <th>...</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(row, index) in rowOfValues.data.tabA21noorg" :key="row.id">
      <td colspan="3" style="text-align: left">
        <span class="kcp-code">{{ row.idugn }}</span>
        <span class="kcp-name">{{ row.ugtxt }}</span>
      </td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qdugs20, 0, 'integer') }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qdugs21, 0, 'integer') }}</td>
      <td>...</td>
      <td v-if="index === 0" :rowspan="rowOfValues.data.tabA21noorg.length" class="border-bottom-zero border-right-zero"><v-icon size="30">mdi-close</v-icon></td>
      <td class="border-right-zero" colspan="2">{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt,2) }}</td>
    </tr>
    <tr>
      <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
      <td colspan="5"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
        перевод значений в группу научных специальностей
      </td>
    </tr>
    <tr>
      <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей</td>
      <td colspan="5" class="border-bottom-zero border-right-zero ">
        <img class="ww-100" :src="require('@/assets/images/brackets/bracket-5.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td colspan="3" style="text-align: left" class="border-bottom-zero font-weight-bold">
        <span class="kcp-name">{{ rowOfValues.data.uotxt }}</span>
      </td>
      <td class="border-bottom-zero border-top-1"></td>
      <td class="border-bottom-zero border-top-1"></td>
      <td class="border-bottom-zero border-top-1"></td>
    </tr>
    <tr v-for="(row) in rowOfValues.data.tabA21biggroup" :key="row.id">
      <td colspan="3" style="text-align: left">
        <span class="kcp-code">{{ row.kcpGroup.code }}</span>
        <span class="kcp-name">{{ row.kcpGroup.name }}</span>
      </td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qdugs20, 2) }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qdugs21, 2) }}</td>
      <td>...</td>
    </tr>
    <tr>
      <td colspan="3" class=" border-right-zero border-bottom-zero text-left"></td>
      <td colspan="2" class="border-bottom-zero border-right-zero ">
        <img class="ww-100" :src="require('@/assets/images/brackets/bracket-2.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td colspan="2" class="border-bottom-zero border-right-zero formul-text-table">
        ЗКН = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qduosS2, 2) }}
      </td>
    </tr>
    </tbody>
  </table>
</div>
</template>

<script>
export default {
  name: "blockZkn",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
}
</script>

<style scoped>

</style>