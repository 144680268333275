<template>
<div class="full-indicators-info">
<table class="full-indicators-info__table">
  <thead>
  <tr>
    <th>Образовательная организация. УГСН (НПС)</th>
    <th>Очная форма</th>
    <th>Очно-заочная форма</th>
    <th>Заочная форма</th>
  </tr>
  </thead>
  <tbody>
  <template v-if="!loading">
    <tr v-for="item in serverData" :key="calcIdRow(item)" >
      <td v-if="item.orgFlag" class="full-indicators-info__table-org" colspan="4">{{item.orgName}}</td>
      <td v-else-if="item.uslName" class="full-indicators-info__table-usl" colspan="4">{{ item.uslName }}</td>
      <template v-else>
        <td>
          <span class="kcp-code">{{ item.kcpGroup.code }}</span>
          <span class="kcp-name">{{ item.kcpGroup.name }}</span>
        </td>
        <td @click="openScore(item.id, 1, 1, item.iokio22, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)" class="kcp-percent-scale" :class="{'kcp-percent-scale__disabled': item.iokio22 == null}" :style="calcBackground(item.iokio22,item.iowgt22)">
          {{ showingValue(item, 'iokio22')}}
        </td>
        <td @click="openScore(item.id, 1, 2, item.iokiv22, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)" class="kcp-percent-scale" :class="{'kcp-percent-scale__disabled': item.iokiv22 == null}" :style="calcBackground(item.iokiv22,item.iowgt22)">
          {{showingValue(item, 'iokiv22') }}
        </td>
        <td @click="openScore(item.id, 1, 3, item.iokiz22, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)" class="kcp-percent-scale" :class="{'kcp-percent-scale__disabled': item.iokiz22 == null}" :style="calcBackground(item.iokiz22,item.iowgt22)">
          {{showingValue(item, 'iokiz22') }}
        </td>
      </template>
    </tr>

  </template>
  <template v-else>
    <tr >
      <td class="full-indicators-info__table-org" rowspan="4" colspan="4">
        <v-progress-linear indeterminate></v-progress-linear>
      </td>
    </tr>
  </template>
  </tbody>
</table>
</div>
</template>

<script>
import {calcBackground} from "@/utils/indicators";
import {getAllGroupedKcpIndicators} from "@/API/indicators";

export default {
  name: "allList",
  data() {
    return {
      serverData: [],
      loading: false
    }
  },
  created() {
    this.getServerData();
  },
  methods: {
    async getServerData(){
      try{
        this.loading = true;
        this.serverData = await getAllGroupedKcpIndicators();
        this.loading = false;
      }catch (e) {
        this.loading = false;
      }
    },
    calcBackground(value, maxValue, minValue = 0){
     return calcBackground(value,maxValue,minValue)
    },
    openScore(rowId,s0b, appForm, score, usl, kcp, org) {
      const {id, code, name} = kcp;
      if (score != null)
        this.$router.push({path: '/indicators/details', query: {fo: appForm, id: rowId,kcpId : id, code: code, name: name, value: score, usl: usl, org: org}})
    },
    showingValue(item, dataField){
      return item[dataField] == null  ?  'нет заявки' : item[dataField]/*.toFixed(3)*/
    },
    calcIdRow(item){
      return item.kcpGroup?.id ? Math.random() + '-' +(item.kcpGroup.id + '-' +item.kcpGroup.usl.id+'-' + item.orgId ): (Math.random() + '-' + item.orgId)
    }
  },
}
</script>

<style scoped>

</style>