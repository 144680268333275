export default {
    countCells: 6,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 1,
            text: 'УГСН (код и наименование)',
            class: 'd-flex flex-row align-start justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
        },

        {
            dataField: 'npg13',
            cols: 1,
            rows: 1,
            headerClass: 'text-center',
            text: "Регистрационный номер",
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg15',
            cols: 1,
            rows: 1,
            headerClass: 'text-center',
            text: `Дата выдачи`,
            order: 4,
            variable: false,
        },
        {
            dataField: 'npg16',
            cols: 1,
            rows: 1,
            headerClass: 'text-center',
            text: `Дата окончания действия `,
            order: 4,
            variable: false,
        },
        {
            dataField: 'npg16',
            cols: 2,
            rows: 1,
            html: "Примечания  <br/>" +
                "<span class='font-size-small'>(Год первичного обязательства в получении государственной аккредитации)</span>",
            text: "Примечания" +
                "(Год первичного обязательства в получении государственной аккредитации, заполняется при необходимости)",
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row',
            text: `1`,
            order: 6,
            variable: true,
            template: (row) => {
                return row.sprKcpGroupId + ' - ' + row.sprKcpGroupName
            }
        },
        // {
        //     dataField: 'gadoc22K21',
        //     cols: 1,
        //     rows: 1,
        //     text: `3`,
        //     order: 7,
        //     rules: [
        //         v => !!v || 'введите значение',
        //         v => !!v && v.length === 9 || 'Количество символов номера и серии должна быть 9'
        //     ],
        //     variable: true,
        //     styles: 'justify-content: unset; text-align: center; text-align: center;',
        // },
        {
            dataField: 'gasnm22K21',
            cols: 1,
            rows: 1,
            text: `3`,
            order: 8,
            rules: [

            ],
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
            styles: 'justify-content: unset; text-align: center;',
        },
        // {
        //     dataField: 'gasnp22K21',
        //     cols: 1,
        //     rows: 1,
        //     text: `5`,
        //     order: 9,
        //     variable: true,
        //     typeValue: 'date',
        //     styles: 'justify-content: unset; text-align: center;',
        // },
        {
            dataField: 'gadtb22K21',
            cols: 1,
            rows: 1,
            text: `4`,
            order: 10,
            variable: true,
            rules: [

            ],
            headerClass: 'pa-0 font-size-small__important',
            typeValue: 'date',
            styles: 'justify-content: unset; text-align: center;',
        },
        {
            dataField: 'gadte22K21',
            cols: 1,
            rows: 1,
            text: `5`,
            order: 10,
            variable: true,
            rules: [

            ],
            headerClass: 'pa-0 font-size-small__important',
            typeValue: 'date',
            styles: 'justify-content: unset; text-align: center;',
        },
        {
            dataField: 'gatxt22K21',
            cols: 2,
            rows: 1,
            text: `6`,
            order: 10,
            rules: [

            ],
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
            styles: 'justify-content: unset; text-align: center;',
        },
    ]
}
