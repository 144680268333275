<template>
  <ContainerRequestsOrg class="view-request"/>
</template>

<script>
import ContainerRequestsOrg from '@/containers/admin/ContainerRequestsOrg';

export default {
  components: {
    ContainerRequestsOrg,
  },
}
</script>

<style lang="sass">
.view-request
  width: 100%
</style>