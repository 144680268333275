<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      </v-col>
    </v-row>
    <v-row dense >
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.18. (фрагмент)</span><br>
          Численность обучающихся по программам подготовки научных и научно-педагогических кадров в аспирантуре
          <br>
          по данным формы 1-НК, человек
        </p>
      </v-col>
    </v-row>
    <table >
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th></th>
        <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
        <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
        <th rowspan="4" class="border-bottom-zero border-right-zero" style="min-width: 55px;"></th>
        <th rowspan="2" class="border-right-zero" colspan="2"><span class="formul-text-table">dgs</span></th>
      </tr>
      <tr>
        <th></th>
        <th colspan="3">
          на 1.01.2021 г.
        </th>
        <th colspan="3">
          на 1.01.2021 г.
        </th>
      </tr>
      <tr>
        <th>
          ...
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th rowspan="2" colspan="2" class="border-right-zero">
          вес группы<br> научных<br> специальностей<br> в УГСН
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th>...</th>
        <th v-for="(item) in 6" :key="item + Math.random()">{{ item + 5 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in rowOfValues.data[rowOfValues.data.ison422 === '0' ? 'tabA21ugorg': 'tabA21noorg']" :key="row.id">
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ row.idugn }}</span>
          <span class="kcp-name">{{ row.ugtxt }}</span>
        </td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer') }}</td>
        <td v-if="index === 0" :rowspan="rowOfValues.data[rowOfValues.data.ison422 === '0' ? 'tabA21ugorg': 'tabA21noorg'].length"
            class="border-bottom-zero border-right-zero">
          <v-icon size="30">mdi-close</v-icon>
        </td>
        <td class="border-right-zero" colspan="2">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(row.stdgsSt,2) }}
        </td>
      </tr>
      <tr>
        <td colspan="3"  class="border-right-zero line-height-small__important border-bottom-zero text-left"></td>
        <td colspan="9"  class="font-size-small__important line-height-small__important border-bottom-zero border-right-zero">
          перевод значений в группу научных специальностей
        </td>
      </tr>
      <tr>
        <td colspan="3" class=" border-right-zero text-left">Группа научных специальностей</td>
        <td colspan="9" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-9.svg')" alt=""/>
        </td>
      </tr>
<!-- TabA21biggroup     v-for="(row) in rowOfValues.data[rowOfValues.data.ison422 === '0' ? 'tabA21biggroup']" :key="row.id"-->
      <template v-if="rowOfValues.data.ison422 === '0'">
        <tr >
          <td colspan="3" style="text-align: left">
            <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
            <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
          </td>
          <td class="border-top-1">...</td>
          <td class="border-top-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgo21, 2) }}
          </td>
          <td class="border-top-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgv21, 2) }}
          </td>
          <td class="border-top-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgz21, 2) }}
          </td>
          <td class="border-top-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgo21, 2) }}
          </td>
          <td class="border-top-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgv21, 2) }}
          </td>
          <td class="border-top-1">
            {{ $scripts.TableJs.  checkValueWithNullAndReturnND(rowOfValues.data.noqgz21, 2) }}
          </td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="(row) in rowOfValues.data.tabA21biggroup" :key="row.id">
          <td colspan="3" style="text-align: left">
            <span class="kcp-code">{{ row.kcpGroup.code }}</span>
            <span class="kcp-name">{{ row.kcpGroup.name }}</span>
          </td>
          <td class="border-top-1">...</td>
          <td class="border-top-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 2) }}
          </td>
          <td class="border-top-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 2) }}
          </td>
          <td class="border-top-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 2) }}
          </td>
          <td class="border-top-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 2) }}
          </td>
          <td class="border-top-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 2) }}
          </td>
          <td class="border-top-1">
            {{ $scripts.TableJs.  checkValueWithNullAndReturnND(row.noqgz21, 2) }}
          </td>
        </tr>
      </template>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item + Math.random()"></td>
        <td colspan="6" class="border-bottom-zero border-right-zero ">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-6.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item + Math.random()"></td>
        <td colspan="6" class="border-bottom-zero border-right-zero formul-text-table">
          ACO =
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[ rowOfValues.data.ison422 === '0' ? 'nougs21' : 'noons21'], 2) }}
        </td>
      </tr>
      </tbody>
    </table>
    <p>ACO - численность обучающихся по программам подготовки научных и научно-педагогических кадров в аспирантуре по
      группе научных специальностей по данным отчетного года. Для групп научных специальностей «Клиническая медицина»,
      «Профилактическая медицина», «Медико-биологические науки», «Фармацевтические науки» ACO рассчитывается по области
      медицинских наук в целом</p>
    <p>Если численность обучающихся в аспирантуре по группе научных специальностей по данным отчетного года менее 3,
      значение АСО принимается равным 3</p>
    <v-row dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Таблица Г. (фрагмент)</span><br>
          Перечень научно-исследовательских, опытно-конструкторских и технологических работ (НИОКТР), срок реализации
          которых на 1 января года проведения конкурса не истек
          <br>
          по данным ЕГИСУ НИОКТР
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left">Регистрационный номер НИОКТР</th>
        <th rowspan="2" colspan="3">Параметры НИОКТР</th>
        <th colspan="1" rowspan="3">Рубрики ГРНТИ</th>
        <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>

        <th rowspan="2" colspan="2">Число лет</th>
        <th rowspan="2" class="border-right-zero" colspan="2">Число рубрик ГРНТИ</th>
        <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="1" class="border-right-zero" colspan="1">Расчетный объем финансирования
        </th>
      </tr>
      <tr>

        <th rowspan="2" class="border-right-zero">
          <div class="d-flex flex-row justify-center align-center formul-text-table">
            <div>ПФНz ×</div>
            <div class="d-flex flex-column mx-1" >
              <span style="border-bottom: 1px solid black"> ЛОz</span>
              <span> ЛПz</span>
            </div>
            <div class="mx-1"> ×</div>
            <div class="d-flex flex-column" >
              <span style="border-bottom: 1px solid black"> КРzj</span>
              <span> КРz</span>
            </div>
          </div>

        </th>
        <!--   <th class="border-right-zero border-bottom-zero">
                  ЛПz
                </th>
                <th class=" border-bottom-zero border-right-zero">
                  КРz
                </th>      -->
      </tr>
      <tr>

        <th colspan="1">
          начало
        </th>
        <th colspan="1">
          конец
        </th>
        <th colspan="1">
          тыс.рублей
        </th>

        <th class="border-bottom-zero">
          до окончания
        </th>
        <th class="border-bottom-zero">
          реализации НИОКТР
        </th>
        <th class="border-bottom-zero">
          по науч.специальности
        </th>
        <th class="border-bottom-zero border-right-zero">
          всего соответствующих науч.специальностям
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span></th>
        <th v-for="(item) in 4" :key="item + Math.random()">{{ item + 1 }}</th>
        <th>
          <span class="font-weight-bold">гр.3 - 2022 г.</span>
        </th>
        <th>
          <span class="font-weight-bold">гр.3 - гр.2</span>
        </th>
        <th>
          <span class="font-weight-bold">выбор из гр.5</span>
        </th>
        <th class="border-right-zero">
          <span class="font-weight-bold ">п.5.5. Приложения 2 к Порядку</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-if="rowOfValues.data.tabA36nir.length > 0">
      <tr v-for="(row, index) in rowOfValues.data.tabA36nir" :key="row.id">
        <td colspan="3" style="text-align: left; min-width: 250px" class="">
          {{ row.rdreg22 }}
        </td>
        <td>{{ formatDate(row.rddtb22) }}</td>
        <td>{{ formatDate(row.rddte22) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.rdfin22,1,'float', true, '', (v) => {
          return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
        <td >{{ row.rdrub22.replaceAll('|', ';&nbsp;') }}</td>

        <td v-if="index === 0" :rowspan="rowOfValues.data.tabA36nir.length"
            class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')"
                                            alt=""/></td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.tyost22, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.tynir22, 2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qrost22, 0, 'integer') }}</td>
        <td class="border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.qrnir22, 0, 'integer') }}</td>
        <td v-if="index === 0" :rowspan="rowOfValues.data.tabA36nir.length"
            class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')"
                                                              alt=""/></td>
        <!--        <td class="border-bottom-zero border-right-zero"></td>-->
        <td colspan="1" class=" border-right-zero">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(row.dpnir22, 1, 'float', true, '', (v) => {
          return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
        </td>
        <!--        <td class="border-bottom-zero"></td>-->
      </tr>
      </template>
      <zero-rows v-else :colspan="14">нет сведений о действующих НИОКТР</zero-rows>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 5" :key="item + Math.random()"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-1.svg')" alt=""/>
        </td>
        <td colspan="2" class="border-bottom-zero border-right-zero border-left-zero">
        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-1.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero" style="min-width: 170px">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-2.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero" style="min-width: 170px">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-2.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero" style="min-width: 170px">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-2.svg')" alt=""/>
        </td>

      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 5" :key="item + Math.random()"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ПФН<span
              class="little-text font-size-extra-small__important" style="line-height:10px; ">z</span></span>
        </td>
        <td colspan="2" class="border-bottom-zero border-right-zero border-left-zero">
        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ЛО<span
              class="little-text font-size-extra-small__important" style="line-height:10px; ">z</span></span>

        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ЛП<span
              class="little-text font-size-extra-small__important" style="line-height:10px; ">z</span></span>

        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">KP<span
              class="little-text font-size-extra-small__important" style="line-height:10px; ">zj</span></span>

        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">KP<span
              class="little-text font-size-extra-small__important" style="line-height:10px; ">z</span></span>

        </td>
        <td class="border-bottom-zero border-right-zero"></td>
        <td colspan="1" class="border-bottom-zero border-right-zero formul-text-table">ПФН =
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.dpugs22,1, 'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
        </td>
      </tr>
      </tbody>
    </table>
    <p>ПФНz - плановый объем финансирования z-й НИОКТР, срок реализации которой на 1 января года проведения конкурса не
      истек</p>
    <p>ЛОz - количество лет с 1 января года проведения конкурса до окончания z-й НИОКТР, срок реализации которой на 1
      января года проведения конкурса не истек, но не более 6 лет</p>
    <p>ЛПz - количество лет с даты начала до даты окончания z-й НИОКТР, срок реализации которой на 1 января года
      проведения конкурса не истек</p>
    <p>КРzj - число рубрик ГРНТИ по z-й НИОКТР соответствующих группе научных специальностей</p>
    <p class="mb-4">КРz - общее число рубрик ГРНТИ по z-й НИОКТР, поставленных в соответствие группам научных
      специальностей</p>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-5 alternate-text"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          ПФН
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">ACO</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-5 alternate-text"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[ rowOfValues.data.ison422 === '0' ? 'dpugs22' : 'dpugs22'], 1, 'float', true, '', (v) => {
          return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')})  }}
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[ rowOfValues.data.ison422 === '0' ? 'nougs21' : 'noons21'], 2, 'float', true,'', formatACO())
            }}</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-5 alternate-text"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-5">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName + 'b022'], item.prd+1, 'float')
            }}</span>
        </template>
      </formuls-fraction>
    </div>
    <block-g v-bind="$props"/>
  </div>
</template>

<script>
import BlockG from "@/components/cards/indicatiors/details/blockG";
import ZeroRows from "@/components/cards/indicatiors/details/zeroRows";
import moment from "moment";
moment.locale('ru');
export default {
  name: "a36",
  components: {ZeroRows, BlockG},
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    formatACO(){
      return (v) => {
        if (Number.parseFloat(v) < 3)
          return  3;
        else
          return  v;
      }
    }
  },
}
</script>

<style lang="sass" scoped>

</style>