<template>
<div class="d-flex flex-row align-center justify-center"  style="min-height: 100%;">
  <v-progress-circular v-if="loading.value && id === loading.id" color="primary" indeterminate size="20"></v-progress-circular>
  <div
      v-else
class="d-flex flex-column align-center"
      style="flex: 0 1 auto; width: 100%; min-height: 22px;"
      @click="sendValue"
  >
<!--    <div v-if="typeof value === 'object' && value" class="d-flex flex-row align-center justify-center">-->
<!--      <span :style="value.access === true ? 'text-decoration: line-through; color: #78909C;' : ''">{{value.oldValue}}</span>-->
<!--      <span class="ml-3" :style="value.access === false ? 'text-decoration: line-through;' : 'color: #78909C;'">{{ value.newValue }}</span>-->
<!--       <span class="ml-1">-->
<!--        <v-icon v-if="value.access == null"   color="" size="15" >mdi-timer-sand</v-icon>-->
<!--        <v-icon v-if="value.access === true"  color="error" size="15" >mdi-close</v-icon>-->
<!--        <v-icon v-if="value.access === false" color="success" size="15" >mdi-check</v-icon>-->
<!--       </span>-->
<!--    </div>-->
    <span style="">{{value}}</span>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "fivesTableCell",
  props: {
    value: {
      type: [Number, String, Object],
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    id: {
      type: [String, Number]
    },
    cell: {
      type: [Object, String]
    },
    row: {
      type: [Object, String],
      validator: (value) =>{
        return ['string', 'object'].includes(typeof value)
      }
    },
    loading: {
      type: [Boolean, Object],
      default: false
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
    additionalDataActive: {
      type: Boolean,
      default: false
    },
    additionalData: {
      type: [Object, Array]
    },
    loadingAdditionalData: {
      type: [Boolean, Object],
      default: false
    },
  },
  computed: {
    ...mapGetters('checkFilled' , { simpleBlock: 'simpleBlock'}),
  },
  methods: {
    sendValue(){
      // if (!this.simpleBlock)
      //   this.$emit('open-dialog', this.value);
    },
  },

}
</script>

<style scoped>

</style>