import axios from "axios";
import {saveFile} from "@/utils/saveFile";
import {mapGetters} from "vuex";
export const getFileFromServer ={
    computed: {
        ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
        token() {
            return this.getAuthData.token
        },
    },
    methods: {
        async getFile(id) {
            this.uploadNow = true;
            try {
                const response = await axios.get(`/api/file/scan/download?file=${id}`, {
                    headers: {Authorization: this.token ? `Bearer ${this.token}` : ''},
                })
                this.uploadNow = false;
                saveFile({...response.data, fileBytes: response.data.file}, false);
            } catch (e) {
                this.uploadNow = false;
                console.error("e", e)
            }
        }
    }
}
