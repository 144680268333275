<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.04. (фрагмент)</span><br>
          Общая численность обучающихся по ... программам подготовки научных и научно-педагогических кадров в
          аспирантуре
          <br>
          по данным формы 1-НК, человек
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left">Уровни образования</th>
        <th rowspan="3" style="vertical-align: bottom">...</th>
        <th rowspan="2">Всего<br> обучающихся<br>
          (вкл. квоту<br> иностр.гражд.)
        </th>
        <th colspan="2" rowspan="2">Обучающихся (искл. квоту иностр.граждан)</th>
        <th colspan="5">Обучающихся - иностранных граждан</th>
        <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="2" colspan="2">Всего обучающихся</th>
        <th rowspan="2" class="border-right-zero" colspan="2">Всего иностр.граждан</th>
      </tr>
      <tr>
        <th colspan="1">
          всего
        </th>
        <th colspan="2">
          на усл.общего приема
        </th>
        <th colspan="2">
          обучающихся по квоте
        </th>
      </tr>
      <tr>
        <th>
          на 1.01.2019 г.
        </th>
        <th>
          на 1.01.2020 г.
        </th>
        <th>
          на 1.01.2021 г.
        </th>
        <th>
          на 1.01.2019 г.
        </th>
        <th>
          на 1.01.2020 г.
        </th>
        <th>
          на 1.01.2021 г.
        </th>
        <th>
          на 1.01.2020 г.
        </th>
        <th>
          на 1.01.2021 г.
        </th>
        <th rowspan="2" style="border-bottom: 0">
          на 1.01.2020 г.<br/>
          <span class="font-weight-bold">гр.5 + гр.10</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">

          на 1.01.2021 г.<br/>
          <span class="font-weight-bold">гр.6 + гр.11</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">
          на 1.01.2020 г.<br/>
          <span class="font-weight-bold">гр.8 + гр.10</span>
        </th>
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">

          на 1.01.2021 г.<br/>
          <span class="font-weight-bold">гр.9 + гр.11</span>
        </th>

      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th>...</th>
        <th v-for="(item) in 8" :key="item +Math.random()">{{ item + 3 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left; min-width: 250px" class="kcp-name">
          Аспирантура (все формы обучения)
        </td>
        <td class="">...</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noaps19, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsaps20, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nsaps21, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.niaps19, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nfaps20, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nfaps21, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqaps20, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nqaps21, 0, 'integer') }}</td>
        <td class="border-bottom-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')"
                                            alt=""/></td>
        <td class="border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noaps20, 0, 'integer') }}</td>
        <td class="border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noaps21, 0, 'integer') }}</td>
        <td class="border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.niaps20, 0, 'integer') }}</td>
        <td class="border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.niaps21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item +Math.random()"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td colspan="2" class="border-bottom-zero border-right-zero border-left-zero">
        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>

        <td class="border-bottom-zero border-right-zero" v-for="(item) in 5" :key="item"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item +Math.random()"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">АСП<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
        </td>
        <td colspan="2" class="border-bottom-zero border-right-zero border-left-zero">
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ИА<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
        </td>

        <td class="border-bottom-zero border-right-zero" v-for="(item) in 5" :key="item +Math.random()"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">АСП<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">АСП<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2021</span></span>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ИА<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
        </td>

        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ИА<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2021</span></span>
        </td>
      </tr>
      </tbody>
    </table>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <div class="d-flex alternate-text formul-text mb-5" style="line-height: 70px; font-size: 55px">
        (
      </div>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ИА
            <div class="little-text ">2019</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              АСП
              <div class="little-text ">2019</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ИА
            <div class="little-text ">2020</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              АСП
              <div class="little-text ">2020</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ИА
            <div class="little-text ">2021</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              АСП
              <div class="little-text ">2021</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        )
      </div>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 ml-1 alternate-text">× 100 ÷</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" class="mr-1">
        <template>
          <span class="mt-8"> n </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        (
      </div>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.niaps19, 0, 'integer') }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noaps19, 0, 'integer') }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.niaps20, 0, 'integer') }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noaps20, 0, 'integer') }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.niaps21, 0, 'integer') }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1">
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noaps21, 0, 'integer') }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <div class="d-flex align-center alternate-text mb-5" style="line-height: 70px; font-size: 55px">
        )
      </div>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 ml-1 alternate-text"> × 100 ÷</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" class="mr-1">
        <template>
          <span class="mt-8"> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noapsQ3, 0, 'integer') }}</span>
        </template>
      </formuls-fraction>

      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>АСПk - общая численность обучающихся аспирантуры по данным k-го года</p>
    <p>ИАk - численность иностраных граждан, обучающихся в аспирантуре, по данным k-го года</p>
    <p >n - число лет, за которые имеются отчетные данные АСПk. Значение показателя А34 определяется за те годы, за которые имеются отчетные значения АСП, равные 10 или более.</p>
    <p class="mb-4" v-if="rowOfValues.data[item.dataFieldName+'nf22'] !== '0'">Если
    значение АСП за все годы отсутствует или менее 10, значение показателя А34 не рассчитывается, а его нормированная
      оценка определяется в соответствии с пунктом 7 Методики (по средним).</p>


  </div>
</template>

<script>
export default {
  name: "a34",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>

</style>