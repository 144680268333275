<template>
  <component :is="componentName"/>
</template>

<script>
import {mapGetters} from 'vuex';
import LkOrganization from "./LK/LkOrganization";
import LkAdmin from "@/View/LK/LkAdmin";
import LkFounder from "@/View/LK/LkFounder";
import LkRegion from "@/View/LK/LkRegion";

export default {
  name: "ViewLK",
  components: {LkOrganization, LkAdmin, LkFounder, LkRegion},
  data() {
    return {
      // showAlert: true
    }
  },
  computed: {
    ...mapGetters('checkFilled', {
      get_check_filled_status: 'get_check_filled_status',
    }),
    ...mapGetters('keycloak', {
      isAdmin: 'isAdmin',
      isFounder: 'isFounder',
      isOrganization: 'isOrganization',
      isRegion: 'isRegion',
    }),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    role() {
      // const arr = this.authData?.realm_access.roles.filter( el => el!=='default-roles-ppk_sso')
      if (this.isAdmin) return 'admin'
      else if (this.isFounder) return 'founder'
      else if (this.isRegion) return 'region'
      else return 'org';
    },
    componentName() {
      let component = 'LkOrganization';
      switch (this.role) {
        case 'org':
          component = 'LkOrganization';
          break;
        case 'admin':
          if (this.get_replace_id_admin)
            component = 'LkOrganization';
          else
            component = 'LkAdmin';
          break;
        case 'founder':
          component = 'LkFounder';
          break;
        case 'region':
          component = 'LkRegion';
          break;
        default:
          component = 'LkOrganization';
      }
      return component;
    }
  },
  methods: {},
}
</script>

<style lang="sass">

</style>