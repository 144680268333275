<template>
  <div>
    <p v-if="rowOfValues.data.isnps22 === '0'"><span class="formul-text-table"> OPC, ACC = 0 </span>(по программам ординатуры и ассистентуры-стажировки нет УГСН {{ rowOfValues.data.kcpGroup.code.slice(0, 2) }}.00.00)</p>
    <template v-if="rowOfValues.data.isnps22 === '1'">
    <v-row dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.15. (фрагмент)</span><br>
          Численность обучающихся по программам ординатуры, программам ассистентуры-стажировки
          по данным формы 1-НК, человек
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Уровни образования. Наименование НПС</th>
        <th rowspan="3">...</th>
        <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
        <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
        <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся по УГСН</th>
      </tr>
      <tr>
        <th colspan="3">
          на 1.01.2021 г.
        </th>
        <th colspan="3">
          на 1.01.2021 г.
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>

        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th rowspan="2" style="border-bottom: 0">
          очно<br/>
          <span class="font-weight-bold">гр.6 + гр.9</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">

          очно-заочно<br/>
          <span class="font-weight-bold">гр.7 + гр.10</span>
        </th>
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
          заочно<br/>
          <span class="font-weight-bold">гр.8 + гр.11</span>
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th>...</th>
        <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code.slice(0, 2) }}.00.00</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.ugsName.slice(0,1) + rowOfValues.data.kcpGroup.ugsName.slice(1).toLowerCase() }}</span>
          <span>Ординатура</span>
        </td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td :rowspan="4 + rowOfValues.data['tabB22usl9'].length + rowOfValues.data['tabB22usl12'].length" class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')"
                                                        alt=""/></td>
        <td :rowspan="rowOfValues.data['tabB22usl9'].length + 1" class="border-bottom-zero border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkoro21, 0, 'integer') }}</td>
        <td :rowspan="rowOfValues.data['tabB22usl9'].length + 1" class="border-bottom-zero border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkorv21, 0, 'integer') }}</td>
        <td :rowspan="rowOfValues.data['tabB22usl9'].length + 1" class="border-bottom-zero border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkorz21, 0, 'integer') }}</td>
      </tr>
      <template v-if="rowOfValues.data['tabB22usl9'].length > 0">
        <tr v-for="row in rowOfValues.data['tabB22usl9']" :key="row.id">
          <td colspan="3" style="text-align: left">
            <span class="kcp-code">{{ row.kcpGroup.code }}</span>
            <span class="kcp-name">{{ row.kcpGroup.name }}</span>
          </td>
          <td>...</td>
          <td>
            {{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer')
            }}
          </td>
          <td>
            {{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer')
            }}
          </td>
          <td>
            {{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer')
            }}
          </td>
          <td>{{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer')
            }}
          </td>
          <td>
            {{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer')
            }}
          </td>
          <td>
            {{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer')
            }}
          </td>
        </tr>
      </template>
      <zero-rows v-else :colspan="10"/>
      
      <tr>
        <td colspan="3" style="text-align: left" class="border-bottom-zero">
          <span>Ассистентура-стажировка</span>
        </td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <!--          <td class="border-bottom-zero"></td>-->
        <td :rowspan="rowOfValues.data['tabB22usl12'].length + 1" class="border-bottom-zero border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkaso21, 0, 'integer') }}</td>
        <td :rowspan="rowOfValues.data['tabB22usl12'].length + 1" class="border-bottom-zero border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkasv21, 0, 'integer') }}</td>
        <td :rowspan="rowOfValues.data['tabB22usl12'].length + 1" class="border-bottom-zero border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkasz21, 0, 'integer') }}</td>

      </tr>
      <template v-if="rowOfValues.data['tabB22usl12'].length > 0">
        <tr v-for="row in rowOfValues.data['tabB22usl12']" :key="row.id">
          <td colspan="3" style="text-align: left">
            <span class="kcp-code">{{ row.kcpGroup.code }}</span>
            <span class="kcp-name">{{ row.kcpGroup.name }}</span>
          </td>
          <td>...</td>
          <td>
            {{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer')
            }}
          </td>
          <td>
            {{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer')
            }}
          </td>
          <td>
            {{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer')
            }}
          </td>
          <td>{{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer')
            }}
          </td>
          <td>
            {{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer')
            }}
          </td>
          <td>
            {{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer')
            }}
          </td>
        </tr>
      </template>
      <zero-rows v-else :colspan="10"/>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="4" class="border-bottom-zero border-right-zero text-right">Ординатура по всем формам обучения:</td>
        <td colspan="3" class="border-bottom-zero border-right-zero formul-text-table">
           OPC =  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkors21, 0, 'integer') }}
        </td>
        
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="4" class="border-bottom-zero border-right-zero text-right">Ассистентура-стажировка по всем формам
          обучения:
        </td>
        <td colspan="3" class="border-bottom-zero border-right-zero formul-text-table">
          ACC =  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkass21, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    </template>
    <v-row dense>
      <v-col cols="10">
        <p>
        <span class="font-weight-bold mb-1">Форма 5.18. (фрагмент)</span><br>
          Численность обучающихся по программам подготовки научных и научно-педагогических кадров в аспирантуре
          по данным формы 1-НК, человек
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left">Уровни образования</th>
        <th rowspan="3">...</th>
        <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
        <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
        <th rowspan="6" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
      </tr>
      <tr>
        <th colspan="3">
          на 1.01.2021 г.
        </th>
        <th colspan="3">
          на 1.01.2021 г.
        </th>
        <th colspan="3" class="border-right-zero">
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>

        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th rowspan="2" style="border-bottom: 0">
          очно<br/>
          <span class="font-weight-bold">гр.6 + гр.9</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">

          очно-заочно<br/>
          <span class="font-weight-bold">гр.7 + гр.10</span>
        </th>
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
          заочно<br/>
          <span class="font-weight-bold">гр.8 + гр.11</span>
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th>...</th>
        <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left" class="border-bottom-zero kcp-td">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code.slice(0, 2) }}.00.00</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.ugsName.slice(0,1) + rowOfValues.data.kcpGroup.ugsName.slice(1).toLowerCase() }}</span>
        </td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero border-right-zero"></td>
        <td class="border-bottom-zero border-right-zero"></td>
        <td class="border-bottom-zero border-right-zero"></td>
        <td class="border-bottom-zero border-right-zero"></td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: left">
          Аспирантура
        </td>
        <td>...</td><!--tabB22Asp-->
        <template v-if="rowOfValues.data.tabB22Asp">
        <td>{{
            $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB22Asp.nosgo21, 0, 'integer')
          }}
        </td>
        <td>{{
            $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB22Asp.nosgv21, 0, 'integer')
          }}
        </td>
        <td>{{
            $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB22Asp.nosgz21, 0, 'integer')
          }}
        </td>
        <td>{{
            $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB22Asp.noqgo21, 0, 'integer')
          }}
        </td>
        <td>{{
            $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB22Asp.noqgv21, 0, 'integer')
          }}
        </td>
        <td>{{
            $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB22Asp.noqgz21, 0, 'integer')
          }}
        </td>
        </template><template v-else>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </template>
        <td class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
        <td class="border-bottom-zero border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkapo21, 0, 'integer') }}</td>
        <td class="border-bottom-zero border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkapv21, 0, 'integer') }}</td>
        <td class="border-bottom-zero border-right-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkapz21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="4" class="border-bottom-zero border-right-zero text-right">Аспирантура по формам обучения:</td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">AC<span
              class="little-text font-size-extra-small__important" style="line-height:10px; ">o</span></span>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">AC<span
              class="little-text font-size-extra-small__important" style="line-height:10px; ">v</span></span>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">AC<span
              class="little-text font-size-extra-small__important" style="line-height:10px; ">z</span></span>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import ZeroRows from "@/components/cards/indicatiors/details/zeroRows";
export default {
  name: "blockCo",
  components: {ZeroRows},
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
}
</script>

<style scoped>

</style>