import { render, staticRenderFns } from "./universalDialog(v1.1).vue?vue&type=template&id=22dbb0c3&"
import script from "./universalDialog(v1.1).vue?vue&type=script&lang=js&"
export * from "./universalDialog(v1.1).vue?vue&type=script&lang=js&"
import style0 from "./universalDialog(v1.1).vue?vue&type=style&index=0&id=22dbb0c3&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports