<template>
  <div class="">
    <card-indicator-head
        v-model="show"
        :item="item"
        :loading="loading"
        :available="item.available"
        :typeStyle="item.typeStyle"
    />
    <transition name="fade">
      <div
          class="b-indicator__collapse"
          v-if=" show"
      >

        <containerIndicatorBody @appeal-indicator="$emit('appeal-indicator', $event)" :item="item" :row-of-values="rowOfValues"/>
      </div>
    </transition>
  </div>
</template>

<script>
import CardIndicatorHead from "@/components/cards/indicatiors/cardIndicatorHead";
import containerIndicatorBody from "@/containers/containerIndicatorBody";

export default {
  name: "cardIndicator",
  components: { containerIndicatorBody, CardIndicatorHead},
  props: {
    item: {
      type: Object,
      default: () => {
        return {title: '', about: '', count: '', max: ''}
      }
    },
    rowOfValues: {
      type: Object,
      default: null
    },
    loading:{
      type: Boolean,
      default: false
    },
    typeStyle:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    // show(newValue, oldValue) {
    //   console.log("watcher", newValue, oldValue)
    // }
  },
  computed: {

  },
}
</script>

<style lang="sass" scoped>
.fade-enter-active, .fade-leave-active
  transition: 0.5s
.fade-enter, .fade-leave-to
  opacity: 0

</style>