import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const routes = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import('@/layouts/lkLayout'),
            meta: {},
            children: [
                {
                    path: '',
                    component: () => import('@/View/ViewLK'),
                    meta: {
                        title: 'Профиль'
                    },
                    props: true,
                },
            ]
        },
        {
            path: '/offers',
            component: () => import('@/View/Forms2-4/form2'),
            meta: {
                needProfileData: true,
                title: 'Форма 2. Предложения по установлению контрольных цифр приема за счет бюджетных ассигнований федерального бюджета на 2023/24 учебный год'
            },
            props: true,
        },
        {
            path: '/license',
            component: () => import('@/View/Forms2-4/form3'),
            meta: {
                needProfileData: true,
                title: 'Форма 3. Сведения о наличии лицензии на осуществление образовательной деятельности'
            },
            props: true,
        },
        {
            path: '/accreditation',
            component: () => import('@/View/Forms2-4/form4'),
            meta: {
                needProfileData: true,
                title: 'Форма 4.1. Сведения о наличии государственной аккредитации по образовательным программам высшего образования'
            },
            props: true,
        },
        {
            path: '/accreditation_agree',
            component: () => import('@/View/Founders/accredAgree'),
            meta: {
                title: 'Одобрение аккредитаций'
            },
            props: true,
        },
        {
            path: '/forms',
            component: () => import('@/layouts/formLayout'),
            meta: {
                needProfileData: true,
            },
            children: [
                {
                    path: '',
                    component: () => import('@/View/Forms5/ListForms'),
                    meta: {
                        title: 'Показатели деятельности образовательной организации'
                    },
                    props: true,
                },
                {
                    path: ':form_id',
                    component: () => import('@/View/Forms5/allFifthForms'),
                    meta: {
                        title: ''
                    },
                    props: true,
                },
            ]
        },
        {
            path: '/indicatorsRegions',
            component: () => import('@/layouts/formLayout'),
            meta: {
                needProfileData: false,
            },
            children: [
                {
                    path: '',
                    component: () => import('@/View/kcpBalls/allListStage1Region'),
                    meta: {
                        title: 'Распределение КЦП'
                    },
                    props: true,
                },
            ]
        },
        {
            path: '/indicators',
            component: () => import('@/layouts/formLayout'),
            meta: {},
            children: [
                {
                    path: '',
                    component: () => import('@/View/kcpBalls/allList'),
                    meta: {
                        title: 'Сводные оценки заявок'
                    },
                    props: true,
                    needProfileData: true,
                },
                {
                    path: 'details',
                    component: () => import('@/View/kcpBalls/fullInfo'),
                    meta: {
                        title: ''
                    },
                    props: true,
                },
            ]
        },
        {
            path: '/indicators-stage1',
            component: () => import('@/layouts/formLayout'),
            meta: {
                needProfileData: true,
            },
            children: [
                {
                    path: '',
                    component: () => import('@/View/kcpBalls/allListStage1'),
                    meta: {
                        title: 'Распределение КЦП'
                    },
                    props: true,
                },
                // {
                //     path: 'details',
                //     component: () => import('@/View/kcpBalls/fullInfo'),
                //     meta: {
                //         title: ''
                //     },
                //     props: true,
                // },
                {
                    path: '*',

                    component: () => import('@/View/kcpBalls/allListStage1'),
                    meta: {
                        title: 'Распределение КЦП'
                    },
                    props: true,
                }
            ]
        },
        {
            path: '/indicators-stage-correct-values',
            component: () => import('@/View/kcpBalls/allListStage1CorrectValues'),
            meta: {
                title: 'Согласование предельных объемов увеличения предложений по установлению КЦП (заявок)',
                needProfileData: true,
            },
        },
        {
            path: '/form-a',
            component: () => import('@/View/dopForms/formA-G'),
            meta: {
                title: 'Дополнительные данные'
            },
            props: true,
        },
        {
            path: '/form-b',
            component: () => import('@/View/dopForms/formA-G'),
            meta: {
                title: 'Дополнительные данные'
            },
            props: true,
        },
        {
            path: '/print',
            component: () => import('@/View/LK/PrintBlockOrg'),
            meta: {
                title: 'Печать и Блокировка',
            },
            props: true,
        },
        {
            path: '/form-v',
            component: () => import('@/View/dopForms/formA-G'),
            meta: {
                title: 'Дополнительные данные'
            },
            props: true,
        },
        {
            path: '/form-g',
            component: () => import('@/View/dopForms/formA-G'),
            meta: {
                title: 'Дополнительные данные'
            },
            props: true,
        },
        {
            path: '/reference',
            component: () => import('@/View/admin/reference/ViewReference'),
            meta: {
                title: 'Список организаций',
                isAdmin: true
            },
            props: true,
        },
        {
            path: '/requests',
            component: () => import('@/View/admin/requests/ViewRequests'),
            meta: {
                title: 'Заявки',
                isAdmin: true
            },
            props: true,
        },
        {
            path: '/appeals',
            component: () => import('@/View/admin/appeals/ViewAdminAppeals'),
            meta: {
                title: 'Формы апелляций',
                isAdmin: true
            },
            props: true,
        },
        {
            path: '/appeals-indicators',
            component: () => import('@/View/admin/appeals/ViewIndicatorsAppeals'),
            meta: {
                title: 'Формы апелляций по показателям',
                isAdmin: true
            },
            props: true,
        },
        {
            path: '/exports_admin',
            component: () => import('@/View/admin/Exports'),
            meta: {
                title: 'Выгрузки',
                isAdmin: true
            },
            props: true,
        },
        {
            path: '/konverts',
            component: () => import('@/View/admin/Konverts.vue'),
            meta: {
                title: 'Регистрация конвертов',
                isAdmin: true
            },
            props: true,
        },
    ]
});

routes.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.isAdmin)) {
        if (store.getters['keycloak/isAdmin']) {
            next();
        } else {
            await next({
                path: '/',
            });
        }
    } else {
         next();
    }
    if (to.matched.some(record => record.meta.needProfileData)) {
        if (store.getters['checkFilled/canOrgEdit'] || store.getters['keycloak/isAdmin']) {
            await next();
        } else {
            await store.dispatch('checkFilled/getStatusInfo', {}, {root: true});
            if (!store.getters['checkFilled/canOrgEdit'])
            {
                await store.dispatch('checkFilled/initInfoForStatus', {}, {root: true});
            }
            await next({
                path: '/',
            });
        }
    } else {
        next();
    }
    next();
});
export default routes;