<template>
  <WrapperForm>
    <v-form
        class="contact-card__input"
        ref="org-input"
        v-model="valid"
        lazy-validation
    >
      <div class="info-card__field">
        <div class="info-card__name">
          Полное наименование организации
        </div>
        <v-text-field
            v-model="form.orgFullName"
            :rules="[rules.required, rules.counter]"
            class="info-card__cell"
            color="#00599B"
            outlined
            dense
        />
      </div>
      <div class="info-card__field">
        <div class="info-card__name">
          Наименование организации без организационно правовой формы
        </div>
        <v-text-field
            v-model="form.orgName"
            :rules="[rules.required, rules.counter]"
            class="info-card__cell"
            color="#00599B"
            outlined
            dense
        />
      </div>
      <div class="info-card__field">
        <div class="info-card__name">
          Сокращенное наименование организации
        </div>
        <v-text-field
            v-model="form.orgShortName"
            :rules="[rules.required, rules.counter3]"
            class="info-card__cell"
            color="#00599B"
            outlined
            dense
        />
      </div>
      <div class="info-card__field">
        <div class="info-card__name">
          Наименование учредителя
        </div>
        <v-text-field
            v-model="form.orgFounderName"
            :rules="[rules.required, rules.counter]"
            class="info-card__cell"
            color="#00599B"
            outlined
            dense
        />
      </div>
      <div class="info-card__field">
        <div class="info-card__name">
          Адрес
        </div>
        <v-text-field
            v-model="form.orgAddress"
            :rules="[rules.required, rules.counter]"
            class="info-card__cell"
            color="#00599B"
            outlined
            dense
        />
      </div>
      <div class="info-card__field">
        <div class="info-card__name">
          ИНН
        </div>
        <v-text-field
            v-model="form.inn"
            :rules="[rules.required, rules.inn]"
            class="info-card__cell"
            color="#00599B"
            outlined
            dense
        />
      </div>
      <div class="info-card__field">
        <div class="info-card__name">
          КПП
        </div>
        <v-text-field
            v-model="form.kpp"
            :rules="[rules.required, rules.kpp]"
            class="info-card__cell"
            color="#00599B"
            outlined
            dense
        />
      </div>
      <scan-card
          :item="file"
          class="mb-2"
          :upload-to-serv-now="loadingFileToServer.fileType === file.fileType && loadingFileToServer.value"
          @replace-file="replaceFile($event)"
          @upload-file="uploadFile($event)"
          :token="token"
          identifier="fileType"
      />
      <v-btn @click.prevent="onSubmit" :disabled="!valid"
             :class="['blue__v-btn', {'button_disabled': !valid}]">Заявка на изменение
      </v-btn>
      <v-btn class="white__v-btn ml-2" @click.prevent="cancel">Отмена</v-btn>
    </v-form>
  </WrapperForm>
</template>

<script>
import {minLength, maxLength, integer} from 'vuelidate/lib/validators'
// import Field from '@/components/fields/Field/Field';
// import BSelect from '@/components/controls/BSelect/BSelect';
import WrapperForm from '@/components/wrappers/WrapperForm';
import ScanCard from "@/components/cards/scanCard";
import request from "@/services/request";
import {mapGetters, mapActions} from "vuex";


export default {
  components: {
    ScanCard,

    WrapperForm,
    // BSelect
  },
  data() {
    return {
      // isOnButtonSave: false,
      validator: {
        inn: true,
        kpp: true,
        file: true,
      },
      valid: true,
      rules: {
        required: value => !!value || 'Обязательное поле!',
        counter: value => value.length > 10 || 'Поле не может содержать меньше 10 символов',
        counter3: value => value.length >= 3 || 'Поле не может содержать меньше 3 символов',
        inn: value => value.length === 10 || 'Поле должно содержать 10 символов',
        kpp: value => value.length === 9 || 'Поле должно содержать 9 символов',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неверно указан адрес почты'
        },
      },
      loadingFileToServer: {
        value: false,
        fileType: ''
      },
      file: {
        name: 'Подтверждающие документы',
        types: ['application/pdf'],
      },
      form: {
        orgFullName: this.$attrs.value.orgFullName,
        orgName: this.$attrs.value.orgName,
        orgShortName: this.$attrs.value.orgShortName,
        orgFounderName: this.$attrs.value.orgFounderName,
        sprOrgType: this.$attrs.value.sprOrgType,
        orgAddress: this.$attrs.value.orgAddress,
        inn: this.$attrs.value.inn,
        kpp: this.$attrs.value.kpp,
        orgOpCode: this.$attrs.value.orgOpCode,
        fileId: this.$attrs.value?.fileId ?? null,
      },

    }
  },
  computed: {
    orgType() {
      return this.$attrs.value.sprOrgType;
    },
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
    token() {
      return this.getAuthData.token
    },
  },

  validations: {
    form: {
      inn: {
        integer: integer,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      kpp: {
        integer: integer,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
    }
  },
  methods: {
    getFile() {
      this.file = {...this.file, id: this.$attrs.value.fileId, fileType: 'ORG_APPEAL' };
    },
    ...mapActions('notifications', {addNotification: "addNotification"}),
    async uploadFile(event) {
      this.loadingFileToServer.value = true;
      this.loadingFileToServer.fileType = event.fileType;
      let fd = new FormData();
      fd.append('file', event.file);
      try {
        const res = await request({
          endpoint: `/api/file/scan/upload?scanType=${event.fileType}`,
          method: 'put',
          body: fd,
        });
        this.file = {...this.file, ...res.data};
        this.loadingFileToServer.value = false;
      } catch (e) {
        this.loadingFileToServer.value = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка загрузки',
          timeout: 3000
        })
      }
    },
    async deleteFile(event) {
      try {
        const response = await request({
          endpoint: `/api/file/scan/delete?fileId=${event.id}`,
          method: 'DELETE',
          token: this.token,
        });
        if (response.status === 200) {
          this.file = {
            name: this.file.name,
            fileType: event.fileType,
            types: this.file.types
          }
        }
      } catch (e) {
        // this.uploadNow = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка удаления',
          timeout: 3000
        });
        console.log("e delete", e)
      }
    },
    async replaceFile(event) {
      await this.deleteFile(event)
      await this.uploadFile(event)
    },
    async validate() {
      this.$refs['org-input'].validate()
    },
    async onSubmit() {
      // console.log(this.$v)
      // this.$v.form.$touch();
      // this.validator.inn = this.$v.form.inn.$invalid ? false : true;
      // this.validator.kpp = this.$v.form.kpp.$invalid ? false : true;
      // this.validator.file = this.form.file !== null;

      await this.validate();
      if (this.valid && this.file.id){
        // let check = Object.values(this.validator);
        // if (check.every(el => el === true)) {
        //   this.$emit('input', this.form);
        // }
        this.$emit('input', {...this.form, file: this.file});
      }else if (!this.file.id){
        await this.addNotification( {
          notificationStatus: 'error',
          notificationMessage: 'Приложите файл ',
          timeout: 3000
        });
      }

    },
    onChange() {
      this.isOnButtonSave = true;
    },
    cancel() {
      this.$emit('cancel');
    }
  }
}
</script>

<style lang="sass">
.button_inline
  margin: 10px
  color: #00599b
  text-decoration: underline

.field__input_file
  width: 100%

.field__input_file.field_invalid
  background: #f8d7da

.field__input_file
  margin-bottom: 10px
</style>