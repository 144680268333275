import {mapActions, mapGetters} from "vuex";

export const setAppealDialog = {
    data() {
        return {
            loadingMenu: {value: false, id: ''},
            appealId: null,
            fileId: null,
            files: [],
            appealCell: null,
            appealValue: null,
            appealAccept: null,
            specialObj: null,
            appealInfo: {
                ugs: null,
                cell: null
            },
            appealsArr: []
        }
    },
    created() {
        if (this.appeal_loading === false)
            this.initCellsFilesForAppeals();
    },
    watch: {
        appealsLength() {
            this.initCellsFilesForAppeals();
        },
        get_replace_id(){
            this.loadingDataTable();
        }
    },
    computed: {
        ...mapGetters('tables', {
            appeal_data: 'get_appeals_data',
            appeal_error: 'get_appeals_error',
            appeal_loading: 'get_appeals_loading',
        }),
        ...mapGetters('auth', {
            authData: 'authData',
            get_replace_id: 'get_replace_id'
        }),
        appealsLength() {
            return this.appeal_data.counter
        },
        headersForRowsWithEditableCells() {
            return this.headersForRows.filter(el => el.text !== '1')
        },
    },
    methods: {
        ...mapActions('tables', {pushNewElement: 'pushNewElement'}),
        initCellsFilesForAppeals() {
            if (!this.appeal_loading && this.appeal_data.counter > 0 && this.appeal_error == null) {
                this.headersForRowsWithEditableCells.forEach(el => {
                    const item = this.appeal_data.data.find(appealElement => appealElement.jsonId === el.dataField && appealElement.org.id === this.authData.orgID && appealElement.fileId)
                    if (item)
                        this.files.push({
                            ...item,
                            dataField: el.dataField,
                            fileId: item.fileId,
                            decision: item.decision,
                            accept: item.accept,
                            appeal: item.appeal,
                        });
                });
            }
        },
        closingAppealDialog() {
            this.appealId = null;
            this.appealAccept = null;
            this.appealCell = null;
            this.fileId = null;
            this.specialObj = null;
            this.appealsArr = [];
        },
        async openDialog(value, dataField = '', cell, row) {
            this.appealValue = value;
            this.appealInfo = {
                ugs: ((row.spec ? row.spec.kodSpec : row.sprKcpGroup.code) + ' ' + (row.spec ? row.spec.name : row.sprKcpGroup.name)),
                kcpGroup: row.sprKcpGroup,
                cellInfo: this.findInfoForCell(dataField),
                rowId: row.id,
                orgId: row.sprOrgId
            }
            this.specialObj = {form520Id: row.id, form5Id: null};
            const res = this.appeal_data.data.filter(el => el.jsonId === dataField && el.org?.id === row.sprOrgId);
            this.appealsArr.push(...res);
            const item = this.appeal_data.data.find(el => el.jsonId === dataField && el.org?.id === row.sprOrgId  && el.fileId);
            if (value?.id) {
                this.appealId = value.id;
                this.appealAccept = value.accept;
                this.fileId = item?.fileId;
            } else {
                if (item) {
                    this.appealCell = item;
                    this.appealId = null;
                    this.fileId = item.fileId;
                    this.appealAccept = item.appealAccept;
                }else{
                    const itemWithoutFile = this.appeal_data.data.find(el => el.jsonId === dataField && el.org?.id === row.sprOrgId);
                    this.appealCell = itemWithoutFile;
                    this.appealId = null;
                    this.fileId = itemWithoutFile?.fileId;
                    this.appealAccept = itemWithoutFile?.appealAccept;
                }
            }
            await this.$refs["appeal-dialog"].openDialog();
        },
        saveValue(e) {
            let item = this.serverData.find(el => el.id === e.form5Id);
            if (!item){
                item = this.serverData.find(el => el.sprKcpGroup?.id === e.kcpGroup.id);
                item.id = e.form5Id;
            }
            const appeal = this.files.find(el => el.jsonId === e.dataField)
            const el = {
                dataField: e.dataField,
                fileId: e.fileId,
                decision: e.decision,
                accept: e.accept,
                appeal: e.appeal
            };
            if (!appeal) {
                this.files.push(el);
            }
            this.pushNewElement({...e, ...el});
            item[e.dataField] = {oldValue: e.oldValue, newValue: e.newValue, id: e.id, accept: e.accept};
            this.$refs["appeal-dialog"].closeDialog();
        }
    }
}