<template>
  <div>
    <!--  <cardTopCustomIndicatorDetail :row-of-values="rowOfValues" :item="item"/>-->
    <card-top-description v-if="item.dataFieldName !== 'generalValue'" :item="item" :row-of-values="rowOfValues"/>
    <card-top-tables :item="item" :row-of-values="rowOfValues" @appeal-indicator="$emit('appeal-indicator', $event)"/>
    <card-bottom v-if="item.dataFieldName  !== 'generalValue'" :available="available" :row-of-values="rowOfValues" :item="item" @appeal-indicator="$emit('appeal-indicator', $event)"/>
  </div>
</template>

<script>
import CardBottom from "@/components/cards/indicatiors/details/cardBottom";
import CardTopDescription from "@/components/cards/indicatiors/details/cardTopDescription";
import CardTopTables from "@/components/cards/indicatiors/details/cardTopTables";

export default {
  name: "containerIndicatorBody",
  components: {
    CardTopTables,
    CardTopDescription,
    CardBottom,
    // cardTopCustomIndicatorDetail
  },
  props: {
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
    available: {
      type: Array,
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
}
</script>

<style scoped>

</style>