export default {
    countCells: 7,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 4,
            text: 'Код и наименование УГСН (НПС)',
            class: 'd-flex flex-row align-center justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
            // template: (row) => {
            //     return row.sprKcpGroupId + ' - ' + row.sprKcpGroupName
            // }
        },
        {
            dataField: 'npg11',
            cols: 6,
            rows: 1,
            text: 'Численность выпускников (искл. продолживших обучение)',
            headerClass: 'border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg12',
            cols: 3,
            rows: 1,
            text: 'всего',
            headerClass: 'text-center border-bottom-zero',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 3,
            rows: 1,
            text: "из них - трудоустроенных",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },

        {
            dataField: 'npg22',
            cols: 1,
            rows: 1,
            text: `в 2017 г.`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg23',
            cols: 1,
            rows: 1,
            text: `в 2018 г.`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg24',
            cols: 1,
            rows: 1,
            text: `в 2019 г.`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg25',
            cols: 1,
            rows: 1,
            text: `в 2018 г.`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg26',
            cols: 1,
            rows: 1,
            text: `в 2019 г.`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg27',
            cols: 1,
            rows: 1,
            text: `в 2020 г.`,
            headerClass: 'text-center border-bottom-zero',
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg31',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg32',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg33',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg34',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg35',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg36',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row text-left',
            text: `1`,
            order: 6,
            variable: true,
        },
        {
            dataField: 'nvtrs17Mtr',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `3`,
            order: 7,
            variable: true,
        },
        {
            dataField: 'nvtrs18Mtr',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `4`,
            order: 8,
            variable: true,

        },
        {
            dataField: 'nvtrs19Mtr',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `5`,
            order: 9,
            variable: true,
        },
        {
            dataField: 'nttrs17Mtr',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `6`,
            order: 7,
            variable: true,
        },
        {
            dataField: 'nttrs18Mtr',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `7`,
            order: 8,
            variable: true,
        },
        {
            dataField: 'nttrs19Mtr',
            cols: 1,
            rows: 1,
            headerClass: 'pa-0 font-size-small__important',
            text: `8`,
            order: 9,
            variable: true,
        },
    ]
}
