<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      </v-col>
    </v-row>
    <v-row dense v-if="rowOfValues.data.isbsm22 === '1'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.14. (фрагмент)</span><br>
          Дополнительные сведения о приеме на очную форму обучения по программам магистратуры
          <br>
          без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом,
          обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование
          иностранных граждан и лиц без гражданства в Российской Федерации
          <br>
          по данным Мониторинга приемной кампании, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isbsm22 === '1'">
      <thead>
      <tr>
        <th rowspan="4" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th rowspan="3" colspan="1"></th>
        <th colspan="2">Подано заявлений о приеме</th>
        <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr>
        <th colspan="2">
          в 2021/2022 учебном году
        </th>
      </tr>
      <tr>
        <th>
          всего
        </th>
        <th>
          от выпускн. <br>др.орг-ций
        </th>
      </tr>
      <tr>
        <th>
          ...
        </th>
        <th>
          очно
        </th>
        <th>
          очно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th>...</th>
        <th v-for="(item) in 2" :key="item">{{ item + 5 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>...</td>
        <td>  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qasgo21, 0, 'integer') }}</td>
        <td>  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qadro21, 0, 'integer') }}</td>

      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ЗМ
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ЗМД
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense v-if="rowOfValues.data.isbsm22 === '0'">
      <v-col cols="10">
      <p>
          <span class="font-weight-bold mb-1">Форма 5.17. (фрагмент)</span><br>
        Дополнительные сведения о целевом обучении и о приеме на очную форму обучения по программам ординатуры, программам ассистентуры-стажировки
          <br>
        без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации
        <br>
        по данным  Мониторинга приемной кампании, человек
      </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isbsm22 === '0'">
      <thead>
      <tr>
        <th rowspan="4" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th rowspan="3" colspan="1"></th>
        <th colspan="2">Подано заявлений о приеме</th>
        <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr>
        <th colspan="2">
          в 2021 г.
        </th>
      </tr>
      <tr>
        <th>
          всего
        </th>
        <th>
          от выпускн. <br>др.орг-ций
        </th>
      </tr>
      <tr>
        <th>
          ...
        </th>
        <th>
          очно
        </th>
        <th>
          очно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th>...</th>
        <th v-for="(item) in 2" :key="item">{{ item + 5 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>...</td>
        <td>  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qasgo21, 0, 'integer') }}</td>
        <td>  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qadro21, 0, 'integer') }}</td>

      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ЗМ
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ЗМД
        </td>
      </tr>
      </tbody>
    </table>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          ЗМД
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">ЗМ</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction-simple :need-bottom-line="false">
        <template>
          <span class="alternate-text mt-1">× 100</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qadro21, 0, 'integer') }}
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qasgo21, 0, 'integer') }}</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction-simple :need-bottom-line="false">
        <template>
          <span class="alternate-text mt-1">× 100</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>ЗМД - число заявлений на поступление на I курс очной формы, поданных в отчетном году лицами с дипломом бакалавра, специалиста, магистра, полученном в другой образовательной организации</p>
    <p class="mb-4">ЗМ - всего заявлений на поступление на I курс очной формы, поданных в отчетном году</p>

  </div>
</template>

<script>
export default {
  name: "m11",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>

</style>