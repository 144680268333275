<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
      </v-col>
    </v-row>
    <template v-if="rowOfValues.data.isdpo22 === '1'">
      <v-row dense>
        <v-col cols="10">
          <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
          <p>
            <span class="font-weight-bold mb-1">Форма 5.08. (фрагмент)</span><br>
            Численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры<br>
            по данным формы ВПО-1, человек
          </p>
        </v-col>
      </v-row>
      <table>
        <thead>
        <tr>
          <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
          <th rowspan="3">...</th>
          <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
          <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
          <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
          <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
        </tr>
        <tr>
          <th colspan="3">
            на 1.10.2021 г.
          </th>
          <th colspan="3">
            на 1.10.2021 г.
          </th>
        </tr>
        <tr>
          <th>
            очно
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th>
            очно
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>
          <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
            очно<br/>
            <span class="font-weight-bold">гр.6 + гр.9</span>
          </th>
          <th rowspan="2" class="border-right-zero" style="border-bottom: 0">

            очно-заочно<br/>
            <span class="font-weight-bold">гр.7 + гр.10</span>
          </th>
          <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
            заочно<br/>
            <span class="font-weight-bold">гр.8 + гр.11</span>
          </th>
        </tr>
        <tr style="border-top: 1px solid #B7CED9">
          <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
          <th>...</th>
          <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Бакалавриат</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td :rowspan="(rowOfValues.data.tabB23usl1.length === 0 ? 1: rowOfValues.data.tabB23usl1.length) + (rowOfValues.data.tabB23usl2.length === 0 ? 1: rowOfValues.data.tabB23usl2.length)
          + (rowOfValues.data.tabB23usl3.length  === 0 ? 1 : rowOfValues.data.tabB23usl3.length) + 3"
              class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')"
                                                                alt=""/></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
        </tr>
        <template v-if="rowOfValues.data.tabB23usl1.length > 0">
          <tr v-for="row in rowOfValues.data.tabB23usl1" :key="row.id">
            <td colspan="3" style="text-align: left">
              <span class="kcp-code">{{ row.kcpGroup.code }}</span>
              <span class="kcp-name">{{ row.kcpGroup.name }}</span>
            </td>
            <td>...</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer') }}</td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougo21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougv21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougz21, 0, 'integer')
              }}
            </td>
          </tr>
        </template>
        <zero-rows v-else :colspan="10">нет сведений о реализации образовательных программ</zero-rows>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Специалитет</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
        </tr>
        <template v-if="rowOfValues.data.tabB23usl2.length > 0">
          <tr v-for="row in rowOfValues.data.tabB23usl2" :key="row.id">
            <td colspan="3" style="text-align: left">
              <span class="kcp-code">{{ row.kcpGroup.code }}</span>
              <span class="kcp-name">{{ row.kcpGroup.name }}</span>
            </td>
            <td>...</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer') }}</td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougo21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougv21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougz21, 0, 'integer')
              }}
            </td>
          </tr>
        </template>
        <zero-rows v-else :colspan="10">нет сведений о реализации образовательных программ</zero-rows>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Магистратура</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
        </tr>
        <template v-if="rowOfValues.data.tabB23usl3.length > 0">
          <tr v-for="row in rowOfValues.data.tabB23usl3" :key="row.id">
            <td colspan="3" style="text-align: left">
              <span class="kcp-code">{{ row.kcpGroup.code }}</span>
              <span class="kcp-name">{{ row.kcpGroup.name }}</span>
            </td>
            <td>...</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer') }}</td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougo21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougv21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougz21, 0, 'integer')
              }}
            </td>
          </tr>
        </template>
        <zero-rows v-else :colspan="10">нет сведений о реализации образовательных программ</zero-rows>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
          <td class="border-bottom-zero border-right-zero border-left-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
          <td colspan="4" class="border-bottom-zero border-right-zero text-right">суммы по формам обучения:</td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodao21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodav21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodaz21, 0, 'integer') }}
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
          <td colspan="4" class="border-bottom-zero border-right-zero text-right"></td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            No
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            Nv
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            Nz
          </td>
        </tr>
        </tbody>
      </table>
      <v-row dense>
        <v-col cols="10">
          <p>
            <span class="font-weight-bold mb-1">Форма 5.15. (фрагмент)</span><br>
            Численность обучающихся по программам ординатуры, программам ассистентуры-стажировки<br>
            по данным формы 1-НК, человек
          </p>
        </v-col>
      </v-row>
      <table>
        <thead>
        <tr>
          <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
          <th rowspan="3">...</th>
          <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
          <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
          <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
          <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
        </tr>
        <tr>
          <th colspan="3">
            на 1.10.2021 г.
          </th>
          <th colspan="3">
            на 1.10.2021 г.
          </th>
        </tr>
        <tr>
          <th>
            очно
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th>
            очно
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>
          <th rowspan="2" style="border-bottom: 0">
            очно<br/>
            <span class="font-weight-bold">гр.6 + гр.9</span>
          </th>
          <th rowspan="2" style="border-bottom: 0">

            очно-заочно<br/>
            <span class="font-weight-bold">гр.7 + гр.10</span>
          </th>
          <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
            заочно<br/>
            <span class="font-weight-bold">гр.8 + гр.11</span>
          </th>
        </tr>
        <tr style="border-top: 1px solid #B7CED9">
          <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
          <th>...</th>
          <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Ординатура</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td :rowspan="(rowOfValues.data.tabB23usl12.length === 0 ? 1: rowOfValues.data.tabB23usl12.length) + (rowOfValues.data.tabB23usl9.length === 0 ? 1: rowOfValues.data.tabB23usl9.length) + 2"
              class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')"
                                                                alt=""/></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
        </tr>
        <template v-if="rowOfValues.data.tabB23usl9.length > 0">
          <tr v-for="row in rowOfValues.data.tabB23usl9" :key="row.id">
            <td colspan="3" style="text-align: left">
              <span class="kcp-code">{{ row.kcpGroup.code }}</span>
              <span class="kcp-name">{{ row.kcpGroup.name }}</span>
            </td>
            <td>...</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer') }}</td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougo21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougv21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougz21, 0, 'integer')
              }}
            </td>
          </tr>
        </template>
        <zero-rows v-else :colspan="10">нет сведений о реализации образовательных программ</zero-rows>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Ассистентура-стажировка</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
        </tr>
        <template v-if="rowOfValues.data.tabB23usl12.length > 0">
          <tr v-for="row in rowOfValues.data.tabB23usl12" :key="row.id">
            <td colspan="3" style="text-align: left">
              <span class="kcp-code">{{ row.kcpGroup.code }}</span>
              <span class="kcp-name">{{ row.kcpGroup.name }}</span>
            </td>
            <td>...</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer') }}</td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougo21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougv21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougz21, 0, 'integer')
              }}
            </td>
          </tr>
        </template>
        <zero-rows v-else :colspan="10">нет сведений о реализации образовательных программ</zero-rows>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
          <td colspan="3" class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
          <td colspan="4" class="border-bottom-zero border-right-zero text-right">суммы по формам обучения:</td>
          <!--          <td class="border-bottom-zero border-right-zero">-->
          <!--            -->
          <!--          </td>-->
          <td colspan="3" class="border-bottom-zero border-right-zero formul-text-table">
            NOA = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodcs21, 0, 'integer') }}
          </td>
          <!--          <td class="border-bottom-zero border-right-zero">-->
          <!--            -->
          <!--          </td>-->
        </tr>
        </tbody>
      </table>
      <v-row dense>
        <v-col cols="10">
          <p>
            <span class="font-weight-bold mb-1">Форма 5.21. (фрагмент)</span><br>
            Численность прошедших обучение по программам дополнительного профессионального образования<br>
            по данным формы 1-ПК, человек
          </p>
        </v-col>
      </v-row>
      <table>
        <thead>
        <tr>
          <th rowspan="3" colspan="3" class="kcp-td text-left">Виды экономической<br> деятельности (по разделам ОКВЭД)
          </th>
          <th colspan="3">по программам повышения квалификации</th>
          <th colspan="3">по программам профессиональной переподготовки</th>
          <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
          <th rowspan="2" class="border-right-zero" colspan="2">Прошедших обучение по очной форме</th>
        </tr>

        <tr>
          <th colspan="3">
            в 2020 г.
          </th>
          <th colspan="3">
            в 2020 г.
          </th>
          <!--          <th colspan="2">-->
          <!--          </th>-->
        </tr>
        <tr>
          <th>
            все формы
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th>
            все формы
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th rowspan="2" style="border-bottom: 0">
            по программам <br>повышения<br> квалификации
            <br/>
            <span class="font-weight-bold">гр.3 - гр.4 - гр.5</span>
          </th>
          <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
            по программам<br> профессиональной<br> переподготовки
            <br/>
            <span class="font-weight-bold">гр.6 - гр.7 - гр.8</span>
          </th>
        </tr>
        <tr style="border-top: 1px solid #B7CED9">
          <th colspan="3" class="kcp-td" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span>
          </th>
          <th v-for="(item) in 6" :key="item">{{ item + 2 }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <template v-if="rowOfValues.data.tabB23dpo ">
            <td colspan="3" style="text-align: left">
              <span class="kcp-name">{{ rowOfValues.data.tabB23dpo.txdpo22 }}</span>
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvpks21, 0, 'integer')
              }}
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvpkv21, 0, 'integer')
              }}
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvpkz21, 0, 'integer')
              }}
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvpps21, 0, 'integer')
              }}
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvppv21, 0, 'integer')
              }}
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvppz21, 0, 'integer')
              }}
            </td>
            <td class="border-bottom-zero border-right-zero"><img
                :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
            <td class="border-bottom-zero">
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvpko21'), 0, 'integer') }}
            </td>
            <td class="border-bottom-zero border-right-zero">
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvppo21'), 0, 'integer') }}
            </td>
          </template>
          <template v-else>
            <td colspan="12" class="border-right-zero" style="line-height: 20px">нет сведений о реализации
              образовательных программ
            </td>
          </template>

          <!--          <td class="border-bottom-zero">xxx</td>-->
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            КПv
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            КПz
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            ППv
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            ППz
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            КПo
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            ППo
          </td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex flex-row mt-3">
        <div class="d-flex flex-column">
          <div class="formul-text">NP</div>
          <div class="formul-text">ПКП</div>
          <formuls-fraction :need-bottom-line="false">
            ППП
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">=</div>
          <div class="alternate-text">=</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">=</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text text-center"><span class="alternate-text ">1,0 ×</span> No</div>
          <div class="formul-text text-center"><span class="alternate-text ">1,0 ×</span> КПo</div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">1,0 ×</span>ППo</template>
            <template #bottom>очная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text text-center"><span class="alternate-text ">   0,25 ×</span> Nv</div>
          <div class="formul-text text-center"><span class="alternate-text ">   0,25 ×</span> КПv</div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,25 ×</span>ППv</template>
            <template #bottom>очно-заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text"><span class="alternate-text ">   0,1 ×</span> Nz</div>
          <div class="formul-text"><span class="alternate-text ">   0,1 ×</span> КПz</div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,1 ×</span>ППz</template>
            <template #bottom>заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">=</div>
          <div class="alternate-text">=</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">=</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text text-center"><span class="alternate-text ">1,0 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodao21, 0, 'integer') }}
          </div>
          <div class="formul-text text-center"><span class="alternate-text ">1,0 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvpko21'), 0, 'integer') }}
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">1,0 × </span>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvppo21'), 0, 'integer')
              }}
            </template>
            <template #bottom>очная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text text-center"><span class="alternate-text ">   0,25 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodav21, 0, 'integer') }}
          </div>
          <div class="formul-text text-center"><span class="alternate-text ">   0,25 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvpkv21'), 0, 'integer') }}
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,25 ×</span>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvppv21'), 0, 'integer')
              }}
            </template>
            <template #bottom>очно-заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text text-center"><span class="alternate-text ">   0,1 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodaz21, 0, 'integer') }}
          </div>
          <div class="formul-text text-center"><span class="alternate-text ">   0,1 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvpkz21'), 0, 'integer') }}
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,1 ×</span>
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvppz21'), 0, 'integer') }}
            </template>
            <template #bottom>заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">=</div>
          <div class="alternate-text">=</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">=</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text text-center">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodap21, 1, 'float') }}
          </div>
          <div class="formul-text text-center">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvpkp21, 1, 'float') }}
          </div>
          <formuls-fraction class="mr-0" :need-bottom-line="true">
            <template> {{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvppp21, 1, 'float')
              }}
            </template>
            <template #bottom>приведенный
              <br>
              контингент
            </template>
          </formuls-fraction>
        </div>
      </div>
      <p>NP - приведенный контингент обучающихся по программам бакалавриата, специалитета и магистратуры</p>
      <p>ПКП - приведенный контингент обучившихся по программам повышения квалификации</p>
      <p>ППП - приведенный контингент обучившихся по программам профессиональной переподготовки</p>

      <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
      <div class="d-flex flex-row align-center">
        <formuls-fraction :need-bottom-line="true">
          <template>
            <div class="d-flex flex-row align-end">
              {{ item.title }}
              <div class="little-text ">0</div>
            </div>
          </template>
          <template #bottom>значение</template>
        </formuls-fraction>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8 alternate-text"> = </span>
          </template>
        </formuls-fraction>
        <formuls-fraction-simple>
          <template>
            <span class="alternate-text mx-1">0,25  × </span> ПКП <span class="alternate-text mx-1">+</span> ППП
          </template>
          <template #bottom>
            <span class="formul-text" style="  min-height: 49px">NP <span class="alternate-text ">+</span> NOA</span>
          </template>
        </formuls-fraction-simple>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8 alternate-text"> = </span>
          </template>
        </formuls-fraction>
        <formuls-fraction-simple>
          <template>
            <span class="alternate-text mx-1">0,25  × </span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvpkp21, 1, 'float') }} <span
              class="alternate-text mx-1">+</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvppp21, 1, 'float') }}
          </template>
          <template #bottom>
            <span class="formul-text" style="  min-height: 49px"> {{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodap21, 1, 'float')
              }} <span class="alternate-text ">+</span> {{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodcs21, 1, 'float')
              }}</span>
          </template>
        </formuls-fraction-simple>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8 alternate-text"> = </span>
          </template>
        </formuls-fraction>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName + 'b022'], item.prd+1, 'float')
              }}</span>
          </template>
        </formuls-fraction>
      </div>
      <p class="mb-5">Если сумма значений показателей NP и NOA равна нулю, а сумма значений показателей ПКП и ППП больше
        нуля, значение принимается равным 1.</p>
    </template>
    <template v-if="rowOfValues.data.isdpo22 === '2'">

      <v-row dense>
        <v-col cols="10">
          <h3 class="b-indicator__collapse-subtitle mb-1">Расчет параметров по разделам "G", "K", "N" и "O" ОКВЭД,
            поставленных в соответствие с УГСН 38.00.00</h3>
          <p>
            <span class="font-weight-bold mb-1">Форма 5.08. (фрагмент)</span><br>
            Численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры<br>
            по данным формы ВПО-1, человек
          </p>
        </v-col>
      </v-row>
      <table>
        <thead>
        <tr>
          <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
          <th rowspan="3">...</th>
          <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
          <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
          <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
          <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
        </tr>
        <tr>
          <th colspan="3">
            на 1.10.2021 г.
          </th>
          <th colspan="3">
            на 1.10.2021 г.
          </th>
        </tr>
        <tr>
          <th>
            очно
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th>
            очно
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>
          <th rowspan="2" style="border-bottom: 0">
            очно<br/>
            <span class="font-weight-bold">гр.6 + гр.9</span>
          </th>
          <th rowspan="2" style="border-bottom: 0">

            очно-заочно<br/>
            <span class="font-weight-bold">гр.7 + гр.10</span>
          </th>
          <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
            заочно<br/>
            <span class="font-weight-bold">гр.8 + гр.11</span>
          </th>
        </tr>
        <tr style="border-top: 1px solid #B7CED9">
          <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
          <th>...</th>
          <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Бакалавриат (специалитет)</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td rowspan="6" class="border-bottom-zero border-right-zero"><img
              :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
          <td rowspan="2" class=" border-right-zero">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3803.nougo21, 0, 'integer') }}
          </td>
          <td rowspan="2" class=" border-right-zero">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3803.nougv21, 0, 'integer') }}
          </td>
          <td rowspan="2" class=" border-right-zero">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3803.nougz21, 0, 'integer') }}
          </td>
        </tr>

        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px">
            <span class="kcp-code">38.03.00</span>
            <span class="kcp-name">Экономика и управление</span>
          </td>
          <td class="">...</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3803.nosgo21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3803.nosgv21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3803.nosgz21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3803.noqgo21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3803.noqgv21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3803.noqgz21, 0, 'integer') }}</td>

        </tr>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Специалитет</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td rowspan="2" class=" border-right-zero">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3805.nougo21, 0, 'integer') }}
          </td>
          <td rowspan="2" class=" border-right-zero">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3805.nougv21, 0, 'integer') }}
          </td>
          <td rowspan="2" class=" border-right-zero">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3805.nougz21, 0, 'integer') }}
          </td>
        </tr>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="">
            <span class="kcp-code">38.05.00</span>
            <span class="kcp-name">Экономика и управление</span>
          </td>
          <td class="">...</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3805.nosgo21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3805.nosgv21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3805.nosgz21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3805.noqgo21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3805.noqgv21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3805.noqgz21, 0, 'integer') }}</td>
        </tr>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Магистратура</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td rowspan="2" class=" border-right-zero">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3804.nougo21, 0, 'integer') }}
          </td>
          <td rowspan="2" class=" border-right-zero">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3804.nougv21, 0, 'integer') }}
          </td>
          <td rowspan="2" class=" border-right-zero">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3804.nougz21, 0, 'integer') }}
          </td>
        </tr>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px">
            <span class="kcp-code">38.04.00</span>
            <span class="kcp-name">Экономика и управление</span>
          </td>
          <td class="">...</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3804.nosgo21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3804.nosgv21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3804.nosgz21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3804.noqgo21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3804.noqgv21, 0, 'integer') }}</td>
          <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(ugs3804.noqgz21, 0, 'integer') }}</td>

        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
          <td class="border-bottom-zero border-right-zero border-left-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
          <td colspan="4" class="border-bottom-zero border-right-zero text-right">суммы по формам обучения:</td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodeo21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodev21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodez21, 0, 'integer') }}
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
          <td colspan="4" class="border-bottom-zero border-right-zero text-right"></td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">No<span
                class="little-text font-size-extra-small__important" style="line-height:10px; ">1</span></span>
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">Nv<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">1</span></span>
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">Nz<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">1</span></span>
          </td>
        </tr>
        </tbody>
      </table>
      <p class="mb-3 d-flex">
        <span class="d-flex flex-row align-end mr-1">NOA<span class="little-text "
                                                              style="line-height:12px;">1</span></span> = 0 (по
        программам ординатуры и ассистентуры-стажировки нет УГСН 38.00.00)</p>
      <v-row dense>
        <v-col cols="10">
          <p>
            <span class="font-weight-bold mb-1">Форма 5.21. (фрагмент)</span><br>
            Численность прошедших обучение по программам дополнительного профессионального образования<br>
            по данным формы 1-ПК, человек
          </p>
        </v-col>
      </v-row>
      <table>
        <thead>
        <tr>
          <th rowspan="3" colspan="3" class="text-left">Виды экономической<br> деятельности (по разделам ОКВЭД)</th>
          <th colspan="3">по программам повышения квалификации</th>
          <th colspan="3">по программам профессиональной переподготовки</th>
          <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
          <th rowspan="2" class="border-right-zero" colspan="2">Прошедших обучение по очной форме</th>
        </tr>

        <tr>
          <th colspan="3">
            в 2020 г.
          </th>
          <th colspan="3">
            в 2020 г.
          </th>
          <!--          <th colspan="2">-->
          <!--          </th>-->
        </tr>
        <tr>
          <th>
            все формы
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th>
            все формы
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th rowspan="2" style="border-bottom: 0">
            по программам <br>повышения<br> квалификации
            <br/>
            <span class="font-weight-bold">гр.3 - гр.4 - гр.5</span>
          </th>
          <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
            по программам<br> профессиональной<br> переподготовки
            <br/>
            <span class="font-weight-bold">гр.6 - гр.7 - гр.8</span>
          </th>
        </tr>
        <tr style="border-top: 1px solid #B7CED9">
          <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
          <th v-for="(item) in 6" :key="item">{{ item + 2 }}</th>
        </tr>
        </thead>
        <tbody>
        <!--     id !==   437-->
        <tr v-for="(row, index) in rowOfValues.data.tabB23isDpo2Dpo.filter(el => el.kcpGroup.id !== 437)" :key="row.id">
          <td colspan="3" style="text-align: left" class="kcp-td">
            <!--            <span class="kcp-code">01.00.00</span>-->
            <span class="kcp-code">{{ row.kcpGroup.code }}</span>
            <span class="kcp-name">{{ row.kcpGroup.name }}</span>
          </td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nvpks21, 0, 'integer') }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nvpkv21, 0, 'integer') }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nvpkz21, 0, 'integer') }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nvpps21, 0, 'integer') }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nvppv21, 0, 'integer') }}</td>
          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nvppz21, 0, 'integer') }}</td>
          <td v-if="index === 0" :rowspan="rowOfValues.data.tabB23isDpo2Dpo.filter(el => el.kcpGroup.id !== 437).length"
              class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')"
                                                                alt=""/></td>
          <td class="border-right-zero">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.nvpko21, 0, 'integer')
            }}
          </td>
          <td class="border-right-zero">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(row.nvppo21, 0, 'integer')
            }}
          </td>
          <!--          <td class="border-bottom-zero">xxx</td>-->
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvekv21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvekz21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvepv21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvepz21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nveko21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvepo21, 0, 'integer') }}
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">КПv<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">1</span></span>
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">КПz<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">1</span></span>
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">ППv<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">1</span></span>
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">ППz<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">1</span></span>
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">КПo<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">1</span></span>
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">ППo<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">1</span></span>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex flex-row  mt-3">
        <div class="d-flex flex-column">
          <div class="formul-text"><span class="d-flex flex-row align-end">NP<span class="little-text "
                                                                                   style="line-height:12px;">1</span></span>
          </div>
          <div class="formul-text"><span class="d-flex flex-row align-end">ПКП<span class="little-text "
                                                                                    style="line-height:12px;">1</span></span>
          </div>
          <formuls-fraction :need-bottom-line="false">
            <span class="d-flex flex-row align-end">ППП<span class="little-text "
                                                             style="line-height:12px;">1</span></span>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">=</div>
          <div class="alternate-text">=</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">=</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text d-flex"><span class="alternate-text mr-1">1,0 ×</span> <span
              class="d-flex flex-row align-end">No<span class="little-text " style="line-height:12px;">1</span></span>
          </div>
          <div class="formul-text d-flex"><span class="alternate-text mr-1">1,0 ×</span> <span
              class="d-flex flex-row align-end">КПo<span class="little-text " style="line-height:12px;">1</span></span>
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template>
              <span class="alternate-text mr-1">1,0 ×</span>
              <span class="d-flex flex-row align-end">ППo<span class="little-text "
                                                               style="line-height:12px;">1</span></span>
            </template>
            <template #bottom>очная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text d-flex"><span class="alternate-text mr-1">   0,25 ×</span> <span
              class="d-flex flex-row align-end">Nv<span class="little-text " style="line-height:12px;">1</span></span>
          </div>
          <div class="formul-text d-flex"><span class="alternate-text mr-1">   0,25 ×</span> <span
              class="d-flex flex-row align-end">КПv<span class="little-text " style="line-height:12px;">1</span></span>
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,25 ×</span><span class="d-flex flex-row align-end">ППv<span
                class="little-text " style="line-height:12px;">1</span></span></template>
            <template #bottom>очно-заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text d-flex"><span class="alternate-text mr-1">   0,1 ×</span> <span
              class="d-flex flex-row align-end">Nz<span class="little-text " style="line-height:12px;">1</span></span>
          </div>
          <div class="formul-text d-flex"><span class="alternate-text mr-1">   0,1 ×</span> <span
              class="d-flex flex-row align-end">КПz<span class="little-text " style="line-height:12px;">1</span></span>
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,1 ×</span><span class="d-flex flex-row align-end">ППz<span
                class="little-text " style="line-height:12px;">1</span></span></template>
            <template #bottom>заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">=</div>
          <div class="alternate-text">=</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">=</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text"><span class="alternate-text mr-1">1,0 ×</span>{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodeo21, 0, 'integer')
            }}
          </div>
          <div class="formul-text"><span class="alternate-text mr-1">1,0 ×</span>{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nveko21, 0, 'integer')
            }}
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">1,0 ×</span>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvepo21, 0, 'integer')
              }}
            </template>
            <template #bottom>очная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text"><span class="alternate-text mr-1">   0,25 ×</span>{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodev21, 0, 'integer')
            }}
          </div>
          <div class="formul-text"><span class="alternate-text mr-1">   0,25 ×</span>{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvekv21, 0, 'integer')
            }}
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,25 ×</span>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvepv21, 0, 'integer')
              }}
            </template>
            <template #bottom>очно-заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text"><span class="alternate-text mr-1">   0,1 ×</span>{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodez21, 0, 'integer')
            }}
          </div>
          <div class="formul-text"><span class="alternate-text mr-1">   0,1 ×</span>{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvekz21, 0, 'integer')
            }}
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,1 ×</span>
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvepz21, 0, 'integer') }}
            </template>
            <template #bottom>заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">=</div>
          <div class="alternate-text">=</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">=</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text text-center">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodep21, 1, 'float') }}
          </div>
          <div class="formul-text text-center">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvekp21, 1, 'float') }}
          </div>
          <formuls-fraction :need-bottom-line="true" class="mr-0">
            <template>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvepp21, 1, 'float')
              }}
            </template>
            <template #bottom>приведенный
              <br>
              контингент
            </template>
          </formuls-fraction>
        </div>
      </div>
      <p class="d-flex flex-row"><span class="d-flex flex-row align-end">NP<span class="little-text "
                                                                                 style="line-height:12px;">1</span></span>
        - приведенный контингент обучающихся по программам бакалавриата, специалитета и магистратуры</p>
      <p class="d-flex flex-row"><span class="d-flex flex-row align-end">ПКП<span class="little-text "
                                                                                  style="line-height:12px;">1</span></span>
        - приведенный контингент обучившихся по программам повышения квалификации</p>
      <p class="d-flex flex-row mb-5"><span class="d-flex flex-row align-end">ППП<span class="little-text "
                                                                                       style="line-height:12px;">1</span></span>
        - приведенный контингент обучившихся по программам профессиональной переподготовки</p>
      <v-row dense>
        <v-col cols="10">
          <h3 class="b-indicator__collapse-subtitle mb-1">Расчет параметров по разделу "M" ОКВЭД:</h3>
          <p>
            <span class="font-weight-bold mb-1">Форма 5.08. (фрагмент)</span><br>
            Численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры<br>
            по данным формы ВПО-1, человек
          </p>
        </v-col>
      </v-row>
      <table>
        <thead>
        <tr>
          <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
          <th rowspan="3">...</th>
          <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
          <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
          <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
          <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
        </tr>
        <tr>
          <th colspan="3">
            на 1.10.2021 г.
          </th>
          <th colspan="3">
            на 1.10.2021 г.
          </th>
        </tr>
        <tr>
          <th>
            очно
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th>
            очно
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>
          <th rowspan="2" style="border-bottom: 0">
            очно<br/>
            <span class="font-weight-bold">гр.6 + гр.9</span>
          </th>
          <th rowspan="2" style="border-bottom: 0">

            очно-заочно<br/>
            <span class="font-weight-bold">гр.7 + гр.10</span>
          </th>
          <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
            заочно<br/>
            <span class="font-weight-bold">гр.8 + гр.11</span>
          </th>
        </tr>
        <tr style="border-top: 1px solid #B7CED9">
          <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
          <th>...</th>
          <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Бакалавриат</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td :rowspan="(rowOfValues.data.tabB23usl1.length === 0 ? 1: rowOfValues.data.tabB23usl1.length) + (rowOfValues.data.tabB23usl2.length === 0 ? 1: rowOfValues.data.tabB23usl2.length)
          + (rowOfValues.data.tabB23usl3.length  === 0 ? 1 : rowOfValues.data.tabB23usl3.length) + 3"
              class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')"
                                                                alt=""/></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
        </tr>
        <template v-if="rowOfValues.data.tabB23usl1.length > 0">
          <tr v-for="row in rowOfValues.data.tabB23usl1" :key="row.id">
            <td colspan="3" style="text-align: left">
              <span class="kcp-code">{{ row.kcpGroup.code }}</span>
              <span class="kcp-name">{{ row.kcpGroup.name }}</span>
            </td>
            <td>...</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer') }}</td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougo21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougv21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougz21, 0, 'integer')
              }}
            </td>
          </tr>
        </template>
        <zero-rows v-else :colspan="10">нет сведений о реализации образовательных программ</zero-rows>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Специалитет</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
        </tr>
        <template v-if="rowOfValues.data.tabB23usl2.length > 0">
          <tr v-for="row in rowOfValues.data.tabB23usl2" :key="row.id">
            <td colspan="3" style="text-align: left">
              <span class="kcp-code">{{ row.kcpGroup.code }}</span>
              <span class="kcp-name">{{ row.kcpGroup.name }}</span>
            </td>
            <td>...</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer') }}</td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougo21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougv21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougz21, 0, 'integer')
              }}
            </td>
          </tr>
        </template>
        <zero-rows v-else :colspan="10">нет сведений о реализации образовательных программ</zero-rows>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Магистратура</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
        </tr>
        <template v-if="rowOfValues.data.tabB23usl3.length > 0">
          <tr v-for="row in rowOfValues.data.tabB23usl3" :key="row.id">
            <td colspan="3" style="text-align: left">
              <span class="kcp-code">{{ row.kcpGroup.code }}</span>
              <span class="kcp-name">{{ row.kcpGroup.name }}</span>
            </td>
            <td>...</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgz21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgv21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.noqgz21, 0, 'integer') }}</td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougo21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougv21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougz21, 0, 'integer')
              }}
            </td>
          </tr>
        </template>
        <zero-rows v-else :colspan="10">нет сведений о реализации образовательных программ</zero-rows>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
          <td class="border-bottom-zero border-right-zero border-left-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
          <td colspan="4" class="border-bottom-zero border-right-zero text-right">суммы по формам обучения:</td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodao21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodav21, 0, 'integer') }}
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodaz21, 0, 'integer') }}
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
          <td colspan="4" class="border-bottom-zero border-right-zero text-right"></td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">No<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">2</span></span>
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">Nv<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">2</span></span>
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            <span class="d-flex flex-row justify-center align-end">Nz<span
                class="little-text font-size-extra-small__important" style="line-height:10px;">2</span></span>
          </td>
        </tr>
        </tbody>
      </table>
      <v-row dense>
        <v-col cols="10">
          <p>
            <span class="font-weight-bold mb-1">Форма 5.15. (фрагмент)</span><br>
            Численность обучающихся по программам ординатуры, программам ассистентуры-стажировки<br>
            по данным формы 1-НК, человек
          </p>
        </v-col>
      </v-row>
      <table>
        <thead>
        <tr>
          <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
          <th rowspan="3">...</th>
          <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
          <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
          <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
          <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
        </tr>
        <tr>
          <th colspan="3">
            на 1.01.2021 г.
          </th>
          <th colspan="3">
            на 1.01.2021 г.
          </th>
        </tr>
        <tr>
          <th>
            очно
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th>
            очно
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>
          <th rowspan="2" style="border-bottom: 0">
            очно<br/>
            <span class="font-weight-bold">гр.6 + гр.9</span>
          </th>
          <th rowspan="2" style="border-bottom: 0">

            очно-заочно<br/>
            <span class="font-weight-bold">гр.7 + гр.10</span>
          </th>
          <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
            заочно<br/>
            <span class="font-weight-bold">гр.8 + гр.11</span>
          </th>
        </tr>
        <tr style="border-top: 1px solid #B7CED9">
          <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
          <th>...</th>
          <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
            <span class="usl-name">Ассистентура-стажировка</span>
          </td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td class="border-bottom-zero"></td>
          <td :rowspan="(rowOfValues.data.tabB23usl12.length === 0 ? 1: rowOfValues.data.tabB23usl12.length) + 1" class="border-bottom-zero border-right-zero"><img
              :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>

          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
          <td rowspan="1" class="border-bottom-zero border-right-zero"></td>
        </tr>
        <template v-if="rowOfValues.data.tabB23usl12.length > 0">
          <tr v-for="row in rowOfValues.data.tabB23usl12" :key="row.id">
            <td colspan="3" style="text-align: left">
              <span class="kcp-code">{{ row.kcpGroup.code }}</span>
              <span class="kcp-name">{{ row.kcpGroup.name }}</span>
            </td>
            <td>...</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(row.nosgo21, 0, 'integer') }}</td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougo21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougv21, 0, 'integer')
              }}
            </td>
            <td class="border-right-zero">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(row.nougz21, 0, 'integer')
              }}
            </td>
          </tr>
        </template>
        <zero-rows v-else :colspan="10">нет сведений о реализации образовательных программ</zero-rows>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
          <td colspan="3" class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
          <td colspan="4" class="border-bottom-zero border-right-zero text-right">сумма по всем формам обучения:</td>
          <td colspan="3" class="border-bottom-zero border-right-zero formul-text-table">
            NOA2 = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodcs21, 0, 'integer') }}
          </td>

        </tr>
        </tbody>
      </table>
      <v-row dense>
        <v-col cols="10">
          <p>
            <span class="font-weight-bold mb-1">Форма 5.21. (фрагмент)</span><br>
            Численность прошедших обучение по программам дополнительного профессионального образования<br>
            по данным формы 1-ПК, человек
          </p>
        </v-col>
      </v-row>
      <table>
        <thead>
        <tr>
          <th rowspan="3" colspan="3" class="kcp-td text-left">Виды экономической<br> деятельности (по разделам ОКВЭД)
          </th>
          <th colspan="3">по программам повышения квалификации</th>
          <th colspan="3">по программам профессиональной переподготовки</th>
          <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
          <th rowspan="2" class="border-right-zero" colspan="2">Прошедших обучение по очной форме</th>
        </tr>

        <tr>
          <th colspan="3">
            в 2020 г.
          </th>
          <th colspan="3">
            в 2020 г.
          </th>
          <!--          <th colspan="2">-->
          <!--          </th>-->
        </tr>
        <tr>
          <th>
            все формы
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th>
            все формы
          </th>
          <th>
            очно-заочно
          </th>
          <th>
            заочно
          </th>

          <th rowspan="2" style="border-bottom: 0">
            по программам <br>повышения<br> квалификации
            <br/>
            <span class="font-weight-bold">гр.3 - гр.4 - гр.5</span>
          </th>
          <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
            по программам<br> профессиональной<br> переподготовки
            <br/>
            <span class="font-weight-bold">гр.6 - гр.7 - гр.8</span>
          </th>
        </tr>
        <tr style="border-top: 1px solid #B7CED9">
          <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
          <th v-for="(item) in 6" :key="item">{{ item + 2 }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <template v-if="rowOfValues.data.tabB23dpo">
            <td colspan="3" style="text-align: left">
              <span class="kcp-name">{{ rowOfValues.data.tabB23dpo.txdpo22 }}</span>
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvpks21, 0, 'integer')
              }}
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvpkv21, 0, 'integer')
              }}
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvpkz21, 0, 'integer')
              }}
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvpps21, 0, 'integer')
              }}
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvppv21, 0, 'integer')
              }}
            </td>
            <td>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.tabB23dpo.nvppz21, 0, 'integer')
              }}
            </td>
            <td class="border-bottom-zero border-right-zero"><img
                :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
            <td class="border-bottom-zero">
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvpko21'), 0, 'integer') }}
            </td>
            <td class="border-bottom-zero border-right-zero">
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvppo21'), 0, 'integer') }}
            </td>
          </template>
          <template v-else>
            <td colspan="12" class="border-right-zero" style="line-height: 20px">нет сведений о реализации
              образовательных программ
            </td>
          </template>

          <!--          <td colspan="3" style="text-align: left">-->
          <!--            <span class="kcp-name">M. Деятельность профессиональная, научная и техническая</span>-->
          <!--          </td>-->
          <!--          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvpks21, 0, 'integer') }}</td>-->
          <!--          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvpkv21, 0, 'integer') }}</td>-->
          <!--          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvpkz21, 0, 'integer') }}</td>-->
          <!--          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvpps21, 0, 'integer') }}</td>-->
          <!--          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvppv21, 0, 'integer') }}</td>-->
          <!--          <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvppz21, 0, 'integer') }}</td>-->
          <!--          <td class="border-bottom-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>-->
          <!--          <td class="border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvpko21, 0, 'integer') }}</td>-->
          <!--          <td class="border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvppo21, 0, 'integer') }}</td>-->
          <!--          <td class="border-bottom-zero">xxx</td>-->
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
          <td class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
          </td>
        </tr>
        <tr>
          <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            КПv2
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            КПz2
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            ППv2
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            ППz2
          </td>
          <td class="border-bottom-zero border-right-zero">

          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            КПo2
          </td>
          <td class="border-bottom-zero border-right-zero formul-text-table">
            ППo2
          </td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex flex-row mt-3">
        <div class="d-flex flex-column">
          <div class="formul-text"><span class="d-flex flex-row align-end">NP<span class="little-text "
                                                                                   style="line-height:12px;">2</span></span>
          </div>
          <div class="formul-text"><span class="d-flex flex-row align-end">ПКП<span class="little-text "
                                                                                    style="line-height:12px;">2</span></span>
          </div>
          <formuls-fraction :need-bottom-line="false">
            <span class="d-flex flex-row align-end">ППП<span class="little-text "
                                                             style="line-height:12px;">2</span></span>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">=</div>
          <div class="alternate-text">=</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">=</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text d-flex"><span class="alternate-text mr-1">1,0 ×</span> <span
              class="d-flex flex-row align-end">No<span class="little-text " style="line-height:12px;">2</span></span>
          </div>
          <div class="formul-text d-flex"><span class="alternate-text mr-1">1,0 ×</span> <span
              class="d-flex flex-row align-end">КПo<span class="little-text " style="line-height:12px;">2</span></span>
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template>
              <span class="alternate-text mr-1">1,0 ×</span>
              <span class="d-flex flex-row align-end">ППo<span class="little-text "
                                                               style="line-height:12px;">2</span></span>
            </template>
            <template #bottom>очная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text d-flex"><span class="alternate-text mr-1">   0,25 ×</span> <span
              class="d-flex flex-row align-end">Nv<span class="little-text " style="line-height:12px;">2</span></span>
          </div>
          <div class="formul-text d-flex"><span class="alternate-text mr-1">   0,25 ×</span> <span
              class="d-flex flex-row align-end">КПv<span class="little-text " style="line-height:12px;">2</span></span>
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,25 ×</span><span class="d-flex flex-row align-end">ППv<span
                class="little-text " style="line-height:12px;">2</span></span></template>
            <template #bottom>очно-заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text d-flex"><span class="alternate-text mr-1">   0,1 ×</span> <span
              class="d-flex flex-row align-end">Nz<span class="little-text " style="line-height:12px;">2</span></span>
          </div>
          <div class="formul-text d-flex"><span class="alternate-text mr-1">   0,1 ×</span> <span
              class="d-flex flex-row align-end">КПz<span class="little-text " style="line-height:12px;">2</span></span>
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,1 ×</span><span class="d-flex flex-row align-end">ППz<span
                class="little-text " style="line-height:12px;">2</span></span></template>
            <template #bottom>заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">=</div>
          <div class="alternate-text">=</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">=</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text"><span class="alternate-text ">1,0 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodao21, 0, 'integer') }}
          </div>
          <div class="formul-text"><span class="alternate-text ">1,0 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvpko21'), 0, 'integer') }}
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">1,0 ×</span>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvppo21'), 0, 'integer')
              }}
            </template>
            <template #bottom>очная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text"><span class="alternate-text ">   0,25 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodav21, 0, 'integer') }}
          </div>
          <div class="formul-text"><span class="alternate-text ">   0,25 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvpkv21'), 0, 'integer') }}
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,25 ×</span>{{
                $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvppv21'), 0, 'integer')
              }}
            </template>
            <template #bottom>очно-заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">+</div>
          <div class="alternate-text">+</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">+</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text"><span class="alternate-text ">   0,1 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodaz21, 0, 'integer') }}
          </div>
          <div class="formul-text"><span class="alternate-text ">   0,1 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvpkz21'), 0, 'integer') }}
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template><span class="alternate-text mr-1">   0,1 ×</span>
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(checkData('nvppz21'), 0, 'integer') }}
            </template>
            <template #bottom>заочная <br>
              форма
            </template>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column mx-1">
          <div class="alternate-text">=</div>
          <div class="alternate-text">=</div>
          <formuls-fraction :need-bottom-line="false">
            <div class="alternate-text">=</div>
          </formuls-fraction>
        </div>
        <div class="d-flex flex-column">
          <div class="formul-text text-center">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodap21, 1, 'float') }}
          </div>
          <div class="formul-text text-center">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvpkp21, 1, 'float') }}
          </div>
          <formuls-fraction :need-bottom-line="true">
            <template> {{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvppp21, 1, 'float')
              }}
            </template>
            <template #bottom>приведенный
              <br>
              контингент
            </template>
          </formuls-fraction>
        </div>
      </div>
      <p class="d-flex flex-row"><span class="d-flex flex-row align-end">NP<span class="little-text "
                                                                                 style="line-height:12px;">2</span></span>
        - приведенный контингент обучающихся по программам бакалавриата, специалитета и магистратуры</p>
      <p class="d-flex flex-row"><span class="d-flex flex-row align-end">ПКП<span class="little-text "
                                                                                  style="line-height:12px;">2</span></span>
        - приведенный контингент обучившихся по программам повышения квалификации</p>
      <p class="d-flex flex-row mb-5"><span class="d-flex flex-row align-end">ППП<span class="little-text "
                                                                                       style="line-height:12px;">2</span></span>
        - приведенный контингент обучившихся по программам профессиональной переподготовки</p>
      <div class="d-flex flex-row align-center">
        <formuls-fraction :need-bottom-line="true">
          <template>
            <div class="d-flex flex-row align-end">
              {{ item.title }}
              <div class="little-text ">0</div>
            </div>
          </template>
          <template #bottom>значение</template>
        </formuls-fraction>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8 alternate-text"> = </span>
          </template>
        </formuls-fraction>
        <formuls-fraction-simple>
          <template>
            <span class="alternate-text mx-1">0,25  × </span> <span class="d-flex flex-row align-end">ПКП<span
              class="little-text " style="line-height:12px;">1</span></span> <span class="alternate-text mx-1">+</span>
            <span class="d-flex flex-row align-end">ППП<span class="little-text "
                                                             style="line-height:12px;">1</span></span>
          </template>
          <template #bottom>
            <div class="formul-text d-flex align-start" style="  min-height: 49px"><span
                class="d-flex flex-row align-end">NP<span class="little-text " style="line-height:12px;">1</span></span>
              <span class="mx-1 d-flex alternate-text">+</span> <span class="d-flex flex-row align-end">NOA<span
                  class="little-text " style="line-height:12px;">1</span></span></div>
          </template>
        </formuls-fraction-simple>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8 alternate-text"> + </span>
          </template>
        </formuls-fraction>
        <formuls-fraction-simple>
          <template>
            <span class="alternate-text mx-1">0,25  × </span> <span class="d-flex flex-row align-end">ПКП<span
              class="little-text " style="line-height:12px;">2</span></span> <span class="alternate-text mx-1">+</span>
            <span class="d-flex flex-row align-end">ППП<span class="little-text "
                                                             style="line-height:12px;">2</span></span>
          </template>
          <template #bottom>
            <div class="formul-text d-flex align-start" style="  min-height: 49px"><span
                class="d-flex flex-row align-end">NP<span class="little-text " style="line-height:12px;">2</span></span>
              <span class="mx-1 d-flex alternate-text">+</span> <span class="d-flex flex-row align-end">NOA<span
                  class="little-text " style="line-height:12px;">2</span></span></div>
          </template>
        </formuls-fraction-simple>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8 alternate-text"> = </span>
          </template>
        </formuls-fraction>
        <formuls-fraction-simple>
          <template>
            <span class="alternate-text mx-1">0,25  × </span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvekp21, 1, 'float') }} <span
              class="alternate-text mx-1">+</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvepp21, 1, 'float') }}
          </template>
          <template #bottom>
            <span class="formul-text" style="  min-height: 49px"> {{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodep21, 1, 'float')
              }} <span class="alternate-text mx-1">+</span> 0</span>
          </template>
        </formuls-fraction-simple>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8 alternate-text"> + </span>
          </template>
        </formuls-fraction>
        <formuls-fraction-simple>
          <template>
            <span class="alternate-text mx-1">0,25  × </span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvpkp21, 1, 'float') }} <span
              class="alternate-text mx-1">+</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvppp21, 1, 'float') }}
          </template>
          <template #bottom>
            <span class="formul-text" style="  min-height: 49px"> {{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodap21, 1, 'float')
              }} <span class="alternate-text mx-1">+</span> {{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nodcs21, 1, 'float')
              }}</span>
          </template>
        </formuls-fraction-simple>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8 alternate-text"> = </span>
          </template>
        </formuls-fraction>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8">{{
                $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName + 'b022'], item.prd+1, 'float')
              }}</span>
          </template>
        </formuls-fraction>
      </div>
      <p class="mb-3">Если хотя бы для одного вида экономической деятельности сумма значений показателей NP и NOA равна
        нулю, а сумма значений показателей ПКП и ППП больше нуля, значение принимается равным 1.</p>
    </template>
    <block-g :item="item" :row-of-values="rowOfValues"/>
  </div>
</template>

<script>
import BlockG from "@/components/cards/indicatiors/details/blockG";
import ZeroRows from "@/components/cards/indicatiors/details/zeroRows";

export default {
  name: "b23",
  components: {ZeroRows, BlockG},
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  computed: {
    ugs3803() {
      return this.rowOfValues.data.tabB23isDpo2.find(el => el.kcpGroup.id === 100) ?? {
        nougo21: 0,
        nougv21: 0,
        nougz21: 0,
        nosgo21: 0,
        nosgv21: 0,
        nosgz21: 0,
        noqgo21: 0,
        noqgv21: 0,
        noqgz21: 0,
      };
    },
    ugs3804() {
      return this.rowOfValues.data.tabB23isDpo2.find(el => el.kcpGroup.id === 101) ?? {
        nougo21: 0,
        nougv21: 0,
        nougz21: 0,
        nosgo21: 0,
        nosgv21: 0,
        nosgz21: 0,
        noqgo21: 0,
        noqgv21: 0,
        noqgz21: 0,
      };
    },
    ugs3805() {
      return this.rowOfValues.data.tabB23isDpo2.find(el => el.kcpGroup.id === 102) ?? {
        nougo21: 0,
        nougv21: 0,
        nougz21: 0,
        nosgo21: 0,
        nosgv21: 0,
        nosgz21: 0,
        noqgo21: 0,
        noqgv21: 0,
        noqgz21: 0,
      };
    },

  },
  methods: {
    checkData(value) {
      return this.rowOfValues.data.tabB23dpo ? this.rowOfValues.data.tabB23dpo[value] : 0;
    },

  },
}
</script>

<style lang="sass" scoped>
</style>