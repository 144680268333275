<template>
  <article class="applications__card" :class="'application-status_' + item.applicationStatus"
           itemscope
           itemtype="http://schema.org/NewsArticle">

    <div class="applications__item applications__header">
      <div v-if="orgType === 'SubOrg'">
        <div class="applications__title">
          Головная организация:
          <span>{{ item.actualData["Учредитель"] }}</span>
        </div>
        <div class="applications__title">
          Филиал: <span>{{ item.orgName }}</span>
        </div>
      </div>
      <div class="applications__title" v-else>Организация:
        <span>{{ `(ID: ${item.orgID}) ${item.orgName}` }}</span>
      </div>
      <div class="applications__id">ID заявки:<span>{{ item.applicationID }}</span></div>
      <div class="applications__date">Дата создания:<span>{{ createDate }}</span></div>
    </div>


    <div class="applications__item applications__content">
      <div class="applications__type" v-if="orgType === 'SubOrg'">
        <div :class="['application-type', 'application-type_' + item.applicationStatus]">
          {{ item.applicationStatus }} <br> {{item.applicationTypeName}}
        </div>
      </div>
      <button class="white__v-btn v-btn v-btn--contained v-btn--router theme--light v-size--default"
              @click="$emit('aboutApplication', {id: item.applicationID})"><span
          class="v-btn__content">Подробно об изменениях</span></button>
      <!--<button class="button_mini" @click="getDocument">Подтверждающий документ</button>-->
    </div>

    <div class="applications__item applications__actions">
      <div class="cms-card-news__ok" @click="resolve" v-if="item.applicationStatus">
        <v-icon :size="24" color="#ffffff" class="fr-topbar__icon">mdi-check-bold</v-icon>
      </div>
      <div class="cms-card-news__delete" @click="reject" v-if="item.applicationStatus">
        <v-icon :size="24" color="#ffffff" class="fr-topbar__icon">mdi-cancel</v-icon>
      </div>
      <!--      <div class="application-moderate">-->
      <!--        <div class="application__status" v-if="item.applicationStatus">-->
      <!--          {{ item.applicationStatus === 'DONE' ? 'Изменения приняты' : '' }}-->
      <!--          {{ item.applicationStatus === 'REJECT' ? 'Изменения отклонены' : '' }}-->
      <!--        </div>-->
      <!--        <span v-if="editDate" class="application__moderate-time">{{ editDate }}</span>-->
      <!--      </div>-->
    </div>

  </article>

</template>

<script>
import moment from 'moment';
import request from "@/services/request";
import {saveFile} from '@/utils/saveFile';

moment.locale('ru');

export default {
  props: {
    item: {
      type: Object,
    },
    orgType: {
      type: String,
    }
  },
  computed: {
    createDate() {
      return moment(this.item.createDate).format('LLL')
    },
    editDate() {
      if (this.item.editDate) {
        return moment(this.item.editDate).format('LLL')
      } else {
        return '';
      }
    },
  },
  methods: {
    reject() {
      this.$emit('reject', {id: this.item.applicationID});
    },
    resolve() {
      this.$emit('resolve', {id: this.item.applicationID});
    },

    async getDocument() {
      let response = await request({
        endpoint: '/api/fileServer/downloadFileByID',
        method: 'POST',
        // headers: {ResponseType: 'blob'},
        body: {
          fileServerID: this.item.applicationsFiles.fileServerID,
          fileName: this.item.applicationsFiles.fileName,
          fileFormat: this.item.applicationsFiles.fileFormat
        }
      });
      saveFile(response.data);
    },
  },

}
</script>
<style lang="sass">
</style>