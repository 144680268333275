<template>
  <div>
    <v-row dense class="" v-if="rowOfValues.data.orgtp22 === '1'">
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <p class="">
          <span class="font-weight-bold mb-1 ">Форма 5.01а. (фрагмент)</span><br>
          Численность научно-педагогических работников и доходы образовательной организации
          <br>
          по данным формы ВПО-2
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.orgtp22 === '1'">
      <thead>
      <tr>
        <th rowspan="1" colspan="4" class="text-left kcp-td">Показатели</th>
        <th colspan="1">за 2018 г.</th>
        <th colspan="1">за 2019 г.</th>
        <th colspan="1">за 2020 г.</th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span>
          <!--          <span style="margin-left: 66px">2</span>-->
        </th>
        <th >2</th>
        <th >3</th>
        <th >4</th>
        <th colspan="1">5</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" class="border-right-zero" style="text-align: left">
          <span class="kcp-name">Объем поступивших средств по виду деятельности «научные исследования и разработки»</span>
        </td>
        <td>тыс.рублей</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.denir19, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.denir20, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.denir21, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ДН<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2018</span></span>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ДН<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ДН<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
        </td>
      </tr>
      </tbody>
    </table>
     <v-row v-if="rowOfValues.data.orgtp22 === '3'" dense class="">
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <p class="">
          <span class="font-weight-bold mb-1 ">Форма 5.01б. (фрагмент)</span><br>
          Численность научно-педагогических работников и доходы организации дополнительного профессионального образованния
          <br>
          по данным формы 1-ПК
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.orgtp22 === '3'">
      <thead>
      <tr>
        <th rowspan="1" colspan="4">Показатели</th>
        <th colspan="1">за 2018 г.</th>
        <th colspan="1">за 2019 г.</th>
        <th colspan="1">за 2020 г.</th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span>
          <!--          <span style="margin-left: 66px">2</span>-->
        </th>
        <th >2</th>
        <th >3</th>
        <th >4</th>
        <th colspan="1">5</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" class="border-right-zero" style="text-align: left">
          <span class="kcp-name">Объем поступивших средств по виду деятельности «научные исследования и разработки»</span>
        </td>
        <td>тыс.рублей</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.denir19, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.denir20, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.denir21, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ДН<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2018</span></span>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ДН<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ДН<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
        </td>
      </tr>
      </tbody>
    </table>
    <v-row v-if="rowOfValues.data.orgtp22 === '2'" dense class="">
      <v-col cols="10">
        <p class="">
          <span class="font-weight-bold mb-1 ">Форма 5.01в. (фрагмент)</span><br>
          Численность научно-педагогических работников и доходы научной организации
          <br>
          по данным формы 2-наука
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.orgtp22 === '2'">
      <thead>
      <tr>
        <th rowspan="1" colspan="4">Показатели</th>
        <th colspan="1">за 2018 г.</th>
        <th colspan="1">за 2019 г.</th>
        <th colspan="1">за 2020 г.</th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span>
          <!--          <span style="margin-left: 66px">2</span>-->
        </th>
        <th >2</th>
        <th >3</th>
        <th >4</th>
        <th colspan="1">5</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" class="border-right-zero" style="text-align: left">
          <span class="kcp-name">Выполнено работ, услуг, произведено товаров (без НДС, акцизов и других подобных платежей) - исследования и разработки</span>
        </td>
        <td>тыс.рублей</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.dsnir19, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.dsnir20, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.dsnir21, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ДН<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2018</span></span>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ДН<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          <span class="d-flex flex-row justify-center align-end">ДН<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
        </td>
      </tr>
      </tbody>
    </table>
    <block-npr :item="item" :row-of-values="rowOfValues" />
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <div class="d-flex align-center alternate-text mb-5 mr-2" style="line-height: 70px; font-size: 55px">
        (
      </div>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ДН
            <div class="little-text ">2018</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              НПР
              <div class="little-text ">2018</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
       <template>
          <span class="mt-7 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ДН
            <div class="little-text ">2019</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              НПР
              <div class="little-text ">2019</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
       <template>
          <span class="mt-7 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            ДН
            <div class="little-text ">2020</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              НПР
              <div class="little-text ">2020</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <div class="d-flex align-center alternate-text ml-2 mb-5" style="line-height: 70px; font-size: 55px">
        )
      </div>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 ml-1 alternate-text"> ÷</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" class="mr-1">
        <template>
          <span class="mt-7 ">n</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 ml-1 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <div class="d-flex align-center alternate-text mb-5 mr-2" style="line-height: 70px; font-size: 55px">
        (
      </div>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.dnorg19, 1, 'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr19, 1, 'float', true, '', (v) => {
              return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
       <template>
          <span class="mt-7 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.dnorg20, 1, 'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr20, 1, 'float', true, '', (v) => {
              return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
       <template>
          <span class="mt-7 alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.dnorg21, 1, 'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnpr21, 1, 'float', true, '', (v) => {
              return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <div class="d-flex align-center alternate-text ml-2 mb-5" style="line-height: 70px; font-size: 55px">
        )
      </div>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 ml-1 alternate-text"> ÷</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" class="mr-1">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.wpnprQ3, 0, 'integer') }}</span>
        </template>
      </formuls-fraction>

      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>ДНk	-	объем поступлений от выполнения НИОКР в k-ом году</p>
    <p>НПРk	-	численность научно-педагогических (научных) работников в k-м году</p>
    <p class="mb-4">n	-	число лет, за которые имеются отчетные данные НПРk</p>
    <block-g :item="item" :row-of-values="rowOfValues"/>
  </div>
</template>

<script>
import BlockNpr from "@/components/cards/indicatiors/details/blockNpr";
import BlockG from "@/components/cards/indicatiors/details/blockG";
export default {
  name: "b42",
  components: {BlockG, BlockNpr},
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
}
</script>

<style lang="sass" scoped>

</style>