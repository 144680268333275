import axios from 'axios'
import mapper from '@/services/mapper.js'
import utils from "@/utils/utils";
/**
* [request description]
* @param {Object} options содержит свойства endpoint, method, body, path, model, headers
* @return {Object} возвращает promise
* */
async function request (options) {
	const {endpoint, method, body, path, model, headers} = options;
	let response;
		try {
			if (method.toLowerCase() === 'get') {
				response = await axios.get(endpoint, {headers: {...headers, ...utils.authHeader() }});
			} else if (method.toLowerCase() === 'post') {
				if (headers) {
					response = await axios.post(endpoint, body, {headers: {...headers, ...utils.authHeader() } });
				} else {
					response = await axios.post(endpoint, body, {headers: utils.authHeader()});
				}
			}
			else if (method.toLowerCase() === 'put') {
				if (headers) {
					response = await axios.put(endpoint, body, {headers: {...headers, ...utils.authHeader() } });
				} else {
					response = await axios.put(endpoint, body, {headers: utils.authHeader()});
				}
			} else 	if (method.toLowerCase() === 'delete')
				response = await axios.delete(endpoint, {headers: {...headers, ...utils.authHeader() }});
			if (response.status !== 200 && response.status !== 201) {
				throw new Error(response.statusText);
			}
			// Если нужно маппить
			if (model) {
				let formatData;
				if (path) {
					formatData = await mapper(response.data[path], model);
				} else {
					formatData = await mapper(response.data, model);
				}
				const counterData = response.data.counter;
				return {data: formatData, counter: counterData};
			} else {
				return response;
			}
		} catch(e) {
			// console.error(e);
			return {error: e}
		}
}

export default request;