<template>
  <v-row dense class="cms-card-news"
         :class="konvertClass"
         itemscope
         itemtype="http://schema.org/NewsArticle"
  >
    <v-col class="applications__header">
      <div class="applications__title"><span>Организация:</span>
        <!--<router-link :to="'/kcp-contest-admin/reference/' + item.sprOrg.orgID"> -->{{ item.orgName }} (id:
        {{ item.orgId }})<!--</router-link>--></div>
      <!--    <div class="applications__id"><span>ID конверта:</span> {{item.konvertNumber}}</div>-->
      <div class="applications__date"><span>Дата отправки:</span> {{ sendDate }}</div>
    </v-col>

    <v-col class="application__file">
      <div v-if="item.konvertFile" @click="getDocument" class="application__file_link">Загруженная заявка на конкурс
        (.pdf)
      </div>
      <div v-else class="application__file_link">Файл отсутсвует</div>
    </v-col>
    <v-col>
      <div>Заблокирован: {{item.block ? 'да' : 'нет'}}</div>
      <div>Отправлен конверт: {{item.konvert ? 'да' : 'нет'}}</div>
    </v-col>
    <v-col cols="1">    <v-btn @click="accept()" class="blue__v-btn">Решение</v-btn></v-col>
    <v-col class="applications__actions">
<!--      <div v-if="item.konvertAccept === null">-->

<!--      </div>-->

      <div class="application-moderate">
        <div class="application__status" v-if="item.konvertAccept === true || item.konvertAccept === false">
          <div class="convert_received">Конверт {{ item.konvertAccept === true ? 'принят' : 'отклонен'}}</div>
          <div class="convert_span">ФИО: {{ item.fio }}</div>
          <div class="convert_span">ID: {{ item.konvertNumber }}</div>
          <div class="convert_span">Должность: {{ item.position }}</div>
          <div class="convert_span">Дата: {{ receiptDate }}</div>
        </div>
      </div>
    </v-col>
  </v-row>


</template>

<script>
import moment from 'moment';

moment.locale('ru');
import {getFileFromServer} from "@/mixins/getFile";

export default {
  props: {
    item: {
      type: Object,
    }
  },
  mixins: [getFileFromServer],
  computed: {
    konvertClass(){
      switch (this.item.konvertAccept) {
        case null:
          return 'application-status_ACTIVE'
        case true:
          return 'application-status_DONE'
        case false:
          return 'application-status_REJECT'
        default:
          return 'application-status_ACTIVE'
      }
    },
    sendDate() {
      return this.item.konvertDate ? moment(this.item.konvertDate).format('LLL'): 'Остутсвует'
    },
    receiptDate() {
      if (this.item.konvertAcceptDate) {
        return moment(this.item.konvertAcceptDate).format('LLL')
      } else {
        return '';
      }
    },
  },
  methods: {
    accept() {
      this.$emit('acceptConvert',  this.item.orgId);
    },

    getDocument() {
      this.getFile(this.item.konvertFile.id)
    }
  },

}
</script>
<style lang="sass">
.cms-card-news__actions
  display: flex

.cms-card-news
  background: #fff
  margin-bottom: 15px
  padding: 5px 10px
  display: flex
  justify-content: space-between
  align-items: center
  border-left: 3px solid #00599b
  transition: 0.3s

.cms-card-news__header
  flex-grow: 0
  flex-basis: 700px
  overflow: hidden

.application-change__title
  font-weight: 500
  color: #eb8e1c

.cms-card-news:hover
  transform: translateY(-5px)
  box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
  transition: 0.3s

.cms-card-news__ok
  background: #41cf8f
  padding: 3px
  border-radius: 3px
  border: 1px solid #129a5d
  cursor: pointer

.cms-card-news__ok:hover
  background: #108751

.cms-card-news__edit:hover
  background: #ffeeba

.cms-card-news__delete
  background: #ce303f
  padding: 3px
  border-radius: 3px
  border: 1px solid #8c0c1a
  cursor: pointer

.cms-card-news__delete:hover
  background: #4c040b

.application-status_ACTIVE
  background: #fff

.application-change-content__field
  display: flex

.application-change-content__label
  white-space: nowrap
  color: #00599b
  font-weight: 500
  margin-right: 15px

.application-status_DONE
  .application__status
    color: #155724!important
    background: #d4edda!important
    border: 1px solid #c3e6cb!important

.application-status_REJECT
  .application__status
    color: #721c24!important
    background: #f8d7da!important
    border: 1px solid #f5c6cb!important

.applications__actions
  min-width: 200px

.application__status
  text-align: center
  font-weight: 500
  padding: 5px
  border-radius: 5px
  min-width: 190px

.applications__actions
  min-width: 200px
  display: flex
  justify-content: center
  align-items: center

.cms-card-news__ok
  margin-right: 5px

.application__moderate-time
  font-size: 12px

.application-moderate
  text-align: center

.application-status_DONE, .application-status_REJECT
  opacity: 0.7

.application-status_DONE:hover, .application-status_REJECT:hover
  opacity: 1

.applications__header span
  background: #e3effb
  color: #00599b
  padding: 1px 7px
  border-radius: 3px
  margin-right: 10px
  display: inline-block
  white-space: nowrap
  margin-bottom: 5px

.applications__content
  text-align: center

.application__file_link
  color: #0d72bd
  text-decoration: underline
  cursor: pointer

.convert_span
  font-size: 12px
  font-weight: 400

.application__status
  max-width: 225px
  background: #EBF9EB
  color: #29A128
  border: 1px solid #8FE28E
  border-radius: 10px
</style>