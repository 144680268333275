<template>
  <div>
    <div :class="list.headerTextClass" class="nav-section__general" @click="hide = !hide">
      <div v-if="list.icon">
        <img v-if="!list.icon.isMdi" alt="" :src="require(`/src/assets/icons/${list.icon.src}`)"
             :class="list.icon.classes">
        <v-icon v-else :class="list.icon.classes" :color="list.icon.color">{{ list.icon.name }}
        </v-icon>
      </div>
      <div class="">{{ list.headText }}</div>
    </div>
    <transition name="fade">
      <ul class="nav-section pt-2" v-if="!hide">
        <li v-for="(item, i) in list.children" :class="item.class" :key="i">
          <router-link
              v-if="!item.linkToOtherSite && !item.action"
              :to="item.link"
              class="nav-section__item " active-class="nav-section__item-active"
          >
            <template v-if="item.icon">
              <img v-if="!item.icon.isMdi" alt="" :src="require(`/src/assets/icons/${item.icon.src}`)"
                   :class="item.icon.classes">
              <v-icon v-else :class="item.icon.classes" :color="item.icon.color">{{ item.icon.name }}
              </v-icon>
            </template>
            <span>
              {{ item.text }}
          </span>
          </router-link>
          <a v-else-if="item.linkToOtherSite && !item.action" :href="item.link" class="nav-section__item"
             target="_blank">{{ item.text }}
          </a>
          <div
              v-else-if="item.action"
              class="nav-section__item"
              :class="{
              [item.itemClass]: true,
            'nav-section__item-active': item.active
            }"

              @click="$emit('click-nav-item', item)"
          >
            {{ item.text }}
          </div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: "navList",
  props: {
    list: {
      type: [Array, Object],
      default: null
    },
  },
  data() {
    return {
      hide: !this.list?.active
    }
  },
}
</script>

<style scoped>
.fade-enter-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>