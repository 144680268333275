<template>
  <div>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <GridTable
          :data-rows="showingServerData"
          :headers="headers.massive"
          :headers-for-rows="headersForRows"
          :loading-data="loadingData"
          :num-cols="headers.countCells"
          :max-table-height="maxTableHeight"
          ref="npg-table"
          :key="'form-3'"
      >
        <template #header-npg0="{item}">
          <v-btn  @click="loadFullInfo" class="white__v-btn">{{ !full ? 'Показать все' : "Скрыть пустые" }}  строки </v-btn>
          {{item.text}}
        </template>
        <!--  v-if="!changingHeaders"  :key='' когда меняется хедер проверить    -->
        <template v-for="item in headersForRows" v-slot:[calcHeaderName(item.dataField)]>
          <div :key="item.dataField" :class="item.headerClass" style="max-height: 22px;min-width: 100%">
            <template v-if="item.text === '1'">
              <div style="min-width: 60px">1</div>
              <div class="text-center" style="width: 100%">2</div>
            </template>
            <template v-else>
              <div class="d-flex flex-row align-center" style="max-height: 22px;">
                {{ item.text }}
               </div>
            </template>
          </div>
        </template>
        <template #npg="{row}">
          <template v-if="row.uslName">
            <div class="font-weight-bold font-size-medium ">
              {{ row.uslName[0].toUpperCase() + row.uslName.slice(1) }}
            </div>
          </template>
          <template v-else>
            <div><v-btn icon :loading="deleteLoading" :disabled="simpleBlock || row.id == null" @click="deleteRow(row)"><v-icon color="error" size="20">mdi-close</v-icon> </v-btn></div>
            <div style="">
              {{ row.sprSpec ? row.sprSpec.kodSpec : row.sprKcpGroup.code }}
            </div>
            <div class="ml-3">
              {{ row.sprSpec ? row.sprSpec.name : row.sprKcpGroup.name }}
            </div>
          </template>
        </template>
        <template v-for="item in headersForRowsWithEditableCells" v-slot:[item.dataField]="{row,cell}">
          <threeFourTableCell :key="item.dataField" v-bind="item.props(row,cell, loadingMenu)"
                            @open-dialog="openDialog($event, item.dataField, cell, row)"/>
        </template>
      </GridTable>
      <div class='font-size-small' style="color: #89A0B0;">
        Примечание: сведения о наличии лицензий внесены на основании данных конкурса прошлого года
       </div>
      <div class="mt-3 d-flex flex-row justify-center align-center">
        <v-autocomplete
            class="pagination-table"
            v-model="userPages"
            :items="pagesPaginate"
            solo
            dense
            filled
            height="25"
            rounded
            style="max-width: 150px"
            @change="changePage($event)"
        ></v-autocomplete>
        <v-pagination
            v-model="page"
            :length="pagesVuetify"
            :total-visible="7"
            @input="changePage($event)"
        >
        </v-pagination>
      </div>
    </WrapperPreviewCategory>
    <tooltip-forms5-dialog
        ref="tooltip-dialog"
        :data-field="dataFieldTooltip"
    >
    </tooltip-forms5-dialog>
<!--    <edit-forms34-dialog-->
<!--        ref="edit-dialog"-->
<!--        :row="row"-->
<!--        :value="value"-->
<!--        :data-field="dataField"-->
<!--        :api="editApi"-->
<!--        :type-value="typeValue"-->
<!--        :edit-info="editInfo"-->
<!--        :rules="rules"-->
<!--        @saved-value="saveValue($event)"-->
<!--        @closing-dialog="closingEditDialog"-->
<!--    ></edit-forms34-dialog>-->
    <universal-dialog-v1
        ref="dialog"
        :data-for-dialog="dataForDialog"
        :headers-for-dialog="headersForDialog"
        @fetch-new-data="fetchDialogData($event)"
        :loading="savingData"
        :additional-data="additionalData"
        @closing-dialog="clearDataForDialog()"
    >
      <template #title>
        <h2>{{ mainHeaderTitle ? mainHeaderTitle : 'Добавить' }}</h2>
      </template>
      <template #actions="{close, sendData, loading, validSolo, isOneStage}">
        <div class="d-flex flex-row align-center justify-center" style="width: 100%">
          <v-btn
              class='white__v-btn'
              @click="close()"
              width="120px"
          >
            Отменить
          </v-btn>
          <v-btn
              v-if="isOneStage"
              class='blue__v-btn  ml-2'
              @click="sendData()"
              :loading="loading"
              :disabled="!validSolo"
              width="120px"
          >
            Отправить
          </v-btn>
        </div>
      </template>
    </universal-dialog-v1>
  </div>
</template>

<script>
import GridTable from "@/components/GridTable/GridTable";
import form3 from "@/helpers/forms2-4/form3";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {getFormData} from "@/API/form5";
import {mapGetters} from "vuex";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import {setEditDialog} from "@/mixins/forms2-4/editDialog";
import threeFourTableCell from "@/components/fields/threeFourTableCell";
// import EditForms34Dialog from "@/components/Dialogs/editFormsThreeFourDialog";
import {tablePagination} from "@/mixins/tablePagination";
import UniversalDialogV1 from "@/components/Dialogs/universalDialog(v1.1)";
// import request from "@/services/request";
import {sendFormData} from "@/API/forms2-4";
import request from "@/services/request";
export default {
  name: "form3",
  mixins: [headerTooltipFunctions, setEditDialog,tablePagination],
  // eslint-disable-next-line vue/no-unused-components
  components: {
    UniversalDialogV1,
    // EditForms34Dialog,
    WrapperPreviewCategory,
    GridTable,
    tooltipForms5Dialog,
    threeFourTableCell
  },
  data() {
    return {
      serverData: [],
      loadingData: false,
      changingHeaders: false,
      deleteLoading: false,
      headers: form3,
      editApi: '/api/form3/set',
      full : false,
      savingData : false,
      dataForDialog: null,
      additionalData: null,
      mainHeaderTitle: '',
      headersForDialog: [
        {
          "text": "Регистрационный номер",
          "dataField": "glsnm22K21",
          "typeVar": "string",
          "rules": 'customRules',
          "customsRulesArray": [
            v => !!v || 'введите значение',
          ],
          "order": 0,
        },
        {
          "text": "Дата выдачи лицензии",
          "dataField": "gldtb22K21",
          "typeVar": "date",
          "rules": 3,
          "order": 2,
        },
        {
          "text": "Примечания",
          "dataField": "gltxt22K21",
          "typeVar": "string",
          "rules": 0,
          "order": 4,
        },
      ]
    }
  },
  created() {
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    },

  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_usl_error: 'get_spr_data_usl_error',
    }),
    ...mapGetters('checkFilled', {simpleBlock: 'simpleBlock'}),
    maxTableHeight() {
      return window.screen.availHeight - 350 + 'px'
    },
    headersForRows() {
      return this.headers.massive.filter(el => el.variable).map(el => {
        return {
          ...el,
          styles: (row) => {
            if (el.dataField !== 'npg')
              return row.uslName ? 'display: none;' : 'justify-content: unset; text-align: center; '
            else
              return ''
          },
          colsDataCalc: (row) => {
            if (el.dataField !== 'npg')
              return 1;
            else
              return row.uslName ? 4 : 1;
          },
          props: (row, cell, loading) => {
            return {
              value: row[cell.dataField],
              title: '',
              cell: cell,
              id: row.id,
              row: row,
              loading: loading,
            }
          }
        }
      })
    },
  },
  methods: {
    loadFullInfo(){
      this.full = !this.full;
      this.loadingDataTable()
    },
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getFormData('', true, `/api/form3/get?full=${this.full}`);
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },
    async deleteRow(row){
      try{
        this.deleteLoading = true;
        const res = await request({
          endpoint: `/api/form3/delete?rowId=${row.id}`,
          method: 'delete'
        });
        if (res.status === 200){
          const ind = this.serverData.findIndex(  el => el.id === row.id);
          if (ind !== -1)
            this.serverData.splice(ind,  1);
        }
        this.deleteLoading = false;
      }catch (e) {
        console.error('/api/form3/delete', e)
        this.deleteLoading = false;
      }
    },
    clearDataForDialog() {
      this.mainHeaderTitle = '';
      this.dataForDialog = null;
      this.additionalData = null;
    },
    async openDialog(e, dataField, cell, row){
      if (row.sprSpec)
        this.mainHeaderTitle = row.sprSpec?.kodSpec  + ' ' + row.sprSpec?.name;
      else
        this.mainHeaderTitle = row.kcpUgs?.code  + ' ' + row.kcpUgs?.name;
      this.additionalData = {
        id: row?.id ?? null,
        "sprSpec": row?.sprSpec?.id ? {
          "id": row?.sprSpec?.id,
        } : null,
        "kcpUgs": row.kcpUgs?.id ?{
          "id": row.kcpUgs?.id,
        } : null,
        "sprOrgId": row.sprOrgId,
      };
      this.dataForDialog = {
        glsnm22K21: row.glsnm22K21,
        gldtb22K21: row.gldtb22K21,  //date   this.parseDate(
        gltxt22K21: row.gltxt22K21,
      };
      await this.$refs.dialog.openDialog()
    },
    formatDate (date) {
      if (!date) return null;
      const  [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async fetchDialogData(obj) {
      this.savingData = true;
      try {
        const mail = {
          ...obj,
          gltxt22K21: obj.gltxt22K21 ?? '',
          gldtb22K21: obj.gldtb22K21, //this.formatDate(
        }
        const res = await sendFormData(this.editApi, mail);
        if (res) {
          this.savingData = false;
          if (obj.id) {
            const index = this.serverData.findIndex(el => el.id === obj.id);
            this.serverData.splice(index, 1,res) //res.data
          } else {
            const index = this.serverData.findIndex(el => el.sprSpec?.id === obj.sprSpec?.id);
            this.serverData.splice(index, 1, { ...res, sprSpec: this.serverData[index].sprSpec})
          }
          this.dataForDialog = null;
          await this.$refs.dialog.closeDialog();
        }
        this.savingData = false;
      } catch (e) {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения',
          timeout: 3000
        });
        console.log("e", e)
        this.savingData = false;
      }
    }

  },
}
</script>

<style scoped>

</style>