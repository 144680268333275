<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      </v-col>
    </v-row>
    <v-row dense v-if="rowOfValues.data.isnps22 === '0'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.08. (фрагмент)</span><br>
          Численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры
          <br>
          по данным формы ВПО-1, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isnps22 === '0'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Уровни образования</th>
        <th rowspan="3">...</th>
        <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
        <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
        <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
      </tr>
      <tr>
        <th colspan="3">
          на 1.10.2021 г.
        </th>
        <th colspan="3">
          на 1.10.2021 г.
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>

        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th rowspan="2" style="border-bottom: 0">
          очно<br/>
          <span class="font-weight-bold">гр.6 + гр.9</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">

          очно-заочно<br/>
          <span class="font-weight-bold">гр.7 + гр.10</span>
        </th>
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
          заочно<br/>
          <span class="font-weight-bold">гр.8 + гр.11</span>
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th>...</th>
        <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left; min-width: 250px" class="">
          <div class="d-flex flex-column">
            <div class="font-weight-bold">
              <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code.slice(0, 2) }}.00.00</span>
              <span class="kcp-name">{{ rowOfValues.data.kcpGroup.ugsName.slice(0,1) + rowOfValues.data.kcpGroup.ugsName.slice(1).toLowerCase() }}</span>
            </div>
            <div class="kcp-name">
              Магистратура
            </div>
          </div>
        </td>
        <td class="">...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgz21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgz21, 0, 'integer') }}</td>
        <td class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')"
                                                              alt=""/></td>
        <td class="border-right-zero">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgo21, 0, 'integer') }}
        </td>
        <td class="border-right-zero">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgv21, 0, 'integer') }}
        </td>
        <td class="border-right-zero">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgz21, 0, 'integer') }}
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="4" class="border-bottom-zero border-right-zero text-right">Магистратура по формам обучения:</td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NMo
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NMv
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NMz
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense v-if="rowOfValues.data.isnps22 === '1'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.08. (фрагмент)</span><br>
          Численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры
          <br>
          по данным формы ВПО-1, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isnps22 === '1'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Уровни образования. Наименование НПС</th>
        <th rowspan="3">...</th>
        <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
        <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
        <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся по УГСН</th>
      </tr>
      <tr>
        <th colspan="3">
          на 1.10.2021 г.
        </th>
        <th colspan="3">
          на 1.10.2021 г.
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>

        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th rowspan="2" style="border-bottom: 0">
          очно<br/>
          <span class="font-weight-bold">гр.6 + гр.9</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">

          очно-заочно<br/>
          <span class="font-weight-bold">гр.7 + гр.10</span>
        </th>
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
          заочно<br/>
          <span class="font-weight-bold">гр.8 + гр.11</span>
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th>...</th>
        <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left; min-width: 250px" class="border-bottom-zero">
          <div class="d-flex flex-column">
            <div class="font-weight-bold">
              <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code.slice(0, 2) }}.00.00</span>
              <span class="kcp-name">{{ rowOfValues.data.kcpGroup.ugsName.slice(0,1) + rowOfValues.data.kcpGroup.ugsName.slice(1).toLowerCase() }}</span>
            </div>
            <div class="kcp-name">
              Магистратура
            </div>
          </div>

        </td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td class="border-bottom-zero"></td>
        <td :rowspan="rowOfValues.data.tabM22usl3.length" class="border-bottom-zero border-right-zero"><img
            :src="require('@/assets/images/Vector_rigth_arrow.svg')"
            alt=""/></td>
        <td :rowspan="rowOfValues.data.tabM22usl3.length" class="border-bottom-zero border-right-zero">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgo21, 0, 'integer') }}
        </td>
        <td :rowspan="rowOfValues.data.tabM22usl3.length" class="border-bottom-zero border-right-zero">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgv21, 0, 'integer') }}
        </td>
        <td :rowspan="rowOfValues.data.tabM22usl3.length" class="border-bottom-zero border-right-zero">
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgz21, 0, 'integer') }}
        </td>
      </tr>
      <tr v-for="row in rowOfValues.data.tabM22usl3" :key="row.id">
        <td colspan="3" style="" class="text-left">
          <span class="kcp-code">{{ row.kcpGroup.code }}</span>
          <span class="kcp-name">{{ row.kcpGroup.name }}</span>
        </td>
        <td class="">...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgz21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgz21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 5" :key="item"></td>
        <td colspan="6" class="border-bottom-zero border-right-zero text-right">Бакалавриат (специалитет) по формам
          обучения:
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NBo
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NBv
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NBz
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 5" :key="item"></td>
        <td colspan="6" class="border-bottom-zero border-right-zero text-right">Магистратура по формам обучения:</td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NMo
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NMv
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NMz
        </td>
      </tr>
      </tbody>
    </table>
    <block-co :item="item" :row-of-values="rowOfValues"/>
    <h3 class="b-indicator__collapse-subtitle mb-3">Расчет приведенных контингентов:</h3>
    <div class="d-flex flex-row ">
      <div class="d-flex flex-column">
        <!--          <div class="formul-text"><span class="d-flex flex-row align-end">NMP<span class="little-text " style="line-height:12px;">2018</span></span></div>-->
        <div class="formul-text">NMP</div>
        <formuls-fraction :need-bottom-line="false">
          ACP
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ">
        <div class="formul-text alternate-text mx-1">=</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="formul-text alternate-text">=</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex justify-center"><span class="alternate-text mr-1">1,0 ×</span> NMo</div>
        <formuls-fraction :need-bottom-line="true">
          <template>
            <span class="alternate-text mr-1">1,0 ×</span> ACo
          </template>
          <template #bottom>контингент<br>
            очной формы
          </template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">+</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">+</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex justify-center"><span class="alternate-text mr-1">   0,25 ×</span> NMv</div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="alternate-text mr-1">   0,25 ×</span>ACv</template>
          <template #bottom>контингент<br>
            оч.-заоч. формы
          </template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">+</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">+</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex justify-center"><span class="alternate-text mr-1">   0,1 ×</span> NMz</div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="alternate-text mr-1">   0,1 ×</span>ACz</template>
          <template #bottom>контингент<br> заочной формы</template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">=</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">=</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex justify-center"><span class="alternate-text mr-1">1,0 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgo21, 0, 'integer') }}</div>
        <formuls-fraction :need-bottom-line="true">
          <template>
            <span class="alternate-text mr-1">1,0 ×</span>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkapo21, 0, 'integer') }}
          </template>
          <template #bottom>контингент<br>
            очной формы
          </template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">+</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">+</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex justify-center"><span class="alternate-text mr-1">   0,25 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgv21, 0, 'integer') }}</div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="alternate-text mr-1">   0,25 ×</span>  {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkapv21, 0, 'integer') }}</template>
          <template #bottom>контингент<br>
            оч.-заоч. формы
          </template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">+</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">+</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text d-flex  justify-center"><span class="alternate-text mr-1">   0,1 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgz21, 0, 'integer') }}</div>
        <formuls-fraction :need-bottom-line="true">
          <template><span class="alternate-text mr-1">   0,1 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkapz21, 0, 'integer') }}</template>
          <template #bottom>контингент<br> заочной формы</template>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column ml-1">
        <div class="alternate-text">=</div>
        <formuls-fraction :need-bottom-line="false">
          <div class="alternate-text">=</div>
        </formuls-fraction>
      </div>
      <div class="d-flex flex-column">
        <div class="formul-text text-center"> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgp21, 1, 'float') }}</div>
        <formuls-fraction :need-bottom-line="true">
          <template> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkapp21, 1,   'float') }}</template>
          <template #bottom>приведенный
            <br>
            контингент
          </template>
        </formuls-fraction>
      </div>
    </div>
    <p>NMP - приведенный контингент студентов магистратуры</p>
    <p class="mb-4">ACP - приведенный контингент аспирантуры (для целей демонстрации расчета и в силу отсутствия данных
      по научным специальностям расчетная формула упрощена).</p>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          ACP  <div class="alternate-text mx-1">+</div> ОРС  <div class="alternate-text mx-1">+</div> ACС
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">NMP</span>
        </template>
      </formuls-fraction-simple>

      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkapp21,1) }}
          <div class="alternate-text mx-1">+</div>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noors21, 0, 'integer') }}
          <div class="alternate-text mx-1">+</div>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noass21, 0, 'integer') }}
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">   {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nkmgp21, 1) }}</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>

      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p class="mb-4">Если сумма значений показателей ACP, ОРС, АСС больше нуля, а значение показателя NMP равно нулю
      (либо отсутствует), значение показателя принимается равным 1.
    </p>
    <block-g v-bind="$props"/>
  </div>
</template>

<script>
import BlockCo from "@/components/cards/indicatiors/details/blockCo";
import BlockG from "@/components/cards/indicatiors/details/blockG";

export default {
  name: "m22",
  components: {BlockG, BlockCo},
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>

</style>