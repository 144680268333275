<template>
  <div>
    <WrapperPreviewCategory customClass="section-preview_lk">
      Кабинет администратора
    </WrapperPreviewCategory>

  </div>
</template>

<script>
// import request from "@/services/request";
// import {mapActions, mapGetters} from 'vuex';
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';


export default {
  name: "LkOrganization",
  components: {

    WrapperPreviewCategory,

  },
  data() {
    return {
    }
  },
  created() {

  },
  computed: {

  },
  watch: {

  },
  methods: {

  }
}
</script>

<style lang="sass">
.section-preview_lk
  background: #fff
  border-radius: 6px
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06)
  margin-bottom: 20px
  .lk-list_flex_start
    justify-content: flex-start
</style>