<template>
<div class="d-flex align-self-stretch justify-center" >
  <v-progress-circular v-if="loading" color="primary" indeterminate size="20"></v-progress-circular>
  <div
      v-else
      class="five-cell-dop d-flex flex-row align-center justify-center "
      style="flex: 0 0 100%;"
  >
    <v-text-field
        v-model.lazy="vv"
        :type="typeValue"
        class="input-text-center-dop"
        dense
        :disabled="simpleBlock"
        placeholder="Введите значение"
        color="primary"
        @change="emitData($event)"
    >
      <template #default>
        <span class="text-center">{{vv}}</span>
      </template>
    </v-text-field>
<!--    <span style=" flex: 0 0 100%;" class="text-center">{{value === null ? 'null' : formattedValue()}}</span>-->
  </div>
</div>
</template>

<script>
import {sendDataWithDopCell} from "@/API/form5";
import {mapGetters} from "vuex";

export default {
  name: "fivesTableCellDop",
  props: {
    value: {
      type: [Number, String, Object],
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    maxValue: {
      type: [String, Number]
    },
    minValue: {
      type: [String, Number]
    },
    id: {
      type: [String, Number]
    },
    cell: {
      type: [Object, String]
    },
    row: {
      type: [Object, String],
      validator: (value) =>{
        return ['string', 'object'].includes(typeof value)
      }
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
  },
  data() {
    return {
      loading: false,
      vv: this.value
    }
  },
  computed: {
    typeValue(){
      return this.cell?.typeValue ? this.cell.typeValue : 'Number'
    },
    ...mapGetters('checkFilled' , { simpleBlock: 'simpleBlock'}),
  },
  methods: {
    formattedValue() {
      return this.cell.template ? this.cell.template(this.value) : this.value;
    },
    async sendData(value){
      try{
        this.loading = true;
        const obj = {
          "rowId": this.row?.id ?? null,
          "jsonId": this.cell.dataField,
          "newValue": value,
          "kcpGroup": this.row.sprKcpGroup?.id,
          "org": this.row.sprOrgId
        };
        const res = await sendDataWithDopCell(obj);
        this.loading = false;
        return res;
      }catch (e) {
        this.loading = false;
        return false
      }
    },
    async emitData(e){
      // if (!this.simpleBlock){
        try{
          const res = await this.sendData(e);
          if (res || res === 0)
            this.$emit('change-value-dop', {value: e, id: this.row.id, cell: this.cell, dataField: this.cell.dataField});
          else
            this.vv = null;
        }catch (e) {
          this.vv = null;
          console.error('not emit ', e)
        }
      // }
    }
  },
}
</script>

<style lang="sass">
.five-cell-dop
  .input-text-center-dop
    .v-text-field__details
      display: none
    .v-input__slot
      &:before
        border: 0!important
    input
      font-size: 14px
      color: #455A64!important
      text-align: center
      &::placeholder
        font-size: 12px
        color: #455A64
.five-cell

  &:hover
    color: #00599B
    font-weight: 500
</style>