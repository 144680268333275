import { render, staticRenderFns } from "./k41.vue?vue&type=template&id=16118be2&scoped=true&"
import script from "./k41.vue?vue&type=script&lang=js&"
export * from "./k41.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16118be2",
  null
  
)

export default component.exports