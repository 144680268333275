<template>
  <div>
    <div>
      <div v-if="loadedFiles">
        <CardFile v-for="item in files"
                  :key="item.fileType"
                  :item="item"
                  :upload-to-serv-now="loadingFileToServer.fileType === item.fileType && loadingFileToServer.value"
                  @replace-file="replaceFile($event)"
                  @upload-file="uploadFile($event)"
                  :token="token"
                  identifier="fileType"
                  :disabled="simpleBlock"
                  style="border-bottom: 1px solid #E5E5E5; padding: 0 24px 10px 24px"
        />
      </div>
    </div>
<!--    <div v-else>-->
<!--      <div v-if="loadedFiles">-->
<!--        <CardFile v-for="item in files" :key="item.fileType" :item="item"/>-->
<!--      </div>-->
<!--    </div>-->


  </div>
</template>

<script>

import CardFile from "@/components/cards/scanCard";
import {mapGetters, mapActions} from 'vuex'
import request from "@/services/request";

export default {
  components: {
    CardFile
  },
  data() {
    return {
      loadingFileToServer: {
        value: false,
        fileType: ''
      },
      files: [
        {
          name: 'Устав образовательной организации',
          types: ['application/pdf'],
          fileType: 'USTAV_EDUCATIONAL_ORG'
        },
        // {
        //   name: 'Лицензия на осуществление образовательной деятельности',
        //   types: ['application/pdf'],
        //   fileType: 'LICENSE_EDUCATIONAL_ORG'},
        // {
        //   name: 'Приложения к лицензии на осуществление образовательной деятельности',
        //   types: ['application/pdf'],
        //   fileType: 'APP_LICENSE_EDUCATIONAL_ORG'
        // },
        // {name: 'Свидетельство о государственной аккредитации',
        //   types: ['application/pdf'],
        //   fileType: 'CERTIFICATE_GOS_ACCREDITATION'},
        // {
        //   name: 'Приложения к свидетельству о государственной аккредитации',
        //   types: ['application/pdf'],
        //   fileType: 'APP_CERTIFICATE_GOS_ACCREDITATION'
        // },
        {
          name: 'Приказ учредителя о назначении руководителя образовательной организации',
          types: ['application/pdf'],
          fileType: 'DECREE_ABOUT_DIRECTOR_OBR_ORG'
        },
      ],
      loadedFiles: false,
    }
  },

  computed: {
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
    ...mapGetters('checkFilled' , { simpleBlock: 'simpleBlock'}),
    token(){
      return this.getAuthData.token
    }
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    ...mapActions('checkFilled', {getStatusInfo: 'getStatusInfo'}),
    async getDocuments() {
      this.loadedFiles = false;
      const res = await request({
        endpoint: `/api/file/scan/list`,
        method: 'GET',
      });
      res.data.forEach(el => {
        const overlapIndex = this.files.findIndex(file => file.fileType === el.fileType);
        if (overlapIndex !== -1) {
          this.files.splice(overlapIndex, 1, {...this.files[overlapIndex], ...el});
        }
      });
      this.loadedFiles = true;
    },
    async uploadFile(event) {
      this.loadingFileToServer.value = true;
      this.loadingFileToServer.fileType = event.fileType;
      let fd = new FormData();
      fd.append('file', event.file);
      try {
        const res = await request({
          endpoint: `/api/file/scan/upload?scanType=${event.fileType}`,
          method: 'POST',
          body: fd,
        });
        const overlapIndex = this.files.findIndex(file => file.fileType === res.data.fileType);
        if (overlapIndex !== -1) {
          this.files.splice(overlapIndex, 1, {...this.files[overlapIndex], ...res.data});
        }
        await this.getStatusInfo();
        this.loadingFileToServer.value = false;
      } catch (e) {
        this.loadingFileToServer.value = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка загрузки',
          timeout: 3000
        })
      }
      // this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});
    },
    async deleteFile(event) {
      try {
        const response = await request({
          endpoint: `/api/file/scan/delete?fileId=${event.id}`,
          method: 'DELETE',
          token: this.token,
        });
        if (response.status === 200) {
          const ind = this.files.findIndex(file => file.fileType === event.fileType);
          this.files.splice(ind, 1, {
                name: this.files[ind].name,
                fileType: event.fileType,
                types: this.files[ind].types
              }
          )
        }
      } catch (e) {
        // this.uploadNow = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка удаления',
          timeout: 3000
        });
        console.log("e delete", e)
      }
    },
    async replaceFile(event) {
      await this.deleteFile(event)
      await this.uploadFile(event)
    },
  },
}
</script>

<style lang="sass">
.info-card__button
  margin-top: 10px
  margin-left: 10px

.file-edit
  display: flex
  align-items: center

.file-edit .v-file-input
  max-width: 300px
  margin-right: 10px
  min-width: 200px

.file-edit
  display: inline-flex
  align-items: center
  background: #FFEDE2
  padding: 0px 15px
  border-radius: 10px
  margin: 20px

.border_margin_right
  margin-right: 10px
</style>