<template>
  <div>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <!--    <v-btn @click="rwrw = !rwrw">rwrw</v-btn>-->
      <GridTable
          :data-rows="showingServerData"
          :rewrite-headers="rwrw"
          :headers="headers"
          :headers-for-rows="headersForRows"
          :loading-data="loadingData"
          :num-cols="countCells"
          :max-table-height="maxTableHeight"
          ref="npg-table"
          key="form-B"
      >
        <!--  v-if="!changingHeaders"  :key='' когда меняется хедер проверить    -->

        <template v-for="item in headersForRows" v-slot:[calcHeaderName(item.dataField)]>
          <div :key="item.dataField" :class="item.headerClass" style="min-width: 100%">
<!--            <template v-if="item.text === '1'">-->
<!--              <div style="min-width: 60px">1</div>-->
<!--              <div class="text-center" style="width: 100%">2</div>-->
<!--            </template>-->
<!--            <template v-else>-->
              <div class="d-flex flex-row align-center" style=" writing-mode: vertical-rl; text-orientation: mixed;">
                {{ item.text }}
<!--                <v-btn small icon @click="openTooltip(item.dataField)">-->
<!--                  <v-icon-->
<!--                      :size="15"-->
<!--                      class="grey-light-color__important "-->
<!--                  >-->
<!--                    mdi-information-->
<!--                  </v-icon>-->
<!--                </v-btn>-->
              </div>
<!--            </template>-->
          </div>
        </template>
        <template #header-qwer="{item}">
<!--          <v-btn @click="loadFullInfo" class="white__v-btn">{{ !full ? 'Показать все' : "Скрыть пустые" }} строки-->
<!--          </v-btn>-->
          {{ item.text }}
        </template>
        <template #sprKcpGroup="{row}">
          <template v-if="row.uslName">
            <div class="font-weight-bold font-size-medium ">
              {{ row.uslName[0].toUpperCase() + row.uslName.slice(1) }}
            </div>
          </template>
          <template v-else>
            <div style="">
              {{calcCodeUgs(row)}}
            </div>
            <div class="ml-3">
              {{ calcNameUgs(row) }}
            </div>
          </template>
        </template>
            <template v-for="item in headersForRowsWithEditableCells" v-slot:[item.dataField]="{row}">
              {{calcTemplate(row[item.dataField])}}
        <!--      <fives-table-cell :key="item.dataField" v-bind="item.props(row,cell, loadingMenu)"-->
        <!--                        @open-dialog="openDialog($event, item.dataField, cell, row)"/>-->
            </template>
      </GridTable>
      <div class="mt-2" style="color: #89A0B0; font-size: 14px;">
        Примечание: Если выпускник имел несколько мест трудоустройства в разных регионах, он учитывался по каждому такому региону пропорционально полученной в соответствующем регионе заработной плате
      </div>
      <div class="d-flex flex-row justify-center align-center">
        <v-autocomplete
            class="pagination-table"
            v-model="userPages"
            :items="pagesPaginate"
            solo
            dense
            filled
            height="25"
            rounded
            style="max-width: 150px"
        ></v-autocomplete>
        <v-pagination
            v-model="page"
            :length="pagesVuetify"
            :total-visible="7"
        >
        </v-pagination>

      </div>
    </WrapperPreviewCategory>

  </div>
</template>

<script>
import GridTable from "@/components/GridTable/GridTable";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {mapGetters} from "vuex";
import {getFormData} from "@/API/form5";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import {setAppealDialog} from "@/mixins/forms5/appealDialog";

export default {
  name: "formB",
  mixins: [headerTooltipFunctions, setAppealDialog],
  components: { WrapperPreviewCategory, GridTable},
  data() {
    return {
      serverData: [],
      loadingData: false,
      changingHeaders: false,
      headers: [],
      headersRows: [],
      headersRowsDefault: [
        {
          dataField: 'sprKcpGroup',
          cols: 1,
          rows: 1,
          headerClass: 'text-center',
          class: 'd-flex flex-row justify-start',
          text: `1`,
          order: 2,
          variable: true,
        },
      ],
      headersDefault: [
        {
          dataField: 'qwer',
          cols: 1,
          rows: 2,
          text: 'Код и наименование УГСН (НПС)',
          class: 'd-flex flex-row align-start justify-start ',
          headerClass: 'align-end ',
          headerStyles: `min-width: 350px;`,
          order: 0,
          variable: false,
        },
        {
          dataField: 'npg11',
          cols: 1,
          rows: 1,
          headerClass: 'text-center',
          text: `2018`,
          order: 1,
          variable: false,
        },
        {
          dataField: 'npg12',
          cols: 1,
          rows: 1,
          headerClass: 'text-center',
          text: `2019`,
          order: 1,
          variable: false,
        },
        {
          dataField: 'npg13',
          cols: 1,
          rows: 1,
          headerClass: 'text-center',
          text: `2020`,
          order: 2,
          variable: false,
        },
      ],
      countCells: 1,
      loadingMenu: {value: false, id: ''},
      full: false,
      rwrw: true,
      page: 1,
      userPages: 25,
      pagesPaginate:[
        {value: 25,   text: 25},
        {value: 10000, text: 'все'},
      ]
    }
  },
  created() {
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    }
  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_usl_error: 'get_spr_data_usl_error',
    }),
    maxTableHeight() {
      return window.innerHeight - 225 + 'px'
    },
    showingServerData(){
      return this.serverData.slice((this.page-1) * this.userPages, (this.page) * this.userPages)
    },
    pagesVuetify(){
      return Math.ceil(this.serverData.length/parseInt(this.userPages, 10));
    },
    headersForRows() {
      return this.headersRows.filter(el => el.variable).map(el => {
        return {
          ...el,
          styles: (row) => {
            if (el.dataField !== 'sprKcpGroup')
              return row.uslName ? 'display: none;' : 'justify-content: unset; text-align: center;';
            else
              return '';
          },
          colsDataCalc: (row, cell) => {
            if (el.dataField !== 'sprKcpGroup')
              return cell.cols;
            else
              return row.uslName ? this.countCells : 1;
          },
        }
      })
    },
  },
  methods: {
    loadFullInfo() {
      this.full = !this.full;
      this.loadingDataTable()
    },
    calcTemplate(v){
      if (!v) return v;
      const dot  = v.indexOf(',');
      if (dot !== -1)
        return v.slice(0,dot+2);
      else
        return v;
    },
    calcCodeUgs(row){
      return    row.spec ? row.spec?.kodSpec : row.sprKcpGroup?.code
    },
    calcNameUgs(row){
      return    row.spec ? row.spec?.name : row.sprKcpGroup?.name
    },
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getFormData('getPartB', true, `/api/getB?full=${this.full}`, this.full);
        const res = this.serverData.find(el => !el.uslName);
        const headers = {
          '2018': [],
          '2019': [],
          '2020': []
        };
        for (const value of Object.keys(res)) {
          // if (key === 'id') continue;
          const indexSearchMtr = value.indexOf('Mtr');
          if (indexSearchMtr !== -1){
            const year = '20' + value.substr(-5 , 2);
            headers[year].push(value);
          }
        }
        this.headers = [...this.headersDefault];
        this.headersRows = [...this.headersRowsDefault];
        this.countCells = 1;
        for( const [key,value] of Object.entries(headers)){
          const item = this.headers.find(el => el.text === key);
          item.cols = value.length;
          if (value.length === 0){
            const removedItemIndex = this.headers.findIndex(el => el.text === key);
            this.headers.splice(removedItemIndex, 1)
            continue;
          }
          this.countCells += value.length;
          const newArr = value.map(el => {
            const info = this.findInfoForCell(el);
            // console.log(info)
            return {
              dataField: el,
              cols: 1,
              rows: 1,
              text: info.form,
              order: 7,
              headerClass: 'pa-0 font-size-small__important',
              variable: true,
            }
          });
          this.headersRows.push(...newArr);
          this.headers.push(...newArr);
        }
        this.rwrw = false;
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
        this.rwrw = false;
      }
    },
  },
}
</script>

<style lang="sass">
.pagination-table
  max-width: 70px!important
  .v-input__slot
    input
      min-width: 30px!important
      max-width: 40px!important
    padding: 0 5px 0 10px !important
  .v-text-field__details
    display: none
</style>