<template>
 <div>
 <div class='table' v-if="!loadingData && serverData">
   <div class='table__item table__item-header without-line left-line right-line bottom-line'>Показатели</div>
   <div class='table__item table__item-header left-line right-line bottom-line text-center'>за 2018 г</div>
   <div class='table__item table__item-header left-line right-line bottom-line text-center'>за 2019 г</div>
   <div class='table__item table__item-header left-line right-line bottom-line text-center'>за 2020 г</div>

   <div  class=' table__item pa-0 table__item-header without-line2 left-line right-line bottom-line'>1</div>
   <div  class=' table__item pa-0 table__item-header left-line bottom-line text-end pr-2'>2</div>
   <div  class=' table__item pa-0 table__item-header bottom-line left-line text-center'>3</div>
   <div  class=' table__item pa-0 table__item-header bottom-line left-line text-center'>4</div>
   <div  class=' table__item pa-0 table__item-header bottom-line left-line text-center right-line'>5</div>

   <div class='table__item without-line3 without-line-height left-line right-line bottom-line'>Профессорско-преподавательский состав</div>
   <div class='table__item left-line right-line bottom-line'>среднесписочная численность (без внешних совместителей)</div>
   <div class='table__item left-line bottom-line text-end'>человек</div>
   <div class='table__item bottom-line left-line item-data text-center d-flex flex-row align-center justify-space-between' v-for="item in dataFields.slice(0,3)" :key="item.name">
     <fives-table-cell
           style="flex: 1 0"
         v-bind="calcPropsForCell(item.name)"
             :cell="calcPropsForCell(item.name)"
         @open-dialog="openDialog($event, item.name)"/>
      <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
   </div>

   <div class='table__item left-line right-line bottom-line'>внешние совместители (пропорционально отработанному времени)</div>
   <div class='table__item left-line bottom-line text-end'>человек</div>
   <div class='table__item bottom-line left-line item-data text-center d-flex flex-row align-center justify-space-between' v-for="item in dataFields.slice(3,6)" :key="item.name">
     <fives-table-cell
           style="flex: 1 0"
         v-bind="calcPropsForCell(item.name)"
             :cell="calcPropsForCell(item.name)"
         @open-dialog="openDialog($event, item.name)"/>
      <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
   </div>

   <div class='table__item without-line4 left-line right-line bottom-line'>Научные работники</div>
   <div class='table__item left-line right-line bottom-line'>среднесписочная численность (без внешних совместителей)</div>
   <div class='table__item left-line bottom-line text-end'>человек</div>
   <div class='table__item bottom-line left-line item-data text-center d-flex flex-row align-center justify-space-between' v-for="item in dataFields.slice(6,9)" :key="item.name">
     <fives-table-cell
           style="flex: 1 0"
         v-bind="calcPropsForCell(item.name)"
             :cell="calcPropsForCell(item.name)"
         @open-dialog="openDialog($event, item.name)"/>
      <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
   </div>

   <div class='table__item left-line right-line bottom-line'>внешние совместители (пропорционально отработанному времени)</div>
   <div class='table__item left-line bottom-line text-end'>человек</div>
   <div class='table__item bottom-line left-line item-data text-center d-flex flex-row align-center justify-space-between' v-for="item in dataFields.slice(9,12)" :key="item.name">
     <fives-table-cell
           style="flex: 1 0"
         v-bind="calcPropsForCell(item.name)"
             :cell="calcPropsForCell(item.name)"
         @open-dialog="openDialog($event, item.name)"/>
      <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
   </div>

   <div class='table__item without-line3 left-line right-line bottom-line'>Объем поступивших средств, всего</div>
   <div class='table__item left-line right-line bottom-line'></div>
   <div class='table__item left-line bottom-line text-end'>тыс.рублей</div>
   <div class='table__item bottom-line left-line item-data text-center d-flex flex-row align-center justify-space-between' v-for="item in dataFields.slice(12,15)" :key="item.name">
     <fives-table-cell
           style="flex: 1 0"
         v-bind="calcPropsForCell(item.name)"
             :cell="calcPropsForCell(item.name)"
         @open-dialog="openDialog($event, item.name)"/>
      <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
   </div>

   <div class='table__item without-line2 left-line right-line bottom-line'>из них - по виду деятельности «научные исследования и разработки»</div>
   <div class='table__item left-line bottom-line text-end'>тыс.рублей</div>
   <div class='table__item bottom-line left-line item-data text-center d-flex flex-row align-center justify-space-between' v-for="item in dataFields.slice(15, 18)" :key="item.name">
     <fives-table-cell
           style="flex: 1 0"
         v-bind="calcPropsForCell(item.name)"
             :cell="calcPropsForCell(item.name)"
         @open-dialog="openDialog($event, item.name)"/>
      <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
   </div>
   <div class='table__item left-line right-line bottom-line'></div>
   <div class='table__item right-line left-line bottom-line text-end'>в том числе:</div>
   <div class='table__item left-line right-line bottom-line te'>средства бюджетов всех уровней (субсидий)</div>
   <div class='table__item left-line bottom-line text-end'>тыс.рублей</div>
   <div class='table__item bottom-line left-line item-data text-center d-flex flex-row align-center justify-space-between' v-for="item in dataFields.slice(18,21)" :key="item.name">
     <fives-table-cell
           style="flex: 1 0"
         v-bind="calcPropsForCell(item.name)"
             :cell="calcPropsForCell(item.name)"
         @open-dialog="openDialog($event, item.name)"/>
      <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
   </div>

   <div class='table__item left-line right-line'></div>
   <div class='table__item left-line right-line'></div>
   <div class='table__item left-line right-line'>средства внебюджетных фондов</div>
   <div class='table__item left-line text-end'>тыс.рублей</div>
   <div class='table__item  left-line item-data text-center d-flex flex-row align-center justify-space-between' v-for="item in dataFields.slice(21,24)" :key="item.name">
     <fives-table-cell
           style="flex: 1 0"
         v-bind="calcPropsForCell(item.name)"
             :cell="calcPropsForCell(item.name)"
         @open-dialog="openDialog($event, item.name)"/>
      <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
   </div>
  </div>
   <div v-else class="d-flex flex-row align-center justify-center" style="min-width: 150px;">
     <v-progress-linear indeterminate />
   </div>
   <appeal-dialog
       ref="appeal-dialog"
       :appeal-id="appealId"
       :file-id="fileId"
       :appeal="appealCell"
       :value="appealValue"
       :appeal-info="appealInfo"
       @saved-value="saveValue($event)"
       @closing-dialog="closingAppealDialog"
   ></appeal-dialog>
   <tooltip-forms5-dialog
       ref="tooltip-dialog"
       :data-field="dataFieldTooltip"
   >
   </tooltip-forms5-dialog>
 </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getFormData} from "@/API/form5";
import {setAppealFromOneToFourDialog} from "@/mixins/forms5/appealDialogF1-4";
import FivesTableCell from "@/components/fields/fivesTableCell";
import appealDialog from "@/components/Dialogs/appealForms5_1-4Dialog";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";

export default {
  name: "form5_1b",
  mixins: [setAppealFromOneToFourDialog, headerTooltipFunctions],
  components: {FivesTableCell, appealDialog, tooltipForms5Dialog},
  data() {
    return {
      serverData: null,
      loadingData: false,
      loadingMenu: {value: false, id: ''},
      dataFields: [
        {name: "wpppo19Pk1"},
        {name: "wpppo20Pk1"},
        {name: "wpppo21Pk1"},
        {name: "wppps19Pk1"},
        {name: "wppps20Pk1"},
        {name: "wppps21Pk1"},
        {name: "wpnro19Pk1"},
        {name: "wpnro20Pk1"},
        {name: "wpnro21Pk1"},
        {name: "wpnrs19Pk1"},
        {name: "wpnrs20Pk1"},
        {name: "wpnrs21Pk1"},
        {name: "deorg19Pk1"},
        {name: "deorg20Pk1"},
        {name: "deorg21Pk1"},
        {name: "denir19Pk1"},
        {name: "denir20Pk1"},
        {name: "denir21Pk1"},
        {name: "desbs19Pk1"},
        {name: "desbs20Pk1"},
        {name: "desbs21Pk1"},
        {name: "devbt19Pk1"},
        {name: "devbt20Pk1"},
        {name: "devbt21Pk1"}
      ]
    }
  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_usl_error: 'get_spr_data_usl_error',
    }),
  },
  created() {
    this.appealInfo.nameForm = 'FORM5_1B'
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    }
  },
  methods: {
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getFormData('getPart1b', false);
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },
    calcPropsForCell(dataField) {
      return {
        value: this.serverData[dataField],
        title: '',
        cell: {dataField: dataField},
        id: this.serverData.id,
        row: this.serverData,
        loading: this.loading,
        template: (v) => {
          if (v == null || v === 'н.д.')
            return 'н.д.';
          const dot  = v.indexOf(',');
          if (dot !== -1)
          {
            const newV = v.slice(0,dot) + '.'+v.slice(dot+1,dot+2);
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(newV));
          }
          else
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v));
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table{
  display: grid;
  grid-template-columns: 100px 1fr 1fr 100px minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px);
  margin:auto;
  color: #89A0B0;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  //margin-bottom: 20px;
  padding: 12px;
  //margin-right: auto;
  //margin-left: auto;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 8px;
  }
  
}
.table__item-header{
  font-size: 12px;
}

.table__item {
  padding: 5px;
  font-size: 14px;
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  border: 1px solid #CFD8DC;
  &:not(.table__item-header){
    color: #455A64
  }
}
.table__header{
  border-top: 1px solid #CFD8DC;
}
.without-line{
  grid-column-start: 1;
  grid-column-end: 5;
}
.without-line2{
  grid-column-start: 1;
  grid-column-end: 4;
}
.without-line3{
  grid-column-start: 1;
  grid-column-end: 3;
}
.without-line-height{
  grid-row-start:3;
  grid-row-end: 5;
}
.without-line4{
  grid-column-start:1;
  grid-column-end:3;
  grid-row-start:5; 
  grid-row-end:7;
}
.top-line{
  border-top: none;
}
.left-line{
  border-left: none;
}
.bottom-line{
  border-bottom: none;
}
.right-line{
  border-right: none;
}
.close{
  top: 95px;
  right: 90px;
}
.old__item{
  text-decoration: line-through;
  font-size: 35px;
}
.new__item{
  background: #E3EFFA;
  border-radius: 7px;
  height: 55px;
  width: 190px;
  padding-left: 20px;
  padding-right: 20px;
}
.new__item::placeholder { 
  font-size: 20px;
  align-items: center;
  
}
.items{
  display: flex;
  justify-content: space-between;
  padding: 0px 120px;
  margin-top: 13%;
}
.item-data:hover {
  background: rgba(207, 224, 236, 0.42);
} 
.background-left{
  border-top-left-radius: 10px;
}
.background-right{
  border-top-right-radius: 10px;
}

</style>