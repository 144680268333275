<template>
  <v-app :class="{ 'normalContainer' :isMon, 'hugeContainer': !isMon }">
    <CNotificationsFromStore/>
    <nav-bar/>
    <!--style="background-color: #F5F6F8;"-->
    <v-main >
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import CNotificationsFromStore from "@/components/CNotificationsFromStore";
// import TopBar from "@/components/Navigation/TopBarOldVersion";
import {mapActions, mapGetters} from 'vuex'
// import axios from 'axios'
import NavBar from "@/components/Navigation/navBar";
import axios from "axios";

export default {
  name: 'App',
  components: {
    NavBar,
    // TopBar,
    CNotificationsFromStore
  },
  props: {
    keycloak: Object
  },
  data() {
    return {
      showModal: true
    }
  },
  watch: {
    get_replace_id_admin(newValue, oldValue) {
      if (newValue !== oldValue && (!this.isFounder || !this.isRegion || this.isAdmin))
        this.getStatusInfo();
    }
  },
  created() {
    // if (this.get_replace_id_admin)
    this.setAuthData(this.keycloak);
    if (!this.isFounder && !this.isRegion) this.getStatusInfo();
    axios.interceptors.response.use(undefined, err => {
      // eslint-disable-next-line no-unused-vars
      return new Promise(/*function (resolve, reject)*/() => {
        if (process.env.NODE_ENV === 'development') {
          console.log("err.request", err.request)
          console.log("err.response", err.response)
        }
        if (err.request.status === 401 && err.isAxiosError) {
          // console.log("!!!", this.getAuthData)
          this.getAuthData.updateToken(0).then(refreshed => {
            if (!refreshed) {
              location.reload()
              this.getAuthData.updateToken(0).then(con => console.log('con', con))
            }
          })
        }
        throw err;
      });
    });
  },
  computed: {
    ...mapGetters('keycloak', {getAuthData: 'getAuthData',isAdmin:'isAdmin', isFounder: 'isFounder', isRegion: 'isRegion'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    isMon() {
      return false
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions('keycloak', {setAuthData: 'setAuthData'}),
    ...mapActions('checkFilled', {getStatusInfo: 'getStatusInfo'}),
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/utils/RalewayFont.sass"
//.footer
//  border-top: 1px solid #EBEFF2
//  background-color: #00559b
//  color: #FFFFFF
  //img
  //  filter: invert(22%) sepia(24%) saturate(5637%) hue-rotate(189deg) brightness(99%) contrast(101%)
//@media (min-width: 1264px)
.hugeContainer
  //p,div,span,td,th,li
  //  font-family: "Raleway", sans-serif
  .container
    max-width: 1900px !important
//.normalContainer
//  .container
//    padding-left: 0
//    padding-right: 0
//    max-width: 1110px !important
//    p,div
//      //color: #78909C
//      font-weight: 500
//      font-size: 16px
//      line-height: 19px
//      letter-spacing: 0.01em
</style>