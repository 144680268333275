<template>
<div>
  <v-row v-if="rowOfValues.data.orgtp22 === '1'" dense class="">
    <v-col cols="10">
       <p class="">
        <span class="font-weight-bold mb-1 ">Форма 5.01а. (фрагмент)</span><br>
         Численность научно-педагогических работников и доходы образовательной организации
         <br>
         по данным формы ВПО-2
       </p>
    </v-col>
  </v-row>
  <table v-if="rowOfValues.data.orgtp22 === '1'">
    <thead>
    <tr>
      <th rowspan="1" colspan="3" class="text-left kcp-td" >Показатели</th>
      <th colspan="1">за 2018 г.</th>
      <th colspan="1">за 2019 г.</th>
      <th colspan="1">за 2020 г.</th>
    </tr>
    <tr style="border-top: 1px solid #B7CED9">
      <th colspan="2" style="text-align: left">
        <span>1</span>
      </th>
      <th >2</th>
      <th >3</th>
      <th >4</th>
      <th >5</th>
    </tr>
    </thead>
    <tbody>
    <tr>
<!--      <td rowspan="2" class="border-right-zero" style="text-align: left">Объем поступивших средств, всего</td>-->
      <td colspan="2" style="min-width: 700px; max-width: 700px;" class="border-right-zero kcp-name text-left">
        Объем поступивших средств, всего
      </td>
      <td>тыс.рублей</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.deorg19, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.deorg20, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.deorg21, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">ОД<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2018</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">ОД<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">ОД<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
      </td>
    </tr>
    </tbody>
  </table>
  <v-row v-if="rowOfValues.data.orgtp22 === '3'" dense class="">
    <v-col cols="10">
      <p class="">
        <span class="font-weight-bold mb-1 ">Форма 5.01б. (фрагмент)</span><br>
        Численность научно-педагогических работников и доходы организации дополнительного профессионального образованния
        <br>
        по данным формы 1-ПК
      </p>
    </v-col>
  </v-row>
  <table v-if="rowOfValues.data.orgtp22 === '3'">
    <thead>
    <tr>
      <th rowspan="1" colspan="3" class="text-left" >Показатели</th>
      <th colspan="1">за 2018 г.</th>
      <th colspan="1">за 2019 г.</th>
      <th colspan="1">за 2020 г.</th>
    </tr>
    <tr style="border-top: 1px solid #B7CED9">
      <th colspan="2" style="text-align: left">
        <span>1</span>
      </th>
      <th >2</th>
      <th >3</th>
      <th >4</th>
      <th >5</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <!--      <td rowspan="2" class="border-right-zero" style="text-align: left">Объем поступивших средств, всего</td>-->
      <td colspan="2" style="min-width: 700px; max-width: 700px;" class="border-right-zero kcp-name text-left">
        Объем поступивших средств, всего
      </td>
      <td>тыс.рублей</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.deorg19, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.deorg20, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.deorg21, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">ОД<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2018</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">ОД<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">ОД<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
      </td>
    </tr>
    </tbody>
  </table>
  <v-row dense class="" v-if="rowOfValues.data.orgtp22 === '2'">
    <v-col cols="10">
      <p class="">
        <span class="font-weight-bold mb-1 ">Форма 5.01в. (фрагмент)</span><br>
        Численность научно-педагогических работников и доходы научной организации
        <br>
        по данным формы 2-наука
      </p>
    </v-col>
  </v-row>
  <table v-if="rowOfValues.data.orgtp22 === '2'">
    <thead>
    <tr>
      <th rowspan="1" colspan="3" class="text-left kcp-td" >Показатели</th>
      <th colspan="1">за 2018 г.</th>
      <th colspan="1">за 2019 г.</th>
      <th colspan="1">за 2020 г.</th>
    </tr>
    <tr style="border-top: 1px solid #B7CED9">
      <th colspan="2" style="text-align: left">
        <span>1</span>
      </th>
      <th >2</th>
      <th >3</th>
      <th >4</th>
      <th >5</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <!--      <td rowspan="2" class="border-right-zero" style="text-align: left">Объем поступивших средств, всего</td>-->
      <td colspan="2" class="border-right-zero kcp-name text-left" style="min-width: 700px; max-width: 700px;">
        Выполнено работ, услуг, произведено товаров, всего (без НДС, акцизов и других подобных платежей)
      </td>
      <td>тыс.рублей</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.dsorg19, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.dsorg20, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
      <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.dsorg21, 1, 'float', true, '', (v) => {
        return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
      <td colspan="1" class="border-bottom-zero border-right-zero">
        <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
      </td>
    </tr>
    <tr>
      <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">ОД<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2018</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">ОД<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2019</span></span>
      </td>
      <td class="border-bottom-zero border-right-zero formul-text-table">
        <span class="d-flex flex-row justify-center align-end">ОД<span class="little-text font-size-extra-small__important" style="line-height:10px; ">2020</span></span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
</template>

<script>
export default {
  name: "blockOd",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
}
</script>

<style scoped>

</style>