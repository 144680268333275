<template>
<div>
  <p class="mb-0">{{item.ptx}}</p>
  <p>{{item.ppr}} Приложения 2 к Порядку</p>
</div>
</template>

<script>
export default {
  name: "cardTopDescription",
  props: {
    nameComponent: {
      type: String,
      default: ''
    },
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {

  },
}
</script>

<style scoped>

</style>