import Api from "@/utils/Api";
import request from "@/services/request";
import {getSprKcpPok} from "@/API/indicators";

//eslint-disable-next-line no-unused-vars
async function setServerData(commit, req, type) {
    if (req.status === 200) {
        commit('SET_DATA_SPR', {data: req.data, type: type})
        commit('SET_DATA_SPR_LOADING', {data: false, type: type})
    } else {
        commit('SET_DATA_SPR_LOADING', {data: false, type: type})
        commit('SET_DATA_SPR_ERROR', {data: req.error, type: type})
    }
}

export default {
    namespaced: true,
    state: {
        sprDataOrg: {data: [], counter: 0},
        sprDataOrgLoading: false,
        sprDataOrgError: null,
        sprDataKcpGroup: {data: [], counter: 0},
        sprDataKcpGroupLoading: false,
        sprDataKcpGroupError: null,
        sprDataRegion: {data: [], counter: 0},
        sprDataRegionLoading: false,
        sprDataRegionError: null,
        sprDataFounder: {data: [], counter: 0},
        sprDataFounderLoading: false,
        sprDataFounderError: null,
        sprDataSpec: {data: [], counter: 0},
        sprDataSpecLoading: false,
        sprDataSpecError: null,
        sprDataUsl: {data: [], counter: 0},
        sprDataUslLoading: false,
        sprDataUslError: null,
        sprDataFf: {data: [], counter: 0},
        sprDataFfLoading: false,
        sprDataFfError: null,
        sprDataFo: {data: [], counter: 0},
        sprDataFoLoading: false,
        sprDataFoError: null,
        sprDataUgs: {data: [], counter: 0},
        sprDataUgsLoading: false,
        sprDataUgsError: null,
        sprDataLanguages: {data: [], counter: 0},
        sprDataLanguagesLoading: false,
        sprDataLanguagesError: null,
        sprDataOksm: {data: [], counter: 0},
        sprDataOksmLoading: false,
        sprDataOksmError: null,
        sprDataOlympiadProfile: {data: [], counter: 0},
        sprDataOlympiadProfileLoading: false,
        sprDataOlympiadProfileError: null,
        sprDataJobs: {data: [], counter: 0},
        sprDataJobsLoading: false,
        sprDataJobsError: null,
        sprDataCategories: {data: [], counter: 0},
        sprDataCategoriesLoading: false,
        sprDataCategoriesError: null,
        sprDataDistrictsrf: {data: [], counter: 0},
        sprDataDistrictsrfLoading: false,
        sprDataDistrictsrfError: null,
        sprDataImzpotr: {data: [], counter: 0},
        sprDataImzpotrLoading: false,
        sprDataImzpotrError: null,
        sprDataOdsd: {data: [], counter: 0},
        sprDataOdsdLoading: false,
        sprDataOdsdError: null,
        sprDataAreaact: {data: [], counter: 0},
        sprDataAreaactLoading: false,
        sprDataAreaactError: null,
        sprDataJobtips: {data: [], counter: 0},
        sprDataJobtipsLoading: false,
        sprDataJobtipsError: null,
        sprDataAreacult: {data: [], counter: 0},
        sprDataAreacultLoading: false,
        sprDataAreacultError: null,
        sprDataAreanpg: {data: [], counter: 0},
        sprDataAreanpgLoading: false,
        sprDataAreanpgError: null,
        sprDataIndicators: {data: [], counter: 0},
        sprDataIndicatorsLoading: false,
        sprDataIndicatorsError: null,
        sprDataPokazateli: {data: [], counter: 0},
        sprDataPokazateliLoading: false,
        sprDataPokazateliError: null,
    },
    actions: {
        async dataForSpr({commit, state, dispatch}) {
            /*
            * org, founder, region, fo, ugs, usl, spec
            * */
            const typesSpr = ['getSprUsls'];
            const typesSpr1 = [
                {name: 'getSprUsls', stateName: 'sprDataUsl',},
                // {name: 'categories', stateName: 'sprDataCategories',},
                // {name: 'imzpotr', stateName: 'sprDataImzpotr',},
                // {name: 'odsd', stateName: 'sprDataOdsd',},
                // {name: 'jobtips', stateName: 'sprDataJobtips',},
                // {name: 'areaact', stateName: 'sprDataAreaact',},
                // {name: 'areacult', stateName: 'sprDataAreacult',},
                // {name: 'areanpg', stateName: 'sprDataAreanpg',},
            ];
            typesSpr1.forEach((el) => {
                if (state[el.stateName].counter !== 0) {
                    typesSpr.splice(typesSpr.findIndex(el1 => el1 === el.name), 1)
                }
            })
            dispatch('getAllIndicators')
            typesSpr.forEach(el => commit('SET_DATA_SPR_LOADING', {data: true, type: el}))
            try {
                await Api.sprDataLoad(typesSpr)
                    .then(data => {
                        typesSpr.forEach((el, index) => {
                            if (data[index].status === 200) {
                                commit('SET_DATA_SPR', {data: data[index].data, type: el});
                                commit('SET_DATA_SPR_LOADING', {data: false, type: el});
                            } else {
                                commit('SET_DATA_SPR_LOADING', {data: false, type: el});
                                commit('SET_DATA_SPR_ERROR', {data: data[index].error, type: el});
                                throw new Error("error in spr - " + el)
                            }
                        })

                    })
                    .catch(ee => {
                        console.log("eee in spr", ee)
                        typesSpr.forEach((el) => {
                            commit('SET_DATA_SPR_LOADING', {data: false, type: el});
                        });
                    })

            } catch (e) {
                console.log("err ", e)
            }
        },
        async getAllIndicators({commit, state}) {
            if (state.sprDataRegion.counter === 0) {
                try {
                    const res = await request({
                        endpoint: `/api/form5/getReference`,
                        method: 'get',
                    });
                    await setServerData(commit, res, 'indicators')

                } catch (e) {
                    console.error('/api/form5/getReference', e)
                }

            }
        },
        async getAllKcpPok({commit, state}) {
            if (state.sprDataPokazateli.counter === 0) {
                try {
                    const res = await getSprKcpPok();
                    await setServerData(commit, res, 'pokazateli')

                } catch (e) {
                    console.error('/api/pok', e)
                }

            }
        },
        async getKcpGroupSpr({commit, state}) {
            if (state.sprDataKcpGroup.counter === 0) {
                try {
                    const res = await request({
                        endpoint: `/api/spr/kcpgroup`,
                        method: 'get',
                    });
                    await setServerData(commit, res, 'kcpgroup')

                } catch (e) {
                    console.error('/api/spr/kcpgroup', e)
                }

            }
        },
    },
    mutations: {
        ['SET_DATA_SPR'](state, payload) {
            switch (payload.type) {
                case 'getSprUsls':
                    state.sprDataUsl = {
                        data: payload.data.map(el => {
                            return {...el, requestName: 'usl' + el.id}
                        }), counter: payload.data.length
                    }
                    break
                case 'indicators':
                    state.sprDataIndicators = {data: payload.data, counter: payload.data.length}
                    break;
                case 'pokazateli':
                    state.sprDataPokazateli = {data: payload.data, counter: payload.data.length}
                    break;
                case 'kcpgroup':
                    state.sprDataKcpGroup = {data: payload.data, counter: payload.data.length}
                    break
                case 'region':
                    state.sprDataRegion = {data: payload.data, counter: payload.data.length}
                    break
                case 'spec':
                    state.sprDataSpec = {data: payload.data, counter: payload.data.length}
                    break
                case 'org':
                    state.sprDataOrg = {data: payload.data, counter: payload.data.length}
                    break
                case 'ff':
                    state.sprDataFf = {data: payload.data, counter: payload.data.length}
                    break
                case 'fo':
                    state.sprDataFo = {data: payload.data, counter: payload.data.length}
                    break
                case 'ugs':
                    state.sprDataUgs = {data: payload.data, counter: payload.data.length}
                    break
                case 'founder':
                    state.sprDataFounder = {data: payload.data, counter: payload.data.length}
                    break
                case 'jobs':
                    state.sprDataJobs = {data: payload.data, counter: payload.data.length}
                    break
                case 'categories':
                    state.sprDataCategories = {data: payload.data, counter: payload.data.length}
                    break
                case 'districtsrf':
                    state.sprDataDistrictsrf = {data: payload.data, counter: payload.data.length}
                    break
                case 'imzpotr':
                    state.sprDataImzpotr = {data: payload.data, counter: payload.data.length}
                    break
                case 'odsd':
                    state.sprDataOdsd = {data: payload.data, counter: payload.data.length}
                    break
                case 'jobtips':
                    state.sprDataJobtips = {data: payload.data, counter: payload.data.length}
                    break
                case 'areaact':
                    state.sprDataAreaact = {data: payload.data, counter: payload.data.length}
                    break
                case 'areacult':
                    state.sprDataAreacult = {data: payload.data, counter: payload.data.length}
                    break
                case 'areanpg':
                    state.sprDataAreanpg = {data: payload.data, counter: payload.data.length}
                    break
            }
        },
        ['SET_DATA_SPR_LOADING'](state, payload) {
            switch (payload.type) {
                case 'getSprUsls':
                    state.sprDataUslLoading = payload.data
                    break
                case 'indicators':
                    state.sprDataIndicatorsLoading = payload.data
                    break;
                case 'pokazateli':
                    state.sprDataPokazateliLoading = payload.data
                    break
                case 'kcpgroup':
                    state.sprDataKcpGroupLoading = payload.data
                    break
                case 'region':
                    state.sprDataRegionLoading = payload.data
                    break
                case 'spec':
                    state.sprDataSpecLoading = payload.data
                    break
                case 'org':
                    state.sprDataOrgLoading = payload.data
                    break
                case 'ff':
                    state.sprDataFfLoading = payload.data
                    break
                case 'fo':
                    state.sprDataFoLoading = payload.data
                    break
                case 'ugs':
                    state.sprDataUgsLoading = payload.data
                    break
                case 'founder':
                    state.sprDataFounderLoading = payload.data
                    break;
                case 'jobs':
                    state.sprDataJobsLoading = payload.data
                    break
                case 'categories':
                    state.sprDataCategoriesLoading = payload.data
                    break
                case 'districtsrf':
                    state.sprDataDistrictsrfLoading = payload.data
                    break
                case 'imzpotr':
                    state.sprDataImzpotrLoading = payload.data
                    break
                case 'odsd':
                    state.sprDataOdsdLoading = payload.data
                    break
                case 'jobtips':
                    state.sprDataJobtipsLoading = payload.data
                    break
                case 'areaact':
                    state.sprDataAreaactLoading = payload.data
                    break
                case 'areacult':
                    state.sprDataAreacultLoading = payload.data
                    break
                case 'areanpg':
                    state.sprDataAreanpgLoading = payload.data
                    break

            }
        },
        ['SET_DATA_SPR_ERROR'](state, payload) {
            switch (payload.type) {
                case 'getSprUsls':
                    state.sprDataUslError = payload.data
                    break
                case 'indicators':
                    state.sprDataIndicatorsError = payload.data
                    break; 
                case 'pokazateli':
                    state.sprDataPokazateliError = payload.data
                    break
                case 'kcpgroup':
                    state.sprDataKcpGroupError = payload.data
                    break
                case 'region':
                    state.sprDataRegionError = payload.data
                    break
                case 'spec':
                    state.sprDataSpecError = payload.data
                    break
                case 'org':
                    state.sprDataOrgError = payload.data
                    break
                case 'ff':
                    state.sprDataFfError = payload.data
                    break
                case 'fo':
                    state.sprDataFoError = payload.data
                    break
                case 'igs':
                    state.sprDataUgsError = payload.data
                    break
                case 'founder':
                    state.sprDataFounderError = payload.data
                    break
                case 'jobs':
                    state.sprDataJobsError = payload.data
                    break
                case 'categories':
                    state.sprDataCategoriesError = payload.data
                    break
                case 'districtsrf':
                    state.sprDataDistrictsrfError = payload.data
                    break
                case 'imzpotr':
                    state.sprDataImzpotrError = payload.data
                    break
                case 'odsd':
                    state.sprDataOdsdError = payload.data
                    break
                case 'jobtips':
                    state.sprDataJobtipsError = payload.data
                    break
                case 'areaact':
                    state.sprDataAreaactError = payload.data
                    break
                case 'areacult':
                    state.sprDataAreacultError = payload.data
                    break
                case 'areanpg':
                    state.sprDataAreanpgError = payload.data
                    break

            }
        },
    },
    getters: {
        get_spr_data_jobs(state) {
            return state.sprDataJobs
        },
        get_spr_data_jobs_loading(state) {
            return state.sprDataJobsLoading
        },
        get_spr_data_jobs_error(state) {
            return state.sprDataJobsError
        },
        get_spr_data_indicators(state) {
            return state.sprDataIndicators
        },
        get_spr_data_indicators_loading(state) {
            return state.sprDataIndicatorsLoading
        },
        get_spr_data_indicators_error(state) {
            return state.sprDataIndicatorsError
        },
        get_spr_data_pokazateli(state) {
            return state.sprDataPokazateli
        },
        get_spr_data_pokazateli_loading(state) {
            return state.sprDataPokazateliLoading
        },
        get_spr_data_pokazateli_error(state) {
            return state.sprDataPokazateliError
        },
        get_spr_data_kcp_groups(state) {
            return state.sprDataKcpGroup
        },
        get_spr_data_kcp_groups_loading(state) {
            return state.sprDataKcpGroupLoading
        },
        get_spr_data_kcp_groups_error(state) {
            return state.sprDataKcpGroupError
        },
        get_spr_data_areaact(state) {
            return state.sprDataAreaact
        },
        get_spr_data_areaact_loading(state) {
            return state.sprDataAreaactLoading
        },
        get_spr_data_areaact_error(state) {
            return state.sprDataAreaactError
        },
        get_spr_data_areacult(state) {
            return state.sprDataAreacult
        },
        get_spr_data_areacult_loading(state) {
            return state.sprDataAreacultLoading
        },
        get_spr_data_areacult_error(state) {
            return state.sprDataAreacultError
        },
        get_spr_data_areanpg(state) {
            return state.sprDataAreanpg
        },
        get_spr_data_areanpg_loading(state) {
            return state.sprDataAreanpgLoading
        },
        get_spr_data_areanpg_error(state) {
            return state.sprDataAreanpgError
        },
        get_spr_data_job_tips(state) {
            return state.sprDataJobtips
        },
        get_spr_data_job_tips_loading(state) {
            return state.sprDataJobtipsLoading
        },
        get_spr_data_job_tips_error(state) {
            return state.sprDataJobtipsError
        },
        get_spr_data_categories(state) {
            return state.sprDataCategories
        },
        get_spr_data_categories_loading(state) {
            return state.sprDataCategoriesLoading
        },
        get_spr_data_categories_error(state) {
            return state.sprDataCategoriesError
        },
        get_spr_data_districtsrf(state) {
            return state.sprDataDistrictsrf
        },
        get_spr_data_districtsrf_loading(state) {
            return state.sprDataDistrictsrfLoading
        },
        get_spr_data_districtsrf_error(state) {
            return state.sprDataDistrictsrfError
        },
        get_spr_data_imzpotr(state) {
            return state.sprDataImzpotr
        },
        get_spr_data_imzpotr_loading(state) {
            return state.sprDataImzpotrLoading
        },
        get_spr_data_imzpotr_error(state) {
            return state.sprDataImzpotrError
        },
        get_spr_data_odsd(state) {
            return state.sprDataOdsd
        },
        get_spr_data_odsd_loading(state) {
            return state.sprDataOdsdLoading
        },
        get_spr_data_odsd_error(state) {
            return state.sprDataOdsdError
        },
        get_spr_data_usl(state) {
            return state.sprDataUsl
        },
        get_spr_data_usl_loading(state) {
            return state.sprDataUslLoading
        },
        get_spr_data_usl_error(state) {
            return state.sprDataUslError
        },
        get_spr_data_region(state) {
            return state.sprDataRegion
        },
        get_spr_data_region_loading(state) {
            return state.sprDataRegionLoading
        },
        get_spr_data_region_error(state) {
            return state.sprDataRegionError
        },
        get_spr_data_founder(state) {
            return state.sprDataFounder
        },
        get_spr_data_founder_loading(state) {
            return state.sprDataFounderLoading
        },
        get_spr_data_founder_error(state) {
            return state.sprDataFounderError
        },
        get_spr_data_ugs(state) {
            return state.sprDataUgs
        },
        get_spr_data_ugs_loading(state) {
            return state.sprDataUgsLoading
        },
        get_spr_data_ugs_error(state) {
            return state.sprDataUgsError
        },
        get_spr_data_spec(state) {
            return state.sprDataSpec
        },
        get_spr_data_spec_loading(state) {
            return state.sprDataSpecLoading
        },
        get_spr_data_spec_error(state) {
            return state.sprDataSpecError
        },
        get_spr_data_org(state) {
            return state.sprDataOrg
        },
        get_spr_data_org_main(state) {
            return state.sprDataOrg
            // return state.sprDataOrg.data.filter(el => el.orgStatus == '1');
        },
        get_spr_data_org_loading(state) {
            return state.sprDataOrgLoading
        },
        get_spr_data_org_error(state) {
            return state.sprDataOrgError
        },
        get_spr_data_ff(state) {
            return state.sprDataFf
        },
        get_spr_data_ff_loading(state) {
            return state.sprDataFfLoading
        },
        get_spr_data_ff_error(state) {
            return state.sprDataFfError
        },
        get_spr_data_fo(state) {
            return state.sprDataFo
        },
        get_spr_data_fo_loading(state) {
            return state.sprDataFoLoading
        },
        get_spr_data_fo_error(state) {
            return state.sprDataFoError
        },
        get_spr_data_oksm(state) {
            return state.sprDataOksm
        },
        get_spr_data_oksm_loading(state) {
            return state.sprDataOksmLoading
        },
        get_spr_data_oksm_error(state) {
            return state.sprDataOksmError
        },
        get_spr_data_olympiad(state) {
            return state.sprDataOlympiadProfile
        },
        get_spr_data_olympiad_loading(state) {
            return state.sprDataOlympiadProfileLoading
        },
        get_spr_data_olympiad_error(state) {
            return state.sprDataOlympiadProfileError
        },
        get_spr_data_languages(state) {
            return state.sprDataLanguages
        },
        get_spr_data_languages_loading(state) {
            return state.sprDataLanguagesLoading
        },
        get_spr_data_languages_error(state) {
            return state.sprDataLanguagesError
        },


    }
}