<template>
  <div>
    <div class='table' v-if="!loadingData && serverData">
      <div class="table__item table__item-header left-line right-line">Показатели</div>
      <div class="table__item table__item-header text-center right-line">за 2018 г.</div>
      <div class="table__item table__item-header text-center right-line">за 2019 г.</div>
      <div class="table__item table__item-header text-center right-line">за 2020 г.</div>

      <div class="table__item pa-0 table__item-header text-center left-line top-line right-line">1</div>
      <div class="table__item pa-0 table__item-header text-center bottom-line top-line right-line " >2</div>
      <div class="table__item pa-0 table__item-header text-center bottom-line top-line right-line">3</div>
      <div class="table__item pa-0 table__item-header text-center bottom-line top-line right-line">4</div>

      <div class="table__item left-line top-line right-line bottom-line mr-0 mb-0">Количество публикаций, индексируемых в Web of Science</div>
      <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(0,2)"
           :key="item.name">
        <fives-table-cell
            style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>
      <div class='table__item bottom-line right-line item-data text-center d-flex flex-row align-center justify-space-around'>
        <fives-table-cell-dop :cell="calcPropsForCell('qpwsc21Dop')" :row="serverData" :value="serverData['qpwsc21Dop']" @change-value-dop="dopChanges($event)"></fives-table-cell-dop>
      </div>
      <div class="table__item left-line right-line ">Количество публикаций, индексируемых в Scopus</div>
      <div class='table__item right-line item-data text-center d-flex flex-row align-center justify-space-around' v-for="item in dataFields.slice(3,5)"
           :key="item.name">
        <fives-table-cell
            style="flex: 1 0"
            v-bind="calcPropsForCell(item.name)"
            @open-dialog="openDialog($event, item.name)"/>
        <v-btn small icon @click="openTooltip(item.name)">
          <v-icon
              :size="15"
              class="grey-light-color__important "
          >
            mdi-information
          </v-icon>
        </v-btn>
      </div>
      <div class='table__item  right-line item-data text-center d-flex flex-row align-center justify-space-around'>
        <fives-table-cell-dop :cell="calcPropsForCell('qpscp21Dop')" :row="serverData" :value="serverData['qpscp21Dop']" @change-value-dop="dopChanges($event)"></fives-table-cell-dop>
      </div>

      <div class="table__item left-line top-line bottom-line right-line without-line background">Примечания: в форму
         внесены сведения о количестве публикаций за 2018-2019 годы на основании данных конкурса
        предыдущего года
      </div>

    </div>
    <div v-else class="d-flex flex-row align-center justify-center" style="min-width: 150px;">
      <v-progress-linear indeterminate/>
    </div>
    <appeal-dialog
        ref="appeal-dialog"
        :appeal-id="appealId"
        :file-id="fileId"
        :appeal="appealCell"
        :value="appealValue"
        :appeal-info="appealInfo"
        @saved-value="saveValue($event)"
        @closing-dialog="closingAppealDialog"
    ></appeal-dialog>
    <tooltip-forms5-dialog
        ref="tooltip-dialog"
        :data-field="dataFieldTooltip"
    >
    </tooltip-forms5-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getFormData} from "@/API/form5";
import {setAppealFromOneToFourDialog} from "@/mixins/forms5/appealDialogF1-4";
import FivesTableCell from "@/components/fields/fivesTableCell";
import appealDialog from "@/components/Dialogs/appealForms5_1-4Dialog";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";
import fivesTableCellDop from "@/components/fields/fivesTableCellDop";

export default {
  mixins: [setAppealFromOneToFourDialog, headerTooltipFunctions],
  components: {FivesTableCell, appealDialog, tooltipForms5Dialog, fivesTableCellDop},
 data() {
    return {
      serverData: null,
      loadingData: false,
      loadingMenu: {value: false, id: ''},
      dataFields: [
        {name: 'qpwsc19K21',},
        {name: 'qpwsc20K21',},
        {name: 'qpwsc21Dop',},
        {name: 'qpscp19K21',},
        {name: 'qpscp20K21',},
        {name: 'qpscp21Dop',},
      ]
    }
  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_usl_error: 'get_spr_data_usl_error',
    }),
  },
  created() {
    this.appealInfo.nameForm = 'FORM5_2'
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    }
  },
  methods: {
    dopChanges(e){
      this.serverData[e.dataField] =  e.value;
    },
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getFormData('getPart2', false);
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },
    calcPropsForCell(dataField) {
      return {
        value: this.serverData[dataField],
        title: '',
        dataField: dataField,
        cell: {dataField: dataField},
        id: this.serverData.id,
        row: this.serverData,
        loading: this.loading,
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.table {
  display: grid;
  grid-template-columns: 1fr minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px);
  color: #89A0B0;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  margin-bottom: 20px;
  overflow: auto;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }
}

.table__item-header{
  font-size: 12px;
}

.table__item {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #CFD8DC;
  &:not(.table__item-header){
    color: #455A64
  }
}

.table__header {
  border-top: 1px solid #CFD8DC;
}

.top-line {
  border-top: none;
}

.left-line {
  border-left: none;
}

.bottom-line {
  border-bottom: none;
}

.right-line {
  border-right: none;
}

.without-line {
  grid-column-start: 1;
  grid-column-end: 5;
}

.item-data:hover {
  background: rgba(207, 224, 236, 0.42);
}
</style>