//Обычный браузерный select
<template>
  <select
    v-model="$attrs.value"
    @change="onValueChanged"
    :class="['select', customClass]"
    :name="name"
    >
        <option v-for="(item, index) in data" :key="index">{{item}}</option>        
  </select>
</template>
// Доделать стили

<script>
	export default {
		props: {
			name: {
				type: String,
				default: '',
			},
			customClass: {
				type: String,
				default: '',
			},
			data: {
				type: Array,
				default() {
					return [];
				},
			}	
		},
	methods: {
		onValueChanged() {
			this.$emit('input', this.$attrs.value);
		}
	}
}	
</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"
.select
  background-image: url('../../../assets/images/arrow-up-down.svg')
  background-repeat: no-repeat
  background-position: 98% center
  padding: 0px 25px 0px 5px
  border-radius: 5px
  background-color: $color-b

</style>