<template>
  <div>
    <v-btn class="blue__v-btn mb-2" @click="$router.go(-1)">Назад</v-btn> <span class="formul-text-table">{{nameOrgForRegion}}</span>
    <v-row dense>
      <v-col class="header-columns mr-11" cols="5">
        Показатели
      </v-col>
      <v-col class="header-columns" cols="5">
        Балльная оценка
      </v-col>
      <v-col></v-col>
    </v-row>
    <template v-if="!loading">
      <card-indicator
          :item="{
          title: 'Сводная оценка заявки',
          about: 'Сумма баллов по всем показателям',
          count: middleValue,
          dataFieldName: 'generalValue',
          max: indicators.iowgt22,
          maxGradient: indicators.iowgt22,
          available: true,
          typeStyle: 'general',
          idUsl: idUsl
        }"
          :row-of-values="indicators"
          @appeal-indicator="openAppeal($event)"
      />
      <card-indicator
          v-for="(b, index) in bIndicatorsCalculated"
          :key="index"
          :item="b"
          :loading="get_spr_data_pokazateli_loading"
          :row-of-values="indicator"
          @appeal-indicator="openAppeal($event)"
      />
    </template>
    <template v-else>
      <div class="d-flex flex-row align-center justify-center" style="min-height: 200px;">
        <v-progress-linear indeterminate/>
      </div>
    </template>
<!--    <p class="mt-5 mb-7">-->
<!--      В публикации дробные значения показателей и аргументов расчета округлены. В действительности все вычисления проводятся с точностью до седьмого знака после запятой.-->
<!--    </p>-->
<!--    <ul class="pl-2 mt-10">-->
<!--      Механизм подачи апелляций:-->
<!--      <li>- апелляцию на ошибку в исходных данных можно подать в разделе «Показатели деятельности», содержащем формы заявки с исходными данными, нажав на ячейку с некорректным значением</li>-->
<!--      <li>- апелляцию на ошибку в расчете значения показателя и (или) его нормированной оценки можно подать, нажав на оранжевый значок звездочки около значения количества баллов по показателю</li>-->
<!--      <li>- апелляцию на ошибку в расчете сводной оценки заявки можно подать, нажав на оранжевый значок звездочки около значения сводной оценки заявки</li>-->
<!--    </ul>-->
    <appeal-indicators
        ref="appeal-dialog"
        :appeal="appeal"
        :loading="laodingAppeals"
        :appeal-info="appealInfo"
        @saved-value="saveValue($event)"
        @closing-dialog="closingAppealDialog"
    ></appeal-indicators>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CardIndicator from "@/components/cards/indicatiors/cardIndicator";
import {getAppeals, getKcpPokFull} from "@/API/indicators";
import AppealIndicators from "@/components/Dialogs/appeaIndicators";

export default {
  name: "fullInfo",
  components: {AppealIndicators, CardIndicator},
  data() {
    return {
      indicators: null,
      loading: false,
      laodingAppeals: false,
      appealsArr: [],
      appeal: null,
      appealInfo: null,
      foList: [
        {id: 1, text: 'очная', value: 'o'},
        {id: 2, text: 'очно-заочная', value: 'v'},
        {id: 3, text: 'заочная', value: 'z'},
      ],
      bIndicators: [
        {
          title: 'Б11',
          dataFieldName: 'b11',
          about: 'Средний балл ЕГЭ',
          count: '64.8',
          max: 23,
          idUsl: 1,
        },
        {
          title: 'Б12',
          dataFieldName: 'b12',
          about: 'Исполнение КЦП прошлых лет',
          count: '19.4',
          max: 3,
          idUsl: 1,
        },
        {
          title: 'Б13',
          dataFieldName: 'b13',
          about: 'Доля целевого приема',
          count: '3.0',
          max: 4,
          idUsl: 1,
        },
        {
          title: 'Б21',
          dataFieldName: 'b21',
          about: 'Наличие ПОА',
          count: '1.8',
          max: 2,
          idUsl: 1,
        },
        {
          title: 'Б22',
          dataFieldName: 'b22',
          about: 'Соотношение (Маг+Асп)/БС',
          count: '9.0',
          max: 9,
          idUsl: 1,
        },
        {
          title: 'Б23',
          dataFieldName: 'b23',
          about: 'Соотношение (Маг+Асп+Орд)/БС',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 1,
        },
        {
          title: 'Б24',
          dataFieldName: 'b24',
          about: 'Соотношение (Маг+Асп+Аcc)/БС',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 1,
        },
        {
          title: 'Б31',
          dataFieldName: 'b31',
          about: 'Трудоустройство выпускников',
          count: '18.7',
          max: 25,
          idUsl: 1,
        },
        {
          title: 'Б32',
          dataFieldName: 'b32',
          about: 'Сохранность контингента',
          count: '4.7',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б33',
          dataFieldName: 'b33',
          about: 'Доходы выпускников',
          count: '4.7',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б41',
          dataFieldName: 'b41',
          about: 'Публикации на 100 НПР',
          count: '0.4',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б42',
          dataFieldName: 'b42',
          about: 'Доходы от НИОКР на 1 НПР',
          count: '1.3',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б43',
          dataFieldName: 'b43',
          about: 'Доля иностранных студентов',
          count: '2.4',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б44',
          dataFieldName: 'b44',
          about: 'Доходы на 1 студента',
          count: '0.9',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б11',
          dataFieldName: 'b11',
          about: 'Средний балл ЕГЭ',
          count: '64.8',
          max: 23,
          idUsl: 2,
        },
        {
          title: 'Б12',
          dataFieldName: 'b12',
          about: 'Исполнение КЦП прошлых лет',
          count: '19.4',
          max: 3,
          idUsl: 2,
        },
        {
          title: 'Б13',
          dataFieldName: 'b13',
          about: 'Доля целевого приема',
          count: '3.0',
          max: 4,
          idUsl: 2,
        },
        {
          title: 'Б21',
          dataFieldName: 'b21',
          about: 'Наличие ПОА',
          count: '1.8',
          max: 2,
          idUsl: 2,
        },
        {
          title: 'Б22',
          dataFieldName: 'b22',
          about: 'Соотношение (Маг+Асп)/БС',
          count: '9.0',
          max: 9,
          idUsl: 2,
        },
        {
          title: 'Б23',
          dataFieldName: 'b23',
          about: 'Соотношение (Маг+Асп+Орд)/БС',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 2,
        },
        {
          title: 'Б24',
          dataFieldName: 'b24',
          about: 'Соотношение (Маг+Асп+Асс)/БС',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 2,
        },
        {
          title: 'Б31',
          dataFieldName: 'b31',
          about: 'Трудоустройство выпускников',
          count: '18.7',
          max: 25,
          idUsl: 2,
        },
        {
          title: 'Б32',
          dataFieldName: 'b32',
          about: 'Сохранность контингента',
          count: '4.7',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'Б33',
          dataFieldName: 'b33',
          about: 'Доходы выпускников',
          count: '4.7',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'Б41',
          dataFieldName: 'b41',
          about: 'Публикации на 100 НПР',
          count: '0.4',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'Б42',
          dataFieldName: 'b42',
          about: 'Доходы от НИОКР на 1 НПР',
          count: '1.3',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'Б43',
          dataFieldName: 'b43',
          about: 'Доля иностранных студентов',
          count: '2.4',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'Б44',
          dataFieldName: 'b44',
          about: 'Доходы на 1 студента',
          count: '0.9',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'M11',
          dataFieldName: 'm11',
          about: 'Заявления от выпусников др.организаций',
          idUsl: 3,
        },
        {
          title: 'M12',
          dataFieldName: 'm12',
          about: 'Поданные заявления / прием',
          idUsl: 3,
        },
        {
          title: 'M13',
          dataFieldName: 'm13',
          about: 'Доля платного приема',
          idUsl: 3,
        },
        {
          title: 'M14',
          dataFieldName: 'm14',
          about: 'Исполнение КЦП прошлых лет',
          idUsl: 3,
        },
        {
          title: 'M21',
          dataFieldName: 'm21',
          about: 'Наличие ПОА',
          idUsl: 3,
        },
        {
          title: 'M22',
          dataFieldName: 'm22',
          about: 'Соотношение Асп/Маг',
          idUsl: 3,
        },
        {
          title: 'M23',
          dataFieldName: 'm23',
          about: 'Соотношение ДПО/(БС+Маг)',
          idUsl: 3,
        },
        {
          title: 'M24',
          dataFieldName: 'm24',
          about: 'Доля целевого обучения',
          idUsl: 3,
        },
        {
          title: 'M31',
          dataFieldName: 'm31',
          about: 'Ср.доходы выпускников / СПМ',
          idUsl: 3,
        },
        {
          title: 'M32',
          dataFieldName: 'm32',
          about: 'Сохранность контингента',
          idUsl: 3,
        },
        {
          title: 'M41',
          dataFieldName: 'm41',
          about: 'Публикации на 100 НПР',
          idUsl: 3,
        },
        {
          title: 'M42',
          dataFieldName: 'm42',
          about: 'Доходы от НИОКР на 1 НПР',
          idUsl: 3,
        },
        {
          title: 'M43',
          dataFieldName: 'm43',
          about: 'Доля иностранных студентов',
          idUsl: 3,
        },
        {
          title: 'M44',
          dataFieldName: 'm44',
          about: 'Доходы на 1 студента',
          idUsl: 3,
        },
        {
          title: 'О11',
          dataFieldName: 'o11',
          about: 'Заявления от выпускников др.организаций',
          idUsl: 9,
        },
        {
          title: 'О12',
          dataFieldName: 'o12',
          about: 'Поданные заявления / прием',
          idUsl: 9,
        },
        {
          title: 'О13',
          dataFieldName: 'o13',
          about: 'Доля платного приема',
          idUsl: 9,
        },
        {
          title: 'О14',
          dataFieldName: 'o14',
          about: 'Исполнение КЦП прошлых лет',
          idUsl: 9,
        },
        {
          title: 'О21',
          dataFieldName: 'o21',
          about: 'Наличие ПОА',
          idUsl: 9,
        },
        {
          title: 'О22',
          dataFieldName: 'o22',
          about: 'Соотношение ДПО/(БС+Маг+Орд)',
          idUsl: 9,
        },
        {
          title: 'О23',
          dataFieldName: 'o23',
          about: 'Доля целевого обучения',
          idUsl: 9,
        },
        {
          title: 'О31',
          dataFieldName: 'o31',
          about: 'Ср.доходы выпускников / СПМ',
          idUsl: 9,
        },
        {
          title: 'О32',
          dataFieldName: 'o32',
          about: 'Ср.доходы выпускников / СПМ',
          idUsl: 9,
        },
        {
          title: 'О41',
          dataFieldName: 'o41',
          about: 'Публикации на 100 НПР',
          idUsl: 9,
        },
        {
          title: 'О42',
          dataFieldName: 'o42',
          about: 'Доходы от НИОКР на 1 НПР',
          idUsl: 9,
        },
        {
          title: 'О43',
          dataFieldName: 'o43',
          about: 'Доля иностранных студентов',
          idUsl: 9,
        },
        {
          title: 'О44',
          dataFieldName: 'o44',
          about: 'Доходы на обучающегося',
          idUsl: 9,
        },
        {
          title: 'K11',
          dataFieldName: 'k11',
          about: 'Заявления от выпускников др.организаций',
          idUsl: 12,
        },
        {
          title: 'K12',
          dataFieldName: 'k12',
          about: 'Поданные заявления / прием',
          idUsl: 12,
        },
        {
          title: 'K13',
          dataFieldName: 'k13',
          about: 'Доля платного приема',
          idUsl: 12,
        },
        {
          title: 'K14',
          dataFieldName: 'k14',
          about: 'Исполнение КЦП прошлых лет',
          idUsl: 12,
        },
        {
          title: 'K21',
          dataFieldName: 'k21',
          about: 'Наличие ПОА',
          idUsl: 12,
        },
        {
          title: 'K22',
          dataFieldName: 'k22',
          about: 'Соотношение ДПО/(БС+Маг+Асс)',
          idUsl: 12,
        },
        {
          title: 'K23',
          dataFieldName: 'k23',
          about: 'Доля целевого обучения',
          idUsl: 12,
        },
        {
          title: 'K31',
          dataFieldName: 'k31',
          about: 'Ср.доходы выпускников / СПМ',
          idUsl: 12,
        },
        {
          title: 'K32',
          dataFieldName: 'k32',
          about: 'Сохранность контингента',
          idUsl: 12,
        },
        {
          title: 'K41',
          dataFieldName: 'k41',
          about: 'Доля иностранных студентов',
          idUsl: 12,
        },
        {
          title: 'K42',
          dataFieldName: 'k42',
          about: 'Доходы на 1 обучающегося',
          idUsl: 12,
        },
        {
          title: 'A11',
          dataFieldName: 'a11',
          about: 'Исполнение КЦП прошлых лет',
          idUsl: 6,
        },
        {
          title: 'A21',
          dataFieldName: 'a21',
          about: 'Соотношение защиты/выпуск',
          idUsl: 6,
        },
        {
          title: 'A22',
          dataFieldName: 'a22',
          about: 'Соотношение защиты/прием',
          idUsl: 6,
        },
        {
          title: 'A23',
          dataFieldName: 'a23',
          about: 'Доля защит при целевом обучении',
          idUsl: 6,
        },
        {
          title: 'A31',
          dataFieldName: 'a31',
          about: 'Публикации на 100 НПР',
          idUsl: 6,
        },
        {
          title: 'A32',
          dataFieldName: 'a32',
          about: 'Доходы от НИОКР на 1 НПР',
          idUsl: 6,
        },
        {
          title: 'A33',
          dataFieldName: 'a33',
          about: 'Доходы от внебюджетных НИОКР на 1 НПР',
          idUsl: 6,
        },
        {
          title: 'A34',
          dataFieldName: 'a34',
          about: 'Доля иностранных аспирантов',
          idUsl: 6,
        },
        {
          title: 'A35',
          dataFieldName: 'a35',
          about: 'Доходы на 1 НПР',
          idUsl: 6,
        },
        {
          title: 'A36',
          dataFieldName: 'a36',
          about: 'Доходы на 1 НПР',
          idUsl: 6,
        },
      ]
    }
  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl: 'get_spr_data_usl', get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_pokazateli: 'get_spr_data_pokazateli', get_spr_data_pokazateli_loading: 'get_spr_data_pokazateli_loading',
    }),
    ...mapGetters('checkFilled', {getMainOrg: 'getMainOrg', getFilials: 'getFilials'}),
    indicator() {
      return this.indicators != null ? {data: this.indicators, fo: this.idFO} : {data: null, fo: this.idFO}
    },
    idFO() {
      return this.$route.query.fo != null ? Number.parseInt(this.$route?.query.fo) : 1
    },
    foName() {
      return this.foList.find(el => el.id === this.idFO)
    },
    middleValue() {
      return this.$route.query.value != null ? Number.parseFloat(this.$route.query?.value) : null
    },
    idUsl() {
      return this.$route.query.usl != null ? Number.parseInt(this.$route?.query.usl) : 1
    },
    uslName() {
      return this.get_spr_data_usl.data.find(el => el.id === this.idUsl)?.name ?? ''
    },
    codeName() {
      return this.$route.query.code ?? ''
    },
    kcpName() {
      return this.$route.query.name ?? ''
    },
    orgId() {
      return Number.parseInt(this.$route.query.org ?? null) ?? null;
    },
    kcpId() {
      return Number.parseInt(this.$route.query.kcpId ?? null) ?? null;
    },
    id() {
      return this.$route.query.id != null ? Number.parseInt(this.$route.query?.id) : null
    },
    defaultText() {
      return this.codeName + ' ' + this.kcpName + '. ' + this.uslName + ' - ' + this.foName?.text;
    },
    availableMainCard(){
      return this.indicators.isbsm22 === '1'
    },
    chosenOrg() {
      // const orgId = this.$route.query.org;
      if (this.getMainOrg.data?.id === this.orgId)
        return this.getMainOrg.data;
      else if (this.getFilials.data.find(el => el.id === this.orgId))
        return this.getFilials.data.find(el => el.id === this.orgId);
      else return null;
    },
    nameOrgForRegion(){
      return  this.indicators?.orgId?.name ?? ''
    },
    bIndicatorsCalculated() {
      // if (this.indicator.data != null)
        return this.bIndicators
            .filter(el => el.idUsl === this.idUsl)
            .map(el => {
              const sprEl = this.get_spr_data_pokazateli.data.find(pok => pok.pid.toLowerCase() === el.dataFieldName);
              if (sprEl)
                return {
                  ...el,
                  // count: (Math.random() *100).toFixed(sprEl.prd),
                  count: this.indicators[sprEl.pid.toLowerCase()+'wt22'] == '0' ? 'Показатель не рассчитывается для этой УГСН(НПС)' : this.$scripts.TableJs.checkValueWithNullAndReturnND(this.indicators[sprEl.pid.toLowerCase()+'o'+this.foName?.value +'22'], 3),
                  max: sprEl.pmx,
                  min: sprEl.pmi,
                  about: sprEl.pnm,
                  available: this.indicators[sprEl.pid.toLowerCase()+'wt22'] === '0' ? false : true,
                  ppr: sprEl.ppr,
                  ptx: sprEl.ptx,
                  maxGradient: this.indicators[sprEl.pid.toLowerCase()+'wt22'],
                  minGradient: 0,
                  prd: sprEl.prd
                }
              return {
                ...el,
                count: (Math.random() *100).toFixed(3),//this.indicator.data[el.dataFieldName + 's0'] === '1' ? this.indicator.data[el.dataFieldName + this.idFO] :
                    //'Показатель не рассчитывается для этой УГСН(НПС)',
                max: 100//this.indicator.data[el.dataFieldName + 'w']
              }
            })
      // else return []
    }
  },
  watch: {
    $route() {
      this.initHeader();
    },
    chosenOrg(){
      this.initHeader()
    }
  },
  created() {
    this.initHeader();
    this.loadingAppeals();
    this.getAllKcpPok();
    this.getServerData();
  },
  methods: {
    ...mapActions('spr', {getAllKcpPok: 'getAllKcpPok'}),
    async loadingAppeals(){
      this.laodingAppeals = true;
      const res = await getAppeals();
      this.appealsArr = res.filter(el => el.kcp.id === this.kcpId && el.fo.id === this.idFO && el.usl.id === this.idUsl && el.org?.id === this.orgId);
      this.laodingAppeals = false;
    },
    async getServerData(){
      try{
        this.loading = true;
        this.indicators = await getKcpPokFull(this.orgId,this.kcpId, this.idUsl, this.idFO)
        this.loading = false;
      }catch (e) {
        this.loading = false;
        console.error('getServerData '+ e)
      }
    },
    closingAppealDialog() {
      this.appeal = null;
      this.appealInfo = null;
    },
    async openAppeal(e) {
      this.appeal = this.appealsArr.find(el => el.indicator === e.dataFieldName) ?? null;
      this.appealInfo = {...e, usl: {id: this.idUsl}, fo: {id: this.idFO},kcp: {id: this.kcpId}, org: {id: this.orgId}};
      await this.$refs["appeal-dialog"].openDialog();
    },
    saveValue(e) {
      const ind = this.appealsArr.findIndex(el => el.indicator === e.indicator)
      if (ind === -1)
        this.appealsArr.push(e);
      else this.appealsArr.splice(ind, 1, e)
      this.$refs["appeal-dialog"].closeDialog();
    },
    initHeader() {
      let org = '';
      if (this.chosenOrg)
        org = `<span class="formul-text-table">${this.chosenOrg.name}</span>`;
      this.$route.meta.title = org + '<br/> ' + this.defaultText;
    }
  },
}
</script>

<style scoped>
.header-columns {
  font-weight: 500;
  font-size: 12px;
  line-height: 124.9%;
  /* identical to box height, or 15px */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Anton Grey */
  border-bottom: 1px solid #78909C;
  color: #78909C;
}
</style>