<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      </v-col>
    </v-row>
    <v-row v-if="rowOfValues.data.isbsm22 === '1'" dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.08. (фрагмент)</span><br>
          Численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры
          <br>
          по данным формы ВПО-1, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isbsm22 === '1'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="6">Обучающихся (искл. квоту иностр.граждан)</th>
        <th rowspan="3">...</th>
      </tr>
      <tr>
        <th colspan="3">на 1.10.2020 г.</th>
        <th colspan="3">на 1.10.2021 г.</th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th v-for="(item) in 6" :key="item">{{ item + 2 }}</th>
        <th style="min-width: 50px"> ...</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgo20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgv20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgz20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgz21, 0, 'integer') }}</td>
        <td>...</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          Npr = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgs20, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          N = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgs21, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense v-if="rowOfValues.data.isbsm22 === '1'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.10. (фрагмент)</span><br>
          Численность принятых на обучение и завершивших обучение по программам бакалавриата, программам специалитета,
          программам магистратуры
          <br>
          без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом,
          обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование
          иностранных граждан и лиц без гражданства в Российской Федерации
          <br>по данным формы ВПО-1, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isbsm22 === '1'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="3">Принятых на обучение <br>(искл. квоту иностр.граждан)</th>
        <th colspan="3">Выпускников <br>(искл. квоту иностр.граждан)</th>
        <th rowspan="3">...</th>
      </tr>
      <tr>
        <th colspan="3">на 1.10.2020 г.</th>
        <th colspan="3">на 1.10.2021 г.</th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th v-for="(item) in 6" :key="item">{{ item + 2 }}</th>
        <th style="min-width: 50px"> ...</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsgz21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvsgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvsgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvsgz21, 0, 'integer') }}</td>
        <td>...</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          PO = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsgs21, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          VO = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvsgs21, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense v-if="rowOfValues.data.isbsm22 === '0'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.15. (фрагмент)</span><br>
          Численность обучающихся по программам ординатуры, программам ассистентуры-стажировки
          <br>по данным формы 1-НК, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isbsm22 === '0'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="6">Обучающихся (искл. квоту иностр.граждан)</th>
        <!--      <th colspan="3">Выпускников <br>(искл. квоту иностр.граждан)</th>-->
        <th rowspan="3">...</th>
      </tr>
      <tr>
        <th colspan="3">на 1.01.2020 г.</th>
        <th colspan="3">на 1.01.2021 г.</th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th v-for="(item) in 6" :key="item">{{ item + 2 }}</th>
        <th style="min-width: 50px"> ...</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgo20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgv20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgz20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgz21, 0, 'integer') }}</td>
        <td>...</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          Npr = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgs20, 0, 'integer') }}
        </td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero">

        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          N = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgs21, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense v-if="rowOfValues.data.isbsm22 === '0'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.16. (фрагмент)</span><br>
          Численность принятых на обучение и завершивших обучение по программам ординатуры, программам
          ассистентуры-стажировки
          <br>
          без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом,
          обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование
          иностранных граждан и лиц без гражданства в Российской Федерации
          <br>
          по данным формы 1-НК, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isbsm22 === '0'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="3">Принятых на обучение<br>
          (искл. квоту иностр.граждан)
        </th>
        <th colspan="3">Выпускников<br>
          (искл. квоту иностр.граждан)
        </th>
        <!--      <th colspan="3">Выпускников <br>(искл. квоту иностр.граждан)</th>-->
      </tr>
      <tr>
        <th colspan="3">в 2020 г.</th>
        <th colspan="3">в 2020 г.</th>
      </tr>
      <tr>
        <th colspan="3">
          очно
        </th>
        <th colspan="3">
          очно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th colspan="3">3</th>
        <th colspan="3">4</th>
        <!--      <th v-for="(item) in 6" :key="item">{{ item + 2 }}</th>-->

      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td colspan="3">{{
            $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsgs21, 0, 'integer')
          }}
        </td>
        <td colspan="3">{{
            $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvsgs21, 0, 'integer')
          }}
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
        <td colspan="3" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="3" class="border-bottom-zero border-right-zero formul-text-table">
          PO
        </td>
        <td colspan="3" class="border-bottom-zero border-right-zero formul-text-table">
          VO
        </td>
      </tr>
      </tbody>
    </table>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          N <span class="alternate-text mx-1">+</span> VO <span class="alternate-text mx-1">−</span> PO
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">Npr</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text">× 100</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgs21, 0, 'integer') }} <span
            class="alternate-text mx-1">+</span>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nvsgs21, 0, 'integer') }} <span
            class="alternate-text mx-1">−</span>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsgs21, 0, 'integer') }}
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgs20, 0, 'integer')
            }}</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text">× 100</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName + 'b022'], item.prd+1, 'float')
            }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>N, Npr - численность обучающихся по данным отчетного года и года, предшествующего отчетному, соответственно</p>
    <p>VO, PO - численность выпуска обучающихся и принятых на обучение по данным отчетного года, соответственно</p>
    <p >Значения N, Npr, VO, PO принимаются за исключением обучающихся, принятых в рамках установленной
      Правительством Российской Федерации квоты на образование иностранных граждан и лиц без гражданства в Российской
      Федерации
    </p>
    <p v-if="rowOfValues.data[item.dataFieldName+'nf22'] !== '0'" class="mb-4">Если значение NPR менее 5, значение показателя не рассчитывается, а его нормированная оценка определяется в соответствии с пунктом 7 Методики (по средним).</p>
  </div>
</template>

<script>
export default {
  name: "b32",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>

</style>