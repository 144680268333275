<template>
  <section>
    <div v-if="statusOrg.numBsmAdd !== null" class="container-files-kcp">
      <div class="container-files-kcp__title">
        Приложение к приказу от&nbsp;«24» апреля 2023 г. № 440
      </div>
      <div>
        <div class="d-flex justify-space-between mb-2">
          <v-btn class="success__v-btn"
                 @click="downloadFile(null,`/api/report/getPrikazPril?orgId=${getOrgId}&prikazType=6`,
            true, 'Итог перераспределения КЦП БСМ.pdf', 'loadingFileKcpAdd')"
                 :loading="loadingFileKcpAdd">Итоги перераспределения контрольных цифр приема по программам
            бакалавриата, программам специалитета, программам магистратуры</v-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {setFileFunctions} from "@/mixins/fileFucntions";
import {mapGetters} from "vuex";

export default {
  name: "ContainerKCPInformationDocuments",
  mixins: [setFileFunctions],
  props: {
    statusOrg: Object
  },
  data() { return {
    loadingFile1: false,
    loadingFile2: false,
    loadingFile3: false,
    loadingFile4: false,
    loadingFile5: false,
    loadingFileKcpAdd: false,
  }},
  computed: {
    ...mapGetters('auth', {authData: 'authData', get_replace_id_admin: 'get_replace_id_admin'}),
    getOrgId() {
      return this.get_replace_id_admin;
    }
  }
}
</script>

<style lang="sass">
.container-files-kcp
  display: flex
  flex-direction: row
  align-items: center

  &__title
    display: flex
    flex-direction: row
    flex-basis: 300px
    min-width: 300px
    margin-right: 10px

.info-card__button
  margin-top: 10px
  margin-left: 10px

.file-edit
  display: flex
  align-items: center

.file-edit .v-file-input
  max-width: 300px
  margin-right: 10px
  min-width: 200px

.file-edit
  display: inline-flex
  align-items: center
  background: #FFEDE2
  padding: 0 15px
  border-radius: 10px
  margin: 20px

.border_margin_right
  margin-right: 10px
</style>
