<template>
  <div>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <GridTable
          :data-rows="showingServerData"
          :headers="headers.massive"
          :headers-for-rows="headersForRows"
          :loading-data="loadingData"
          :num-cols="headers.countCells"
          :max-table-height="maxTableHeight"
          ref="npg-table"
          key="form-4"
      >
        <template #header-npg0="{item}">
          <v-btn  @click="loadFullInfo" class="white__v-btn">{{ !full ? 'Показать все' : "Скрыть пустые" }}  строки </v-btn>
          {{item.text}}
        </template>
        <!--  v-if="!changingHeaders"  :key='' когда меняется хедер проверить    -->
        <template v-for="item in headersForRows" v-slot:[calcHeaderName(item.dataField)]>
          <div :key="item.dataField" :class="item.headerClass" style="max-height: 22px;min-width: 100%">
            <template v-if="item.text === '1'">
              <div style="min-width: 60px">1</div>
              <div class="text-center" style="width: 100%">2</div>
            </template>
            <template v-else>
              <div class="d-flex flex-row align-center" style="max-height: 22px;">
                {{ item.text }}
              </div>
            </template>
          </div>
        </template>
        <template #npg="{row}">
          <template v-if="row.uslName">
            <div class="font-weight-bold font-size-medium ">
              {{ row.uslName[0].toUpperCase() + row.uslName.slice(1) }}
            </div>
          </template>
          <template v-else>
            <div style="">
              {{ row.sprSpec ? row.sprSpec.kodSpec : row.sprKcpUgs.code }}
            </div>
            <div class="ml-3">
              {{ row.sprSpec ? row.sprSpec.name : row.sprKcpUgs.name }}
            </div>
          </template>
        </template>
        <template v-for="item in headersForRowsWithEditableCells" v-slot:[item.dataField]="{row,cell}">
          <threeFourTableCell :key="item.dataField" v-bind="item.props(row,cell, loadingMenu)"
                              @open-dialog="openDialog($event, item.dataField, cell, row)"/>
        </template>
      </GridTable>
      <div class='font-size-small' style="color: #89A0B0;">
        Примечание: сведения об аккредитациях внесены на основании данных конкурса прошлого года
      </div>
      <div class="mt-3 d-flex flex-row justify-center align-center">
        <v-autocomplete
            class="pagination-table"
            v-model="userPages"
            :items="pagesPaginate"
            solo
            dense
            filled
            height="25"
            rounded
            style="max-width: 150px"
            @change="changePage($event)"
        ></v-autocomplete>
        <v-pagination
            v-model="page"
            :length="pagesVuetify"
            :total-visible="7"
            @input="changePage($event)"
        >
        </v-pagination>

      </div>
    </WrapperPreviewCategory>
    <tooltip-forms5-dialog
        ref="tooltip-dialog"
        :data-field="dataFieldTooltip"
    >
    </tooltip-forms5-dialog>
    <universal-dialog-v1
        ref="dialog"
        :data-for-dialog="dataForDialog"
        :headers-for-dialog="headersForDialog"
        @fetch-new-data="fetchDialogData($event)"
        :loading="savingData"
        :additional-data="additionalData"
        @closing-dialog="clearDataForDialog()"
    >
      <template #title>
        <h2>{{ mainHeaderTitle ? mainHeaderTitle : 'Добавить' }}</h2>
      </template>
      <template #actions="{close, sendData, loading, validSolo, isOneStage}">
        <div class="d-flex flex-row align-center justify-center" style="width: 100%">
          <v-btn
              class='white__v-btn'
              @click="close()"
              width="120px"
          >
            Отменить
          </v-btn>
          <v-btn
              v-if="isOneStage"
              class='blue__v-btn  ml-2'
              @click="sendData()"
              :loading="loading"
              :disabled="!validSolo"
              width="120px"
          >
            Отправить
          </v-btn>
        </div>
      </template>
    </universal-dialog-v1>
  </div>
</template>

<script>
import GridTable from "@/components/GridTable/GridTable";
import form4 from "@/helpers/forms2-4/form4";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {getFormData} from "@/API/form5";
import {mapGetters} from "vuex";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import {setEditDialog} from "@/mixins/forms2-4/editDialog";
// import EditForms34Dialog from "@/components/Dialogs/editFormsThreeFourDialog";
import threeFourTableCell from "@/components/fields/threeFourTableCell";
import {tablePagination} from "@/mixins/tablePagination";
import UniversalDialogV1 from "@/components/Dialogs/universalDialog(v1.1)";
import {sendFormData} from "@/API/forms2-4";

export default {
  name: "form4",
  mixins: [headerTooltipFunctions, setEditDialog, tablePagination],
  // eslint-disable-next-line vue/no-unused-components
  components: {
    UniversalDialogV1,
    WrapperPreviewCategory, GridTable, tooltipForms5Dialog,  threeFourTableCell
  },  data() {
    return {
      serverData: [],
      loadingData: false,
      changingHeaders: false,
      headers: form4,
      editApi: '/api/form4/set',
      full : false,
      savingData : false,
      dataForDialog: null,
      additionalData: null,
      mainHeaderTitle: '',
      headersForDialog: [
        {
          "text": "Наличие аккредитации",
          "dataField": "isAccred",
          "typeVar": "customSelector",
          "data": [
            {value: true, text: 'Есть'},
            {value: false, text: 'Нет'},
          ],
          "rules": 1,
          "order": 0,
        },
        {
          "text": "Регистрационный номер",
          "dataField": "gasnm22K21",
          "typeVar": "string",
          "rules": 1,
          "customsRulesArray": [
            v => !!v || 'введите значение',
          ],
          "order": 1,
        },
        {
          "text": "Дата выдачи",
          "dataField": "gadtb22K21",
          "typeVar": "date",
          "rules": 1,
          "order": 2,
        },
        {
          "text": "Дата окончания действия ",
          "dataField": "gadte22K21",
          "typeVar": "date",
          "rules": 1,
          "order": 3,
        },
        {
          "text": "Примечания " +
              "(Год первичного обязательства в получении государственной аккредитации)",
          "dataField": "gatxt22K21",
          "typeVar": "string",
          "rules": 1,
          "order": 4,
        },
      ]

    }
  },
  created() {
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    },

  },
  computed: {
    ...mapGetters('spr', {
      get_spr_data_usl_loading: 'get_spr_data_usl_loading',
      get_spr_data_usl_error: 'get_spr_data_usl_error',
    }),
    maxTableHeight() {
      return window.screen.availHeight - 350 + 'px'
    },
    headersForRows() {
      return this.headers.massive.filter(el => el.variable).map(el => {
        return {
          ...el,
          styles: (row) => {
            if (el.dataField !== 'npg')
              return row.uslName ? 'display: none;' : 'justify-content: unset; text-align: center; '
            else
              return ''
          },
          colsDataCalc: (row, cell) => {
            if (el.dataField !== 'npg')
              return cell.cols;
            else
              return row.uslName ? 6 : 1;
          },
          props: (row, cell, loading) => {
            return {
              value: row[cell.dataField],
              title: '',
              cell: cell,
              id: row.id,
              row: row,
              loading: loading,
            }
          }
        }
      })
    },
  },
  methods: {
    loadFullInfo(){
      this.full = !this.full;
      this.loadingDataTable()
    },
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getFormData('', true, `/api/form4/get?full=${this.full}`);
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },
    clearDataForDialog() {
      this.mainHeaderTitle = '';
      this.dataForDialog = null;
      this.additionalData = null;
    },
    async openDialog(e, dataField, cell, row){
      if (row.sprSpec)
        this.mainHeaderTitle = row.sprSpec?.kodSpec  + ' ' + row.sprSpec?.name;
      else
        this.mainHeaderTitle = row.sprKcpUgs?.code  + ' ' + row.sprKcpUgs?.name;
      this.additionalData = {
        id: row?.id ?? null,
        "sprSpec": row?.sprSpec?.id ? {
          "id": row?.sprSpec?.id,
        } : null,
        "sprKcpUgs": row.sprKcpUgs?.id ?{
          "id": row.sprKcpUgs?.id,
        } : null,
        "sprOrgId": row.sprOrgId,
      };
      this.dataForDialog = {
        gasnm22K21: row.gasnm22K21,
        isAccred: row.isAccred,
        gadtb22K21: row.gadtb22K21,
        gadte22K21: row.gadte22K21,  //date   this.parseDate(
        gatxt22K21: row.gatxt22K21,
      };
      await this.$refs.dialog.openDialog()
    },
    formatDate (date) {
      if (!date) return null;
      const  [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async fetchDialogData(obj) {
      this.savingData = true;
      try {
        const mail = {
          ...obj,
          gatxt22K21: obj.gatxt22K21 ?? '',
          // gldtb22K21: obj.gldtb22K21, //this.formatDate(
        }
        const res = await sendFormData(this.editApi, mail);
        if (res) {
          this.savingData = false;
          if (obj.id) {
            const index = this.serverData.findIndex(el => el.id === obj.id);
            this.serverData.splice(index, 1,res) //res.data
          } else {
            const index = this.serverData.findIndex(el => el.sprKcpUgs?.id === obj.sprKcpUgs?.id);
            this.serverData.splice(index, 1, { ...res, sprKcpUgs: this.serverData[index].sprKcpUgs})
          }
          this.dataForDialog = null;
          await this.$refs.dialog.closeDialog();
        }
        this.savingData = false;
      } catch (e) {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения',
          timeout: 3000
        });
        console.log("e", e)
        this.savingData = false;
      }
    }
  },
}
</script>

<style scoped>

</style>