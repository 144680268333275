<template>
  <div>
    <v-app-bar
        app
        class="top-bar"
        color="#FFFFFF"
        elevation="0"
        :extended="false"
        ref="nav_top_bar"
        :height="navTopBarHeight"
    >
      <div class="top-bar__title">
        <span v-html="routerTitle"></span><br> {{orgName}}
      </div>

      <!--      <v-spacer></v-spacer>-->
      <!--      <v-btn-->
      <!--          class="blue__v-btn mr-2"-->
      <!--          v-if="isAdmin && adminAuthInAnotherOrg"-->
      <!--          @click="adminLogout()"-->
      <!--      >-->
      <!--        Выйти из организации-->
      <!--      </v-btn>-->
    </v-app-bar>
    <v-navigation-drawer
        :width="navDrawerWidth"
        app
        :fixed="true"
        mobile-breakpoint="300"
    >
      <div class="header__logo" :style="`min-height: ${navTopBarHeight}px!important`">
        <router-link to="/">
          <img alt="ИНЕД" style="width: 40px; height: 40px;color: #00599b;" src="../../assets/icons/Logo_ined.svg">
          <span v-if="showNavigation" class="white--text ml-2 font-size-medium text-uppercase"
          >Интеробразование</span>
        </router-link>
      </div>
      <div class="v-navigation-drawer__center">
        <nav>
          <div class="nav-user-profile" :class="{'nav-user-profile__hide': !showNavigation}">
            <div class="nav-user-profile__main">
              <v-row no-gutters>
                <v-col cols="3">
                  <v-avatar alt="" src="" width="47" height="47">
                    <img alt="" src="@/assets/images/pngtree-university-emblem-logo-image_83109.jpg">
                  </v-avatar>
                </v-col>
                <transition name="slide-fade">
                  <v-col v-if="showNavigation" class="d-flex ml-2 flex-column justify-center">
                    <div class="nav-user-profile__main-user">{{ userName }}</div>
                    <div class="nav-user-profile__main-user-role">{{ userRole.text }}</div>
                  </v-col>
                </transition>
              </v-row>
            </div>
            <transition name="slide-fade">
              <div v-if="showNavigation" class="nav-user-profile__actions">
                <v-btn class="white__v-btn mr-3" to="/">
                  Профиль
                </v-btn>
                <v-btn class="white__v-btn" @click="logout">
                  Выход
                </v-btn>
              </div>
            </transition>
            <v-btn v-if="showNavigation && get_replace_id_admin && isAdmin" class=" mt-2 white__v-btn"
                   @click="logoutAdmin" height="25px">
              Выход из кабинета
            </v-btn>
          </div>
          <transition-group name="slide-fade">
            <template v-if="showNavigation">
              <nav-list v-for="(item, j) in navLists" :key="j" :list="item"
                        @click-nav-item="changeOrgId($event)"></nav-list>
            </template>
          </transition-group>
        </nav>
      </div>

      <div class="v-navigation-drawer__bottom" :class="{'v-navigation-drawer__hide': !showNavigation}"
           @click="showNavigation = !showNavigation">
        <v-btn icon>
          <v-icon
              class="v-navigation-drawer__bottom-icon " :class="{'v-navigation-drawer__hide-icon': showNavigation}"
              style="height: 14px; width: 14px;">mdi-chevron-double-right
          </v-icon>
        </v-btn>
        <span v-if="showNavigation">Свернуть</span>
      </div>
    </v-navigation-drawer>
  </div>

</template>

<script>

import NavList from "@/components/Lists/navList";
import {mapActions, mapGetters} from "vuex";
import {getAppealChangeCorrectValues} from "@/API/indicators";

const title = 'Открытый публичный конкурс по распределению контрольных цифр приема';
export default {
  name: "navBar",
  components: {NavList},
  data() {
    return {
      navDrawerWidthDefault: 265,
      showNavigation: true,
      navTopBarHeight: 64,
      headerTitle: '',
      to: '',
      from: '',
      routerTitle: '',
      orgName: '',
      navListsDefault: [
        {
          headText: 'Заявка',
          headerTextClass: 'pa-2',
          active: true,
          icon: {
            color: 'green',
            name: '',
            isMdi: false,
            src: 'icon-nav-indicators.svg',
            classes: 'nav-lk__icon ml-2 icon_nav-lk'
          },
          roles: ['org', 'admin'],
          children: [
            {
              link: '/offers',
              text: 'Предложения по КЦП',
              role: 'org'
            },

            {
              link: '/license',
              text: 'Сведения о лицензии',
              role: 'org'
            },
            {
              link: '/accreditation',
              text: 'Сведения об аккредитации',
              role: 'org'
            },
            {
              link: '/forms',
              text: 'Показатели деятельности',
            },
            {
              link: '/print',
              text: 'Печать',
            },
          ]
        },
        {
          headText: 'Дополнительные данные',
          headerTextClass: 'pa-2',
          active: false,
          icon: {
            color: 'green',
            name: '',
            isMdi: false,
            src: 'icon-nav-other.svg',
            classes: 'nav-lk__icon ml-2 icon_nav-lk'
          },
          roles: ['org', 'admin'],
          children: [

            {
              link: '/form-a',
              text: 'Таблица A.',
              role: 'org'
            },
            {
              link: '/form-b',
              text: 'Таблица Б.',
              role: 'org'
            },
            {
              link: '/form-v',
              text: 'Таблица В.',
              role: 'org'
            },
            {
              link: '/form-g',
              text: 'Таблица Г.',
              role: 'org'
            },

          ]
        },
        {
          headText: 'Результаты',
          headerTextClass: 'pa-2',
          active: true,
          icon: {
            color: 'green',
            name: '',
            isMdi: false,
            src: 'icon-nav-indicators.svg',
            classes: 'nav-lk__icon ml-2 icon_nav-lk'
          },
          roles: ['org', 'admin'],
          children: [
            {
              link: '/indicators',
              text: 'Сводные оценки заявок',
              role: 'org'
            },
            {
              link: '/indicators-stage1',
              text: 'Распределение КЦП',
              role: 'org'
            },

          ]
        },
        {
          headText: 'Заявка',
          headerTextClass: 'pa-2',
          active: true,
          icon: {
            color: 'green',
            name: '',
            isMdi: false,
            src: 'icon-nav-other.svg',
            classes: 'nav-lk__icon ml-2 icon_nav-lk'
          },
          roles: ['founder'],
          children: [
            {
              link: '/accreditation_agree',
              text: 'Одобрение аккредитаций',
              role: 'org'
            },
          ]
        },
        {
          headText: 'Организации',
          headerTextClass: 'pa-2',
          orgs: true,
          active: true,
          icon: {
            color: 'success',
            name: 'mdi-format-list-checkbox',
            isMdi: true,
            classes: 'nav-lk__icon ml-2 icon_nav-lk'
          },
          roles: ['org', 'admin'],
          children: []
        },
        {
          headText: 'Разделы администратора',
          active: true,
          headerTextClass: 'pa-2',
          roles: ['admin'],
          children: [
            {
              link: '/reference',
              text: 'Список организаций',
              icon: {
                color: 'blue',
                name: '',
                isMdi: false,
                src: 'list.svg',
                classes: 'nav-lk__icon ml-2 icon_nav-lk-children'
              },
            },
            {
              link: '/exports_admin',
              text: 'Выгрузки',
              icon: {
                color: 'primary',
                name: 'mdi-file-export',
                isMdi: true,
                src: 'icon-success.svg',
                classes: 'nav-lk__icon  ml-2 icon_nav-lk-children'
              },
            },
            {
              link: '/requests',
              text: 'Заявки',
              icon: {
                color: 'blue',
                name: '',
                isMdi: false,
                src: 'icon-primary.svg',
                classes: 'nav-lk__icon ml-2 icon_nav-lk-children'
              },
            },
            {
              link: '/appeals',
              text: 'Формы апелляций',
              icon: {
                color: 'blue',
                name: '',
                isMdi: false,
                src: 'icon-warning.svg',
                classes: 'nav-lk__icon ml-2 icon_nav-lk-children'
              },
            },
            {
              link: '/appeals-indicators',
              text: 'Формы апелляций по показателям',
              icon: {
                color: '#00599B',
                name: 'mdi-file-document-outline',
                isMdi: true,
                src: '',
                classes: 'nav-lk__icon ml-2 icon_nav-lk-children'
              },
            },
            {
              link: '/konverts',
              text: 'Регистрация конвертов',
              icon: {
                color: '#00599B',
                name: 'mdi-email',
                isMdi: true,
                src: 'icon-success.svg',
                classes: 'nav-lk__icon  ml-2 icon_nav-lk-children'
              },
            },
          ]
        },
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.from = from;
        this.to = to;
        document.title = to.meta?.title ? title + ' - ' + to.meta?.title : title;
        // this.$nextTick(() => {
        //   this.headerTitle = to.meta?.title ?? '';
        // })
      }
    },
    to: {
      immediate: true,
      handler(newValue){
        this.$nextTick(() => {
          this.routerTitle = newValue.meta.title ?? '';
        });
      }
    },
    filialsLoading() {
      this.initFilials();
    },
    mainOrgLoading() {
      this.initMainOrg();
      if (!this.isRegion)
      this.changeDefaultNavBarList();
    }
  },
  created() {
    if (!this.getMainOrg.data && this.userRole.value !== 'founder' && this.userRole.value !== 'region')
    {
      this.getOrgInfo();
      this.changeDefaultNavBarList()
    }
    if (!this.getFilials.data && this.userRole.value !== 'founder' && this.userRole.value !== 'region')
      this.getFilialsOrgInfo();
    this.initMainOrg();
    this.initFilials();

  },
  computed: {
    navDrawerWidth() {
      return this.showNavigation ? this.navDrawerWidthDefault : 70
    },
    ...mapGetters('auth', {
      authData: 'authData',
      get_replace_id_admin: 'get_replace_id_admin',
      get_replace_id: 'get_replace_id',
      replaceNameAdmin: 'replaceNameAdmin'
    }),
    // ...mapGetters('pages', {
    //   get_pages: 'get_pages',
    //   getStatus: 'getStatus'
    // }),
    ...mapGetters('keycloak', {
      isAdmin: 'isAdmin',
      isFounder: 'isFounder',
      isRegion: 'isRegion',
      isOrganization: 'isOrganization',
    }),
    ...mapGetters('checkFilled', {
      getFilials: 'getFilials',
      getMainOrg: 'getMainOrg',
    }),
    filialsLoading() {
      return this.getFilials.loading
    },
    mainOrgLoading() {
      return this.getMainOrg.loading
    },
    adminAuthInAnotherOrg() {
      return !!this.get_replace_id_admin
    },
    userName() {
      return this.isAdmin ? this.replaceNameAdmin ?? 'Администратор' : this.authData?.given_name ? this.authData?.given_name : this.getMainOrg.data?.name
    },
    navLists() {
      return this.navListsDefault.filter(el => {
        if (el?.admin && el.admin === true) {
          return this.isAdmin ? el : null;
        } else if (el.roles.includes(this.userRole.value))
          return el
      }).map(el => {
        const childrens = el.children.map(el2 => {
          return {...el2, show: true || !!el2.ignore}
        })
        return {...el, children: childrens};
      })
      // if (this.authData.realm_access.roles.indexOf("ADMIN_ROLE") !== -1) {
      //   return this.navListsDefault.filter(el => el.role === 'admin')
      // } else {
      //   return this.navListsDefault.filter(el => el.role !== 'admin')
      // }
    },
    userRole() {
      if (this.isAdmin) return {value: 'admin', text: 'Администратор'}
      else if (this.isFounder) return {value: 'founder', text: 'Министерство'}
      else if (this.isRegion) return {value: 'region', text: 'Субъект РФ'}
      else return {value: 'org', text: 'Организация'};
    }
  },
  mounted() {
    window.addEventListener("resize", this.calculateHeightTopBar);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateHeightTopBar);
  },
  methods: {
    ...mapActions('keycloak', {keycloakLogout: 'keycloakLogout'}),
    ...mapActions('auth', {replaceIdAdmin: 'replaceIdAdmin'}),
    ...mapActions('auth', {replaceId: 'replaceId'}),
    ...mapActions('checkFilled', {getOrgInfo: 'getOrgInfo'}),
    ...mapActions('checkFilled', {getFilialsOrgInfo: 'getFilialsOrgInfo'}),
    logoutAdmin() {
      this.replaceIdAdmin({id: null, role: 'admin'});
      this.$router.push('/reference');
    },
    async changeDefaultNavBarList(){
      try {
        const res = await getAppealChangeCorrectValues();
        const section = res.length > 0;
        if (section)
        {
          this.showNavigation = false;
          const searchRes = this.navListsDefault.find(el => el.headText === 'Предварительные результаты');
          const exist = searchRes.children.find(el => el.link === '/indicators-stage-correct-values')
          if (!exist) {
            searchRes.children.push(
                {
                  link: '/indicators-stage-correct-values',
                  text: 'Согласование увеличения заявок',
                  role: 'org'
                },
            );

          }
          this.showNavigation = true;
        }
      }catch (e) {
        console.log(e)
      }
    },
    changeOrgId(e) {
      this.orgName = e.text;
      this.replaceId(e.orgId);
      const item = this.navListsDefault.find(el => el.orgs === true);
      item.children = [];
      this.initMainOrg();
      this.initFilials();
    },
    initMainOrg() {
      if (this.userRole.value === 'founder') return;
      if (this.getMainOrg.data && this.getMainOrg.loading === false && !this.getMainOrg.error) {
        const item = this.navListsDefault.find(el => el.orgs === true);
        item.children = [];
        const res = this.getMainOrg.data;
        const searchRes = item.children.findIndex(el => el.orgId === res.id)
        if (searchRes === -1)
        item.children.push({
          action: true,
          text: res.name,
          name: res.name,
          role: 'org',
          orgId: res.id,
          class: 'mt-0',
          active: this.get_replace_id === res.id,
          itemClass: 'font-size-small__important mt-1'
        });
        else{
            item.children.splice(searchRes,1,{
              action: true,
              text: res.name,
              name: res.name,
              role: 'org',
              orgId: res.id,
              class: 'mt-0',
              active: this.get_replace_id === res.id,
              itemClass: 'font-size-small__important mt-1'
            })
        }
      }
    },
    initFilials() {
      if (this.userRole.value === 'founder') return;
      if (this.getFilials.data && this.getFilials.loading === false && !this.getFilials.error) {
        const item = this.navListsDefault.find(el => el.orgs === true);
        this.getFilials.data.forEach(el => {
          const searchRes = item.children.findIndex(el2 => el2.orgId === el.id)
          if (searchRes === -1)
            item.children.push({
              action: true,
              text: el.name,
              role: 'org',
              orgId: el.id,
              class: 'mt-0',
              active: this.get_replace_id === el.id,
              itemClass: 'font-size-small__important mt-0'
            });
          else{
            item.children.splice(searchRes,1,{
              action: true,
              text: el.name,
              role: 'org',
              orgId: el.id,
              class: 'mt-0',
              active: this.get_replace_id === el.id,
              itemClass: 'font-size-small__important mt-0'
            })
          }
        })
      }
    },
    logout() {
      this.keycloakLogout()
    },
    calculateHeightTopBar() {
      if (!this.$refs.nav_top_bar) {
        return;
      }
      this.navTopBarHeight = this.$refs.nav_top_bar.computedHeight;
    },

  },
}
</script>

<style lang="sass">
.icon_nav-lk
  width: 40px
  height: 40px

.icon_nav-lk-children
  width: 20px
  height: 20px

.nav-lk__icon
  margin-right: 16px

.slide-fade-enter-active
  transition: all .8s ease

.slide-fade-enter, .slide-fade-leave-to
  opacity: 0
.primary-color-icon
  fill: #00599B!important
#app
  scrollbar-width: thin !important
  scrollbar-color: #00599B #CFE0EC

  ::-webkit-scrollbar
    width: 8px
    border-radius: 10px

  ::-webkit-scrollbar-track
    box-shadow: inset 0 0 6px #CFE0EC

  ::-webkit-scrollbar-thumb
    background-color: #00599B
    border-radius: 10px
//outline: 1px solid #00599B
</style>
