<template>
  <v-text-field
      v-model.lazy="vv"
      type="Number"
      class="input-text-center-dop"
      dense
      :rules="[
          v => v <= Number.parseInt(maxValue) || 'значение не может быть больше установленного'
      ]"
      :loading="loading"
      placeholder="Введите значение"
      color="primary"
      :disabled="disabled"
      @change="emitData($event)"
  >
    <template #default>
      <span class="text-center">{{vv}}</span>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "indicatorsFieldCorrect",
  props: {
    value: {
      type: [Number, String, Object],
      default: null
    },
    id: {
      type: [String, Number]
    },
    loading:{},
    maxValue:{
      type: [String, Number]
    },
    dataField: {
      type: [String, Number]
    },
    row: {
      type: [Object, String],
      validator: (value) =>{
        return ['string', 'object'].includes(typeof value)
      }
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
  },
  data() {
    return {
      vv: this.value
    }
  },
  methods: {
    async emitData(e){
      // if (!this.simpleBlock){
      try{
        if (this.vv <= Number.parseInt(this.maxValue))
          this.$emit('change-value-dop', {value: e, id: this.row.id, dataField: this.dataField});
      }catch (e) {
        this.vv = null;
        console.error('not emit ', e)
      }
      // }
    }
  },
}
</script>

<style scoped>

</style>