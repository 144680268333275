<template>
<div>
  <WrapperPreviewCategory customClass="section-preview_lk">
<!--    <v-btn @click="rwrw = !rwrw">rwrw</v-btn>-->
  <GridTable
      :data-rows="serverData"
      :rewrite-headers="rwrw"
      :headers="headers.massive"
      :headers-for-rows="headersForRows"
      :loading-data="loadingData"
      :num-cols="headers.countCells"
      :max-table-height="maxTableHeight"
      ref="npg-table"
      key="form-G"
  >
    <!--  v-if="!changingHeaders"  :key='' когда меняется хедер проверить    -->
    <template #header-npg0="{item}">
<!--      <v-btn  @click="loadFullInfo" class="white__v-btn">{{ !full ? 'Показать все' : "Скрыть пустые" }}  строки </v-btn>-->
      {{item.text}}
    </template>
    <template v-for="item in headersForRows" v-slot:[calcHeaderName(item.dataField)]>
      <div :key="item.dataField" :class="item.headerClass" style="max-height: 22px;min-width: 100%">
        <template v-if="item.text === '1'">
          <div style="min-width: 60px">1</div>
          <div class="text-center" style="width: 100%">2</div>
        </template>
        <template v-else>
          <div class="d-flex flex-row align-center" style="max-height: 22px;">
            {{ item.text }}
            <v-btn small icon @click="openTooltip(item.dataField)">
              <v-icon
                  :size="15"
                  class="grey-light-color__important "
              >
                mdi-information
              </v-icon>
            </v-btn></div>
        </template>
      </div>
    </template>
    <template #npg="{row}">
      <template v-if="row.uslName">
        <div class="font-weight-bold font-size-medium ">
          {{row.uslName[0].toUpperCase()+ row.uslName.slice(1) }}
        </div>
      </template>
      <template v-else>
        <div style="">
          {{row.spec ? row.spec.kodSpec  : row.sprKcpGroup.code }}
        </div>
        <div class="ml-3">
          {{row.spec ?  row.spec.name : row.sprKcpGroup.name}}
        </div>
      </template>
    </template>
<!--    <template v-for="item in headersForRowsWithEditableCells" v-slot:[item.dataField]="{row,cell}">-->
<!--      <fives-table-cell :key="item.dataField" v-bind="item.props(row,cell, loadingMenu)"-->
<!--                        @open-dialog="openDialog($event, item.dataField, cell, row)"/>-->
<!--    </template>-->
  </GridTable>

  </WrapperPreviewCategory>
  <tooltip-forms5-dialog
      ref="tooltip-dialog"
      :data-field="dataFieldTooltip"
  >
  </tooltip-forms5-dialog>
<!--  <appeal-dialog-->
<!--      ref="appeal-dialog"-->
<!--      :appeal-id="appealId"-->
<!--      :file-id="fileId"-->
<!--      :appeal="appealCell"-->
<!--      :value="appealValue"-->
<!--      :appeals-arr="appealsArr"-->
<!--      :appeal-info="appealInfo"-->
<!--      @saved-value="saveValue($event)"-->
<!--      @closing-dialog="closingAppealDialog"-->
<!--  ></appeal-dialog>-->
</div>
</template>

<script>
import GridTable from "@/components/GridTable/GridTable";
import formG from "@/helpers/dopForms/formG";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {mapGetters} from "vuex";
// import {getFormData} from "@/API/form5";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";
import {setAppealDialog} from "@/mixins/forms5/appealDialog";
import FivesTableCell from "@/components/fields/fivesTableCell";
import appealDialog from "@/components/Dialogs/appealDialog";
import {getFormData} from "@/API/form5";

export default {
  name: "formG",
  mixins: [headerTooltipFunctions, setAppealDialog],
  // eslint-disable-next-line vue/no-unused-components
  components: {FivesTableCell,  WrapperPreviewCategory, GridTable, tooltipForms5Dialog, appealDialog},
  data() {
    return {
      serverData: [],
      loadingData: false,
      changingHeaders: false,
      headers: formG,
      loadingMenu: {value: false, id: ''},
      full: false,
      rwrw: false
    }
  },
  created() {
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    }
  },
  computed: {
    ...mapGetters('spr', {get_spr_data_usl_loading: 'get_spr_data_usl_loading', get_spr_data_usl_error: 'get_spr_data_usl_error', }),
    maxTableHeight(){
      return window.innerHeight - 225 + 'px'
    },
    headersForRows(){
      return this.headers.massive.filter(el => el.variable).map(el =>{
        return {...el,
          styles: (row) => {
            if (el.dataField !== 'rdreg22Egs')
              return row.uslName ? 'display: none;' : 'justify-content: unset; text-align: center; '
            else
              return ''
          },
          colsDataCalc: (row, cell) => {
            if (el.dataField !== 'rdreg22Egs')
              return cell.cols;
            else
              return row.uslName ? 6 : 1;
          },
          props: (row, cell, loading) => {
            return {
              value: row[cell.dataField],
              title: '',
              cell: cell,
              id: row.id,
              row: row,
              loading: loading,
            }
          }
        }
      })
    },
  },
  methods: {
    loadFullInfo(){
      this.full = !this.full;
      this.loadingDataTable()
    },
    async loadingDataTable() {
      this.loadingData  = true;
      try{
        // const res  = await getFormData('getPartG', false,`/api/getG?full=${this.full}`, this.full);
        this.serverData =  await getFormData('getPartG', false,`/api/getG?full=${this.full}`, this.full);
        // res.map(el => {
        //   const paresEl = el.rdrub22Egs;
        //   return{
        //     ...el,
        //     rdrk122Egs: paresEl[0] ?? '',
        //     rdrk222Egs: paresEl[1] ?? '',
        //     rdrk322Egs: paresEl[2] ?? '',
        //     rdrk422Egs: paresEl[3] ?? '',
        //     rdrk522Egs: paresEl[4] ?? '',
        //   }
        // });
        this.loadingData = false;
      }catch (e) {
        this.loadingData = false;
      }
    },
  },
}
</script>

<style scoped>

</style>