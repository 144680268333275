<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
        <p>
          <span class="font-weight-bold mb-1">Форма 5.13. (фрагмент)</span><br>
          Дополнительные сведения о приеме на очную форму обучения по программам бакалавриата и специалитета<br>
          без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом,
          обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование
          иностранных граждан и лиц без гражданства в Российской Федерации
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="4" colspan="3" class="kcp-td text-left">Код и наменование УГСН (НПС)</th>
        <th colspan="3">Зачислено на обучение</th>
        <th colspan="3">Средний бал ЕГЭ</th>
      </tr>
      <tr>
        <th colspan="3">
          в 2021/2022<br/> учебном году
        </th>
        <th colspan="2">
          в 2021/2022<br/> учебном году
        </th>
      </tr>
      <tr>
        <th>
          без вступит.<br/> испытаний
        </th>
        <th>
          с учетом ЕГЭ,<br/> общ.конкур.
        </th>
        <th>
          с учетом ЕГЭ <br/>по цел.квоте
        </th>
        <th>
          по общ.<br/> конкурсу
        </th>
        <th>
          по цел. <br/>квоте
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно
        </th>
        <th>
          очно
        </th>

        <th>
          очно
        </th>
        <th>
          очно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th v-for="(item) in 5" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npeao21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npebo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npeco21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.beebo21,2) }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.beeco21,2) }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ENa
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ENb
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ENc
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ЕГЭb
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ЕГЭc
        </td>
      </tr>
      </tbody>
    </table>
    <p>К расчету принимаются данные о результатах ЕГЭ студентов, принятых по общему конкурсу, квоте целевого приема и
      без вступительных испытаний.</p>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <p class="mt-1">Для расчета показателя в отношении студентов, принятых без вступительных испытаний, результаты ЕГЭ
      таких студентов признаются как наивысшие результаты ЕГЭ (100 баллов) по всем общеобразовательным предметам,
      соответствующим НПС, на которые осуществлялся прием.</p>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          ENa <span class="alternate-text mx-1">× 100 +</span> ENb      <span class="alternate-text mx-1">×</span> ЕГЭb <span class="alternate-text mx-1">+</span> ENc <span class="alternate-text mx-1">×</span> ЕГЭc
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">ENa <span class="alternate-text mx-1">+</span> ENb <span class="alternate-text mx-1">+</span> Enc</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npeao21, 0, 'integer') }} <span
            class="alternate-text mx-1">× 100 +</span>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npebo21, 0, 'integer') }} <span class="alternate-text mx-1">×</span>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.beebo21, 2, 'float') }} <span class="alternate-text mx-1">+</span>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npeco21, 0, 'integer') }} <span class="alternate-text mx-1">×</span>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.beeco21, 2, 'float') }}
        </template>
        <template #bottom>
      <span class="formul-text" style="  min-height: 49px">  {{
          $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npeao21, 0, 'integer')
        }} <span class="alternate-text mx-1">+</span>
        {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npebo21, 0, 'integer') }} <span class="alternate-text mx-1">+</span>
      {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npeco21, 0, 'integer') }}</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName + 'b022'], item.prd+1, 'float')
            }}</span>
        </template>
      </formuls-fraction>
    </div>
  </div>
</template>

<script>
export default {
  name: "b11",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
}
</script>

<style lang="sass" scoped>

</style>