export default {
    countCells: 8,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 2,
            text: 'Код и наименование УГСН (НПС), группы научных специальностей',
            html: 'Код и наименование<br> УГСН (НПС), группы научных специальностей',
            class: 'd-flex flex-row align-start justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
        },
        {
            dataField: 'npg01',
            cols: 1,
            rows: 2,
            headerClass: 'text-center',
            text: 'Наличие гос. аккредитации (да/нет)',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg11',
            cols: 3,
            rows: 1,
            headerClass: 'text-center border-bottom-zero',
            text: 'минимальные значения на 2023/2024 учебный год',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg16',
            cols: 3,
            rows: 1,
            headerClass: 'text-center border-bottom-zero',
            text: "максимальные значения " +
                " на 2023/2024 учебный год",
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg12',
            cols: 1,
            rows: 1,
            text: 'очно',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 1,
            rows: 1,
            text: "очно-заочно",
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg14',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 4,
            variable: false,
        },
        {
            dataField: 'npg15',
            cols: 1,
            rows: 1,
            text: 'очно',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg161',
            cols: 1,
            rows: 1,
            text: "очно-заочно",
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg17',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 4,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row',
            text: `1`,
            order: 6,
            variable: true,
        },
        {
            dataField: 'hasAccred',
            cols: 1,
            rows: 1,
            text: `3`,
            order: 7,
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
            styles: 'justify-content: unset; text-align: center; text-align: center;',
        },
        {
            dataField: 'qmino23K22',
            cols: 1,
            rows: 1,
            text: `4`,
            order: 8,
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
            rules: [
                v => !!v || 'введите значение',
            ],
            styles: 'justify-content: unset; text-align: center;',
        },
        {
            dataField: 'qminv23K22',
            cols: 1,
            rows: 1,
            text: `5`,
            order: 9,
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
            rules: [
                v => !!v || 'введите значение',
            ],
            styles: 'justify-content: unset; text-align: center;',
        },
        {
            dataField: 'qminz23K22',
            cols: 1,
            rows: 1,
            text: `6`,
            order: 10,
            headerClass: 'pa-0 font-size-small__important',
            rules: [
                v => !!v || 'введите значение',
            ],
            variable: true,
            styles: 'justify-content: unset; text-align: center;',
        },
        {
            dataField: 'qmaxo23K22',
            cols: 1,
            rows: 1,
            text: `7`,
            order: 8,
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
            rules: [
                v => !!v || 'введите значение',
            ],
            styles: 'justify-content: unset; text-align: center;',
        },
        {
            dataField: 'qmaxv23K22',
            cols: 1,
            rows: 1,
            text: `8`,
            order: 9,
            variable: true,
            headerClass: 'pa-0 font-size-small__important',
            rules: [
                v => !!v || 'введите значение',
            ],
            styles: 'justify-content: unset; text-align: center;',
        },
        {
            dataField: 'qmaxz23K22',
            cols: 1,
            rows: 1,
            text: `9`,
            order: 10,
            headerClass: 'pa-0 font-size-small__important',
            rules: [
                v => !!v || 'введите значение',
            ],
            variable: true,
            styles: 'justify-content: unset; text-align: center;',
        },
    ]
}
