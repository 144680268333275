<template>
<alert alert-type="success" v-if="konvertNumber">
  <p class="mb-0">Конверт принят под номером: {{konvertNumber}}</p>
  <p>Дата принятия: {{dateKonvert}}</p>
</alert>
</template>

<script>
import Alert from "@/components/alerts/Alert";
import {mapGetters} from "vuex";
import moment from "moment";
moment.locale('ru');
export default {
  name: "kovertStatus",
  components: {Alert},
  computed: {
    konvertNumber() {
      return this.statusOrg?.konvertNumber
    },
    dateKonvert() {
      return this.statusOrg.konvertAcceptDate ? moment(this.statusOrg.konvertAcceptDate).format('LLL') : ''
    },
    ...mapGetters('checkFilled' , {statusOrg: 'statusOrg'}),
  },
}
</script>

<style scoped>

</style>