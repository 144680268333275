<template>
  <div class="appeals-list">
    <p class="mb-1">Фильтры: </p>
    <v-row dense>
      <v-col class="d-flex flex-row align-center " >
        <div class="cms-panel-search">
          <v-icon :size="25" color="#cdd1d4" class="fr-topbar__icon"
          >mdi-magnify
          </v-icon
          >
          <input
              type="search"
              class="cms-panel-search__input"
              v-model="searchOrg"
          />
        </div>
        <BSelect
            :data="searchFields"
            v-model="selectedSearchField"
            class="cms-panel-search__sort"
        />
      </v-col>
      <v-col cols="3" class="mx-2">
        <v-autocomplete multiple label="Статусы заявок" v-model="currentStatus" :items="statusArr"></v-autocomplete>
      </v-col>
      <v-col cols="3" class="d-flex align-center">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
                v-model="dates"
                multiple
                chips
                dense
                label="Выбрать даты по которым смотреть"
                small-chips
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-combobox>
          </template>
          <v-date-picker
              v-model="dates"
              multiple
              locale="ru"
              no-title
              scrollable
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <p class="mb-1">Сортировка: </p>
    <v-row>
      <v-col class="d-flex flex-row align-center justify-space-between">
        <v-autocomplete v-model="currentSort" :items="sort"></v-autocomplete>
        <v-autocomplete v-model="currentSortDir" :items="sortDir" class="mx-3"></v-autocomplete>
        <v-btn class="blue__v-btn" @click="sortData" >Сортировать</v-btn>
      </v-col>
    </v-row>
    <v-progress-linear indeterminate
                       color="#00599b"
                       v-if="!appeals.length && !resultSearch.data.length || loading">
    </v-progress-linear>

    <Alert alertType="primary" v-else-if="appeals.length && !resultSearch.data.length ">
      <template v-slot:icon>
        <v-icon :size="30" color="#084298">mdi-information-outline</v-icon>
      </template>
      <template v-slot:default>Поиск не дал результатов</template>
    </Alert>

    <div v-else>

      <CardAppeal
          v-for="(appeal) of resultSearch.data"
          :key="appeal.id"
          :item="appeal"
          @openAboutAppeal="onOpenAboutAppeal($event)"
      />

      <v-dialog
          v-model="modalOpen"
      >
        <v-card>
          <v-card-text class="pt-3">
            <template v-if="activeAppeal">
              <div class="appeal__header">
                <h2>Организация: {{ activeAppeal.org.name }}</h2>
                <h2>Показатель: {{ activeAppeal.indicator }}</h2>
                <h2>КЦП: {{ activeAppeal.kcp.code + ' ' +  activeAppeal.kcp.name}}</h2>
                <h2>ФО: {{  activeAppeal.fo.name}}</h2>
                <h2>Усл: {{ activeAppeal.usl.name}}</h2>
                <h2>Показатель: {{ activeAppeal.indicator }}</h2>
                <h2 class="mb-2 font-weight-bold">Статус: {{ status.text }}</h2>
                <p  class="d-flex align-center mb-1  appeal__span">Описание: {{ activeAppeal.decision }}</p>
                <div class="appeal__container-flex">
                  <v-btn
                      class=" blue__v-btn ml-2"
                      :loading="loadingFile"
                      @click="getFile(file)"
                  >{{ file ? file : 'файл не приложен' }}
                  </v-btn>
                </div>
                <div class="my-4"/>
                <div class="appeal__property">
                  Основание отказ:
                  <div>
                    <textarea v-model="appealText" class="appeal__input" rows="4"></textarea>
                  </div>
                  Описание отказа предыдущее:
                  <p  class="d-flex align-center mb-1  appeal__span">{{ activeAppeal.appeal }}</p>
                </div>
              </div>
              <v-btn class="error__v-btn" :loading="appealChangingStatus" @click="changeAppealStatus(false)">Отказать</v-btn>
              <v-btn class="blue__v-btn ml-3" :loading="appealChangingStatus" @click="changeAppealStatus(true)">Принять</v-btn>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
      <p class="paginator-info">
        Страница: {{ this.paginatorParams.selectedPage }},
        Результатов на странице: {{ this.paginatorParams.itemsPerPage }},
        Всего результатов: {{ resultSearch.counter }}
      </p>
      <BPaginator
          v-if="resultSearch"
          :propDataCount="resultSearch.counter"
          v-model="paginatorParams"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import request from "@/services/request";
import CardAppeal from "@/components/cards/indicatiors/cardAppeal";
import {mapActions, mapGetters} from 'vuex';
import {saveFile} from '@/utils/saveFile';

import BSelect from "@/components/controls/BSelect/BSelect";
import BPaginator from "@/components/paginator/BPaginator/BPaginator";
import utils from "@/utils/utils";
import Alert from "../../../components/UI/Alert";
import {sendAppealAdmin, getAppealsAdmin} from "@/API/indicators";
import {sortArr} from "@/utils/helpers";

moment.locale('ru');

export default {
  name: 'adminIndicatorsAppeals',
  components: {
    Alert,
    CardAppeal,
    BSelect,
    BPaginator
  },
  data() {
    return {
      appeals: [],
      appealText: '',
      activeAppeal: null,
      activeAppealFile: '',
      searchOrg: '',
      searchFields: ['ID', 'Организация', 'UUID'],
      selectedSearchField: 'ID',
      menu: false,
      appealChangingStatus: false,
      dates: [],
      sort: [
        {text: 'id организации', value: 'orgId', type: 'number'},
        {text: 'наименование организации', value: 'id', type: 'string'},
        {text: 'date create', value: 'date', type: 'date'},
        {text: 'status', value: 'accept', type: 'boolean'},
        // {text: 'id', value: 'id'},
      ],
      sortDir: [
        {text: 'По убыванию', value: 'asc'},
        {text: 'По возрастанию', value: 'desc'},
      ],
      statusArr: [
        // {text: 'Все заявки', value: 1},
        {text: 'На рассмотрении', value: null},
        {text: 'Удовлетворена', value: true},
        {text: 'Отказано', value: false},
      ],
      results: 0,
      loading: false,
      loadingFile: false,
      currentStatus: [true, false, null],
      currentSort: 'id',
      currentSortDir: 'desc',
      paginatorParams: {'selectedPage': 1, 'itemsPerPage': 10},
      modalOpen: false,
    }
  },
  computed: {
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    dateCreate() {
      return moment(this.activeAppeal.createDate).format('LLL');
    },
    dateEdit() {
      return moment(this.activeAppeal.changeDate).format('LLL');
    },
    file(){
      return this.activeAppeal?.fileId ?? null
    },
    selectedField() {
      switch (this.selectedSearchField) {
        case "ID":
          return 'id';
        case 'Организация':
          return 'name';
        case 'UUID':
          return 'uuid';
        default:
          return 'name';
      }
    },
    descriptionActiveAppeal(){
      if (!this.activeAppeal?.data)
        return null;
      return this.activeAppeal.data.map(el => { return {text: el.appeal,kcp: this.calcUgsnInfoCode(el.kcpGroup) }});
    },
    decisionActiveAppeal(){
      if (!this.activeAppeal?.data)
        return null;
      return this.activeAppeal.data.map(el => { return {text: el.decision ?? '',kcp: this.calcUgsnInfoCode(el.kcpGroup) }});
    },
    resultSearch() {
      let results;
      let array = this.appeals.filter((item) => {
        // if (this.currentStatus === 1) return item;
        if (this.currentStatus.includes(item.accept))
          return item;
      });
      let search = this.searchOrg;
      if (!search && this.dates.length === 0) {
        results = array.length;
        array = utils.divideArrayInPages(array, this.paginatorParams.selectedPage, this.paginatorParams.itemsPerPage)
        return {'data': array, 'counter': results};
      }
      let filterFn = null;
      search = search.trim().toLowerCase();
      switch (this.selectedField) {
        case "id":
          filterFn = (item) => {
            if (String(item.org.id).toLowerCase().indexOf(search) !== -1) {
              return item;
            }
          };
          break;
        case 'name':
          filterFn = (item) => {
            if (String(item.org[this.selectedField]).toLowerCase().indexOf(search) !== -1) {
              return item;
            }
          };
          break;
        case 'uuid':
          filterFn = (item) => {
            if (item.data.find(el => el.id === search)) {
              return item;
            }
          };
          break;
        default:
          filterFn = (item) => {
            if (String(item.org[this.selectedField]).toLowerCase().indexOf(search) !== -1) {
              return item;
            }
          };
          break;
      }
      if (this.dates.length > 0){
        filterFn = (item) => {
          if (item?.date && (this.dates.includes(item.date.slice(0,10)))) {
            return item;
          }
        };
      }
      array = array.filter((item) => {
        const res = filterFn(item);
        if (res) return res
      })

      results = array.length;
      array = utils.divideArrayInPages(array, this.paginatorParams.selectedPage, this.paginatorParams.itemsPerPage)
      return {'data': array, 'counter': results};
    },
    status() {
      const elementsActive = this.activeAppeal?.accept === null;
      const elementsAgreed = this.activeAppeal?.accept === true;
      const elementsDecline =this.activeAppeal?.accept === false;
      if (elementsActive)
        return {
          text: 'На рассмотрении',
          style: 'appeal_not-set'
        }
      else if(elementsAgreed)
        return {
          text: 'Удовлетворена',
          style: 'appeal_accepted'
        }
      else if (elementsDecline) {
        return {
          text: 'Отказано',
          style: 'appeal_rejected'
        }
      } else {
        return {
          text: 'На рассмотрении',
          style: 'appeal_rejected'
        }
      }
    },

  },
  mounted() {
    this.getAllAppeals();
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    prevPage() {
      if (this.paginatorParams.selectedPage > 1) this.paginatorParams.selectedPage -= 1
    },
    calcUgsnInfoCode(kcp){
      if (!kcp)
        return;
      return kcp.code ;
    },
    calcUgsnInfo(kcp){
      if (!kcp)
        return;
      return kcp.code + ' ' +  kcp.name;
    },
    nextPage() {
      if ((this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage) < this.resultSearch.length) this.paginatorParams.selectedPage += 1
    },
    async changeAppealStatus(flag){
      try {
        this.appealChangingStatus = true;
        if (flag === false && this.appealText){
          console.log('changeAppealStatus false', this.activeAppeal);
          const res  = await sendAppealAdmin({id: this.activeAppeal.id,...this.activeAppeal, accept: flag, appeal: this.appealText});
          this.activeAppeal = res;
          const ind = this.appeals.findIndex(el => el.id === res.id);
          this.appeals.splice(ind, 1, res);
          this.appealChangingStatus = false;
        }
        else if(flag === true){
          console.log('changeAppealStatus true', this.activeAppeal);
          const res = await sendAppealAdmin({id: this.activeAppeal.id, ...this.activeAppeal, accept: flag, appeal: this.appealText});
          this.activeAppeal = res;
          const ind = this.appeals.findIndex(el => el.id === res.id);
          this.appeals.splice(ind, 1, res);
          this.appealChangingStatus = false;
        }else
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Заполните поле причины отказа' ,
            timeout: 5000
          })
        this.appealChangingStatus = false;
      }catch (e) {
        this.appealChangingStatus = false;
      }
    },
    async getAllAppeals() {
      try {
        this.loading = true;
        const res = await getAppealsAdmin();

          // const apps = [];
          // if (res.data.length > 0)
          // {
          //   let filterData = res.data;
          //   res.data.forEach(el => {
          //     if (apps.length === 0)
          //     {
          //       const searchResult = filterData.filter(a => a.jsonId === el.jsonId && a.org.id === el.org.id);
          //       filterData = filterData.filter(a => !(el.jsonId === a.jsonId && a.org.id === el.org.id));
          //       apps.push({...el,orgId:el.org.id, data: searchResult});
          //     }
          //     else{
          //       const searchDouble = apps.find(a => a.jsonId === el.jsonId && a.org.id === el.org.id );
          //       if (!searchDouble){
          //         const searchResult = filterData.filter(a => a.jsonId === el.jsonId && a.org.id === el.org.id);
          //         filterData = filterData.filter(a => !(el.jsonId === a.jsonId && a.org.id === el.org.id));
          //         // console.log(el.jsonId,  el.org.id, searchResult, filterData)
          //         apps.push({...el,orgId:el.org.id, data: searchResult});
          //       }
          //     }
          //   })
          // }
          // console.log('apps',apps);
          this.appeals = res;

        this.loading = false;
      }
      catch (e) {
        this.loading = false;
        console.error('getAllAppeals', e)
      }
    },

    onOpenAboutAppeal(event) {
      this.modalOpen = true;
      this.activeAppeal = event;
    },
    sortData(){
      const item = this.sort.find(el => el.value === this.currentSort)
      // console.log( this.currentSort, this.currentSortDir, item.type)
      this.appeals = sortArr(this.appeals, this.currentSort, this.currentSortDir, item.type);
      // console.log(this.appeals[0].accept)
    },
    async getFile(id =this.activeAppeal.fileId ) {
      try {
        this.loadingFile = true;
        const response = await request({
          endpoint: `/api/file/scan/download?file=${id}`,
          method: 'GET',
        });
        saveFile(response.data, false);
        this.loadingFile = false;
      }catch (e) {
        this.loadingFile = false;
        console.error(e)
      }
    },
  }
}
</script>


<style>
.appeal {
  background: #fff;
  margin-bottom: 15px;
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 3px solid #00599b;
  transition: 0.3s;
}

.appeal__container-mainData {
  width: 100%;
  margin: 10px 0;
  border-bottom: 1px solid #00599B;
  border-radius: 3px;
}

.appeal__mainData {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #00599B;
}

.appeal__mainData:last-child {
  border: none;
}

.appeal__mainData-item {
  flex-basis: 0;
  flex-grow: 1;
  padding: 10px;
  text-align: center;
}

.appeal__mainData-item:nth-child(1), .appeal__mainData-item:nth-child(2) {
  min-width: 220px;
}

.appeal__mainData-item-editable {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00599B;
  cursor: pointer;
}

.appeal__mainData-item-editable > div {
  margin-left: 5px;
}

.appeal__mainData-item-editable > div:hover {
  text-decoration: underline;
}

.appeal__mainData-input {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #00599b;
  border-radius: 7px;
}

.appeal__mainData-action {
  opacity: 0.5;
  cursor: pointer;
}

.appeal__mainData-action:hover {
  opacity: 1;
}

.appeal__mainData-action-selected {
  opacity: 1;
}

.appeal__container-flex > div {
  margin-right: 60px;
}

.appeal__container-flex > div:last-child {
  margin-right: 0;
}

.appeal__value {
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 10px;
}

.appeal__value-header {
  flex-basis: 100px;
}

.appeal__value-status {
  flex-basis: 50px;
}

.appeal__span {
  background: #E3EFFA;
  padding: 2px 5px;
  border-radius: 7px;
  flex-grow: 1;
  color: #00599B;
  line-height: 18.75px;
  height: 36px;
  width: 100%;
  /*display: flex;*/
  /*align-items: center;*/
}

.appeal__dateReason {
  font-size: 12px;
  text-align: center;
}

.appeal:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02);
  transition: 0.3s;
}

.appeal__header {
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
}

.appeal__property {
  margin-bottom: 5px;
}

.appeal__input {
  width: 100%;
  padding: 10px;
  border: 1px solid #00599b;
  border-radius: 7px;
}

.modal_left .modal-content .modal-body {
  overflow: hidden;
  text-align: left;
}

.modal-content {
  min-width: 700px;
}

.appeal_accepted {
  background: #EBF9EB;
  border: 1px solid #8FE28E;
  color: #29A128;
}

.appeal_rejected {
  background: #FDEBEB;
  border: 1px solid #F89B9B;
  color: #ED393E;
}

.appeal-files__item {
  color: #00599b;
  text-decoration: underline;
  cursor: pointer;
}

.appeal-files {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.appeal__text {
  background: #E3EFFA;
  padding: 2px 5px;
  border-radius: 7px;
  flex-grow: 1;
  color: #00599B;
  line-height: 18.75px;
}

.appeal__status {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.appeals-list {
  width: 100%;
}

.appeal__actions {
  justify-content: space-around;
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
}

.cms-panel-search {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 0px 10px;
  border: 1px solid #cdd1d4;
}

.cms-panel {
  margin-bottom: 20px;
  display: flex;
}

.cms-panel-search__sort, .letters__select-type {
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0px 10px;
  border: 1px solid #cdd1d4;
  margin-left: 10px;
}

.sort-panel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.sort-panel {
  font-weight: 600;
  color: #00599b;
  margin-bottom: 10px;
}

.cms-panel-search__sort.select {
  min-width: 100px;
}

.cms-panel-search__input {
  min-width: 300px;
}

</style>