<template>
    <div>
        <div class="d-flex justify-space-between mb-2">
            <div>Сформировать все приложения КЦП</div>
            <v-btn :loading="loadingFileZip"
                   @click="downloadFile(null, `/api/report/getZipForAll`, true, `Архив всех приложений КЦП.zip`, 'loadingFileZip')"
                   class="success__v-btn">Скачать
            </v-btn>
        </div>
        <v-divider/>
        <div class="d-flex justify-space-between mb-2">
          <div>Сформировать все приложения КЦП 2022</div>
          <v-btn :loading="loadingFileZip22"
                 @click="downloadFile(null, `/api/report/getZipForAll22`, true, `Архив всех приложений КЦП22.zip`, 'loadingFileZip22')"
                 class="success__v-btn">Скачать
          </v-btn>
        </div>
        <v-divider/>
        <div class="d-flex justify-space-between mb-2">
            <div>Приложение КЦП БСМ Акреды единое</div>
            <v-btn :loading="loadingFilePril1"
                   @click="downloadFile(null, `/api/report/getStickedPdf?type=1`, true, `Приложение КЦП БСМ Аккреды единое.pdf`, 'loadingFilePril1')"
                   class="success__v-btn">Скачать
            </v-btn>
        </div>
        <v-divider/>
        <div class="d-flex justify-space-between mb-2">
            <div>Приложение КЦП БСМ Не аккреды единое</div>
            <v-btn :loading="loadingFilePril2"
                   @click="downloadFile(null, `/api/report/getStickedPdf?type=2`, true, `Приложение КЦП БСМ Не аккреды единое.pdf`, 'loadingFilePril2')"
                   class="success__v-btn">Скачать
            </v-btn>
        </div>
        <v-divider/>
        <div class="d-flex justify-space-between mb-2">
            <div>Приложение КЦП ОА Акреды единое</div>
            <v-btn :loading="loadingFilePril3"
                   @click="downloadFile(null, `/api/report/getStickedPdf?type=3`, true, `Приложение КЦП ОА Не аккреды единое.pdf`, 'loadingFilePril3')"
                   class="success__v-btn">Скачать
            </v-btn>
        </div>
        <v-divider/>
        <div class="d-flex justify-space-between mb-2">
            <div>Приложение КЦП ОА Не аккреды единое</div>
            <v-btn :loading="loadingFilePril4"
                   @click="downloadFile(null, `/api/report/getStickedPdf?type=4`, true, `Приложение КЦП ОА Не аккреды единое.pdf`, 'loadingFilePril4')"
                   class="success__v-btn">Скачать
            </v-btn>
        </div>
        <v-divider/>
        <div class="d-flex justify-space-between mb-2">
            <div>Приложение КЦП Аспирантура единое</div>
            <v-btn :loading="loadingFilePril5"
                   @click="downloadFile(null, `/api/report/getStickedPdf?type=5`, true, `Приложение КЦП Аспирантура единое.pdf`, 'loadingFilePril5')"
                   class="success__v-btn">Скачать
            </v-btn>
        </div>
        <v-divider/>
        <div class="d-flex justify-space-between mb-2">
            <div>Выгрузка статуса работы организаций в кабинетах</div>
            <v-btn :loading="loadingFileStatus" @click="downloadFile(null, `/api/admin/form2exist`, true, `status.xlsx`, 'loadingFileStatus')"
                   class="success__v-btn">Скачать
            </v-btn>
        </div>
        <v-divider/>
        <div class="d-flex justify-space-between mb-2">
            <div>Выгрузка согласования дополнительного КЦП</div>
            <v-btn :loading="loadingFileDopKcp" @click="downloadFile(null, `/api/kcpAdd/excel`, true, 'ДопКЦП.xlsx', 'loadingFileDopKcp')"
                   class="success__v-btn">
                Скачать
            </v-btn>
        </div>
        <v-divider/>
        <div class="d-flex justify-space-between mb-2">
            <div>Приложение к приказу БСМ 22/23 акред ИД 4</div>
            <v-btn class="success__v-btn"
                   @click="downloadFile(null,`/api/report/getById/de822043-b086-4a17-8a0e-e8fd7a289a2b?orgId=4&prilNumber=1.___&prikDateNumber=«__» __________ 2022 г. № ___`,
                true, 'БСМ аккред 4 тест.pdf', 'loadingFileTestKcp')"
                   :loading="loadingFileTestKcp">Скачать</v-btn>
        </div>
        <v-divider/>
        <div class="d-flex justify-space-between mb-2">
            <div>Приложение к приказу АОА 22/23 акред ИД 4</div>
            <v-btn class="success__v-btn"
                   @click="downloadFile(null,`/api/report/getById/1f8d4c78-584f-4315-8ff1-b62f9d25ef5e?orgId=4&prilNumber=1.___&prikDateNumber=«__» __________ 2022 г. № ___`,
                true, 'АОА аккред 4 тест.pdf', 'loadingFileTestKcp')"
                   :loading="loadingFileTestKcp">Скачать</v-btn>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {setFileFunctions} from "@/mixins/fileFucntions";

    export default {
        name: "Exports",
        mixins: [setFileFunctions],
        data() {
            return {
                loadingFileZip: false,
                loadingFilePril1: false,
                loadingFilePril2: false,
                loadingFilePril3: false,
                loadingFilePril4: false,
                loadingFilePril5: false,
                loadingFileStatus: false,
                loadingFileDopKcp: false,
                loadingFileTestKcp: false,
                loadingFileZip22: false
            }
        },
        computed: {
            ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
            token() {
                return this.getAuthData.token
            },
        }
    }
</script>

<style scoped>

</style>