<template>
  <div>
    <header-main  :subtitle="subtextBodyHeader">
      <template v-if="thirdText">
        <span style=" font-family: 'Arial'; color: #00599B; font-size: 12px">{{thirdText}}</span>
      </template>
    </header-main>
    <component :is="componentName" :text-body-header="textBodyHeader"/>
  </div>
</template>

<script>
import inProgress from '@/components/InProgress'
import form1a from '@/View/Forms5/form5_1a'
import form1b from '@/View/Forms5/form5_1b'
import form1c from '@/View/Forms5/form5_1c'
import form2 from '@/View/Forms5/form5_2'
// import form5b from '@/View/Forms5/form5b'
// import form5c from '@/View/Forms5/form5c'
import form3 from '@/View/Forms5/form5_3'
import form4 from '@/View/Forms5/form5_4'
import form5 from "@/View/Forms5/form5";
import form6 from "@/View/Forms5/form6";
import form7 from "@/View/Forms5/form7";
import form8 from "@/View/Forms5/form8";
import form9 from "@/View/Forms5/form9";
import form10 from "@/View/Forms5/form10";
import form11 from "@/View/Forms5/form11";
import form12a from "@/View/Forms5/form12a";
import form12b from "@/View/Forms5/form12b";
import form13 from "@/View/Forms5/form13";
import form14 from "@/View/Forms5/form14";
import form15 from "@/View/Forms5/form15";
import form16 from "@/View/Forms5/form16";
import form17 from "@/View/Forms5/form17";
import form18 from "@/View/Forms5/form18";
import form19 from "@/View/Forms5/form19";
import form20 from "@/View/Forms5/form20";
import form21 from "@/View/Forms5/form21";
import HeaderMain from "@/components/headers/HeaderMain";

export default {
  name: "allFifthForms",
  components: {
    HeaderMain,
    inProgress, form1a, form1b, form1c, form2, form3,
    form4, form5, form6, form7, form8, form9, form10,
    form11, form12a, form12b, form13, form14, form15,
    form16, form17, form18, form19, form20, form21,
  },
  data() {
    return {
      componentName: 'inProgress',
      textBodyHeader: '' ,
      subtextBodyHeader: '',
      thirdText: '',
      defaultComponent: {name: 'inProgress', formId: 'inProgress', text: 'В процессе разработки'},
      componentsData: [
        {name: 'form1a', formId: 'form-1a', text: 'Форма 5.01а.', textBodyHeader: 'Численность научно-педагогических работников и доходы образовательной организации', subtextBodyHeader: 'по данным формы ВПО-2'},
        {name: 'form1b', formId: 'form-1b', text: 'Форма 5.01б.', textBodyHeader: 'Численность научно-педагогических работников и доходы организации дополнительного профессионального образованния', subtextBodyHeader: 'по данным формы 1-ПК'},
        {name: 'form1c', formId: 'form-1c', text: 'Форма 5.01в.', textBodyHeader: 'Численность научно-педагогических работников и доходы научной организации', subtextBodyHeader: 'по данным формы 2-наука'},
        {name: 'form2', formId: 'form-2', text: 'Форма 5.02.', textBodyHeader: 'Количество публикаций в научных журналах, индексируемых в реферативно-библиографических базах научного цитирования', subtextBodyHeader: 'по данным конкурса прошлого года, 1-мониторинг (для образовательных организаций и организаций ДПО) и ФСМНО (для научных организаций), единиц '},
        {name: 'form3', formId: 'form-3', text: 'Форма 5.03.', textBodyHeader: 'Общая численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры', subtextBodyHeader: 'по данным формы ВПО-1, человек'},
        {name: 'form4', formId: 'form-4', text: 'Форма 5.04.', textBodyHeader: 'Общая численность обучающихся по программам ординатуры, программам ассистентуры-стажировки, программам подготовки научных и научно-педагогических кадров в аспирантуре', subtextBodyHeader: 'по данным формы 1-НК, человек'},
        {name: 'form5', formId: 'form-5', text: 'Форма 5.05.', textBodyHeader: 'Сведения о наличии профессионально-общественной аккредитации', subtextBodyHeader: ''},
        {name: 'form6', formId: 'form-6', text: 'Форма 5.06.', textBodyHeader: 'Контрольные цифры приема, установленные образовательной организации', subtextBodyHeader: 'по данным Министерства науки и высшего образования РФ, мест'},
        {name: 'form7', formId: 'form-7', text: 'Форма 5.07.', textBodyHeader: 'Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета', subtextBodyHeader: 'без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации по данным Мониторинга приемной кампании, человек'},
        {name: 'form8', formId: 'form-8', text: 'Форма 5.08.', textBodyHeader: 'Численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры', subtextBodyHeader: 'по данным формы ВПО-1, человек'},
        {name: 'form9', formId: 'form-9', text: 'Форма 5.09.', textBodyHeader: 'Численность лиц с ограниченными возможностями здоровья, инвалидов, детей-инвалидов, обучающихся по программам бакалавриата, программам специалитета, программам магистратуры', subtextBodyHeader: 'по данным формы ВПО-1, человек'},
        {name: 'form10', formId: 'form-10', text: 'Форма 5.10.', textBodyHeader: 'Численность принятых на обучение и завершивших обучение по программам бакалавриата, программам специалитета, программам магистратуры', subtextBodyHeader: 'без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации', html: ' по данным формы ВПО-1, человек'},
        {name: 'form11', formId: 'form-11', text: 'Форма 5.11.', textBodyHeader: 'Численность обучающихся по договорам о целевом обучении по программам бакалавриата, программам специалитета, программам магистратуры', subtextBodyHeader: 'без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации', html: ' по данным формы ВПО-1, человек'},
        {name: 'form12a', formId: 'form-12a', text: 'Форма 5.12a.', textBodyHeader: 'Численность иностранных граждан и лиц без гражданства, завершивших обучение в 2017-2019 годах по программам бакалавриата, программам специалитета', subtextBodyHeader: 'без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучавшихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации ', html: 'по данным 1-Мониторинг, человек'},
        {name: 'form12b', formId: 'form-12b', text: 'Форма 5.12б.', textBodyHeader: 'Численность иностранных граждан и лиц без гражданства, завершивших обучение в 2017-2019 годах по программам бакалавриата, программам специалитета', subtextBodyHeader: 'из числа иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучавшихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации  ', html: 'по данным 1-Мониторинг, человек'},
        {name: 'form13', formId: 'form-13', text: 'Форма 5.13.', textBodyHeader: 'Дополнительные сведения о приеме на очную форму обучения по программам бакалавриата и специалитета', subtextBodyHeader: 'без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации ', html: 'по данным Мониторинга приемной кампании, человек'},
        {name: 'form14', formId: 'form-14', text: 'Форма 5.14.', textBodyHeader: 'Дополнительные сведения о приеме на очную форму обучения по программам магистратуры', subtextBodyHeader: 'без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации ', html: 'по данным Мониторинга приемной кампании, человек'},
        {name: 'form15', formId: 'form-15', text: 'Форма 5.15.', textBodyHeader: 'Численность обучающихся по программам ординатуры, программам ассистентуры-стажировки', subtextBodyHeader: ' по данным формы 1-НК, человек'},
        {name: 'form16', formId: 'form-16', text: 'Форма 5.16.', textBodyHeader: 'Численность принятых на обучение и завершивших обучение по программам ординатуры, программам ассистентуры-стажировки', subtextBodyHeader: 'без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации ', html: 'по данным формы 1-НК, человек'},
        {name: 'form17', formId: 'form-17', text: 'Форма 5.17.', textBodyHeader: 'Дополнительные сведения о целевом обучении и о приеме на очную форму обучения по программам ординатуры, программам ассистентуры-стажировки', subtextBodyHeader: 'без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации ', html: 'по данным Мониторинга целевого обучения (МЦО) и Мониторинга приемной кампании (МПК), человек'},
        {name: 'form18', formId: 'form-18', text: 'Форма 5.18.', textBodyHeader: 'Численность обучающихся по программам подготовки научных и научно-педагогических кадров в аспирантуре', subtextBodyHeader: 'по данным формы 1-НК, человек'},
        {name: 'form19', formId: 'form-19', text: 'Форма 5.19.', textBodyHeader: 'Численность принятых на обучение и завершивших обучение по программам подготовки научных и научно-педагогических кадров в аспирантуре', subtextBodyHeader: 'по данным формы 1-НК, человек'},
        {name: 'form20', formId: 'form-20', text: 'Форма 5.20.', textBodyHeader: 'Дополнительные сведения о количестве защищенных диссертаций на соискание ученой степени кандидата наук и выпуске закончивших обучение по программам подготовки научных и научно-педагогических кадров в аспирантуре', subtextBodyHeader: ''},
        {name: 'form21', formId: 'form-21', text: 'Форма 5.21.', textBodyHeader: 'Численность прошедших обучение по программам дополнительного профессионального образования', subtextBodyHeader: 'по данным формы 1-ПК, человек'},

      ]
    }
  },
  created() {
    this.initComponent();
  },
  watch: {
    $route() {
      this.initComponent();
    }
  },
  methods: {
    initComponent() {
      const form = this.$route.params.form_id;
      const component = this.componentsData.find(el => form === el.formId);
      this.componentName = component?.name ?? this.defaultComponent.name;
      this.textBodyHeader = component?.text + ' '+  component?.textBodyHeader;
      this.subtextBodyHeader = component?.subtextBodyHeader;
      this.thirdText = component?.html;
      this.$route.meta.title = (component?.text + component?.textBodyHeader) ?? this.defaultComponent.text;
    }
  },
}
</script>

<style scoped>

</style>