<template>
  <section>
    <v-row dense class="">
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
        <p class="">
          <span class="font-weight-bold mb-1 ">Форма 3. (фрагмент)</span><br>
          Сведения о наличии лицензии на осуществление образовательной деятельности
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="1" colspan="3" class="text-left kcp-td">НПС (код и наименование)</th>
        <th colspan="1">Регистрационный номер</th>
        <th colspan="1">Дата выдачи</th>
        <th colspan="2">Примечания</th>
        <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th>3</th>
        <th>4</th>
        <th colspan="2">5</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, i) in rowOfValues.data.tabB21Lics" :key="i">
        <td colspan="3" style="text-align: left; min-width: 400px; max-width: 400px;">
          <span class="kcp-code">{{ row.txnps22.slice(0, 8) }}</span>
          <span class="kcp-name">{{ row.txnps22.slice(8) }}</span>
        </td>
        <td>{{ row.glsnm22 }}</td>
        <td>{{ row.gldtb22 }}</td>
        <td colspan="2">{{ row.gltxt22 }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="4" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-4.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="4" class="border-bottom-zero border-right-zero formul-text-table">
          количество лицензированных НПС =
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qllic22, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense class="mt-3">
      <v-col>
        <p>
          <span class="font-weight-bold mb-1">Форма 5.05. (фрагмент)</span><br>
          Сведения о наличии профессионально-общественной аккредитации (ПОА)
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="2" colspan="3" class="text-left kcp-td">НПС (код и наименование)</th>
        <th colspan="4">Свидетельство о профессионально-общественной аккредитации образовательных программ</th>
        <th rowspan="2" colspan="2">Примечания</th>
        <!--        <th colspan="2">Примечания</th>-->
        <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr>
        <th>серия и номер<br> бланка свидетельства</th>
        <th>серия и номер<br> бланка приложения</th>
        <th>дата выдачи<br> свидетельства</th>
        <th>дата окончания<br> действия</th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left; min-width: 400px; max-width: 400px;">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th>3</th>
        <th>4</th>
        <th>5</th>
        <th>6</th>
        <th colspan="2">7</th>
      </tr>
      </thead>
      <tbody>
      <template v-if="rowOfValues.data.tabB21Poas.length === 0 ">
        <tr>
          <td colspan="9" class="border-right-zero kcp-name">нет сведений о профессионально-общественной аккредитации
          </td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="(row, i) in rowOfValues.data.tabB21Poas" :key="i">
          <td colspan="3" style="text-align: left; min-width: 400px; max-width: 400px;">
            <span class="kcp-code">{{ row.txnps22.slice(0, 8) }}</span>
            <span class="kcp-name">{{ row.txnps22.slice(8) }}</span>
          </td>
          <td>{{ row.gpsnm22 }}</td>
          <td>{{ row.gpsnp22 }}</td>
          <td>{{ row.gpdtb22 }}</td>
          <td>{{ row.gpdte22 }}</td>
          <td colspan="2">{{ row.gptxt22 }}</td>
        </tr>

      </template>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="6" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-6.svg')" alt=""/>
        </td>
      </tr>
      <tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="6" class="border-bottom-zero border-right-zero formul-text-table">
          количество НПС, имеющих ПОА =
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qlpoa22, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-6"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          <span class="formul-text-table font-weight-medium">количество НПС, имеющих ПОА</span>
        </template>
        <template #bottom>
          <span class="formul-text-table font-weight-medium"
                style="  min-height: 49px">количество лицензированных НПС</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-6"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qlpoa22, 0, 'integer') }}
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qllic22, 0, 'integer')
            }}</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-6"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-6">{{
              $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName + 'b022'], item.prd+1, 'float')
            }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p class="mb-5">
      При оценке заявок по УГСН значение показателя определяется как доля НПС, входящих в состав соответствующей УГСН,
      по которым имеются лицензии на осуществление образовательной деятельности и профессионально-общественная
      аккредитация образовательных программ, срок действия которой истекает не ранее завершения года, в котором
      проводится конкурс, в общем числе НПС, входящих в состав соответствующей УГСН, по которым имеются лицензии на
      осуществление образовательной деятельности.
    </p>
  </section>
</template>

<script>
export default {
  name: "b21",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',null,false, 'color: #C6C8CB;')
    }
  },
}
</script>

<style scoped>

</style>