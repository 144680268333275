<template>
  <div class="full-indicators-info">
    <table class="full-indicators-info__table">
      <thead>
      <tr>
        <th>Образовательная организация</th>
        <th>Очная форма</th>
        <th>Очно-заочная форма</th>
        <th>Заочная форма</th>
      </tr>
      </thead>
      <tbody>
      <template v-if="!loading">
        <tr v-for="(item) in serverData" :key="calcIdRow(item)">
          <td v-if="item.orgFlag" class="full-indicators-info__table-org" colspan="4">{{ item.regionName }}</td>
          <template v-else>
            <td>
              <a v-if="item.orgName!='Всего'" @click="openOrg(item)">{{ item.orgName }}</a>
              <span v-else class="full-indicators-info__table-org" style="margin-top: 0 !important;">{{ item.orgName }}</span>
            </td>
            <td
                @click="openScore(item.id, 1, 1, item.qkcpo23, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpo23 == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <span
                    class="d-flex align-center  justify-center hh-100"
                    :class="{'text-decoration-line-through': item.qkcpo23reject && item.qkcpo23}"
                >{{ showingValue(item, 'qkcpo23') }}
                </span>
                <span
                    class="tooltip-indicators-decline"
                >
          </span>
              </div>
            </td>
            <td
                @click="openScore(item.id, 1, 2, item.qkcpv23, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpv23 == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <span
                    class="d-flex align-center  justify-center hh-100"
                    :class="{'text-decoration-line-through': item.qkcpv23reject && item.qkcpv23}"
                >
                  {{ showingValue(item, 'qkcpv23') }}
                </span>
                <span
                    class="tooltip-indicators-decline"
                >
          </span>
              </div>

            </td>
            <td
                @click="openScore(item.id, 1, 3, item.qkcpz23, item.kcpGroup.usl.id, item.kcpGroup, item.orgId)"
                class="kcp-percent-scale__stage-one justify-center"
                :class="{'kcp-percent-scale__disabled': item.qkcpz23 == null}"
            >
              <div
                  class="d-block text-center ww-100 hh-100"
                  style="position: relative">
                <span
                    class="d-flex align-center  justify-center hh-100"
                    :class="{'text-decoration-line-through': item.qkcpz23reject && item.qkcpz23}"
                >
                  {{ showingValue(item, 'qkcpz23') }}
                </span>
                <span
                    class="tooltip-indicators-decline"
                >
          </span>
              </div>
            </td>
          </template>
        </tr>

      </template>
      <template v-else>
        <tr>
          <td class="full-indicators-info__table-org" rowspan="4" colspan="4">
            <v-progress-linear indeterminate></v-progress-linear>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {calcBackground} from "@/utils/indicators";
import {getAllGroupedKcpIndicatorsRegion} from "@/API/indicatorsRegion";
import {mapActions} from "vuex";

export default {
  name: "LkRegion",
  data() {
    return {
      serverData: [],
      loading: false
    }
  },
  created() {
    this.getUserInfo();
    this.getServerData();
  },
  methods: {
    ...mapActions('auth', {getUserInfo: 'getUserInfo', replaceId: 'replaceId'}),
    async getServerData() {
      try {
        this.loading = true;
        this.serverData = await getAllGroupedKcpIndicatorsRegion();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    calcBackground(value, maxValue, minValue = 0) {
      return calcBackground(value, maxValue, minValue)
    },
    showingValue(item, dataField) {
      return item[dataField] == null ? 'нет заявки' : item[dataField]/*.toFixed(3)*/
    },
    calcIdRow(item) {
      /*
        {
          "regionId": 54,
          "regionName": "Пензенская Область",
          "orgId": 61,
          "orgName": "Пензенский казачий институт технологий (филиал) Московского государственного университета технологий и управления имени К.Г. Разумовского (Первый казачий университет)",
          "ownerId": 639,
          "qkcpo23": 76,
          "qkcpv23": 66,
          "qkcpz23": 56
        },
       */
      return Math.random() + '-' + item.orgId
    },
    openOrg(item) {
      // const asd=  item.orgName;
      // console.log(this.$route.meta, item, asd)
      // this.$route.meta.title = asd;
      this.$nextTick(() => this.$router.push({path: '/indicatorsRegions', query: {orgId: item.orgId}}));

    }
  },
}
</script>

<style scoped>

</style>