<template>
  <section>
    <div class="container-files-kcp">
      <div class="container-files-kcp__title">
        Приложение к приказу от&nbsp;«29» апреля 2022 г. № 399
      </div>
      <div>
        <div class="d-flex justify-space-between mb-2">
          <v-btn v-if="statusOrg.numBsmAccred !== null" class="success__v-btn"
               @click="downloadFile(null,`/api/report/getPrikazPril?orgId=${getOrgId}&prikazType=1`,
            true, 'КЦП БСМ Аккредитованные.pdf', 'loadingFile1')"
               :loading="loadingFile1">Контрольные цифры приема по программам бакалавриата, программам специалитета, программам магистратуры, имеющим государственную аккредитацию</v-btn>
          <span v-else>Контрольные цифры приема по программам бакалавриата, программам специалитета, программам магистратуры, имеющим государственную аккредитацию не распределены</span>
        </div>
        <div class="d-flex justify-space-between mb-2">
          <v-btn v-if="statusOrg.numBsmNeacred !== null" class="success__v-btn"
               @click="downloadFile(null,`/api/report/getPrikazPril?orgId=${getOrgId}&prikazType=2`,
            true, 'КЦП БСМ Не аккредитованные.pdf', 'loadingFile2')"
               :loading="loadingFile2">Контрольные цифры приема по программам бакалавриата, программам специалитета, программам магистратуры, не имеющим государственную аккредитацию</v-btn>
          <span v-else>Контрольные цифры приема по программам бакалавриата, программам специалитета, программам магистратуры, не имеющим государственную аккредитацию не распределены</span>
        </div>
      </div>
    </div>
    <v-divider class="my-4"/>
    <div class="container-files-kcp">
      <div class="container-files-kcp__title">
        Приложение к приказу от&nbsp;«29» апреля 2022 г. № 400
      </div>
      <div>
        <div class="d-flex justify-space-between mb-2">
          <v-btn v-if="statusOrg.numOaAccred !== null" class="success__v-btn"
               @click="downloadFile(null,`/api/report/getPrikazPril?orgId=${getOrgId}&prikazType=3`,
            true, 'КЦП ОрдАссист Аккредитованные.pdf', 'loadingFile3')"
               :loading="loadingFile3">Контрольные цифры приема по программам ординатуры, программам ассистентуры-стажировки, имеющим государственную аккредитацию</v-btn>
          <span v-else>Контрольные цифры приема по программам ординатуры, программам ассистентуры-стажировки, имеющим государственную аккредитацию не распределены</span>
        </div>
        <div class="d-flex justify-space-between mb-2">
          <v-btn v-if="statusOrg.numOaNeacred !== null" class="success__v-btn"
               @click="downloadFile(null,`/api/report/getPrikazPril?orgId=${getOrgId}&prikazType=4`,
            true, 'КЦП ОрдАссист Не аккредитованные.pdf', 'loadingFile4')"
               :loading="loadingFile4">Контрольные цифры приема по программам ординатуры, программам ассистентуры-стажировки, не имеющим государственную аккредитацию</v-btn>
          <span v-else>Контрольные цифры приема по программам ординатуры, программам ассистентуры-стажировки, не имеющим государственную аккредитацию не распределены</span>
        </div>
        <div class="d-flex justify-space-between mb-2">
          <v-btn v-if="statusOrg.numAsp !== null" class="success__v-btn"
               @click="downloadFile(null,`/api/report/getPrikazPril?orgId=${getOrgId}&prikazType=5`,
            true, 'КЦП Аспирантура.pdf', 'loadingFile5')"
               :loading="loadingFile5">Контрольные цифры приема по программам научно-педагогических кадров в аспирантуре</v-btn>
          <span v-else>Контрольные цифры приема по программам научно-педагогических кадров в аспирантуре не распределены</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {setFileFunctions} from "@/mixins/fileFucntions";
import {mapGetters} from "vuex";

export default {
  name: "ContainerKCPInformationDocuments",
  mixins: [setFileFunctions],
  props: {
    statusOrg: Object
  },
  data() { return {
    loadingFile1: false,
    loadingFile2: false,
    loadingFile3: false,
    loadingFile4: false,
    loadingFile5: false,
  }},
  computed: {
    ...mapGetters('auth', {authData: 'authData', get_replace_id_admin: 'get_replace_id_admin'}),
    getOrgId() {
      return this.get_replace_id_admin;
    }
  }
}
</script>

<style lang="sass">
.container-files-kcp
  display: flex
  flex-direction: row
  align-items: center

  &__title
    display: flex
    flex-direction: row
    flex-basis: 300px
    min-width: 300px
    margin-right: 10px

.info-card__button
  margin-top: 10px
  margin-left: 10px

.file-edit
  display: flex
  align-items: center

.file-edit .v-file-input
  max-width: 300px
  margin-right: 10px
  min-width: 200px

.file-edit
  display: inline-flex
  align-items: center
  background: #FFEDE2
  padding: 0 15px
  border-radius: 10px
  margin: 20px

.border_margin_right
  margin-right: 10px
</style>