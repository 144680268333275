export default {
    countCells: 10,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 3,
            text: 'Код и наименование УГСН (НПС)',
            class: 'd-flex flex-row align-center justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
            // template: (row) => {
            //     return row.sprKcpGroupId + ' - ' + row.sprKcpGroupName
            // }
        },
        {
            dataField: 'npg11',
            cols: 9,
            rows: 1,
            text: 'Численность выпускников - иностранных граждан, обучавшихся на условиях общего приема',
            headerClass: 'text-center border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg12',
            cols: 3,
            rows: 1,
            text: 'с 01.10.2016 по 30.09.2017',
            headerClass: 'text-center border-bottom-zero',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 3,
            rows: 1,
            text: "с 01.10.2017 по 30.09.2018",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg14',
            cols: 3,
            rows: 1,
            text: `с 01.10.2018 по 30.09.2019`,
            headerClass: 'text-center border-bottom-zero',
            order: 4,
            variable: false,
        },
        {
            dataField: 'npg31',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg32',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg33',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg34',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg35',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg36',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg37',
            cols: 1,
            rows: 1,
            text: `очно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg38',
            cols: 1,
            rows: 1,
            text: `очно-заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg39',
            cols: 1,
            rows: 1,
            text: `заочно`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row text-left',
            text: `1`,
            order: 6,
            variable: true,

        },
        {
            dataField: 'nviso17Mo1',
            cols: 1,
            rows: 1,
            text: `3`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvisv17Mo1',
            cols: 1,
            rows: 1,
            text: `4`,
            order: 8,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvisz17Mo1',
            cols: 1,
            rows: 1,
            text: `5`,
            order: 9,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nviso18Mo1',
            cols: 1,
            rows: 1,
            text: `6`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvisv18Mo1',
            cols: 1,
            rows: 1,
            text: `7`,
            order: 8,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvisz18Mo1',
            cols: 1,
            rows: 1,
            text: `8`,
            order: 9,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nviso19Mo1',
            cols: 1,
            rows: 1,
            text: `9`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvisv19Mo1',
            cols: 1,
            rows: 1,
            text: `10`,
            order: 8,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvisz19Mo1',
            cols: 1,
            rows: 1,
            text: `11`,
            order: 9,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
    ]
}
