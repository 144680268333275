export default {
    countCells: 5,
    massive: [
        {
            dataField: 'npg0',
            cols: 1,
            rows: 4,
            text: 'Код и наименование УГСН (НПС)',
            class: 'd-flex flex-row align-center justify-start ',
            headerClass: 'align-end ',
            headerStyles: `min-width: 350px;`,
            order: 0,
            variable: false,
        },
        {
            dataField: 'npg11',
            cols: 2,
            rows: 2,
            text: 'Защиты диссертаций незав. от места защиты',
            headerClass: 'text-center border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg111',
            cols: 2,
            rows: 1,
            text: 'Выпуск целевого обучения',
            headerClass: 'text-center border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg1111',
            cols: 1,
            rows: 1,
            text: 'всего',
            headerClass: 'text-center border-bottom-zero',
            order: 1,
            variable: false,
        },
        {
            dataField: 'npg12',
            cols: 1,
            rows: 1,
            text: 'с защитой диссер.',
            headerClass: 'text-center border-bottom-zero',
            order: 2,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 1,
            rows: 1,
            text: "в 2019 г.",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg13',
            cols: 1,
            rows: 1,
            text: "в 2020 г.",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg14',
            cols: 1,
            rows: 1,
            text: "в 2020 г.",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg15',
            cols: 1,
            rows: 1,
            text: "в 2020 г.",
            headerClass: 'text-center border-bottom-zero',
            order: 3,
            variable: false,
        },
        {
            dataField: 'npg23',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg24',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg25',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg26',
            cols: 1,
            rows: 1,
            text: `все формы`,
            order: 5,
            variable: false,
        },
        {
            dataField: 'npg',
            cols: 1,
            rows: 1,
            headerClass: 'd-flex flex-row justify-start pa-0 font-size-small__important',
            class: 'justify-start d-flex flex-row text-left',
            text: `1`,
            order: 6,
            variable: true,
        },
        {
            dataField: 'qdugs20K21',
            cols: 1,
            rows: 1,
            text: `3`,
            order: 7,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'qdugs21Dop',
            cols: 1,
            rows: 1,
            text: `4`,
            order: 8,
            alternateEdit: true,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'nvcls21Dop',
            cols: 1,
            rows: 1,
            text: `5`,
            order: 9,
            alternateEdit: true,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
        {
            dataField: 'qdcls21Dop',
            cols: 1,
            rows: 1,
            text: `6`,
            order: 7,
            alternateEdit: true,
            headerClass: 'pa-0 font-size-small__important',
            variable: true,
        },
    ]
}
