<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
        <p>
       расчет на основе данных подсистемы «Анализ трудоустройства граждан» информационно-аналитической
          системы Общероссийская база вакансий «Работа в России» (Минтруд России)
        </p>
      </v-col>
    </v-row>
    <table>
      <thead>
      <tr>
        <th rowspan="6" colspan="3" class="text-left" style="padding-left: 50px;">Регион трудоустройства</th>
        <th colspan="3">Таблица Б. (фрагмент, транспонировано)</th>
        <th colspan="6">Таблица В. (фрагмент, транспонировано)</th>
      </tr>
      <tr>
        <th colspan="3">
          Численность выпускников,<br>
          имевших в году, следующем за годом завершения обучения (выпуска),<br>
          последнее место трудоустройства<br>
          по субъектам РФ (независимо от формы обучения)
        </th>
        <th colspan="6">Среднемесячный размер заработной платы выпускников,<br>
          имевших в году, следующем за годом завершения обучения (выпуска),<br>
          последнее место трудоустройства по субъектам РФ (независимо от формы обучения)<br>
          и прожиточный минимум трудоспрособного населения (по данным Росстата)
        </th>
      </tr>
      <tr>
        <th colspan="3"></th>
        <th colspan="6"></th>
      </tr>
      <tr>
        <th colspan="3" ><span class="formul-text-table">ФТk,r</span></th>
        <th colspan="3" ><span class="formul-text-table">ДВk,r</span></th>
        <th colspan="3" ><span class="formul-text-table">ПМТk,</span>r</th>
      </tr>
      <tr>
        <th colspan="3">Трудоустроено выпускников</th>
        <th colspan="3">Среднемесячный размер заработной платы</th>
        <th colspan="3">Прожиточный минимум трудоуспособного населения</th>
      </tr>
      <tr>
        <th>2018 год</th>
        <th>2019 год</th>
        <th>2020 год</th>
        <th>2018 год</th>
        <th>2019 год</th>
        <th>2020 год</th>
        <th>2018 год</th>
        <th>2019 год</th>
        <th>2020 год</th>
      </tr>
      </thead>
      <tbody class="fixed-table-b33">
        <template v-for="okrug in okrugs">
          <tr :key="okrug.text">
            <td class="text-left border-right-zero border-bottom-zero font-size-medium font-weight-700 border-top-1" style="max-width: 50px!important;">{{okrug.text}}</td>
            <td class="text-left border-left-zero font-size-small border-top-1" colspan="2" style="min-width: 230px;line-height: 15px!important" >{{okrug.regions[0].text}}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['ntr' + okrug.regions[0].id+'18'],1) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['ntr' + okrug.regions[0].id+'19'],1) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['ntr' + okrug.regions[0].id+'20'],1) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rsd' + okrug.regions[0].id+'18'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rsd' + okrug.regions[0].id+'19'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rsd' + okrug.regions[0].id+'20'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rpm' + okrug.regions[0].id+'18'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rpm' + okrug.regions[0].id+'19'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rpm' + okrug.regions[0].id+'20'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
          </tr>
          <tr class="text-left border-right-zero" :key="region.id" v-for="region in okrug.regions.filter((el,i) => i !== 0)">
            <td class="border-right-zero border-bottom-zero font-size-small" style="max-width: 50px!important;"></td>
            <td class="text-left border-left-zero font-size-small" colspan="2" style="line-height: 15px!important;">{{region.text}}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['ntr' + region.id+'18'], 1) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['ntr' + region.id+'19'], 1) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['ntr' + region.id+'20'], 1) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rsd' + region.id+'18'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rsd' + region.id+'19'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rsd' + region.id+'20'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rpm' + region.id+'18'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rpm' + region.id+'19'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
            <td class="font-size-small" style="line-height: 15px!important">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data['rpm' + region.id+'20'], 2,'float', true, '', (v) => {
            return Intl.NumberFormat('ru-RU').format(Number.parseFloat(v)).replace(',', '.')}) }}</td>
          </tr>
        </template>
        <tr>
          <td colspan="3" class="border-right-zero border-bottom-zero font-size-small"></td>
          <td colspan="3" class="border-bottom-zero border-right-zero">
            <img class="ww-100" :src="require('@/assets/images/brackets/bracket-3.svg')" alt=""/>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="border-right-zero border-bottom-zero font-size-small"></td>
          <td colspan="3" class="border-right-zero border-bottom-zero formul-text-table">
            ЧТ = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.ntrxxS3, 0, 'integer') }}
          </td>
        </tr>
      </tbody>
    </table>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <div class=" d-flex flex-column">
        <v-icon color="#263238" size="45">mdi-sigma</v-icon>
        <div class="font-size-small" style="line-height: 12px">по годам, k<br> по регионам, r</div>
      </div>
      <formuls-fraction-simple>
        <template>
          <div class="d-flex flex-row align-end mr-1">
           ДВ
            <div class="little-text ">k,r</div>
          </div>
          <span class=" alternate-text"> ×</span>
          <div class="d-flex flex-row align-end ml-1">
           ФТ
            <div class="little-text ">k,r</div>
          </div>
        </template>
        <template #bottom>
          <div style="  min-height: 49px">
            <div class="d-flex flex-row formul-text align-end mr-1" >
              ПМТ
              <div class="little-text ">k,r</div>
            </div>
          </div>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 ">    <span class=" alternate-text"> ÷</span> ЧТ</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-7 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>ФТk,r	-	число трудоустроенных выпускников (по субъектам Российской Федерации)</p>
    <p>ДВk,r	-	среднемесячный размер заработной платы трудоустроенных выпускников (по субъектам Российской Федерации)</p>
    <p>ПМТk,r	-	величина прожиточного минимума трудоспособного населения (по субъектам Российской Федерации)</p>
    <p >ЧТ	-	сумма занчений показателя ФТk,r по всем субъектам Российской Федерации за три года, за которые имеются данные о доходах выпускников</p>
    <p v-if="rowOfValues.data[item.dataFieldName+'nf22'] !== '0'" class="mb-4">
      Если значение ЧТ менее 5, значение показателя не рассчитывается, а его нормированная оценка определяется в соответствии с пунктом 7 Методики (по средним).
    </p>
  </div>
</template>

<script>
export default {
  name: "b33",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      okrugs: [
        {
          text: 'ЦФО',
          id: 1,
          regions: [
            {text: 'Белгородская область ', id: 31},
            {text: 'Брянская область				 ', id: 32},
            {text: 'Владимирская область ', id: 33},
            {text: 'Воронежская область	 ', id: 36},
            {text: 'Ивановская область		 ', id: 37},
            {text: 'Калужская область			 ', id: 40},
            {text: 'Костромская область	 ', id: 44},
            {text: 'Курская область					 ', id: 46},
            {text: 'Липецкая область				 ', id: 48},
            {text: 'Московская область		 ', id: 50},
            {text: 'Орловская область			 ', id: 57},
            {text: 'Рязанская область			 ', id: 62},
            {text: 'Смоленская область		 ', id: 67},
            {text: 'Тамбовская область		 ', id: 68},
            {text: 'Тверская область				 ', id: 69},
            {text: 'Тульская область				 ', id: 71},
            {text: 'Ярославская область	 ', id: 76},
            {text: 'Москва', id: 77},

          ]
        },
        {
          text: 'СЗФО',
          id: 2,
          regions: [
            {text: 'Республика Карелия					', id: 10},
            {text: 'Республика Коми						  ', id: 11},
            {text: 'Архангельская область		', id: 29},
            {text: 'Ненецкий АО						      ', id: 83},
            {text: 'Вологодская область				', id: 35},
            {text: 'Калининградская область', id: 39},
            {text: 'Ленинградская область		', id: 47},
            {text: 'Мурманская область					', id: 51},
            {text: 'Новгородская область			', id: 53},
            {text: 'Псковская область						', id: 60},
            {text: 'Санкт-Петербург						  ', id: 78},
          ]
        },
        {
          text: 'ПФО',
          id: 3,
          regions: [
            {text: 'Республика Башкортостан', id: '02'},
            {text: 'Республика Марий Эл				', id: 12},
            {text: 'Республика Мордовия				', id: 13},
            {text: 'Республика Татарстан			', id: 16},
            {text: 'Удмуртская Республика		', id: 18},
            {text: 'Чувашская Республика			', id: 21},
            {text: 'Пермский край						    ', id: 59},
            {text: 'Кировская область					 ', id: 43},
            {text: 'Нижегородская область		', id: 52},
            {text: 'Оренбургская область			', id: 56},
            {text: 'Пензенская область				 ', id: 58},
            {text: 'Самарская область					 ', id: 63},
            {text: 'Саратовская область				', id: 64},
            {text: 'Ульяновская область				', id: 73},
          ]
        },
        {
          text: 'ЮФО',
          id: 4,
          regions: [
            {text: 'Республика Адыгея			 ', id: '01'},
            {text: 'Республика Калмыкия		', id: '08'},
            {text: 'Республика Крым				  ', id: 82},
            {text: 'Краснодарский край		 ', id: 23},
            {text: 'Астраханская область	', id: 30},
            {text: 'Волгоградская область', id: 34},
            {text: 'Ростовская область		 ', id: 61},
            {text: 'Севастополь						   ', id: 92},
          ]
        },
        {
          text: 'СКФО',
          id: 5,
          regions: [
            {text: 'Республика Дагестан					     	', id: '05'},
            {text: 'Республика Ингушетия					   	', id: '06'},
            {text: 'Кабардино-Балкарская Республика		', id: '07'},
            {text: 'Республика Карачаево-Черкесия			', id: '09'},
            {text: 'Республика Северная Осетия-Алания	', id: 15},
            {text: 'Чеченская Республика				    	', id: 20},
            {text: 'Ставропольский край				     		', id: 26},
          ]
        },
        {
          text: 'УФО',
          id: 6,
          regions: [
            {text: 'Курганская область						', id: '45'},
            {text: 'Свердловская область					', id: '66'},
            {text: 'Тюменская область						', id: '72'},
            {text: 'Ханты-Мансийский АО - Югра		', id: '86'},
            {text: 'Ямало-Ненецкий АО						', id: '89'},
            {text: 'Челябинская область					', id: '74'},
          ]
        },
        {
          text: 'СФО',
          id: 7,
          regions: [
            {text: 'Республика Алтай			', id: '04'},
            {text: 'Республика Тыва				', id: '17'},
            {text: 'Республика Хакасия		', id: '19'},
            {text: 'Алтайский край				', id: '22'},
            {text: 'Красноярский край			', id: '24'},
            {text: 'Иркутская область			', id: '38'},
            {text: 'Кемеровская область		', id: '42'},
            {text: 'Новосибирская область	', id: '54'},
            {text: 'Омская область				', id: '55'},
            {text: 'Томская область				', id: '70'},
          ]
        },
        {
          text: 'ДФО',
          id: 8,
          regions: [
            {text: 'Республика Бурятия					', id: '03'},
            {text: 'Республика Саха (Якутия)		', id: '14'},
            {text: 'Забайкальский край	       	', id: '75'},
            {text: 'Камчатский край			       	', id: '41'},
            {text: 'Приморский край			       	', id: '25'},
            {text: 'Хабаровский край		       	', id: '27'},
            {text: 'Амурская область		       	', id: '28'},
            {text: 'Магаданская область	       	', id: '49'},
            {text: 'Сахалинская область	       	', id: '65'},
            {text: 'Еврейская автономная область', id: '79'},
            {text: 'Чукотский АО					      ', id: '87'},
          ]
        },
      ]
    }
  },
  methods: {},
}
</script>

<style lang="sass" scoped>
.fixed-table-b33
  td
    width: 100px!important
</style>