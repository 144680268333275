export function mapDialogSpr(spr = {data: [], loading: false, error: null}) {
    if (Object.prototype.toString.call(spr) === '[object Array]'){
        return {data: spr ?? [], loading: false, error: null}
    }else{
        const [data, loading, error] = Object.values(spr);
        if (data !== undefined && loading !== undefined && error !== undefined)
            return spr;
        else
            return {data: data ?? [], loading: loading ?? false, error: error ?? null}
    }
}
export function clearValue(el) {
    if (el.typeVar === 'string')
        el[el.variable] = ''
    else if (el.typeVar === 'integer')
        el[el.variable] = '0'
    else if (el.typeVar === 'float')
        el[el.variable] = '0.0'
    else if (el.typeVar === 'date') {
        if (el.typeDate === 'month')
            el[el.variable] = null
        else
            el[el.variable] = null
    } else {
        el[el.variable] = null
    }
}