import request from "@/services/request";
import store from "@/store";

async function changeStatusLicense(payload = {id: null, flag: null}){
    try{
        const res = await request({
            endpoint: `/api/foiv/form2?rowId=${payload.id}&hasApproved=${payload.flag}`,
            method: 'put',
            // body: payload
        });
        if (res.status === 200 || res.status === 201){
            return res.data;
        }else{
            throw  new Error('error foiv put');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка принятия решения '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error foiv put' + e )
    }
}
export {changeStatusLicense}