<template>
  <v-dialog
      v-model="show"
      scrollable
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
      :style="styles"
      :persistent="needAccept"
      max-width="1180px"
  >
    <v-card>
      <span>
        <v-btn
            icon
            @click="closeDialog()"
            style="position: absolute; right:0;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <v-card-title class="d-flex mt-2 flex-column align-start">
        <div>
          <slot name="title">
            <p>
              Добавление
            </p>
          </slot>
        </div>
        <div v-if="!isOneStage" class="stage-line-wrapper" :style="'min-width: ' + steps.length * 100 +'px'">
          <ul class="step-progress">
            <li v-for="item in steps" :key="item" class="step-progress__item" :class="{'is-done' : checkStep(item)}">
              <div class="step-progress__item-wrapper">
                <div class="font-weight-bold count font-size-small">
                  <span>{{ item + 1 }}</span>
                </div>
                <!--                <div class="font-size-medium ">Этап</div>-->
              </div>
            </li>
          </ul>
        </div>
      </v-card-title>
      <v-card-text class="pt-4">
        <template v-if="isOneStage">
          <v-form
              ref="form"
              v-model="validSolo"
              lazy-validation
          >
            <div v-if="assignedValuesOneStageReady">
              <div v-for="field in assignedValuesOneStage" :key="field.dataField">
                <div v-if="field.dopText !=null" class="dialog-section">
                  <p class="mb-0 ">{{ field.dopText }}</p>
                </div>
                <div v-if="showField(field)" class="d-flex flex-row align-center">
                  <tooltip-dialog
                      v-if="field.tooltip && showField(field)"
                      :full-text="field.tooltip"
                      :short-text="field.shortTooltip"
                  >
                  </tooltip-dialog>
                  <div v-else-if="showField(field)" style="width: 36px">
                  </div>
                  <v-autocomplete
                      v-if="field.typeVar === 'selector'  && showField(field)"
                      v-model="field[field.variable]"
                      :items="computedSprData[field.sprDataName].data"
                      :rules="field[field.variable+'Rules']"
                      class="mt-2"
                      :loading="computedSprData[field.sprDataName].loading"
                      dense
                      @change="changeValueSelectedSpr(field)"
                      no-data-text="По вашему запросу ничего нет"
                      clearable
                      :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                      :label="field.text"
                  >
                  </v-autocomplete>
                  <v-autocomplete
                      v-else-if="field.typeVar === 'multipleSelector' && showField(field)"
                      v-model="field[field.variable]"
                      :items="computedSprData[field.sprDataName].data"
                      :rules="field[field.variable+'Rules']"
                      :multiple="field.multiple  ? field.multiple : false"
                      class="mt-2"
                      :loading="computedSprData[field.sprDataName].loading"
                      dense
                      @change="changeValueSelectedSpr(field)"
                      no-data-text="По вашему запросу ничего нет"
                      clearable
                      :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                      :label="field.text"
                  >
                  </v-autocomplete>
                  <v-autocomplete
                      v-else-if="field.typeVar === 'customSelector'  && showField(field)"
                      v-model="field[field.variable]"
                      :items="field.data"
                      :rules="field[field.variable+'Rules']"
                      :multiple="field.multiple ? field.multiple: false"
                      class="mt-2"
                      dense
                      @change="changeValueSelectedSpr(field)"
                      no-data-text="По вашему запросу ничего нет"
                      clearable
                      :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                      :label="field.text"
                  ></v-autocomplete>
                  <v-menu
                      v-else-if="field.typeVar ==='date' && showField(field)"
                      v-model="field.modalVariable"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="field[field.variable]"
                          :label="field.text"
                          :rules="field[field.variable+'Rules']"
                          prepend-icon=""
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="field[field.variable]"
                        :type="field.typeDate"
                        locale="ru"
                        @input="field.modalVariable = false"
                    ></v-date-picker>
                  </v-menu>
                  <WrapperPreviewCategory
                      v-else-if="field.typeVar === 'hiddenBlock' && showField(field)"
                      custom-class="wrapper-class-dialog"
                      :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                  >
                    <WrapperCollapse
                        :initialOpen="false"
                        active-class="active-wrapper"
                    >
                      <template #title>
                        <div class="d-flex flex-row ">
                          <h3 class="font-size-medium font-weight-bold">{{ field.text }}</h3>
                          <span></span>
                        </div>
                      </template>
                      <div v-for="field1 in field.listFields" :key="field1.dataField">
                        <p v-if="field1.dopText !=null" class="mb-0 main-color">{{ field1.dopText }}</p>
                        <v-autocomplete
                            v-if="field1.typeVar === 'selector'  && showField(field1)"
                            v-model="field1[field1.variable]"
                            :items="computedSprData[field1.sprDataName].data"
                            :rules="field1[field1.variable+'Rules']"
                            class="mt-2"
                            :loading="computedSprData[field1.sprDataName].loading"
                            dense
                            @change="changeValueSelectedSpr(field1, field)"
                            no-data-text="По вашему запросу ничего нет"
                            clearable
                            :style="field1.hidden !=null && field1.hidden === true ? 'display: none;': ''"
                            :label="field1.text"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                            v-else-if="field1.typeVar === 'multipleSelector'  && showField(field1)"
                            v-model="field1[field1.variable]"
                            :items="computedSprData[field1.sprDataName].data"
                            :rules="field1[field1.variable+'Rules']"
                            :multiple="field1.multiple  ? field1.multiple : false"
                            class="mt-2"
                            :loading="computedSprData[field1.sprDataName].loading"
                            dense
                            @change="changeValueSelectedSpr(field1, field)"
                            no-data-text="По вашему запросу ничего нет"
                            clearable
                            :style="field1.hidden !=null && field1.hidden === true ? 'display: none;': ''"
                            :label="field1.text"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                            v-else-if="field1.typeVar === 'customSelector'  && showField(field1)"
                            v-model="field1[field1.variable]"
                            :items="field1.data"
                            :rules="field1[field1.variable+'Rules']"
                            :multiple="field1.multiple ? field1.multiple: false"
                            class="mt-2"
                            dense
                            @change="changeValueSelectedSpr(field1, field)"
                            no-data-text="По вашему запросу ничего нет"
                            clearable
                            :style="field1.hidden !=null && field1.hidden === true ? 'display: none;': ''"
                            :label="field1.text"
                        ></v-autocomplete>
                        <v-menu
                            v-else-if="field1.typeVar ==='date' && showField(field1)"
                            v-model="field1.modalVariable"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            :style="field1.hidden !=null && field1.hidden === true ? 'display: none;': ''"
                            min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="field1[field1.variable]"
                                :label="field1.text"
                                :rules="field1[field1.variable+'Rules']"
                                prepend-icon=""
                                readonly
                                clearable
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="field1[field1.variable]"
                              :type="field1.typeDate"
                              @input="field1.modalVariable = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-text-field
                            v-else-if="showField(field1)"
                            v-model="field1[field1.variable]"
                            :rules="field1[field1.variable+'Rules']"
                            :label="field1.text"
                            :style="field1.hidden !=null && field1.hidden === true ? 'display: none;': ''"
                            class="mt-2"
                            @change="changeValueSelectedSpr(field1, field)"
                            dense
                            clearable
                            required
                        />
                      </div>
                    </WrapperCollapse>
                  </WrapperPreviewCategory>
                  <v-text-field
                      v-else-if="showField(field)"
                      v-model="field[field.variable]"
                      :rules="field[field.variable+'Rules']"
                      :label="field.text"
                      :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                      class="mt-2"
                      @change="changeValueSelectedSpr(field)"
                      dense
                      clearable
                      required
                  />
                </div>
              </div>
            </div>
          </v-form>
        </template>
        <template v-else-if="!isOneStage">
          <v-form
              v-for="index in headersForDialog.length"
              :key="index"
              :ref="'form-' + (index-1)"
              v-model="validMultiple[index-1]"
              lazy-validation
          >

            <div v-if="assignedValuesMultipleStageReady">
              <transition-group name="slide-fade">
                <template v-if="(index-1) === indexMultiForm">
                  <div v-for="field in assignedValuesMultipleStage[indexMultiForm]" :key="field.dataField">
                    <p v-if="field.dopText !=null" class="mb-0 main-color">{{ field.dopText }}</p>
                    <v-autocomplete
                        v-if="field.typeVar === 'selector'  && showField(field)"
                        v-model="field[field.variable]"
                        :items="computedSprData[field.sprDataName].data"
                        :rules="field[field.variable+'Rules']"
                        class="mt-2"
                        :loading="computedSprData[field.sprDataName].loading"
                        dense
                        @change="changeValueSelectedSpr(field)"
                        no-data-text="По вашему запросу ничего нет"
                        clearable
                        :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                        :label="field.text"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                        v-else-if="field.typeVar === 'multipleSelector'  && showField(field)"
                        v-model="field[field.variable]"
                        :items="computedSprData[field.sprDataName].data"
                        :rules="field[field.variable+'Rules']"
                        :multiple="field.multiple  ? field.multiple : false"
                        class="mt-2"
                        :loading="computedSprData[field.sprDataName].loading"
                        dense
                        @change="changeValueSelectedSpr(field)"
                        no-data-text="По вашему запросу ничего нет"
                        clearable
                        :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                        :label="field.text"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                        v-else-if="field.typeVar === 'customSelector'  && showField(field)"
                        v-model="field[field.variable]"
                        :items="field.data"
                        :rules="field[field.variable+'Rules']"
                        :multiple="field.multiple ? field.multiple: false"
                        class="mt-2"
                        dense
                        @change="changeValueSelectedSpr(field)"
                        no-data-text="По вашему запросу ничего нет"
                        clearable
                        :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                        :label="field.text"
                    ></v-autocomplete>
                    <v-menu
                        v-else-if="field.typeVar ==='date' && showField(field)"
                        v-model="field.modalVariable"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="field[field.variable]"
                            :label="field.text"
                            :rules="field[field.variable+'Rules']"
                            prepend-icon=""
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="field[field.variable]"
                          :type="field.typeDate"
                          @input="field.modalVariable = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-text-field
                        v-else-if="showField(field)"
                        v-model="field[field.variable]"
                        :rules="field[field.variable+'Rules']"
                        :label="field.text"
                        :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                        class="mt-2"
                        @change="changeValueSelectedSpr(field)"
                        dense
                        clearable
                        required
                    />
                  </div>
                </template>
              </transition-group>
            </div>
          </v-form>

        </template>
        <template v-else>
          <div>Что то пошло не так перезагрузите страницу</div>
        </template>
      </v-card-text>
      <v-card-actions class="d-flex flex-column align-start">
        <slot name="actions"
              :loading="loading"
              :is-one-stage="isOneStage"
              :send-data="sendData"
              :valid-solo="validSolo"
              :close="closeDialog"
              :reset-form="resetForm"
        >
          <div v-if="!isOneStage" class="d-flex mb-2">
            <v-btn
                :disabled="indexMultiForm === 0"
                class='error__v-btn mr-2'
                @click="indexMultiForm--"
            >
              Назад
            </v-btn>
            <v-btn
                :disabled="indexMultiForm === (assignedValuesMultipleStage.length - 1)"
                class='error__v-btn'
                @click="indexMultiForm++"
            >
              Вперед
            </v-btn>
          </div>
          <div class="d-flex">
            <v-btn
                class='error__v-btn mr-2'
                @click="closeDialog()"
            >
              Закрыть
            </v-btn>
            <!--            <v-btn-->
            <!--                class="blue__v-btn mr-2"-->
            <!--                @click="resetForm()"-->
            <!--            >-->
            <!--              Сбросить значения-->
            <!--            </v-btn>-->
            <v-btn
                v-if="isOneStage"
                class='success__v-btn'
                @click="sendData()"
                :loading="loading"
                :disabled="!validSolo"
            >
              Сохранить
            </v-btn>
            <v-btn
                v-else
                class='success__v-btn'
                @click="sendData()"
                :loading="loading"
                :disabled="!validMultiple[indexMultiForm]"
            >
              Сохранить
            </v-btn>
          </div>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/*
* [x] - перенести шаблон
* [х] - внести изменения в шаблон на возможность переключения нескольких слайдов. а не все в 1м как было раньше
* [x] - управление состоянием диалога сделать внутри и дать возможность управления снаружи
* [x] - убрать все vuex  элементы кроме cNotification
* [x] - все данные для каждой ячейки подготавливать снаружи
* [x] - добавить возможность доавбления кастомный правил корректного ввода для полей
* [х] - проверка не всей формы а только её части и ее отправка
* [] - закрытие при нескольких карточках при потверждении
* [] - написать слот для action btns и вынести их методы в слоте в том числе и переход на след слайд
* [] - добавить финальную проверку данных по каким то критериям, а затем только отправка данных
*
* */
import {mapActions} from "vuex";
import {mapDialogSpr, clearValue} from "@/components/Dialogs/helpers";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import WrapperCollapse from "@/components/wrappers/WrapperCollapse";
import tooltipDialog from "@/components/Dialogs/tooltipDialog";

export default {
  name: "universalDialogV1",
  // eslint-disable-next-line vue/no-unused-components
  components: {WrapperCollapse, WrapperPreviewCategory, tooltipDialog},
  data() {
    return {
      show: false,
      validSolo: false,
      assignedValuesOneStageReady: false,
      assignedValuesOneStage: [],
      validMultiple: [],
      assignedValuesMultipleStageReady: false,
      assignedValuesMultipleStage: [],
      indexMultiForm: 0,
      isOneStage: true,
      steps: [],
    }
  },

  dialogController: null,

  props: {
    dataForDialog: {
      default: null
    },
    additionalData:{
      type: [Object, Array],
    },
    idName: {
      type: String,
      default: 'id'
    },
    minHeight: {
      type: [String, Number],
      default: null,
    },
    headersForDialog: {
      type: Array,
      default: () => []
    },
    sprData: {
      type: [Object],
      default: () => {
      },
    },
    loading: {
      type: Boolean,
      default: false
    },
    needAccept:{
      type: Boolean,
      default: false
    },
    agreeClosing:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    styles() {
      return this.minHeight ? this.minHeight : ''
    },
    computedSprData() {
      const spr = this.sprData;
      for (const [key, value] of Object.entries(this.sprData)) {
        if (value && (Object.prototype.toString.call(value) !== '[object Object]' || Object.prototype.hasOwnProperty.call(value, 'loading'))) {
          spr[key] = mapDialogSpr(value);
        }
      }
      return spr
    }
  },
  watch: {
    async show(newValue) {
      if (newValue === true) {
        if (this.headersForDialog.length > 0) {
          if (Array.isArray(this.headersForDialog[0])) {
            this.isOneStage = false;
            for (let i = 0; i < this.headersForDialog.length; i++) {
              this.steps.push(i);
              this.validMultiple.push(true);
              this.assignedValuesMultipleStage.push(await this.assignDataOneStage([], this.headersForDialog[i]));
            }
            if (this.dataForDialog != null)
              await this.assignEditData(true);
            this.assignedValuesMultipleStageReady = true;
          } else {
            this.isOneStage = true;
            this.assignedValuesOneStage = await this.assignDataOneStage([], this.headersForDialog);
            this.assignedValuesOneStageReady = true;
            if (this.dataForDialog != null)
              await this.assignEditData();
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    changeStage(ind) {
      // console.log("index stage", ind)
      this.assignedValuesOneStage = this.assignedValuesMultipleStage[ind]
    },
    checkStep(index) {
      return index <= this.indexMultiForm
    },
   async closeDialog() {
      await this.$emit('closing-dialog');
      if (!this.needAccept) {
        this.$options.dialogController.resolve(false);
        this.assignedValuesMultipleStage = [];
        this.assignedValuesOneStage = [];
        this.assignedValuesOneStageReady = false;
        this.assignedValuesMultipleStageReady = false;
        this.indexMultiForm = 0;
        this.validSolo = true;
        this.validMultiple = [];
        this.steps = [];
        this.show = false;
      } else {
        if (this.agreeClosing){
          this.$options.dialogController.resolve(false);
          this.assignedValuesMultipleStage = [];
          this.assignedValuesOneStage = [];
          this.assignedValuesOneStageReady = false;
          this.assignedValuesMultipleStageReady = false;
          this.indexMultiForm = 0;
          this.validSolo = true;
          this.validMultiple = [];
          this.steps = [];
          this.show = false;
        }
      }
    },
// надо переделать и под мультипл диалог
    changeValueSelectedSpr(Obj, parentObj = null) {
      this.assignedValuesOneStage.forEach(el => {
        if (Object.prototype.toString.call(Obj, 'dependence')) {
          if (el.dependenceDataField === Obj.dataField) {
            if (!el.dependenceValueActive.includes(Obj[Obj.variable])) {
              if (el.typeVar === 'hiddenBlock') {
                el.listFields.forEach(el2 => {
                  if (Object.prototype.toString.call(Obj, 'dependence')) {
                    if (el2.dependenceDataField === Obj.dataField) {
                      if (!el2.dependenceValueActive.includes(Obj[Obj.variable])) {
                        clearValue(el2);
                      }
                    }
                  }
                })
              }
              clearValue(el);
            }
          }
        }
        if (Object.prototype.hasOwnProperty.call(Obj, 'emits')) {
          if (parentObj)
            this.$emit('dialog-emit', {child: el, parent: parentObj})
          else
            this.$emit('dialog-emit', el)
        }
      })
      if (Object.prototype.hasOwnProperty.call(Obj, 'emits') && Obj.emits) {
        if (parentObj)
          this.$emit('dialog-emit', {child: Obj, parent: parentObj})
        else
          this.$emit('dialog-emit', Obj)
      }
    },
    // надо переделать и под мультипл диалог
    showField(Obj) {
      if (Object.prototype.hasOwnProperty.call(Obj, 'dependence')) {
        if (Obj.dependence !== true)
          return true
        else {
          const ind = this.$scripts.TableJs.arraySearch(this.assignedValuesOneStage, Obj.dependenceDataField, 'dataField')
          if (ind === -1) {
            return true
          } else {
            const buf = this.assignedValuesOneStage[ind][Obj.dependenceDataField]
            return Obj.dependenceValueActive.includes(buf) == null ? false : Obj.dependenceValueActive.includes(buf);
          }
        }
      } else
        return true
    },
    // надо переделать и под мультипл диалог
    // в процессе
    editSoloFunc(assignedValues = this.assignedValuesOneStage) {
      assignedValues.forEach(el => {
        if (el.typeVar === 'string' || el.typeVar === 'integer' || el.typeVar === 'float')
          el[el.variable] = this.dataForDialog[el.variable] != null ? `${this.dataForDialog[el.variable]}` : ``
        else if (el.typeVar === 'date') {
          if (el.typeDate === 'month')
            el[el.variable] = this.dataForDialog[el.variable] != null ? `${this.dataForDialog[el.variable].slice(0, 7)}` : null;
          else
            el[el.variable] = this.dataForDialog[el.variable] != null ? `${this.dataForDialog[el.variable].slice(0, 10)}` : null;
        } else if (el.typeVar === "customSelector") {
          if (el.multiple) {
            if (this.dataForDialog[el.dataField]) {
              const bufData = JSON.parse(this.dataForDialog[el.dataField]);
              el[el.variable] = bufData?.array.map((el1) => {
                return el1.id;
              })
            }
          } else
            el[el.variable] = this.dataForDialog[el.variable];
        } else if (el.typeVar === "multipleSelector") {
          if (this.dataForDialog[el.dataField]) {
            const bufData = JSON.parse(this.dataForDialog[el.dataField]);
            el[el.variable] = bufData?.array.map((el1) => {
              return el1.id
            })
          }
        } else if (el.typeVar === "hiddenBlock") {
          el.listFields.forEach(el2 => {
            el2[el2.variable] = this.dataForDialog[el2.variable]
          })
          el[el.variable] = this.dataForDialog[el.variable]
        } else {
          if (el.sprDataName) {
            el[el.variable] = this.dataForDialog[el.variable]
          } else {
            el[el.variable] = null
          }
        }
      })

    },
    async assignEditData(isMultiple = false) {
      if (!isMultiple) {
        this.editSoloFunc()
      } else {
        this.assignedValuesMultipleStage.forEach(el => this.editSoloFunc(el))
      }
    },
    async assignDataOneStage(assignedValues = this.assignedValuesOneStage, headers = [...this.headersForDialog]) {
      assignedValues = headers.map((field) => {
        let dataVar = field.dataField;
        let arrData = null;
        //для селекторов будет поле с названием готовой переменной
        // так же будет навзвание справочника который передастся полностью тоже готовый в пропсах
        switch (field.typeVar) {
          case 'selector': {
            dataVar = field.variableName;
            arrData = this.computedSprData[field.sprDataName];
            break;
          }
          case 'multipleSelector': {
            dataVar = field.variableName;
            arrData = this.computedSprData[field.sprDataName];
            break;
          }
          default: {
            dataVar = field.dataField;
            arrData = null;
            break;
          }
        }
        if (Object.hasOwnProperty.call(field, 'variable') && Object.hasOwnProperty.call(field, 'sprData'))
          return;
        const bufObj = {...field};
        bufObj['variable'] = dataVar;
        bufObj['sprData'] = arrData;
        switch (field.typeVar) {
          case 'integer':
            bufObj[dataVar] = '';
            break;
          default:
            bufObj[dataVar] = null;
        }
        if (field.typeVar === 'hiddenBlock') {
          const res = this.assignDataOneStage([], [...field.listFields])
          res.then(data => bufObj.listFields = data)
        }
        if (field.typeVar === 'date')
          bufObj['modalVariable'] = false;
        if (!Object.prototype.hasOwnProperty.call(field, 'rules')) {
          this.$set(bufObj, dataVar + 'Rules', [v => !!v || 'Поле не должно быть пустым'])
        } else {
          switch (field.rules) {
            case 1:
              bufObj[dataVar + 'Rules'] = [];
              break;
            case 2:
              bufObj[dataVar + 'Rules'] = [v => v > 0 || 'Значение должно быть больше 0'];
              break;
            case 3:
              bufObj[dataVar + 'Rules'] = [v => !!v || 'Значение не должно быть пустым'];
              break;
            case 'customRules':
              bufObj[dataVar + 'Rules'] = field.customsRulesArray;
              break;
            default:
              bufObj[dataVar + 'Rules'] = [];
              break;
          }
        }
        return bufObj;
      });
      assignedValues.sort((a, b) => a.order - b.order)
      return assignedValues;
    },
    formatDate (date) {
      if (!date) return null

      const  [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    openDialog() {
      let resolve;
      let reject;
      const dialogPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      })
      this.$options.dialogController = {resolve, reject};
      this.show = true;
      return dialogPromise;
    },
    async sendData() {
      this.$options.dialogController.resolve(true);
      await this.validate()
      if (this.validSolo || this.validMultiple[this.indexMultiForm]) {
        const Obj = new Object({
          [this.idName]: this.dataForDialog == null ? null : this.dataForDialog[this.idName]
        })
        let data = this['assignedValuesOneStage']
        if (!this.isOneStage)
          data = this.assignedValuesMultipleStage[this.indexMultiForm]
        data.forEach(el => {
          switch (el.typeVar) {
            case 'integer':
              Obj[el.variable] = parseInt(el[el.variable]);
              break;
            case 'float': {
              let bufStr = el[el.variable]
              let ind = -1
              ind = bufStr.indexOf(',')
              if (ind !== -1)
                bufStr = bufStr.slice(0, ind) + '.' + bufStr.slice(ind + 1)
              Obj[el.variable] = parseFloat(bufStr);
              break;
            }
            case 'multipleSelector': {
              const bufMultiSelectArrResult = [];
              if (el[el.variable] && el[el.variable].length > 0)
                el[el.variable].forEach(el1 => {
                  const bufVar = this[el.sprData].data.find(searchEl => searchEl.value === el1);
                  bufMultiSelectArrResult.push(
                      {id: el1, text: bufVar?.text, code: bufVar?.code}
                  )
                })
              Obj[el.dataField] = JSON.stringify({array: bufMultiSelectArrResult});
              break;
            }
            case 'hiddenBlock': {
              el.listFields.forEach(el => {
                switch (el.typeVar) {
                  case 'integer':
                    Obj[el.variable] = parseInt(el[el.variable]);
                    break;
                  case 'float': {
                    let bufStr = el[el.variable]
                    let ind = -1
                    ind = bufStr.indexOf(',')
                    if (ind !== -1)
                      bufStr = bufStr.slice(0, ind) + '.' + bufStr.slice(ind + 1)
                    Obj[el.variable] = parseFloat(bufStr);
                    break;
                  }
                  case 'multipleSelector': {
                    const bufMultiSelectArrResult = [];
                    if (el[el.variable] && el[el.variable].length > 0)
                      el[el.variable].forEach(el1 => {
                        const bufVar = this[el.sprData].data.find(searchEl => searchEl.value === el1);
                        bufMultiSelectArrResult.push(
                            {id: el1, text: bufVar?.text, code: bufVar?.code}
                        )
                      })
                    Obj[el.dataField] = JSON.stringify({array: bufMultiSelectArrResult});
                    break;
                  }
                  case 'customSelector': {
                    if (el.multiple) {
                      const bufMultiSelectArrResult = [];
                      if (el[el.variable] && el[el.variable].length > 0)
                        el[el.variable].forEach(el1 => {
                          bufMultiSelectArrResult.push(
                              {id: el1, text: el.data.find(searchEl => searchEl.value === el1)?.text}
                          )
                        });
                      Obj[el.dataField] = JSON.stringify({array: bufMultiSelectArrResult});
                    } else
                      Obj[el.variable] = el[el.variable];
                    break;
                  }
                  default:
                    Obj[el.variable] = el[el.variable];
                    break;
                }
              })
              break;
            }
            case 'customSelector': {
              if (el.multiple) {
                const bufMultiSelectArrResult = [];
                if (el[el.variable] && el[el.variable].length > 0)
                  el[el.variable].forEach(el1 => {
                    bufMultiSelectArrResult.push(
                        {id: el1, text: el.data.find(searchEl => searchEl.value === el1)?.text}
                    )
                  });
                Obj[el.dataField] = JSON.stringify({array: bufMultiSelectArrResult});
              } else
                Obj[el.variable] = el[el.variable];
              break;
            }
            default:
              Obj[el.variable] = el[el.variable];
              break;
          }
        })
        this.$emit('fetch-new-data', {...Obj, ...this.additionalData})
      } else
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Не все данные введены корректно',
          timeout: 3000
        })
    },
    async validate() {
      if (this.isOneStage)
        await this.$refs.form.validate()
      else {
        await this.$refs['form-' + this.indexMultiForm][0].validate()
      }
    },
    resetForm() {
      // над осделать и для мульти формы
      this.assignedValuesOneStage.forEach(el => {
        switch (el.typeVar) {
          case 'selector':
            el[el.variable] = null;
            break;
          case 'integer':
            el[el.variable] = '';
            break;
          case 'float':
            el[el.variable] = '0.0';
            break;
          case 'string':
            el[el.variable] = '';
            break;
          default:
            el[el.variable] = null;
            break;
        }
      })

    },

  },
}
</script>

<style lang="sass">
.wrapper-class-dialog
  box-shadow: none
  margin-bottom: 0

  .collapse__toggle
    border-bottom: 1px solid rgba(0, 0, 0, 0.6)

  .active-wrapper
    > .collapse__toggle
      border-bottom: 2px solid #00599B

  > .container
    margin: 0
    padding: 0

.slide-fade-enter-active
  transition: all .5s ease

.slide-fade-leave-active
  transition: all 0s ease

.slide-fade-enter, .slide-fade-leave-to
  opacity: 0
  transform: translateX(10px)

.dialog-section
  min-height: 40px
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  font-weight: 600
  font-size: 20px
  border-top: 2px solid #EBEFF2
  border-bottom: 2px solid #EBEFF2
  margin: 10px 0 20px 0

.stage-line-wrapper
  min-width: 200px

  .step-progress
    position: relative
    list-style: none
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
    padding-left: 0
    -webkit-transition: 2s
    transition: 2s

    &__item
      display: flex
      position: relative
      counter-increment: list
      margin-right: auto
      width: 100%
      justify-content: center

      &:first-child
        .step-progress__item-wrapper
          align-items: flex-start
          padding-left: 17px

          .count
            justify-content: flex-start

            span
              margin-left: 7px

            &:before
          //left: -7px
          &:before
            width: 0

          &:after
            left: 39px
            width: calc(50% + 11px)

      &:last-child
        .step-progress__item-wrapper
          align-items: flex-end
          padding-right: 17px

          .count
            justify-content: flex-end

            span
              margin-right: 7px

            &:before
          //right: -7px
          &:before
            width: calc(50% + 11px)
            left: 0

          &:after
            width: 0

      &-wrapper
        position: relative
        counter-increment: list
        display: flex
        width: 100%
        flex-direction: column
        align-items: center
        justify-content: center

        &:before
          display: flex
          content: ''
          position: absolute
          top: 13px
          height: 2px
          left: -0px
          border-top: 2px solid #C0C4CC
          width: calc(50% - 11px)

        &:after
          display: flex
          content: ''
          position: absolute
          left: calc(50% + 11px)
          top: 13px
          height: 2px
          border-top: 2px solid #C0C4CC
          width: calc(50% - 11px)

      .count
        display: flex
        flex-direction: row
        justify-content: center
        width: 100%
        align-items: center

        &:before
          display: flex
          flex-direction: row
          justify-content: center
          content: ''
          position: absolute
          height: 22px
          top: 5px
          width: 22px
          border: 2px solid #C0C4CC
          border-radius: 50%
          font-size: 16px

      &.font-weight-bold
        display: block

      &.is-done
        color: #00599b

        .step-progress__item-wrapper
          &:before, &:after
            border-top: 2px solid #00599b

        .count
          &:before
            border: 2px solid #00599b
</style>