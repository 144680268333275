<template>
<div class="d-flex align-self-stretch" >
  <v-progress-circular v-if="loading.value && id === loading.id" color="primary" indeterminate size="20"></v-progress-circular>
  <div
      v-else
      class="five-cell d-flex flex-row align-center  "
      style="flex: 0 0 100%;"
      @click="sendValue"
  >
    <div v-if="typeof value === 'object' && value" class="d-flex flex-row align-center justify-center flex-wrap" style="width: 100%">
      <span :style="value.accept === true ? 'text-decoration: line-through; color: #78909C;' : ''">{{ oldValue }}</span>
      <span class="ml-3" :style="value.accept === false ? 'text-decoration: line-through; color: #78909C;' : ''">{{ newValue }}</span>
       <span class="ml-1">
        <v-icon v-if="value.accept == null"   color="" size="15" >mdi-timer-sand</v-icon>
        <v-icon v-if="value.accept === false"  color="error" size="15" >mdi-close</v-icon>
        <v-icon v-if="value.accept === true" color="success" size="15" >mdi-check</v-icon>
       </span>
    </div>
    <span style=" flex: 0 0 100%;" class="text-center" v-else>{{showingValue()}}</span>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "fivesTableCell",
  props: {
    value: {
      type: [Number, String, Object],
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    maxValue: {
      type: [String, Number]
    },
    minValue: {
      type: [String, Number]
    },
    id: {
      type: [String, Number]
    },
    cell: {
      type: [Object, String]
    },
    row: {
      type: [Object, String],
      validator: (value) =>{
        return ['string', 'object'].includes(typeof value)
      }
    },
    loading: {
      type: [Boolean, Object],
      default: false
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
    additionalDataActive: {
      type: Boolean,
      default: false
    },
    additionalData: {
      type: [Object, Array]
    },
    loadingAdditionalData: {
      type: [Boolean, Object],
      default: false
    },
  },
  computed: {
    oldValue() {
      return this.cell.template ? this.cell.template(this.value.oldValue) : this.value.oldValue;
    },
    newValue(){
      return this.cell.template ? this.cell.template(this.value.newValue) : this.value.newValue ;
    },
    ...mapGetters('checkFilled' , { simpleBlock: 'simpleBlock'}),
  },
  methods: {
    showingValue(){
      switch (this.value) {
        case null:
          return 'н.д.';
        case 'NA':
          return 'x';
        default:
          return this.formattedValue();
      }
    },
    sendValue(){
      // if (!this.simpleBlock)
        this.$emit('open-dialog', this.value);
    },
    formattedValue() {
      return this.cell.template ? this.cell.template(this.value) : this.value;
    }
  },
}
</script>

<style lang="sass">
.five-cell

  &:hover
    color: #00599B
    font-weight: 500
</style>