<template>
  <div>
    <CardInput
        v-if="editMode"
        v-model="personToChange"
        :ignore-mobile-phone-number="ignorePersonalPhoneNumber"
        v-on:input="requestPersonUpdate($event)"
        :can-close-enter="canCloseEnter"
        :saving-data="savingData"
        :role="role"
        @cancel="onCancel"
    />
    <div v-else>
      <CardInfo v-if="personInitial" :data="personToShow">
        <template #name="{row}">{{ row.name }}</template>
        <template #value="{row}">
          {{ row.value != null ? (row.value.name != null ? row.value.name : row.value) : 'н/д' }}
        </template>
      </CardInfo>
      <div class="d-flex justify-end mb-1">
        <v-btn v-if="!blocked && !isSingle" class="button blue__v-btn mr-2" @click="addContact">
          Добавить новый контакт
        </v-btn>
        <v-btn v-if="!blocked" :loading="loadingContacts" class="button blue__v-btn mr-2" @click="editMode = !editMode">
          Редактировать
        </v-btn>
        <v-btn v-if="!blocked && canBeDeleted" class="button error__v-btn" @click="deleteContact">Удалить</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CardInfo from '@/components/contacts/CardInfo';
import CardInput from "@/components/contacts/CardInput";

export default {
  components: {
    CardInput,
    CardInfo
  },
  props: {
    isSingle: {
      type: Boolean,
      default: false
    },
    sprData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    savingData: {
      type: Boolean,
      default: false
    },
    loadingContacts: {
      type: Boolean,
      default: false
    },
    ignorePersonalPhoneNumber: {
      type: Boolean,
      default: false
    },
    role: {
      type: String,
      default: 'FILLER'
    },
    blocked: {
      type: Boolean,
      default: false
    },
    canCloseEnter: {
      type: Boolean,
      default: true
    },
    canBeDeleted: {
      type: Boolean,
      default: true
    },
    contact: {
      type: Object,
      default: () => {
        return {
          position: '',
          positionRp: '',
          fio: '',
          fioRp: '',
          workPnAdd: '',
          workPn: '',
          personalPn: '',
          email: ''
        }
      }
    },
  },
  data() {
    return {
      editMode: false,
      personToChange:
          {
            ...this.contact,
          },
      personInitial: this.contact,
    }
  },
  watch: {
    contact(newValue) {
      this.personToChange = {
        ...newValue,
      };
      this.personInitial = {...newValue};
    }
  },
  computed: {
    get_blockedAll() {
      return false
    },
    personToShow() {
      const {
        fio,
        fioRp,
        position,
        positionRp,
        workPnAdd,
        workPn,
        personalPn,
        email
      } = this.contact;
      const fullInfo = [
        {value: fio, name: 'Фамилия Имя Отчество'},
        {value: position, name: 'Должность'},
        {value: email, name: 'Контактный e-mail'},
        {value: workPn + ' ' +  ( workPnAdd ?? '') , name: 'Рабочий и добавочный номер телефона'},
      ]
      if (this.role === 'director'){
        fullInfo.splice( 1,0, {value: fioRp, name: 'ФИО  (в родительном падеже)'});
        fullInfo.splice( 3,0, {value: positionRp, name: 'Должность  (в родительном падеже)'});
        fullInfo.push(  {value: personalPn, name: 'Номер мобильного телефона'})
      }else{
        fullInfo.push(  {value: personalPn, name: 'Номер мобильного телефона'})
      }
      return  fullInfo;
    },
  },
  methods: {
    addContact() {
      this.$emit('add-contact')
    },
    deleteContact() {
      this.$emit('delete-contact', this.contact)
    },
    async requestPersonUpdate(obj) {
      await this.$emit('change-contact', obj)
      this.editMode = false;
    },
    onCancel() {
      this.personToChange = {
        ...this.personInitial,
      };
      this.editMode = false;
    },
  },
}
</script>

<style lang="sass">
.info-card__button
  margin-top: 10px
  margin-left: 10px
</style>