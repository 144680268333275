<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h3 class="b-indicator__collapse-subtitle">Расчет приведенного контингента обучающихся:</h3>
      </v-col>
    </v-row>
    <v-row dense v-if="rowOfValues.data.isbsm22 === '1'">
      <v-col cols="10">
        <p class="mt-2">
          <span class="font-weight-bold mb-1">Форма 5.08. (фрагмент)</span><br>
          Численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры<br>
          по данным формы ВПО-1, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isbsm22 === '1'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th rowspan="3">...</th>
        <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
        <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
        <th rowspan="4" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
      </tr>
      <tr>
        <th colspan="3">
          на 1.10.2021 г.
        </th>
        <th colspan="3">
          на 1.10.2021 г.
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>

        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th rowspan="2" style="border-bottom: 0">
          очно<br/>
          <span class="font-weight-bold">гр.6 + гр.9</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">

          очно-заочно<br/>
          <span class="font-weight-bold">гр.7 + гр.10</span>
        </th>
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
          заочно<br/>
          <span class="font-weight-bold">гр.8 + гр.11</span>
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th>...</th>
        <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left;" >
          <div class="kcp-code">{{  rowOfValues.data.kcpGroup.code  }}</div>
          <div class="kcp-name">{{  rowOfValues.data.kcpGroup.name  }}</div>
        </td>
        <td class="">...</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgo21, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgv21, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgz21, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgo21, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgv21, 0, 'integer') }}</td>
        <td class="">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgz21, 0, 'integer') }}</td>
        <td  class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')"
                                             alt=""/></td>
        <td  class="border-bottom-zero border-right-zero">{{  $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougo21, 0, 'integer')  }}</td>
        <td  class="border-bottom-zero border-right-zero">{{  $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougv21, 0, 'integer')  }}</td>
        <td  class="border-bottom-zero border-right-zero">{{  $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougz21, 0, 'integer')  }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
        <td class="border-bottom-zero border-right-zero border-left-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="4" class="border-bottom-zero border-right-zero text-right"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table" style="">
          PNo
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table" style="">
          PNv
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table" style="">
          PNz
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense v-if="rowOfValues.data.isbsm22 === '0'">
      <v-col cols="10">
        <p>
        <span class="font-weight-bold mb-1">Форма 5.15. (фрагмент)</span><br>
          Численность обучающихся по программам ординатуры, программам ассистентуры-стажировки<br>
          по данным формы 1-НК, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isbsm22 === '0'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Уровни образования</th>
        <th rowspan="3">...</th>
        <th colspan="3">Обучающихся (искл. квоту иностр.граждан)</th>
        <th colspan="3">Иностр.граждан, обучающихся по квоте</th>
        <th rowspan="6" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="2" class="border-right-zero" colspan="3">Всего обучающихся</th>
      </tr>
      <tr>
        <th colspan="3">
          на 1.01.2021 г.
        </th>
        <th colspan="3">
          на 1.01.2021 г.
        </th>
<!--        <th colspan="3">-->
<!--        </th>-->
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>

        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th rowspan="2" style="border-bottom: 0">
          очно<br/>
          <span class="font-weight-bold">гр.6 + гр.9</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">

          очно-заочно<br/>
          <span class="font-weight-bold">гр.7 + гр.10</span>
        </th>
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
          заочно<br/>
          <span class="font-weight-bold">гр.8 + гр.11</span>
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th>...</th>
        <th v-for="(item) in 6" :key="item">{{ item + 5 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>...</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nosgz21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noqgz21, 0, 'integer') }}</td>
        <td class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
        <td class="border-bottom-zero border-right-zero">{{  $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougo21, 0, 'integer')  }}</td>
        <td class="border-bottom-zero border-right-zero">{{  $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougv21, 0, 'integer')  }}</td>
        <td class="border-bottom-zero border-right-zero">{{  $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougz21, 0, 'integer')  }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="4" class="border-bottom-zero border-right-zero text-right"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table" style="">
          PNo
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table" style="">
          PNv
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table" style="">
          PNz
        </td>
      </tr>
      </tbody>
    </table>
    <p>Приведенный контингент обучающихся:</p>
    <div class="d-flex flex-row">
      <formuls-fraction :need-bottom-line="false">
        <template>
          PN
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">1,0 × </span> PNo
        </template>
        <template #bottom>
          очная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> + </span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,25 ×</span> PNv
        </template>
        <template #bottom>
          очно-заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> + </span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,1 ×</span> PNz
        </template>
        <template #bottom>
          заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">1,0 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougo21, 0, 'integer') }}
        </template>
        <template #bottom>
          очная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> + </span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,25 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougv21, 0, 'integer') }}
        </template>
        <template #bottom>
          очно-заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> + </span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,1 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougz21, 0, 'integer') }}
        </template>
        <template #bottom>
          заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougp21, 1, 'float') }}
        </template>
        <template #bottom>
          приведенный<br>контингент
        </template>
      </formuls-fraction>
    </div>
  </div>
</template>

<script>
export default {
  name: "blockPn",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
}
</script>

<style scoped>

</style>