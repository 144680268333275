import request from "@/services/request";
import store from "@/store";
import Table from "@/utils/Table";

async function getAllGroupedKcpIndicators(api = '') {
    try {
        let params = '';
        if (store.getters['auth/get_replace_id_admin'])
            params = `?orgId=${store.getters['auth/get_replace_id_admin'] ? store.getters['auth/get_replace_id_admin'] : store.getters['auth/get_replace_id_admin']}`;

        const res = await request({
            endpoint: api ? api + params : `/api/tab/ind${params}`,
            method: 'get',
        });
        if (res.status === 200) {
            let returnData = [];
            const orgOwner = store.getters['checkFilled/getMainOrg'].data;
            const orgFilials = store.getters['checkFilled/getFilials'].data;
            const usls = store.getters['spr/get_spr_data_usl'].data;
            const mainOrgData = res.data.filter(el => el.id === orgOwner.orgId);
            const filialsData = orgFilials.map(el => {
                return res.data.filter(el2 => el2.orgId === el.id)
            });
            returnData.push(
                {
                    orgName: orgOwner.name,
                    orgId: orgOwner.id,
                    ownerId: orgOwner.id,
                    id: Math.random(),
                    orgFlag: true
                }
            )
            usls.forEach(el => {
                if (res.data.find(elData => elData.kcpGroup.usl.id === el.id))
                    returnData.push({
                        id: Math.random(),
                        uslName: el.name
                    }, ...mainOrgData.filter(elData => elData.kcpGroup.usl.id === el.id && elData.orgId === orgOwner.id).map(elData => {

                        return {
                            ...elData,
                        iokio22: elData.iokio22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iokio22, 3, 'float'),
                        iokiv22: elData.iokiv22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iokiv22, 3, 'float'),
                        iokiz22: elData.iokiz22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iokiz22, 3, 'float'),
                        iowgt22: elData.iowgt22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iowgt22, 3, 'float')
                    }})
                    );
            });
            if (filialsData.length > 0)
                for (const elFilialData of filialsData) {
                    if (elFilialData.length === 0)
                        continue;

                    returnData.push(
                        {
                            orgName: elFilialData[0].orgName,
                            orgId: elFilialData[0].orgId,
                            ownerId: elFilialData[0].ownerId,
                            id: Math.random(),
                            orgFlag: true,
                        }
                    );
                    usls.forEach(el => {
                        if (elFilialData.find(elData => elData.kcpGroup.usl.id === el.id))
                            returnData.push({
                                id: Math.random(),
                                uslName: el.name
                            }, ...elFilialData.filter(elData => elData.kcpGroup.usl.id === el.id).map(elData => {
                                return {
                                    ...elData,
                                    iokio22: elData.iokio22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iokio22, 3, 'float'),
                                    iokiv22: elData.iokiv22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iokiv22, 3, 'float'),
                                    iokiz22: elData.iokiz22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iokiz22, 3, 'float'),
                                    iowgt22: elData.iowgt22 === 'NA' ? null : Table.checkValueWithNullAndReturnND(elData.iowgt22, 3, 'float')
                                }}));
                    });

                }
            return returnData;
        } else {
            throw new Error('error getAllGroupedKcpIndicators');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения общих показателей ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error getAllGroupedKcpIndicators ' + e)
    }
}
async function getAllGroupedKcpIndicatorsStage1() {
    try {
        const res = await request({
            endpoint: `/api/tab/kcp23?orgId=${store.getters['auth/get_replace_id_admin']}`,
            method: 'get',
        });
        if (res.status === 200) {
            let returnData = [];
            const orgOwner = store.getters['checkFilled/getMainOrg'].data;
            const orgFilials = store.getters['checkFilled/getFilials'].data;
            const usls = store.getters['spr/get_spr_data_usl'].data;
            const mainOrgData = res.data.filter(el => (el.orgId === orgOwner.id && (
                el.qkcpo23 !== 'NA' || el.qkcpv23 !== 'NA' || el.qkcpz23 !== 'NA'
            )
            ));
            const filialsData = orgFilials.map(el => {
                return res.data.filter(el2 => (el2.orgId === el.id  && (
                    el2.qkcpo23 !== 'NA' || el2.qkcpv23 !== 'NA' || el2.qkcpz23 !== 'NA'
                )))
            });
            returnData.push(
                {
                    orgName: orgOwner.name,
                    orgId: orgOwner.id,
                    ownerId: orgOwner.id,
                    id: Math.random(),
                    orgFlag: true
                }
            )
            usls.forEach(el => {
                if (res.data.find(elData => elData.kcpGroup.usl.id === el.id))
                    returnData.push({
                            id: Math.random(),
                            uslName: el.name
                        }, ...mainOrgData.filter(elData => elData.kcpGroup.usl.id === el.id ).map(elData => {
                            return {
                                ...elData,
                                qkcpo23: elData.qkcpo23 === 'NA' ? null : elData.qkcpo23,
                                qkcpv23: elData.qkcpv23 === 'NA' ? null : elData.qkcpv23,
                                qkcpz23: elData.qkcpz23 === 'NA' ? null : elData.qkcpz23,
                            }})
                    );
            });
            if (filialsData.length > 0)
                for (const elFilialData of filialsData) {
                    if (elFilialData.length === 0)
                        continue;

                    returnData.push(
                        {
                            orgName: elFilialData[0].orgName,
                            orgId: elFilialData[0].orgId,
                            ownerId: elFilialData[0].ownerId,
                            id: Math.random(),
                            orgFlag: true,
                        }
                    );
                    usls.forEach(el => {
                        if (elFilialData.find(elData => elData.kcpGroup.usl.id === el.id))
                            returnData.push({
                                id: Math.random(),
                                uslName: el.name
                            }, ...elFilialData.filter(elData => elData.kcpGroup.usl.id === el.id  ).map(elData => {
                                return {
                                    ...elData,
                                    qkcpo23: elData.qkcpo23 === 'NA' ? null : elData.qkcpo23,
                                    qkcpv23: elData.qkcpv23 === 'NA' ? null : elData.qkcpv23,
                                    qkcpz23: elData.qkcpz23 === 'NA' ? null : elData.qkcpz23,
                                }}));
                    });

                }
            return returnData;
        } else {
            throw new Error('error getAllGroupedKcpIndicatorsStage1');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения общих показателей ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error getAllGroupedKcpIndicatorsStage1 ' + e)
    }
}
async function getAllGroupedKcpIndicatorsStage1CorrectValues() {
    try {
        const res = await request({
            endpoint: `/api/kcpAdd?orgOwnerId=${store.getters['auth/get_replace_id_admin']}`,
            method: 'get',
        });
        if (res.status === 200) {
            let returnData = [];
            const orgOwner = store.getters['checkFilled/getMainOrg'].data;
            const orgFilials = store.getters['checkFilled/getFilials'].data;
            const usls = store.getters['spr/get_spr_data_usl'].data;
            const mainOrgData = res.data.filter(el => (el.org.id === orgOwner.id && (
                el.qkcpo23Add !== 'NA' || el.qkcpv23Add !== 'NA' || el.qkcpz23Add !== 'NA'
            )
            ));
            const filialsData = orgFilials.map(el => {
                return res.data.filter(el2 => (el2.org.id === el.id  && (
                    el2.qkcpo23Add !== 'NA' || el2.qkcpv23Add !== 'NA' || el2.qkcpz23Add !== 'NA'
                )))
            });
            returnData.push(
                {
                    orgName: orgOwner.name,
                    orgId: orgOwner.id,
                    ownerId: orgOwner.id,
                    id: Math.random(),
                    orgFlag: true
                }
            )
            usls.forEach(el => {
                if (res.data.find(elData => elData.kcpGroup.usl.id === el.id))
                    returnData.push({
                            id: Math.random(),
                            uslName: el.name
                        }, ...mainOrgData.filter(elData => elData.kcpGroup.usl.id === el.id ).map(elData => {
                            return {
                                ...elData,
                                qkcpo23Add: elData.qkcpo23Add === 'NA' ? null : elData.qkcpo23Add,
                                qkcpv23Add: elData.qkcpv23Add === 'NA' ? null : elData.qkcpv23Add,
                                qkcpz23Add: elData.qkcpz23Add === 'NA' ? null : elData.qkcpz23Add,
                                qkcpo23Accept: elData.qkcpo23Accept === 'NA' ? null : Number.parseInt(elData.qkcpo23Accept),
                                qkcpv23Accept: elData.qkcpv23Accept === 'NA' ? null : Number.parseInt(elData.qkcpv23Accept),
                                qkcpz23Accept: elData.qkcpz23Accept === 'NA' ? null : Number.parseInt(elData.qkcpz23Accept),
                            }})
                    );
            });
            if (filialsData.length > 0)
                for (const elFilialData of filialsData) {
                    if (elFilialData.length === 0)
                        continue;

                    returnData.push(
                        {
                            orgName: elFilialData[0].org?.name,
                            orgId: elFilialData[0].org?.id,
                            ownerId: elFilialData[0].org?.owner?.id,
                            id: Math.random(),
                            orgFlag: true,
                        }
                    );
                    usls.forEach(el => {
                        if (elFilialData.find(elData => elData.kcpGroup.usl.id === el.id))
                            returnData.push({
                                id: Math.random(),
                                uslName: el.name
                            }, ...elFilialData.filter(elData => elData.kcpGroup.usl.id === el.id  ).map(elData => {
                                return {
                                    ...elData,
                                    qkcpo23Add: elData.qkcpo23Add === 'NA' ? null : elData.qkcpo23Add,
                                    qkcpv23Add: elData.qkcpv23Add === 'NA' ? null : elData.qkcpv23Add,
                                    qkcpz23Add: elData.qkcpz23Add === 'NA' ? null : elData.qkcpz23Add,
                                    qkcpo23Accept: elData.qkcpo23Accept === 'NA' ? null : Number.parseInt(elData.qkcpo23Accept),
                                    qkcpv23Accept: elData.qkcpv23Accept === 'NA' ? null : Number.parseInt(elData.qkcpv23Accept),
                                    qkcpz23Accept: elData.qkcpz23Accept === 'NA' ? null : Number.parseInt(elData.qkcpz23Accept),
                                }}));
                    });

                }
            return returnData;
        } else {
            throw new Error('error getAllGroupedKcpIndicatorsStage1');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения общих показателей ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error getAllGroupedKcpIndicatorsStage1 ' + e)
    }
}


async function getSprKcpPok() {
    try {
        const res = await request({
            endpoint: `/api/tab/pok`,
            method: 'get',
        });
        if (res.status === 200) {
            return res;
        } else {
            throw new Error('error kcp spr pok');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных формы ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error kcp spr pok' + e)
    }
}
async function getAppeals() {
    try {
        const res = await request({
            endpoint: `/api/appealleft`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error getAppeals');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения списка аппеляций ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error getAppeals' + e)
    }
}

async function sendAppealAdmin(payload){
    try{
        const res = await request({
            endpoint: `/api/appealleft/putAdmin`,
            method: 'put',
            body: payload
        });
        if (res.status === 200 || res.status === 201){
            return res.data;
        }else{
            throw new Error('error appeal post');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка отправки аппеляции '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error appeal post' + e )
    }
}
async function declineIndicatorAppeal(payload){
    try{
        const {id, foId, flag} = payload;
        const res = await request({
            endpoint: `/api/tab/kcp23reject?rowId=${id}&foId=${foId}&flag=${flag}`,
            method: 'post',
        });
        if (res.status === 200 || res.status === 201){
            return {
                ...res.data,
                qkcpo23: res.data.qkcpo23 === 'NA' ? null : res.data.qkcpo23,
                qkcpv23: res.data.qkcpv23 === 'NA' ? null : res.data.qkcpv23,
                qkcpz23: res.data.qkcpz23 === 'NA' ? null : res.data.qkcpz23,
            };
        }else{
            throw new Error('error appeal post');
        }
    }
    catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка отказа от КЦП '+ ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error appeal post' + e )
    }
}

async function getAppealsAdmin() {
    try {
        const res = await request({
            endpoint: `/api/appealleft/getAll`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error getAppeals');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения списка аппеляций ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error getAppeals' + e)
    }
}
async function getAppealChangeCorrectValues() {
    try {
        const res = await request({
            endpoint: `/api/kcpAdd/org?orgOwnerId=${store.getters['auth/get_replace_id_admin']}`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error getAppealChangeCorrectValues');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения ! ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error getAppealChangeCorrectValues' + e)
    }
}

async function putAppeal(obj) {
    try {
        const res = await request({
            endpoint: `/api/appealleft`,
            method: 'put',
            body: obj
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error putAppeal');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка отправки аппеляции ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error putAppeal' + e)
    }
}
async function putCorrectValueAppeal(obj) {
    try {
        const res = await request({
            endpoint: `/api/kcpAdd`,
            method: 'put',
            body: obj
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error putCorrectValueAppeal');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка отправки ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('error putCorrectValueAppeal' + e)
    }
}
async function getKcpPokFull(orgId = '', kcpGroup = '', usl = '', fo = '') {
    try {
        const params = `?orgId=${orgId}&kcpGroup=${kcpGroup}&usl=${usl}&fo=${fo}`;
        const res = await request({
            endpoint:`/api/tab/all${params}`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('getKcpPokFull');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных формы ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('getKcpPokFull ' + e)
    }
}
async function getBlockCorrectIndicatorInfo(){
    try {
        const res = await request({
            endpoint:`/api/kcpAdd/block?orgId=${store.getters['auth/get_replace_id_admin']}`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('getBlockCorrectIndicatorInfo');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения блокировки' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('getBlockCorrectIndicatorInfo ' + e)
    }
}
async function setBlockCorrectIndicatorInfo(obj){
    try {
        const res = await request({
            endpoint:`/api/kcpAdd/block?orgId=${store.getters['auth/get_replace_id_admin']}`,
            method: 'put',
            body: obj
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('getBlockCorrectIndicatorInfo');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения блокировки' + ' ' + e,
            timeout: 3000
        }, {root: true});
        throw new Error('getBlockCorrectIndicatorInfo ' + e)
    }
}
export {getBlockCorrectIndicatorInfo,setBlockCorrectIndicatorInfo, getAllGroupedKcpIndicators, getSprKcpPok,getAppealChangeCorrectValues, getKcpPokFull,putCorrectValueAppeal, getAppeals, putAppeal, getAppealsAdmin, sendAppealAdmin, getAllGroupedKcpIndicatorsStage1 , declineIndicatorAppeal, getAllGroupedKcpIndicatorsStage1CorrectValues}