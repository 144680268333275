<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
      </v-col>
    </v-row>
    <v-row dense v-if="rowOfValues.data.orggl22 === '0'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.06. (фрагмент)</span><br>
          Контрольные цифры приема, установленные образовательной организации<br>
          по данным Министерства науки и высшего образования РФ, мест
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.orggl22 === '0'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="9">Контрольные цифры приема, установленные образовательной организации</th>
        <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr>
        <th colspan="3">
          на 2019/2020 учебный год
        </th>
        <th colspan="3">
          на 2020/2021 учебный год
        </th>
        <th colspan="3">
          на 2021/2022 учебный год
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th v-for="(item) in 9" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpo19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpz19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpo20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpv20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpz20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpz21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="9" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-9.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          КЦО = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpsS3, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense v-if="rowOfValues.data.orggl22 === '0'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.07. (фрагмент)</span><br>
          Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета<br>
          без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом,
          обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование
          иностранных граждан и лиц без гражданства в Российской Федерации
          <br>по данным Мониторинга приемной кампании, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.orggl22 === '0'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="9">Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета</th>
        <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr>
        <th colspan="3">
          в 2019/2020 учебном году

        </th>
        <th colspan="3">
          в 2020/2021 учебном году

        </th>
        <th colspan="3">
          в 2021/2022 учебном году

        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th v-for="(item) in 9" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfo19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfz19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfo20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfv20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfz20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfz21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="9" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-9.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ПРФ = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfsS3, 0, 'integer') }}
        </td>
      </tr>
      </tbody>
    </table>
    <p v-if="rowOfValues.data.orggl22 === '1'">Для головных организаций значение показателя определяется с учетом их
      филиалов</p>
    <v-row dense v-if="rowOfValues.data.orggl22 === '1'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.06. (фрагмент)</span><br>
          Контрольные цифры приема, установленные образовательной организации<br>
          по данным Министерства науки и высшего образования РФ, мест
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.orggl22 === '1'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="9">Контрольные цифры приема, установленные образовательной организации</th>
        <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr>
        <th colspan="3">
          на 2019/2020 учебный год
        </th>
        <th colspan="3">
          на 2020/2021 учебный год
        </th>
        <th colspan="3">
          на 2021/2022 учебный год
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th v-for="(item) in 9" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" class="border-bottom-zero kcp-td" style="text-align: left;">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td v-for="(item) in 9" class="border-bottom-zero" :key="item"></td>
      </tr>
      <tr  v-for="item in rowOfValues.data.tabB12AspQkc" :key="item.id">
        <td colspan="3" style="text-align: left" class="kcp-name kcp-td">
          {{  item.orgId.name  }}
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.qkcpo19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.qkcpv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.qkcpz19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.qkcpo20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.qkcpv20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.qkcpz20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.qkcpo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.qkcpv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.qkcpz21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="9" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-9.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          КЦО = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpsS3, 0, 'integer') }}

        </td>
      </tr>
      </tbody>
    </table>
    <v-row v-if="rowOfValues.data.orggl22 === '1'" dense>
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.07. (фрагмент)</span><br>
          Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета
          без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом,
          обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование
          иностранных граждан и лиц без гражданства в Российской Федерации
          <br>по данным Мониторинга приемной кампании, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.orggl22 === '1'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="9">Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета</th>
        <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr>
        <th colspan="3">
          в 2019/2020 учебном году

        </th>
        <th colspan="3">
          в 2020/2021 учебном году

        </th>
        <th colspan="3">
          в 2021/2022 учебном году

        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th v-for="(item) in 9" :key="item">{{ item + 2 }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" class="border-bottom-zero kcp-td" style="text-align: left; ">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td v-for="(item) in 9" class="border-bottom-zero" :key="item"></td>
      </tr>
      <tr v-for="item in rowOfValues.data.tabB12AspNps" :key="item.id">
        <td colspan="3" style="text-align: left" class="kcp-name kcp-td">
          {{ item.orgId.name }}
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.npsfo19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.npsfv19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.npsfz19, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.npsfo20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.npsfv20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.npsfz20, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.npsfo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.npsfv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(item.npsfz21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td colspan="9" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/bracket-9.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          ПРФ = {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfsS3, 0, 'integer') }}

        </td>
      </tr>
      </tbody>
    </table>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          ПРФ
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">КЦО</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction-simple :need-bottom-line="false">
        <template>
          <span class="alternate-text">× 100</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsfsS3, 0, 'integer') }}
        </template>
        <template #bottom>
          <span class="formul-text"
                style="  min-height: 49px">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.qkcpsS3, 0, 'integer') }}</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction-simple :need-bottom-line="false">
        <template>
          <span class="alternate-text">× 100</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8"> = </span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span
              class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName + 'b022'], (item.prd+1)) }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>
      ПРФ - число обучающихся, зачисленных на обучение за счет средств федерального бюджета на I курс обучения по всем
      формам обучения за три последних отчетных года, предшествовавших текущему учебному году;
    </p>
    <p class="mb-5">
      КЦО - сумма контрольных цифр приема по всем формам обучения (без учета отказа образовательной организации от
      установленных контрольных цифр приема) на учебные годы, за которые рассчитывается показатель ПРФ.
    </p>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "b12",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  computed: {
    ...mapGetters('checkFilled', {getMainOrg: 'getMainOrg', getFilials: 'getFilials'}),
    mainOrg() {
      if (this.getMainOrg.loading) return {name: ''}
      return this.getMainOrg.data ?? {name: 'Головная организация', id: Math.random()}
    },
    mainOrgData() {
      return {
        tabB12AspQkc: this.rowOfValues.data.tabB12AspQkc.find(el => el.orgId.id === this.mainOrg.id),
        tabB12AspNps: this.rowOfValues.data.tabB12AspNps.find(el => el.orgId.id === this.mainOrg.id)
      }
    },
    filialsOrg() {
      if (this.getFilials.loading) return [];
      return this.getFilials.data.map(
          el => {
            return {
              ...el,
              orgData: {
                tabB12AspQkc: this.rowOfValues.data.tabB12AspQkc.find(el2 => el2.orgId.id === el.id),
                tabB12AspNps: this.rowOfValues.data.tabB12AspNps.find(el2 => el2.orgId.id === el.id)
              }
            }
          }
      ) ?? []
    },
  },
  methods: {
    checkData(value) {
      return value//this.$scripts.TableJs.checkValueWithNullAndReturnND(value.value,'float',value.rnd,false, 'color: #C6C8CB;')
    },
    getValue(item, dataField, table) {
      return this.$scripts.TableJs.checkValueWithNullAndReturnND(item.orgData[table] ? item.orgData[table][dataField] : 'NA', 0, 'integer')
    }

  },
}
</script>

<style lang="sass" scoped>
</style>