<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
        <h3 class="b-indicator__collapse-subtitle">Расчет приведенного контингента обучающихся по договорам о целевом
          обучении:</h3>

      </v-col>
    </v-row>
    <v-row dense v-if="rowOfValues.data.isbsm22 === '1'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.11. (фрагмент)</span><br>
          Численность обучающихся по договорам о целевом обучении по программам бакалавриата, программам специалитета,
          программам магистратуры
          <br>без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за
          рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование
          иностранных граждан и лиц без гражданства в Российской Федерации
          <br>по данным формы ВПО-1, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isbsm22 === '1'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>
        <th colspan="6">Обучающихся по договорам о целевом обучении на всех курсах</th>
        <th rowspan="3">...</th>
        <th rowspan="5" class="border-right-zero" style="min-width: 66px;border-bottom: 0!important;"></th>
        <th rowspan="1" class="border-right-zero" colspan="3">Всего обучающихся по договорам о целевом обучении</th>
      </tr>
      <tr>
        <th colspan="3">
          на 1.10.2021 г.
        </th>
        <th colspan="3">
          на 1.10.2021 г.
        </th>
        <th colspan="3" class="border-right-zero">
          на 1.01.2021 г.
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>

        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
        <th rowspan="2" style="border-bottom: 0">
          очно<br/>
          <span class="font-weight-bold">гр.36 + гр.6</span>
        </th>
        <th rowspan="2" style="border-bottom: 0">

          очно-заочно<br/>
          <span class="font-weight-bold">гр.4 + гр.7</span>
        </th>
        <th rowspan="2" class="border-right-zero" style="border-bottom: 0">
          заочно<br/>
          <span class="font-weight-bold">гр.5 + гр.8</span>
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left"><span>1</span><span style="margin-left: 66px">2</span></th>
        <th v-for="(item) in 6" :key="item">{{ item + 2 }}</th>
        <th>...</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nocqo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nocqv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nocqz21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nocso21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nocsv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nocsz21, 0, 'integer') }}</td>
        <td>...</td>
        <td class="border-bottom-zero border-right-zero"><img :src="require('@/assets/images/Vector_rigth_arrow.svg')" alt=""/></td>
        <td class="border-right-zero border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclo21, 0, 'integer') }}</td>
        <td class="border-right-zero border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclv21, 0, 'integer') }}</td>
        <td class="border-right-zero border-bottom-zero">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclz21, 0, 'integer') }}</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 11" :key="item"></td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 7" :key="item"></td>
        <td colspan="4" class="border-bottom-zero border-right-zero text-right"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NAo
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NAv
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NAz
        </td>
      </tr>
      </tbody>
    </table>
    <v-row dense v-if="rowOfValues.data.isbsm22 === '0'">
      <v-col cols="10">
        <p>
          <span class="font-weight-bold mb-1">Форма 5.17. (фрагмент)</span><br>
          Дополнительные сведения о целевом обучении и о приеме на очную форму обучения по программам ординатуры,
          программам ассистентуры-стажировки
          <br>без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за
          рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование
          иностранных граждан и лиц без гражданства в Российской Федерации
          <br>по данным Мониторинга целевого обучения, человек
        </p>
      </v-col>
    </v-row>
    <table v-if="rowOfValues.data.isbsm22 === '0'">
      <thead>
      <tr>
        <th rowspan="3" colspan="3" class="text-left kcp-td">Код и наменование УГСН (НПС)</th>

        <th colspan="3">Всего обучающихся по договорам<br> о целевом обучении</th>
        <th rowspan="3">...</th>
        <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
      </tr>
      <tr>
        <th colspan="3">
          по данным МЦО на начало 2021 г.
        </th>
      </tr>
      <tr>
        <th>
          очно
        </th>
        <th>
          очно-заочно
        </th>
        <th>
          заочно
        </th>
      </tr>
      <tr style="border-top: 1px solid #B7CED9">
        <th colspan="3" style="text-align: left">
          <span>1</span><span style="margin-left: 66px">2</span>
        </th>
        <th v-for="(item) in 3" :key="item">{{ item + 2 }}</th>
        <th>...</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3" style="text-align: left">
          <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
          <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
        </td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclo21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclv21, 0, 'integer') }}</td>
        <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclz21, 0, 'integer') }}</td>
        <td>...</td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
<!--        <td class="border-bottom-zero border-right-zero"></td>-->
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
        <td colspan="1" class="border-bottom-zero border-right-zero">
          <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
        </td>
      </tr>
      <tr>
        <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NAo
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NAv
        </td>
        <td class="border-bottom-zero border-right-zero formul-text-table">
          NAz
        </td>
      </tr>
      </tbody>
    </table>
    <p>Приведенный контингент обучающихся по договорам о целевом обучении:</p>
    <div class="d-flex flex-row">
      <formuls-fraction :need-bottom-line="false">
        <template>
          NAP
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">1,0 × </span> NAo
        </template>
        <template #bottom>
          очная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,25 ×</span> NAv
        </template>
        <template #bottom>
          очно-заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,1 ×</span> NAz
        </template>
        <template #bottom>
          заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">=</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">1,0 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclo21, 0, 'integer') }}
        </template>
        <template #bottom>
          очная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,25 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclv21, 0, 'integer') }}
        </template>
        <template #bottom>
          очно-заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">+</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          <span class="alternate-text mr-1">0,1 ×</span> {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclz21, 0, 'integer') }}
        </template>
        <template #bottom>
          заочная <br>форма
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="alternate-text">=</span>
        </template>
      </formuls-fraction>
      <formuls-fraction>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclp21, 1, 'float') }}
        </template>
        <template #bottom>
          приведенный<br>контингент
        </template>
      </formuls-fraction>
    </div>
    <block-pn :item="item" :row-of-values="rowOfValues"/>
    <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
    <div class="d-flex mt-2 flex-row align-center">
      <formuls-fraction :need-bottom-line="true">
        <template>
          <div class="d-flex flex-row align-end">
            {{ item.title }}
            <div class="little-text ">0</div>
          </div>
        </template>
        <template #bottom>значение<br> показателя</template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false" >
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
     NAP
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">PN</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction-simple>
        <template>
          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.noclp21, 1, 'float') }}
        </template>
        <template #bottom>
          <span class="formul-text" style="  min-height: 49px">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.nougp21, 1, 'float') }}</span>
        </template>
      </formuls-fraction-simple>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8 alternate-text"> =</span>
        </template>
      </formuls-fraction>
      <formuls-fraction :need-bottom-line="false">
        <template>
          <span class="mt-8">          {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
        </template>
      </formuls-fraction>
    </div>
    <p>NAP -  приведенный контингент обучающихся по договорам о целевом обучении, по данным отчетного года</p>
    <p class="mb-5">PN - приведенный контингент обучающихся, по данным отчетного года</p>

  </div>
</template>

<script>
import BlockPn from "@/components/cards/indicatiors/details/blockPn";
export default {
  name: "b24",
  components: {BlockPn},
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>
</style>