<template>
<div>
  <WrapperPreviewCategory customClass="section-preview_lk">
  <GridTable
      :data-rows="showingServerData"
      :headers="headers.massive"
      :headers-for-rows="headersForRows"
      :loading-data="loadingData"
      :num-cols="headers.countCells"
      :max-table-height="maxTableHeight"
      ref="npg-table"
      key="form-5-011"
  >
    <template #header-npg0="{item}">
      <v-btn  @click="loadFullInfo" class="white__v-btn">{{ !full ? 'Показать все' : "Скрыть пустые" }}  строки </v-btn>
      {{item.text}}
    </template>
    <!--  v-if="!changingHeaders"  :key='' когда меняется хедер проверить    -->
    <template v-for="item in headersForRows" v-slot:[calcHeaderName(item.dataField)]>
      <div :key="item.dataField" :class="item.headerClass" style="max-height: 22px;min-width: 100%">
        <template v-if="item.text === '1'">
          <div style="min-width: 60px">1</div>
          <div class="text-center" style="width: 100%">2</div>
        </template>
        <template v-else>
          <div class="d-flex flex-row align-center" style="max-height: 22px;">
            {{ item.text }}
            <v-btn small icon @click="openTooltip(item.dataField)">
              <v-icon
                  :size="15"
                  class="grey-light-color__important "
              >
                mdi-information
              </v-icon>
            </v-btn></div>
        </template>
      </div>
    </template>
    <template #npg="{row}">
      <template v-if="row.uslName">
        <div class="font-weight-bold font-size-medium ">
          {{row.uslName[0].toUpperCase()+ row.uslName.slice(1) }}
        </div>
      </template>
      <template v-else>
        <div style="">
          {{row.spec ? row.spec.kodSpec  : row.sprKcpGroup.code }}
        </div>
        <div class="ml-3">
          {{row.spec ?  row.spec.name : row.sprKcpGroup.name}}
        </div>
      </template>
    </template>
    <template v-for="item in headersForRowsWithEditableCells" v-slot:[item.dataField]="{row,cell}">
      <fives-table-cell :key="item.dataField" v-bind="item.props(row,cell, loadingMenu)"
                        @open-dialog="openDialog($event, item.dataField, cell, row)"/>
    </template>
  </GridTable>
    <div class="mt-3 d-flex flex-row justify-center align-center">
      <v-autocomplete
          class="pagination-table"
          v-model="userPages"
          :items="pagesPaginate"
          solo
          dense
          filled
          height="25"
          rounded
          style="max-width: 150px"
          @change="changePage($event)"
      ></v-autocomplete>
      <v-pagination
          v-model="page"
          :length="pagesVuetify"
          :total-visible="7"
          @input="changePage($event)"
      >
      </v-pagination>

    </div>
  </WrapperPreviewCategory>
  <tooltip-forms5-dialog
      ref="tooltip-dialog"
      :data-field="dataFieldTooltip"
  >
  </tooltip-forms5-dialog>
  <appeal-dialog
      ref="appeal-dialog"
      :appeal-id="appealId"
      :file-id="fileId"
      :appeal="appealCell"
      :value="appealValue"
      :appeals-arr="appealsArr"
      :appeal-info="appealInfo"
      @saved-value="saveValue($event)"
      @closing-dialog="closingAppealDialog"
  ></appeal-dialog>
</div>
</template>

<script>
import GridTable from "@/components/GridTable/GridTable";
import form511 from "@/helpers/forms5/form5-11";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {mapGetters} from "vuex";
import {getFormData} from "@/API/form5";
import {headerTooltipFunctions} from "@/mixins/forms5/headerTooltip";
import tooltipForms5Dialog from "@/components/Dialogs/tooltipForms5Dialog";
import {setAppealDialog} from "@/mixins/forms5/appealDialog";
import FivesTableCell from "@/components/fields/fivesTableCell";
import appealDialog from "@/components/Dialogs/appealDialog";
import {tablePagination} from "@/mixins/tablePagination";
export default {
  name: "form10",
  mixins: [headerTooltipFunctions, setAppealDialog, tablePagination],
  components: {FivesTableCell,  WrapperPreviewCategory, GridTable, tooltipForms5Dialog, appealDialog},
  data() {
    return {
      serverData: [],
      loadingData: false,
      changingHeaders: false,
      headers: form511,
      loadingMenu: {value: false, id: ''},
      full: false
    }
  },
  computed: {
    ...mapGetters('spr', {get_spr_data_usl_loading: 'get_spr_data_usl_loading', get_spr_data_usl_error: 'get_spr_data_usl_error', }),
    maxTableHeight(){
      return window.innerHeight - 225 + 'px'
    },
    headersForRows(){
      return this.headers.massive.filter(el => el.variable).map(el =>{
        return {...el,
          styles: (row) => {
            if (el.dataField !== 'npg')
              return row.uslName ? 'display: none;' : 'justify-content: unset; text-align: center; '
            else
              return ''
          },
          colsDataCalc: (row) => {
            if (el.dataField !== 'npg')
              return 1;
            else
              return row.uslName ? 8 : 1;
          },
          props: (row, cell, loading) => {
            return {
              value: row[cell.dataField],
              title: '',
              cell: cell,
              id: row.id,
              row: row,
              loading: loading,
            }
          }, }
      })
    },
  },
  created() {
    if (this.get_spr_data_usl_loading === false)
      this.loadingDataTable();
  },
  watch: {
    get_spr_data_usl_loading() {
      this.loadingDataTable();
    }
  },
  methods: {
    async openDialog(value, dataField = '', cell, row) {
      if (value === 'NA') return;
      this.appealValue = value;
      this.appealInfo = {
        ugs: ((row.spec ? row.spec.kodSpec : row.sprKcpGroup.code) + ' ' + (row.spec ? row.spec.name : row.sprKcpGroup.name)),
        kcpGroup: row.sprKcpGroup,
        cellInfo: this.findInfoForCell(dataField),
        rowId: row.id,
        orgId: row.sprOrgId
      }
      const res = this.appeal_data.data.filter(el => el.jsonId === dataField && el.org?.id === row.sprOrgId);
      this.appealsArr.push(...res);
      const item = this.appeal_data.data.find(el => el.jsonId === dataField && el.org.id === row.sprOrgId  && el.fileId);
      if (value?.id) {
        this.appealId = value.id;
        this.appealAccept = value.accept;
        this.fileId = item?.fileId;
      } else {
        if (item) {
          this.appealCell = item;
          this.appealId = null;
          this.fileId = item.fileId;
          this.appealAccept = item.appealAccept;
        }else{
          const itemWithoutFile = this.appeal_data.data.find(el => el.jsonId === dataField && el.org.id === row.sprOrgId);
          this.appealCell = itemWithoutFile;
          this.appealId = null;
          this.fileId = itemWithoutFile?.fileId;
          this.appealAccept = itemWithoutFile?.appealAccept;
        }
      }
      await this.$refs["appeal-dialog"].openDialog();
    },
    loadFullInfo(){
      this.full = !this.full;
      this.loadingDataTable()
    },
    async loadingDataTable() {
      this.loadingData  = true;
      try{
        this.serverData = await getFormData('getPart11', true, '', this.full);
        this.loadingData = false;
      }catch (e) {
        this.loadingData = false;
      }
    },
  },
}
</script>

<style scoped>

</style>