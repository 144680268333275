<template>
<router-view/>
</template>

<script>
export default {
  name: "lkLayout"
}
</script>

<style scoped>

</style>