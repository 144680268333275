<template>
  <div>

    <Alert :alertType="calcStatus(app).type" v-for="(app, index) in applicationsCreate" :key="index">
      <template v-slot:icon>
        <v-icon :size="30" color="#856404">mdi-map-marker-plus</v-icon>
      </template>
      <template v-slot:default>
        <div>
          Заявка на добавление филиала: {{ app.name }}
          <button class="ml-2 main-color text-decoration-underline"  @click="selectApplicationToShow(app)">подробно о
            заявке
          </button>
          <button class="ml-2 main-color text-decoration-underline" v-if="!simpleBlock && app.accept === null" @click="deleteApplication(app.id)">отменить или
            создать новую
          </button>
        </div>
        <div>Статус: {{calcStatus(app).text}}</div>
      </template>
    </Alert>

    <Alert :alertType="calcStatus(app).type" v-for="(app) in applicationsUpdate" :key="app.id">
      <template v-slot:icon>
        <v-icon :size="30" color="#856404">mdi-map-marker-plus</v-icon>
      </template>
      <template v-slot:default>
        <div>
          Заявка на редактирование филиала: {{ app.name }}
          <button class="ml-2 main-color text-decoration-underline" @click="selectApplicationToShow(app)">подробно о
            заявке
          </button>
          <button class="ml-2 main-color text-decoration-underline" v-if="!simpleBlock  && app.accept === null" @click="deleteApplication(app.id)">отменить или
            создать новую
          </button>
        </div>
        <div>Статус: {{calcStatus(app).text}}</div>
      </template>
    </Alert>

    <Alert :alertType="calcStatus(app).type" v-for="(app) in applicationsDelete" :key="app.id">
      <template v-slot:icon>
        <v-icon :size="30" color="#856404">mdi-map-marker-plus</v-icon>
      </template>
      <template v-slot:default>
        <div>
          Заявка на ликвидирование филиала: {{ app.name }}
          <button class="ml-2 main-color text-decoration-underline" @click="selectApplicationToShow(app)">подробно о
            заявке
          </button>
          <button class="ml-2 main-color text-decoration-underline" v-if="!simpleBlock  && app.accept === null" @click="deleteApplication(app.id)">отменить или
            создать новую
          </button>
        </div>
        <div>Статус: {{calcStatus(app).text}}</div>
      </template>
    </Alert>


    <v-dialog v-model="modalOpen" title="Подробно о заявке">
      <v-card class="pt-3">
        <v-card-text>
          <CardInfo :data="applicationToShow"/>
        </v-card-text>
      </v-card>

    </v-dialog>


    <div class="branch-offices">
      <div class="branch-offices-table" v-if="branchOffices">
        <div class="branch-offices-table__th">
          <div class="branch-offices-table__td">Название</div>
          <div class="branch-offices-table__td">Адрес</div>
          <div class="branch-offices-table__td">КПП</div>
          <div class="branch-offices-table__td" style="min-width: 350px">Действия</div>
        </div>
        <div v-for="(office, index) in branchOffices" :key="index" class="branch-offices-table__tr">
          <div class="branch-offices-table__td">{{ office.name }}</div>
          <div class="branch-offices-table__td">{{ office.address }}</div>
          <div class="branch-offices-table__td">{{ office.kpp }}</div>
          <div class="branch-offices-table__td" style="min-width: 350px">
            <div
                v-if="!applications.delete.find(el => el.id === office.id) && !applications.update.find(el => el.id === office.id) ">
              <v-btn v-if="!simpleBlock" class="error__v-btn mr-2"
                     @click="openDeleteBranchOffice(office)">Ликвидировать
              </v-btn>
              <v-btn v-if="!simpleBlock" class="blue__v-btn"
                     @click="editBranchOffice(office)">Редактировать
              </v-btn>
            </div>
            <div v-else>
              <span v-if="applications.delete.find(el => el.orgID === office.orgID)">Подана заявка на удаление</span>
              <span v-if="applications.update.find(el => el.orgID === office.orgID)">Подана заявка на изменение</span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <!--редактирование пока выключил нет бэка для него-->
      <FormKcpContestInfoBranchOffice v-if="!simpleBlock" v-model="newBranchOffice"
                                      :loading="loadingCreatingAppeal"
                                      v-on:input="addBranchOffice($event)"/>
      <v-dialog v-on:input="closeDeleteBranchOffice" v-model="modalDeleteOpen" title="Удаление филиала">
        <v-card class="pt-3">
          <v-card-text>
            <scan-card
                :item="file"
                class="mb-2"
                :upload-to-serv-now="loadingFileToServer.fileType === file.fileType && loadingFileToServer.value"
                @replace-file="replaceFile($event)"
                @upload-file="uploadFile($event)"
                :token="token"
                identifier="fileType"
            />
            <v-btn type="submit" class="blue__v-btn" @click.prevent="createApplicationToDelete()">Заявка на
              ликвидирование
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-on:input="closeEditBranchOffice" v-model="modalEditOpen">
        <v-card class="pt-3">
          <v-card-title>
            Редактировать филиал
          </v-card-title>
          <v-card-text>
            <v-progress-linear
                v-if="!editedBranchOffice"
                indeterminate
                color="#00599b"
            ></v-progress-linear>
            <FormKcpContestInfoOrganizationSub v-else v-model="editedBranchOffice" @cancel="closeEditBranchOffice"
                                               v-on:input="requestUpdateBranchOffice($event)"/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex';
import Alert from '@/components/alerts/Alert';
import request from "@/services/request";
import CardInfo from '@/components/cards/CardInfo';
import FormKcpContestInfoBranchOffice from '@/components/forms/custom/formsKcpContestInfo/FormKcpContestInfoBranchOffice';
import FormKcpContestInfoOrganizationSub from '@/components/forms/custom/formsKcpContestInfo/FormKcpContestInfoOrganizationSub';
import {deleteAppealOrgInfo, getAppealOrgInfo, sendAppealORgInfo} from "@/API/lkOrganization";
import {setFileFunctions} from "@/mixins/fileFucntions";
import ScanCard from "@/components/cards/scanCard";

export default {
  mixins: [setFileFunctions],
  components: {
    ScanCard,
    FormKcpContestInfoBranchOffice,
    FormKcpContestInfoOrganizationSub,
    Alert,
    CardInfo
  },
  data() {
    return {
      isWaitingForConfirmation: false,
      applicationToShow: null,
      branchOffices: [],
      file: {
        name: 'Подтверждающие документы',
        types: ['application/pdf'],
        id: null,
        fileType: 'ORG_APPEAL'
      },
      branchOfficeToDelete: null,
      newBranchOffice: {
        orgName: '',
        address: '',
        founderName: '',
        kpp: '',
        file: null,
      },
      modalOpen: false,
      modalEditOpen: false,
      loadingCreatingAppeal: false,
      editedBranchOffice: null,
      initialEditedBranchOffice: null,
      modalDeleteOpen: false,
      applications: {
        create: [],
        delete: [],
        update: [],
      },
      organization: null,
    }
  },
  computed: {
    // ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('checkFilled' , { simpleBlock: 'simpleBlock'}),
    applicationsCreate(){
      return this.applications.create
    },
    applicationsUpdate(){
      return this.applications.update
    },
    applicationsDelete(){
      return this.applications.delete
    },

  },
  created() {
    this.getInfoOrganization();
    this.getAllApplicationsByUser();
  },
  mounted() {
    this.getBranchOffices();
  },
  methods: {
    calcStatus(app){
      switch ( app.accept) {
        case true:{
          return {text: 'Одобрена', type: 'success'};
        }
        case false:{
          return {text: 'Отклонена', type: 'error'};
        }
        case null:{
          return {text: 'На рассмотрении', type: 'warning'};
        }
      }
    },
    async getInfoOrganization() {
      let organization = await request({
        endpoint: `/api/spr/getOrgById`,
        method: 'GET',
      });
      if (organization.data) {
        organization = organization.data;
        for (let key in organization) {
          if (organization[key] === null) {
            organization[key] = '';
          }
        }
        this.organization = {
          inn: organization.inn,
          kpp: organization.kpp,
          orgAddress: organization.address,
          orgFullName: organization.nameFull,
          orgName: organization.name,
          // founderName: organization.founderName,
          orgShortName: organization.nameShort,
          orgFounderName: organization.orgFounder.name,
          sprOrgType: organization?.name,
          ...organization
        };
      }

    },
    async requestUpdateBranchOffice(e) {
      const objToSend = {
        "org": {
          "id": e.id,
        },
        "owner": {
          "id": e.owner.id,
        },
        "orgFounder": {
          "id": e.orgFounder.id,
          "name": "string"
        },
        "nameShort": e.orgShortName,
        "name": e.orgName,
        // "founderName": e.founderName,
        "nameFull": e.orgFullName,
        "address": e.orgAddress,
        "inn": this.organization.inn,
        "kpp": e.kpp,
        "appealType": "FILIAL_ORG_UPDATE",
        "fileId": e.file.id
      }
      try {
        const res = await sendAppealORgInfo(objToSend);
        this.applications.update.push(res);
        this.editedBranchOffice.file = null;
        this.modalEditOpen = false;
      } catch (e) {
        this.editedBranchOffice.file = null;
        this.modalEditOpen = false;
      }

    },

    closeDeleteBranchOffice() {
      this.modalDeleteOpen = false;
      this.branchOfficeToDelete = null;
    },

    selectApplicationToShow(app) {
      this.modalOpen = !this.modalOpen;
      this.applicationToShow = {
        'Название': app.name,
        'Полное Название': app.nameFull,
        'Короткое Название': app.nameShort,
        // 'Учредитель':  app.founderName,
        'Адрес': app.address,
        'КПП': app.kpp,
      };
    },
    async getAllApplicationsByUser() {
      try {
        const res = await getAppealOrgInfo();
        if (res) {
          const applicationActive = res.filter(el => el.appealType !== 'MAIN_ORG');
          this.$set(this.applications, 'create', applicationActive.filter(el => el.appealType === 'FILIAL_ORG_CREATE'  ));
          this.$set(this.applications, 'delete', applicationActive.filter(el => el.appealType === 'FILIAL_ORG_DELETE' ));
          this.$set(this.applications, 'update', applicationActive.filter(el => el.appealType === 'FILIAL_ORG_UPDATE' ));
          // this.applications.delete = applicationActive.filter(el => el.appealType === 'FILIAL_ORG_DELETE');
          // this.applications.update = applicationActive.filter(el => el.appealType === 'FILIAL_ORG_UPDATE');
        }
      } catch (e) {
        console.error('getAllApplicationsByUser')
      }
    },
    async deleteApplication(id) {
      try {
        const res = await deleteAppealOrgInfo(id);
        if (res) {
          await this.getAllApplicationsByUser();
        }
      } catch (e) {
        console.error('deleteApplication')
      }
    },
    async getBranchOffice(organization) {
      this.editedBranchOffice = {
        inn: organization.inn,
        kpp: organization.kpp,
        orgAddress: organization.address,
        orgFullName: organization.nameFull,
        orgName: organization.name,
        // founderName: organization.founderName,
        orgShortName: organization.nameShort,
        orgFounderName: organization.orgFounder.name,
        sprOrgType: organization?.name,
        ...organization
      };
      this.initialEditedBranchOffice = {
        inn: organization.inn,
        kpp: organization.kpp,
        orgAddress: organization.address,
        orgFullName: organization.nameFull,
        orgName: organization.name,
        // founderName: organization.founderName,
        orgShortName: organization.nameShort,
        orgFounderName: organization.orgFounder.name,
        sprOrgType: organization?.name,
        ...organization
      };
    },

    editBranchOffice(org) {
      this.modalEditOpen = !this.modalEditOpen;
      this.getBranchOffice(org);
    },

    async getBranchOffices() {
      let branchOffices = await request({
        endpoint: `/api/spr/getChildByOwnerId`,
        method: 'GET',
      });
      if (branchOffices.data) {
        this.branchOffices = branchOffices.data.filter(el => el.orgStatus.id === 1);
      }

    },
    closeEditBranchOffice() {
      this.modalEditOpen = false;
      this.editedBranchOffice = null;
      this.initialEditedBranchOffice = null;
    },
    openDeleteBranchOffice(org) {
      this.branchOfficeToDelete = org;
      this.modalDeleteOpen = !this.modalDeleteOpen;
    },
    async createApplicationToDelete(e = this.branchOfficeToDelete) {
      const objToSend = {
        "org": {
          "id": e.id,
        },
        "owner": {
          "id": e.owner.id,
        },
        "orgFounder": {
          "id": e.orgFounder.id,
          "name": "string"
        },
        "nameShort": e.nameShort,
        "name": e.name,
        "nameFull": e.nameFull,
        // "founderName": e.founderName,
        "address": e.address,
        "inn": this.organization.inn,
        "kpp": e.kpp,
        "appealType": "FILIAL_ORG_DELETE",
        "fileId": this.file?.id
      }
      if (this.file.id){
        try {
          const res = await sendAppealORgInfo(objToSend);
          this.applications.delete.push(res);
          this.branchOfficeToDelete = null;
          this.file.id = null;
          this.modalDeleteOpen = false;
        } catch (e) {
          this.branchOfficeToDelete = null;
          this.file.id = null;
          this.modalDeleteOpen = false;
        }
      }
    },
    async addBranchOffice(e) {
      const objToSend = {
        "org": {
          "id": this.organization.id,
        },
        "owner": {
          "id": this.organization.owner.id,
        },
        "orgFounder": {
          "id": this.organization.orgFounder.id,
          "name": "string"
        },
        "nameShort": e.orgShortName,
        "name": e.orgName,
        // "founderName": e.founderName,
        "nameFull": e.orgFullName,
        "address": e.orgAddress,
        "inn": this.organization.inn,
        "kpp": e.kpp,
        "appealType": "FILIAL_ORG_CREATE",
        "fileId": e.file.id
      }
      try {
        this.loadingCreatingAppeal = true;
        const res = await sendAppealORgInfo(objToSend);
        this.applicationActive = true;
        this.applications.create.push(res);
        this.newBranchOffice.file = null;
        this.newBranchOffice.orgName = '';
        this.newBranchOffice.address = '';
        this.newBranchOffice.founderName = '';
        this.newBranchOffice.kpp = '';
        this.editMode = false;
        this.loadingCreatingAppeal = false;
      } catch (e) {
        this.newBranchOffice.file = null;
        this.newBranchOffice.orgName = '';
        this.newBranchOffice.address = '';
        this.newBranchOffice.founderName = '';
        this.newBranchOffice.kpp = '';
        this.editMode = false;
        this.loadingCreatingAppeal = false;
      }
    },

  },
}
</script>


<style lang="sass">
.branch-offices-table__th
  display: flex
  font-weight: 600
  color: #00599b
  margin-bottom: 5px

.branch-offices-table__tr
  display: flex
  justify-content: space-between

.branch-offices-table__td
  flex-basis: 0
  flex-grow: 1
  display: flex

.branch-offices-table__tr:nth-of-type(2n)
  background: #e3effb

.branch-offices-table__tr
  padding: 10px 5px
  color: #00599b
  border-radius: 5px

.branch-offices-table__action
  color: #eb8e1c
  margin: 0px 5px
  text-decoration: underline

.branch-offices-table__action:hover
  text-decoration: none
</style>