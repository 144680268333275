var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"blue__v-btn mb-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Назад")]),_vm._v(" "),_c('span',{staticClass:"formul-text-table"},[_vm._v(_vm._s(_vm.nameOrgForRegion))]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"header-columns mr-11",attrs:{"cols":"5"}},[_vm._v(" Показатели ")]),_c('v-col',{staticClass:"header-columns",attrs:{"cols":"5"}},[_vm._v(" Балльная оценка ")]),_c('v-col')],1),(!_vm.loading)?[_c('card-indicator',{attrs:{"item":{
          title: 'Сводная оценка заявки',
          about: 'Сумма баллов по всем показателям',
          count: _vm.middleValue,
          dataFieldName: 'generalValue',
          max: _vm.indicators.iowgt22,
          maxGradient: _vm.indicators.iowgt22,
          available: true,
          typeStyle: 'general',
          idUsl: _vm.idUsl
        },"row-of-values":_vm.indicators},on:{"appeal-indicator":function($event){return _vm.openAppeal($event)}}}),_vm._l((_vm.bIndicatorsCalculated),function(b,index){return _c('card-indicator',{key:index,attrs:{"item":b,"loading":_vm.get_spr_data_pokazateli_loading,"row-of-values":_vm.indicator},on:{"appeal-indicator":function($event){return _vm.openAppeal($event)}}})})]:[_c('div',{staticClass:"d-flex flex-row align-center justify-center",staticStyle:{"min-height":"200px"}},[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1)],_c('appeal-indicators',{ref:"appeal-dialog",attrs:{"appeal":_vm.appeal,"loading":_vm.laodingAppeals,"appeal-info":_vm.appealInfo},on:{"saved-value":function($event){return _vm.saveValue($event)},"closing-dialog":_vm.closingAppealDialog}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }