<template>
  <div>
    <v-list>
      <v-list-item dense v-for="item in formsFiltered" :key="item.formName" :to="
    '/forms/' + item.formName" class="fifth-forms-list">
        {{ item.text }}
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ListForms",
  data() {
    return {
      forms: [
        {
          formName: 'form-1a',
          text: 'Форма 5.01а. Численность научно-педагогических работников и доходы образовательной организации',
          typeOrg: 1
        },
        {
          formName: 'form-1b',
          text: 'Форма 5.01б. Численность научно-педагогических работников и доходы организации дополнительного профессионального образованния',
          typeOrg: 3
        },
        {
          formName: 'form-1c',
          text: 'Форма 5.01в. Численность научно-педагогических работников и доходы научной организации',
          typeOrg: 2
        },
        {
          formName: 'form-2',
          text: 'Форма 5.02. Количество публикаций в научных журналах, индексируемых в реферативно-библиографических базах научного цитирования  '
        },
        {
          formName: 'form-3',
          text: 'Форма 5.03. Общая численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры'
        },
        {
          formName: 'form-4',
          text: 'Форма 5.04. Общая численность обучающихся по программам ординатуры, программам ассистентуры-стажировки, программам подготовки научных и научно-педагогических кадров в аспирантуре'
        },
        {
          formName: 'form-5',
          text: 'Форма 5.05. Сведения о наличии профессионально-общественной аккредитации '
        },
        {
          formName: 'form-6',
          text: 'Форма 5.06. Контрольные цифры приема, установленные образовательной организации'
        },
        {
          formName: 'form-7',
          text: 'Форма 5.07. Численность принятых на обучение за счет бюджетных ассигнований федерального бюджета'
        },
        {
          formName: 'form-8',
          text: 'Форма 5.08. Численность обучающихся по программам бакалавриата, программам специалитета, программам магистратуры'
        },
        {
          formName: 'form-9',
          text: 'Форма 5.09. Численность лиц с ограниченными возможностями здоровья, инвалидов, детей-инвалидов, обучающихся по программам бакалавриата, программам специалитета, программам магистратуры\n'
        },
        {
          formName: 'form-10',
          text: 'Форма 5.10. Численность принятых на обучение и завершивших обучение по программам бакалавриата, программам специалитета, программам магистратуры\n'
        }, {
          formName: 'form-11',
          text: 'Форма 5.11. Численность обучающихся по договорам о целевом обучении по программам бакалавриата, программам специалитета, программам магистратуры\n'
        }, {
          formName: 'form-12a',
          text: 'Форма 5.12a. Численность иностранных граждан и лиц без гражданства, завершивших обучение в 2017-2019 годах по программам бакалавриата, программам специалитета\n'
        }, {
          formName: 'form-12b',
          text: 'Форма 5.12б. Численность иностранных граждан и лиц без гражданства, завершивших обучение в 2017-2019 годах по программам бакалавриата, программам специалитета\n'
        }, {
          formName: 'form-13',
          text: 'Форма 5.13. Дополнительные сведения о приеме на очную форму обучения по программам бакалавриата и специалитета\n'
        }, {
          formName: 'form-14',
          text: 'Форма 5.14. Дополнительные сведения о приеме на очную форму обучения по программам магистратуры'
        }, {
          formName: 'form-15',
          text: 'Форма 5.15. Численность обучающихся по программам ординатуры, программам ассистентуры-стажировки\n'
        }, {
          formName: 'form-16',
          text: 'Форма 5.16. Численность принятых на обучение и завершивших обучение по программам ординатуры, программам ассистентуры-стажировки\n'
        }, {
          formName: 'form-17',
          text: 'Форма 5.17. Дополнительные сведения о целевом обучении и о приеме на очную форму обучения по программам ординатуры, программам ассистентуры-стажировки\n'
        }, {
          formName: 'form-18',
          text: 'Форма 5.18. Численность обучающихся по программам подготовки научных и научно-педагогических кадров в аспирантуре\n'
        }, {
          formName: 'form-19',
          text: 'Форма 5.19. Численность принятых на обучение и завершивших обучение по программам подготовки научных и научно-педагогических кадров в аспирантуре\n'
        }, {
          formName: 'form-20',
          text: 'Форма 5.20. Дополнительные сведения о количестве защищенных диссертаций на соискание ученой степени кандидата наук и выпуске закончивших обучение по программам подготовки научных и научно-педагогических кадров в аспирантуре\n'
        }, {
          formName: 'form-21',
          text: 'Форма 5.21. Численность прошедших обучение по программам дополнительного профессионального образования\n'
        },

      ]
    }
  },
  computed: {
    ...mapGetters('checkFilled', {getMainOrg:'getMainOrg'}),
    formsFiltered() {
      return this.forms.filter(el => (el?.typeOrg && el.typeOrg === this.getMainOrg.data?.orgType?.id) || !el.typeOrg)
    }
  },
}
</script>

<style lang="sass">
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  &.fifth-forms-list
    color: #00599b!important
    &:hover
      color: #eb8e1c!important
      text-decoration: underline


</style>