import {mapGetters} from "vuex";

export const headerTooltipFunctions = {
    data() {
        return {
            dataFieldTooltip: null,
            tooltip: null,
        }
    },
    computed: {
        ...mapGetters('spr', {
            get_spr_data_indicators_loading: 'get_spr_data_indicators_loading',
            get_spr_data_indicators_error: 'get_spr_data_indicators_error',
            get_spr_data_indicators: 'get_spr_data_indicators',
        }),
    },
    methods:{
        calcHeaderName(name) {
            return 'header-' + name;
        },
        async openTooltip(dataField = '') {
            this.dataFieldTooltip = dataField;
            await this.$refs["tooltip-dialog"].openDialog();
        },
        findInfoForCell(dataField = ''){
            if (this.get_spr_data_indicators_error == null && this.get_spr_data_indicators_loading === false){
                return this.get_spr_data_indicators.data.find(el => el.jsonId === dataField);
            }
        },
    }
}