<template>
    <div>
      <v-row dense>
        <v-col cols="10">
          <h2 class="b-indicator__collapse-title">1. Значение показателя</h2>
          <h3 class="b-indicator__collapse-subtitle">Исходные данные:</h3>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col >
          <p>
            <span class="font-weight-bold mb-1">Форма 5.10. (фрагмент)</span><br>
            Численность принятых на обучение и завершивших обучение по программам бакалавриата, программам специалитета, программам магистратуры
            без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации
            по данным формы ВПО-1, человек
          </p>
          <table>
            <thead>
            <tr>
              <th rowspan="3" colspan="3" class="kcp-td text-left">Код и наменование УГСН (НПС)</th>
              <th colspan="2">Принятых на обучение<br>
                (искл. квоту иностр.граждан)
              </th>
              <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
            </tr>
            <tr>
              <th colspan="1">
                на 1.10.2021 г.
              </th>
              <th></th>
            </tr>
            <tr>
              <th>
                очно
              </th>
              <th>
                ...
              </th>
            </tr>
            <tr style="border-top: 1px solid #B7CED9">
              <th colspan="3" style="text-align: left">
                <span>1</span><span style="margin-left: 66px">2</span>
              </th>
              <th >3</th>
              <th >...</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="3" style="text-align: left">
                <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
                <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
              </td>
              <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsgo21, 0, 'integer') }}</td>
              <td>...</td>
            </tr>
                  <tr>
                    <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
                    <td colspan="1" class="border-bottom-zero border-right-zero">
                      <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
                    </td>
                  </tr>
            <tr>
              <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
              <td class="border-bottom-zero border-right-zero formul-text-table">
                П
              </td>
            </tr>
            </tbody>
          </table>
        </v-col>
        <v-col>
          <p>
            <span class="font-weight-bold mb-1">Форма 5.11. (фрагмент)</span><br>
            Численность обучающихся по договорам о целевом обучении по программам бакалавриата, программам специалитета, программам магистратуры
            без учета иностранных граждан и лиц без гражданства, в том числе соотечественников, проживающих за рубежом, обучающихся в соответствии с установленной Правительством Российской Федерации квотой на образование иностранных граждан и лиц без гражданства в Российской Федерации
            по данным формы ВПО-1, человек
          </p>
          <table>
            <thead>
            <tr>
              <th rowspan="3" colspan="3" class="kcp-td text-left">Код и наменование УГСН (НПС)</th>
              <th></th>
              <th colspan="1">Принято <br/>по цел.квоте</th>
              <!--        <th colspan="3">Средний бал ЕГЭ</th>-->
            </tr>
            <tr>
              <th></th>
              <th colspan="1">
                на 1.10.2021 г.
              </th>
            </tr>
            <tr>
              <th>
                ...
              </th>
              <th>
                очно
              </th>
            </tr>
            <tr style="border-top: 1px solid #B7CED9">
              <th colspan="3" style="text-align: left">
                <span>1</span><span style="margin-left: 66px">2</span>
              </th>
              <th>...</th>
              <th>9</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="3" style="text-align: left">
                <span class="kcp-code">{{ rowOfValues.data.kcpGroup.code }}</span>
                <span class="kcp-name">{{ rowOfValues.data.kcpGroup.name }}</span>
              </td>
              <td>...</td>
              <td>{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npcqo21, 0, 'integer') }}</td>
            </tr>
            <tr>
              <td class="border-bottom-zero border-right-zero" v-for="(item) in 3" :key="item"></td>
              <td class="border-bottom-zero border-right-zero"></td>
              <td colspan="1" class="border-bottom-zero border-right-zero">
                <img class="ww-100" :src="require('@/assets/images/brackets/Скобка_general_value_bot.svg')" alt=""/>
              </td>
            </tr>
            <tr>
              <td class="border-bottom-zero border-right-zero" v-for="(item) in 4" :key="item"></td>
              <td class="border-bottom-zero border-right-zero formul-text-table">
                ЦП
              </td>
            </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <h3 class="b-indicator__collapse-subtitle mt-5">Расчет значения показателя:</h3>
      <div class="d-flex flex-row align-center">
        <formuls-fraction :need-bottom-line="true">
          <template>
            <div class="d-flex flex-row align-end">
              {{ item.title }}
              <div class="little-text ">0</div>
            </div>
          </template>
          <template #bottom>значение</template>
        </formuls-fraction>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8"> = </span>
          </template>
        </formuls-fraction>
        <formuls-fraction-simple>
          <template>
            ЦП
          </template>
          <template #bottom>
            <span class="formul-text" style="  min-height: 49px">П</span>
          </template>
        </formuls-fraction-simple>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8"> = </span>
          </template>
        </formuls-fraction>
        <formuls-fraction-simple>
          <template>
            {{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npcqo21, 0, 'integer') }}
          </template>
          <template #bottom>
            <span class="formul-text" style="  min-height: 49px">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data.npsgo21, 0, 'integer') }}</span>
          </template>
        </formuls-fraction-simple>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8"> = </span>
          </template>
        </formuls-fraction>
        <formuls-fraction :need-bottom-line="false">
          <template>
            <span class="mt-8">{{ $scripts.TableJs.checkValueWithNullAndReturnND(rowOfValues.data[item.dataFieldName+'b022'], item.prd+1, 'float') }}</span>
          </template>
        </formuls-fraction>
      </div>
      <p>
        ЦП	-	численность студентов, принятых на обучение в рамках квоты приема на целевое обучение по очной форме обучения в отчетном году;
      </p>
      <p class="mb-5">
        П	-	общая численность студентов, принятых на обучение по очной форме обучения в отчетном году (искл. квоту иностранных граждан).
      </p>
    </div>
</template>

<script>
    export default {
        name: "b13",
        props: {
            item: {
                type: Object
            },
            rowOfValues: {
                type: Object,
                default: () => {
                }
            },
        },
        methods: {
            checkData(value) {
                return value;
                // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value.value,'float',value.rnd,false, 'color: #C6C8CB;')
            }
        },
    }
</script>

<style lang="sass" scoped>
    .indicators__table
        //position: relative
        //display: block
        //border-radius: 5px
        width: 100%

    .indicator-thead
        th
            text-align: center
            &.border
                background-color: inherit
                border: 1px solid #546e7a
                padding: 5px
            &.min
                min-width: 40px
            &.border_bottom
                border-bottom: 1px solid #546e7a
    .indicator-tbody
        td
            &.border
                background-color: inherit
                border: 1px solid #546e7a
                padding: 5px
            &.min
                min-width: 40px
            img
                height: 260px
                margin-right: 0px
                width: 40px
                position: absolute
            &.text
                &_left
                    font-weight: bold
                    vertical-align: middle
                    text-align: left
                &_right
                    font-weight: bold
                    vertical-align: middle
                    text-align: right
                &_center
                    vertical-align: middle
                    text-align: center

    .grid-container__header
        display: grid
        //grid-template-rows: 1fr 1fr
        div
            border: 1px solid black
            text-align: center
        //grid-auto-rows: 50px
        grid-gap: 3px
        grid-template-columns: 25px 1fr 1fr 1fr 1fr 1.5fr 2fr

        .grid-row-span-2
            grid-column: 1
            grid-row: 1/3

        &_inner
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 2px
</style>